import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Button } from 'antd';

import StandardContainer from '../../../../../shared/components/otop/StandardContainer';
import CardWrapper from '../../../../../shared/components/otop/CardWrapper';
import TableCategory from '../components/TableCategory';
import { getCategoryList, deleteCategory } from '../actions';

export default function LandingCategory() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { categories, loading } = state.category;

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg my-5">
          <CardWrapper>
            <Card className="card">
              <div className="flex justify-between card-content">
                <p>Product categories are listed here</p>
                <Link to="/otop-hub/admin/categories/add">
                  <Button className="flex items-center">
                    <i className="fas fa-edit mr-2 text-lg"></i>
                    Add Category
                  </Button>
                </Link>
              </div>
              <div className="card-content">
                <TableCategory
                  categories={categories}
                  loading={loading}
                  getCategoryList={getCategoryList}
                  deleteCategory={deleteCategory}
                />
              </div>
            </Card>
          </CardWrapper>
        </div>
      </div>
    </StandardContainer>
  );
}
