import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_LIVE_LIST,
  API_LIVE_CREATE,
  API_LIVE_READ,
  API_LIVE_UPDATE,
  API_LIVE_DELETE,
} from "../../shared/constant/url";

export const LIVE = {
  LIVE_LIST_START: "LIVE_LIST_START",
  LIVE_LIST_SUCCESS: "LIVE_LIST_SUCCESS",
  LIVE_LIST_ERROR: "LIVE_LIST_ERROR",

  LIVE_READ_START: "LIVE_READ_START",
  LIVE_READ_SUCCESS: "LIVE_READ_SUCCESS",
  LIVE_READ_ERROR: "LIVE_READ_ERROR",

  LIVE_ADD_EDIT_START: "LIVE_ADD_EDIT_START",
  LIVE_ADD_EDIT_SUCCESS: "LIVE_ADD_EDIT_SUCCESS",
  LIVE_ADD_EDIT_ERROR: "LIVE_ADD_EDIT_ERROR",

  LIVE_DELETE_START: "LIVE_DELETE_START",
  LIVE_DELETE_SUCCESS: "LIVE_DELETE_SUCCESS",
  LIVE_DELETE_ERROR: "LIVE_DELETE_ERROR",

};

export const getLiveList = () => {
  return (dispatch) => {
    dispatch({ type: LIVE.LIVE_LIST_START });
    let formData = new FormData();

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_LIVE_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: LIVE.LIVE_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LIVE.LIVE_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readLive = (id) => {
  return (dispatch) => {
    dispatch({ type: LIVE.LIVE_READ_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_LIVE_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: LIVE.LIVE_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LIVE.LIVE_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createLive = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: LIVE.LIVE_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("start_at", params.start_at);
    formData.append("end_at", params.end_at);
    formData.append("link_type", params.link_type);
    formData.append("link", params.link);
    formData.append("description", params.description);
 
    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_LIVE_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: LIVE.LIVE_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: LIVE.LIVE_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editLive = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: LIVE.LIVE_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("start_at", params.start_at);
    formData.append("end_at", params.end_at);
    formData.append("link_type", params.link_type);
    formData.append("link", params.link);
    formData.append("description", params.description);
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_LIVE_UPDATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: LIVE.LIVE_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: LIVE.LIVE_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteLive = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: LIVE.LIVE_DELETE_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_LIVE_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: LIVE.LIVE_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: LIVE.LIVE_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};