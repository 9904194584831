import { getAuthHeader, getError } from "../../shared/utils";
import {
    API_EXHIBITOR_LIST_PAYMENT,
    API_EXHIBITOR_CREATE_PAYMENT,
    API_EXHIBITOR_UPDATE_PAYMENT,
    API_EXHIBITOR_DELETE_PAYMENT
} from "../../shared/constant/url";

export const PAYMENT = {
  LIST_CREATE_START: "LIST_CREATE_START",
  LIST_CREATE_SUCCESS: "LIST_CREATE_SUCCESS",
  LIST_CREATE_ERROR: "LIST_CREATE_ERROR",

  LIST_UPDATE_START: "LIST_UPDATE_START",
  LIST_UPDATE_SUCCESS: "LIST_UPDATE_SUCCESS",
  LIST_UPDATE_ERROR: "LIST_UPDATE_ERROR",

  LIST_DELETE_START: "LIST_DELETE_START",
  LIST_DELETE_SUCCESS: "LIST_DELETE_SUCCESS",
  LIST_DELETE_ERROR: "LIST_DELETE_ERROR",

  LIST_PAYMENT_OPTION_START: "LIST_PAYMENT_OPTION_START",
  LIST_PAYMENT_OPTION_SUCCESS: "LIST_PAYMENT_OPTION_SUCCESS",
  LIST_PAYMENT_OPTION_ERROR: "LIST_PAYMENT_OPTION_ERROR",
};


export const getPaymentOptionList = (per_page) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT.LIST_PAYMENT_OPTION_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    }
    formData.append("include", 'exhibitor');

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_LIST_PAYMENT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: PAYMENT.LIST_PAYMENT_OPTION_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: PAYMENT.LIST_PAYMENT_OPTION_ERROR, payload: error });
      });
  };
};


export const createPayment = (params, callback=null) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT.LIST_CREATE_START });
    let formData = new FormData();
    formData.append("name", params.name);
    formData.append("status", params.status);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_CREATE_PAYMENT, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PAYMENT.LIST_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PAYMENT.LIST_CREATE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deletePayment = (id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT.LIST_DELETE_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_DELETE_PAYMENT, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PAYMENT.LIST_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, error);
        dispatch({
          type: PAYMENT.LIST_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const updatePayment = (params, id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT.LIST_UPDATE_START });
    let formData = new FormData();
    formData.append("id", id);
    if(params.name){
      formData.append("name", params.name);
    }

    if(params.status === '1' || params.status === '0'){
      formData.append("status", params.status);
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_UPDATE_PAYMENT, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PAYMENT.LIST_UPDATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PAYMENT.LIST_UPDATE_ERROR,
          payload: getError(error),
        });
      });
  };
};

