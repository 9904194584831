import { AUTH } from '../auth/actions';

const INITIAL_STATE = {
    loading: false, 
    error: '', 
    user: {}, 
    permission: [],
};

const admin = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case AUTH.LOGIN_START:
            return { ...state, loading: true, error: '' }
        case AUTH.LOGIN_SUCCESS:
            let user = action.payload;
            console.log("users", user)
            return { ...state, loading: false, user: action.payload }
        case AUTH.LOGIN_ERROR:
            return { ...state, loading: false, error: ''}
        default: 
            return state;
    }
}

export default admin;