import React from 'react';
import { Form } from 'antd';
import { getOtopProductList } from '../../products/actions';
import { useDispatch } from 'react-redux';

const FormSearch = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(getOtopProductList(values));
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="searchProducts"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="search-container">
        <Form.Item name="q">
          <input placeholder="Search in OTOP HUB" />
        </Form.Item>
        <button htmltype="submit" className="bg-brown-10">
          <i className="fas text-black px-3 fa-search"></i>
        </button>
      </div>
    </Form>
  );
};

export default FormSearch;
