import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, notification, Upload, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from './Avatar';
import FormWrapper from '../../../shared/components/FormWrapper';
import avatar from '../../../shared/assets/images/avatar.png';
// import regions from '../../../shared/data/region.json';
var regions = require('../../../shared/data/region.json');
var provinces = require('../../../shared/data/province.json');

function FormProfile(props) {
  const {
    profileDetails,
    profileDetailsExhibitor,
    updateProfile,
    updatePassword,
    updatePhoto,
    updateProfileExhibitor,
    updatePasswordExhibitor,
    updatePhotoExhibitor,
    path,
    readProfile,
    readProfileExhibitor,
    industries,
  } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [prov_id, setProvId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [provinceList, setProvinceList] = useState([]);
  const isExhibitor = localStorage.getItem('isExhibitor');
  const { Option } = Select;
  const [isDisabled, setIsDisabled] = useState(false);

  // const renderRegions = () => {
  //   let sortedRegions = regions.RECORDS.sort((a, b) =>
  //     a.regDesc > b.regDesc ? 1 : -1
  //   );

  //   return sortedRegions.map((region) => (
  //     <Option value={region.regDesc} key={region.regCode}>
  //       {region.regDesc}
  //     </Option>
  //   ));
  // };

  useEffect(() => {
    findRegId();
  }, [profileDetailsExhibitor]);

  const findRegId = () => {
    let regCode = profileDetailsExhibitor?.business_info?.region;
    const region = regions.RECORDS.find((item) => item.code === regCode);
    if (region) findProvId(region.regCode);
  };

  const findProvId = (regId) => {
    const province = provinces.RECORDS.find((item) => item.regCode === regId);
    if (province) setProvId(province.id);
  };

  // const cascadeProvince = (val, option) => {
  //   let regCode = option.key;
  //   let provinceList = filter(
  //     provinces.RECORDS,
  //     (item) => item.regCode === regCode
  //   );
  //   setProvinceList(provinceList);
  // };

  // const renderProvinces = () => {
  //   let sortedProvinces = provinceList.sort((a, b) =>
  //     a.provDesc > b.provDesc ? 1 : -1
  //   );

  //   return sortedProvinces.map((prov) => (
  //     <Option value={prov.id.toString()} key={prov.id}>
  //       <p onClick={() => setProvId(prov.id.toString())}>{prov.provDesc}</p>
  //     </Option>
  //   ));
  // };

  useEffect(() => {
    window.scrollTo(0, 0);

    const userDetails =
      isExhibitor === 'false' ? profileDetails : profileDetailsExhibitor || {};

    let profileDetailsProps = {};

    if (isExhibitor === 'false') {
      profileDetailsProps = {
        firstname: userDetails?.firstname ?? '',
        lastname: userDetails?.lastname ?? '',
        email: userDetails?.email ?? '',
        address: userDetails?.address ?? '',
      };
    } else {
      profileDetailsProps = {
        company_name: userDetails?.company_name ?? '',
        business_description: userDetails?.business_info?.description ?? '',
        address: userDetails?.business_info?.address ?? '',
      };
    }

    form.setFieldsValue({
      ...profileDetailsProps,
      contact_number: userDetails?.contact_number ?? '',
      fb_link: userDetails?.fb_link ?? '',
      twitter_link: userDetails?.twitter_link ?? '',
      // gmail_link: userDetails?.gmail_link ?? '',
      instagram_link: userDetails?.instagram_link ?? '',
    });
  }, [profileDetails, profileDetailsExhibitor]);

  const onFinish = (values) => {
    if (values.file) {
      values.file.fileList = [values.file.file];
    }

    if (new RegExp('/profile/:id/edit-password').test(path)) {
      if (values.current_password === values.password) {
        notification.error({
          message: 'New password cannot be the same as your current password.',
          duration: '5',
        });
        return false;
      }
    }

    switch (path) {
      case '/participant/profile/:id/edit':
        dispatch(updateProfile(values, callback));
        break;
      case '/participant/profile/:id/edit-password':
        dispatch(updatePassword(values, callback));
        break;
      case '/participant/profile/:id/edit-avatar':
        dispatch(updatePhoto(values, callback));
        break;
      case '/exhibitor/profile/:id/edit':
        values['terms_condition'] = profileDetailsExhibitor.terms_condition;
        values['refund_policy'] = profileDetailsExhibitor.refund_policy;
        values['business_info'] = {
          ...profileDetailsExhibitor['business_info'],
          ...values['business_info'],
        };
        values = {
          ...profileDetailsExhibitor,
          ...values,
          region: profileDetailsExhibitor?.business_info?.region,
        };
        dispatch(updateProfileExhibitor(values, prov_id, callback));
        break;
      case '/exhibitor/profile/:id/edit-password':
        dispatch(updatePasswordExhibitor(values, callback));
        break;
      case '/exhibitor/profile/:id/edit-avatar':
        dispatch(updatePhotoExhibitor(values, callback));
        break;
      default:
        dispatch(null);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    // console.log("Response here", error)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'INVALID_DATA') {
        if (response.errors.firstname) {
          form.setFields([
            {
              name: 'firstname',
              errors: [`${response.errors.firstname}`],
            },
          ]);
        }
        if (response.errors.lastname) {
          form.setFields([
            {
              name: 'lastname',
              errors: [`${response.errors.lastname}`],
            },
          ]);
        }
        if (response.errors.company_name) {
          form.setFields([
            {
              name: 'company_name',
              errors: [`${response.errors.company_name}`],
            },
          ]);
        }
        if (response.errors.business_description) {
          form.setFields([
            {
              name: 'business_description',
              errors: [`${response.errors.business_description}`],
            },
          ]);
        }
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
        if (response.errors.address) {
          form.setFields([
            {
              name: 'address',
              errors: [`${response.errors.address}`],
            },
          ]);
        }
      } else {
        notification.success({ message: `${response.msg}` });
        if (isExhibitor === 'true') {
          history.push('/exhibitor/profile');
        } else {
          history.push('/participant/profile');
        }
      }

      if (isExhibitor === 'true') {
        dispatch(readProfileExhibitor(profileCallback));
      } else {
        dispatch(readProfile(profileCallback));
      }
    }
  };

  const profileCallback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'ACCOUNT_LOGOUT') {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/login');
      }
    }
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="form-profile"
          className="form-flex"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Avatar
            className="w-1/3 mx-auto mb-5"
            profileDetails={profileDetails}
            profileDetailsExhibitor={profileDetailsExhibitor}
          />
          <h1 className="text-red-10 text-2xl mb-3 mt-5 uppercase w-full">
            PROFILE DETAILS
          </h1>
          <div className="flex flex-col lg:flex-row lg:flex-wrap w-full">
            {isExhibitor === 'false' ? (
              <>
                <div className="relative w-full lg:w-1/3 px-3 my-3">
                  <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                      {
                        pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                        message: 'Invalid format',
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <i className="fas fa-user text-red-20 form-icon pl-2"></i>
                </div>
                <div className="relative w-full lg:w-1/3 px-3 my-3">
                  <Form.Item
                    label="Last Name"
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your last name!',
                      },
                      {
                        pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                        message: 'Invalid format',
                      },
                    ]}
                  >
                    <Input type="text" placeholder="Last Name" />
                  </Form.Item>
                  <i className="fas fa-user text-red-20 form-icon pl-2"></i>
                </div>
              </>
            ) : (
              <div className="relative w-full lg:w-1/3 px-3 my-3">
                <Form.Item
                  label="Company Name"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your company name!',
                    },
                  ]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
                <i className="fas fa-map-pin text-red-20 form-icon pl-2"></i>
              </div>
            )}
            <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Please input your address!',
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
              <i className="fas fa-map-pin text-red-20 form-icon pl-2"></i>
            </div>
            <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item
                label="Contact Number"
                name="contact_number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your contact number!',
                  },
                  {
                    pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                    message: 'Please input the correct contact number!',
                  },
                  {
                    pattern: new RegExp(/[^a-z\s]/g),
                    message: 'Invalid format',
                  },
                ]}
              >
                <Input type="tel" placeholder="Contact Number" />
              </Form.Item>
              <i className="fas fa-phone text-red-20 form-icon pl-2"></i>
            </div>
            {isExhibitor === 'false' ? (
              <div className="relative w-full lg:w-1/3 px-3 my-3">
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input placeholder="Email Address" />
                </Form.Item>
                <i className="fas fa-envelope text-red-20 form-icon pl-2"></i>
              </div>
            ) : (
              <div className="relative w-full px-3 my-3">
                <Form.Item
                  label="Company Description"
                  name="business_description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your company description!',
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Company Description" />
                </Form.Item>
                <i className="fas fa-building text-red-20 form-icon pl-2"></i>
              </div>
            )}
          </div>
          <h1 className="text-red-10 text-2xl mb-3 mt-5 uppercase w-full">
            SOCIAL MEDIA
          </h1>
          <div className="flex flex-col lg:flex-row lg:flex-wrap w-full">
            <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item label="Facebook" name="fb_link">
                <Input placeholder="Facebook" />
              </Form.Item>
              <i className="fa text-red-20 form-icon pl-2">&#xf09a;</i>
            </div>
            <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item label="Twitter" name="twitter_link">
                <Input placeholder="Twitter" />
              </Form.Item>
              <i className="fa text-red-20 form-icon pl-2">&#xf099;</i>
            </div>
            {/* <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item label="Gmail" name="gmail_link">
                <Input placeholder="Gmail" />
              </Form.Item>
              <i className="fa text-red-20 form-icon pl-2">&#xf0e0;</i>
            </div> */}
            <div className="relative w-full lg:w-1/3 px-3 my-3">
              <Form.Item label="Instagram" name="instagram_link">
                <Input placeholder="Instagram" />
              </Form.Item>
              <i className="fa text-red-20 form-icon pl-2">&#xf16d;</i>
            </div>
            {/* {isExhibitor === 'true' && (
              <div className="relative w-full lg:w-1/3 px-3 my-3">
                <Form.Item label="Website" name="website_link">
                  <Input placeholder="Website" />
                </Form.Item>
                <i className="fa text-red-20 form-icon pl-2">&#xf0ac;</i>
              </div>
            )} */}
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="flex flex-col lg:flex-row w-full lg:w-auto">
              <Button className="btn my-1 lg:mr-5" htmlType="submit">
                <span>Save Profile</span>
              </Button>
              <Button
                className="btn btn-white my-1"
                style={{ boxShadow: '0px 3px 6px #00000029' }}
              >
                <Link
                  to={
                    isExhibitor === 'true'
                      ? '/exhibitor/profile'
                      : '/participant/profile'
                  }
                >
                  <span>Cancel</span>
                </Link>
              </Button>
            </div>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormProfile;
