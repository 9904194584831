import React from "react";
import { Card } from "antd";
import FormProfile from "../components/FormProfile";

function EditProfile(props) {
  return (
    <>
      <Card className="rounded-2xl p-10 mt-5">
        <FormProfile {...props} />
      </Card>
    </>
  );
}

export default EditProfile;