import React, { useEffect } from "react";
import FormCreateExpo from "../components/FormCreateExpo";
import StandardLanding from "../../../shared/components/StandardLanding";

function ExpoCreate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StandardLanding>
      <div className="bg-main-content content-bg">
        <div className="content-space px-5 py-10 lg:p-20">
          <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
            <FormCreateExpo />
          </div>
          <h4 className="mt-5">
            One of our team will contact you shortly to accommodate your
            request.
          </h4>
        </div>
      </div>
    </StandardLanding>
  );
}
export default ExpoCreate;