import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { get } from "lodash";

import PageHeader from "../layouts/containers/PageHeader";
import PageFooter from "../layouts/containers/PageFooter";
import PageContent from "../layouts/containers/PageContent";

import { readUser } from "../admin/users/actions";

const Landing = (props) => {
  const { toVte, isAuth, setIsAuth, renderPrivateRoute, setOtopScreen, click } =
    props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const state = useSelector((state) => state);
  const { expoDetails } = state.expo;
  const { userDetails, loading, userType } = state.user;
  let otopToken = localStorage.getItem("otopToken");
  let userId = localStorage.getItem("userId");
  let user = get(userDetails, 'attributes', {})

  function login() {
    setIsAuth(true);
  }

  useEffect(() => {
    if(userId) {
      if(userId === "1") {
        dispatch(readUser(userId, true));
      } else {
        dispatch(readUser(userId));
      }
    }
  }, [userId]);

  useEffect(() => {
    renderPrivateRoute();
  }, []);

  useEffect(() => {
    if (pathname === `/expo/${expoDetails.slug}`) {
      setOtopScreen(false);
    }
  }, []);

  return (
    <>
      {otopToken ? (
        <>
          <PageHeader isAuth={isAuth} setIsAuth={setIsAuth} user={user} />
          <PageContent
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            setOtopScreen={setOtopScreen}
            renderPrivateRoute={renderPrivateRoute}
            user={user}
            loading={loading}
          />
        </>
      ) : (
        <>
          <PageHeader 
          toVte={toVte} 
          isAuth={isAuth} 
          setIsAuth={setIsAuth} 
          />
          <PageContent
            toVte={toVte}
            click={click}
            login={login}
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            renderPrivateRoute={renderPrivateRoute}
            click={click}
          />
          <PageFooter />
        </>
      )}
    </>
  );
};

export default Landing;