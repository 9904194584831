import React from "react";
import { Card } from "antd";
import { isEmpty } from "lodash";

import CardWrapper from "../../../../../shared/components/otop/CardWrapper";
import Avatar from "../../../../../shared/assets/images/avatar.jpg";

const Profile = (props) => {
  const { user, loading } = props;
  return (
    <CardWrapper>
      <Card loading={loading} className="card">
        {!isEmpty(user) && (
          <div className="card-content flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={Avatar}
                alt=""
                className="card-img h-16 w-16 rounded-2xl mr-5"
              />
              <div>
                <p className="md-title">
                  {user.firstname} {user.lastname}
                </p>
                <p>
                  Joined: {user.date_created && user.date_created.time_passed}
                </p>
              </div>
            </div>
            <div className="border-r">&nbsp;</div>
            <div>
              <p className="md-title">00-{localStorage.getItem("userId")}</p>
              <p>OTOP HUB ID#</p>
            </div>
            <div>
              <p className="text-red-10 lowercase">{user.email}</p>
              <p>Email Address</p>
            </div>
            <div>
              <p className="md-title">(+63) {user.contact_number}</p>
              <p>Contact Number</p>
            </div>
          </div>
        )}
      </Card>
    </CardWrapper>
  );
};

export default Profile;