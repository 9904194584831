import React, { useState } from 'react';
import moment from 'moment';
import SubHeader from '../components/SubHeader';
import { logoutOtop } from '../../auth/actions';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, notification, Menu, Dropdown } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import otop from '../../../../shared/assets/otop/Sellet/logo-1.svg';
import HeaderWrapper from '../../../../shared/components/otop/HeaderWrapper';
import FormSearch from '../components/FormSearch';
import dti from '../../../../shared/assets/images/logo/dti logo.png';

const PageHeader = (props) => {
  const { isAuth, setIsAuth, toVte } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { profileDetails } = state.profile;
  const { cartProducts } = state.otopCart;
  const { user } = state.admin;
  let token = localStorage.getItem('token');
  let otopToken = localStorage.getItem('otopToken');
  const { pathname } = history.location;
  let isExhibitor = localStorage.getItem('isExhibitor');

  const onLogout = () => {
    dispatch(logoutOtop(callback));
    setIsAuth(false);
    history.push('/otop-hub/login');
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'LOGIN_SUCCESS') {
        notification.success({ message: `${response.msg}` });
      } else if (statusCode === 'UNAUTHORIZED') {
        notification.warning({ message: `${response.msg}` });
      } else if (statusCode === 'ACCOUNT_NOT_VERIFIED') {
        notification.warning({ message: `${response.msg}` });
      } else {
        notification.error({ message: response.msg });
      }
    }
  };

  const renderUser = () => {
    if (token && profileDetails && Object.keys(profileDetails).length > 0) {
      return (
        <p className="mr-5 text-xs text-brown-10">
          <span className="text-black">WELCOME,</span>&nbsp;
          {profileDetails
            ? profileDetails.firstname + profileDetails.lastname
            : ''}
          !
        </p>
      );
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/otop-hub/my-profile">Update Profile</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderWrapper>
      {otopToken ? (
        <div className="w-full z-50 bg-white">
          <div className="flex item-center justify-between p-5 w-full">
            <div className="flex items-center">
              <Link to="/otop-hub/admin">
                <img src={otop} alt="" className="header-img mr-5" />
              </Link>
              <p className="title-lg font-semibold text-brown-10">
                ONLINE OTOP HUB
              </p>
            </div>
            <div className="flex items-center">
              <div className="flex mr-2 font-semibold">
                <p>Welcome, &nbsp;</p>
                <p className="text-brown-10">
                  {user.firstname} {user.lastname}
                </p>
                <p>&nbsp;Today is &nbsp;</p>
                <p>{moment().format('MMMM Do YYYY, h:mm:ss a')}</p>
              </div>
              <div className="mx-5">
                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                  <Avatar size="large" icon={<UserOutlined />} />
                </Dropdown>
              </div>
              {/* <button className="btn mx-5">
                <i className="far fa-bell text-lg"></i>
              </button> */}
              <button onClick={onLogout} className="btn">
                <i className="fas fa-sign-out-alt"></i>
              </button>
            </div>
          </div>
          <SubHeader isAuth={isAuth} />
        </div>
      ) : (
        <div className="header-container">
          <div className="header-container-top">
            <div className="header-content-top text-xs py-1">
              <div>
                {token ? (
                  renderUser()
                ) : (
                  <p className="mr-5">WELCOME TO OTOP HUB</p>
                )}
              </div>
              {isExhibitor === 'false' && (
                <>
                  <Link
                    to="/otop-hub/my-addresses"
                    className="text-brown-10 mr-5"
                  >
                    MY ADDRESSES
                  </Link>
                  <Link
                    to="/otop-hub/transactions"
                    className="text-brown-10 mr-5"
                  >
                    ORDER HISTORY
                  </Link>
                </>
              )}
              {pathname === '/otop-hub/login' ? (
                ''
              ) : (
                <Link to="/otop-hub/login" className="text-brown-10">
                  LOGIN
                </Link>
              )}
            </div>
          </div>
          <div className="public-header-content">
            <div className="flex items-center">
              <Link to="/otop-hub/products" className="flex">
                <img src={dti} alt="" className="header-img h-20 mr-5" />
                <img src={otop} alt="" className="header-img mr-5" />
              </Link>
              <p className="title-lg font-semibold text-brown-10">
                ONLINE OTOP HUB
              </p>
            </div>
            <div className="flex">
              {pathname === '/otop-hub/login' ? (
                ''
              ) : (
                <div className="flex items-center">
                  <FormSearch />
                  <Link to="/otop-hub/cart">
                    <i className="fas fa-shopping-bag text-brown-10 ml-5">
                      {cartProducts && cartProducts.length > 0 && (
                        <span className="badge">&nbsp;</span>
                      )}
                    </i>
                  </Link>
                  <div className="border-l-2 ml-7">&nbsp;</div>
                  <Button onClick={toVte}>
                    <i className="fas fa-sign-out-alt text-brown-10"></i>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </HeaderWrapper>
  );
};

export default PageHeader;
