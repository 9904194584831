import React from 'react'
import { Card, Button } from 'antd'
import Image from '../../../../../shared/assets/images/img/expo/expo1.png'
import CardWrapper from '../../../../../shared/components/otop/CardWrapper'


const UnpaidOrderDetails = () => {
    const img = [
        {
            image: Image,
        },
        {
            image: Image,
        },
        {
            image: Image,
        },
        {
            image: Image,
        },
        {
            image: Image,
        },
    ]
    return (
        <CardWrapper>
            <div className="card flex justify-between">
                <div className="mr-5">
                    <Card className="w-80 h-80">
                        <img src={Image} alt="" className="card-img h-full"/>
                    </Card>
                    <div className="flex w-80 py-5 overflow-x-auto">
                        {img.map(image => <img src={image.image} alt="" className="w-12 h-14 object-cover rounded-lg mr-5"/> )}
                        
                    </div>
                </div>
                <Card className="card-content w-4/5 ml-5">
                    <div className="flex justify-between w-4/5">
                        <div>
                            <p className="card-title">Product Name Here</p>
                            <p className="card-subtitle">Added on: June 10, 2021</p>
                        </div>
                        <div>
                            <p className="card-title">Product Name Here</p>
                            <p className="card-subtitle">Added on: June 10, 2021</p>
                        </div>
                        <div>
                            <p className="card-title">Product Name Here</p>
                            <p className="card-subtitle">Added on: June 10, 2021</p>
                        </div>
                        <div>
                            <p className="card-title">Product Name Here</p>
                            <p className="card-subtitle">Added on: June 10, 2021</p>
                        </div>
                    </div>
                    <div className="border-b-2">&nbsp;</div>
                    <div className="flex items-center justify-between w-4/5 mt-5">
                        <img src={Image} alt="" className="card-img w-20 h-20 mr-5"/>
                        <div>
                            <p className="card-title">Product Name Here</p>
                            <p className="card-subtitle">Verified Buyer</p>
                        </div>
                        <div>
                            <p className="card-title text-purple-10">Unpaid</p>
                            <p className="card-subtitle">Status</p>
                        </div>
                        <div>
                            <p className="card-title">Payment Type Here</p>
                            <p className="card-subtitle">Payment Type</p>
                        </div>
                    </div>
                    <div className="py-5 w-4/5">
                        <p className="card-title">Enter Shipping Address Here, St.Name, Brgy. Name, City Name, Zip Code</p>
                        <p className="card-subtitle">Buyer Shipping Address</p>
                    </div>
                    <div className="flex py-5 w-3/5 justify-between items-center">
                        <p className="card-subtitle">Proof of Payment (If selected payment method is: Bank Transfer)</p>
                        <Button>View Document</Button>
                    </div>
                </Card>
            </div>
        </CardWrapper>
    )
}

export default UnpaidOrderDetails
