import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, notification } from 'antd';
import { useDispatch } from 'react-redux';
import logo from '../../../../../shared/assets/otop/Sellet/logo-1.svg';

const TableProducts = (props) => {
  const dispatch = useDispatch();
  const { tags, loading, deleteTag, getTagList } = props;
  const [deleteProductID, setDeleteProductID] = useState(null);

  const columns = [
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 900,
      render: (data, rowData) => renderTagName(data, rowData),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderTagName = (data, rowData) => {
    return rowData.attributes.name;
  };

  const onSelectIdToDelete = (data) => {
    setDeleteProductID(data);
  };

  const onConfirm = () => {
    dispatch(deleteTag(deleteProductID, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code, msg } = response;
      if (status_code === 'PRODUCT_DELETED') {
        notification.success({ message: `${msg}` });
      } else {
        notification.warning({ message: `${msg}` });
      }
    } else {
      notification.error({
        message: 'Error',
        // description: error.msg
      });
    }
    dispatch(getTagList(null, 0));
    window.scrollTo(0, 0);
  };

  const renderAction = (data, rowData) => {
    return (
      <div className="flex items-center">
        <Link to={`/otop-hub/admin/tags/${rowData.id}/edit`}>
          <button className="btn">
            <i className="far fa-edit"></i>
          </button>
        </Link>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={onConfirm}
          onClick={() => onSelectIdToDelete(rowData.id)}
        >
          <button className="btn">
            <i className="far fa-trash-alt"></i>
          </button>
        </Popconfirm>
      </div>
    );
  };

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={tags}
      />
    </div>
  );
};

export default TableProducts;
