import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_GUEST_PARTNER_SHOW,
  API_GUEST_EXPO_HALL_EXHIBITOR_LIST,
  API_GUEST_EXPO_HALL_EXHIBITOR_SHOW,
  API_GUEST_DISCUSSION_LIST,
  API_GUEST_EXPO_HALL_SHOW,
  API_GUEST_FEATURED_PRODUCT_LIST,
  API_GUEST_SEMINAR_LIST,
  API_GUEST_MAIN_SEMINAR,
  API_GUEST_EXHIBITOR_PRODUCT_LIST,
  API_GUEST_EXHIBITOR_PRODUCT_SHOW,
  API_GUEST_EXHIBITOR_SHOW,
  API_GUEST_EXHIBITOR_EXPO_LIST,
  API_GUEST_SPEAKER_DETAILS
} from "../../shared/constant/url";

export const GUEST = {
  PARTNER_SHOW_START: "PARTNER_SHOW_START",
  PARTNER_SHOW_SUCCESS: "PARTNER_SHOW_SUCCESS",
  PARTNER_SHOW_ERROR: "PARTNER_SHOW_ERROR",

  EXPO_HALL_EXHIBITOR_LIST_START: "EXPO_HALL_EXHIBITOR_LIST_START",
  EXPO_HALL_EXHIBITOR_LIST_SUCCESS: "EXPO_HALL_EXHIBITOR_LIST_SUCCESS",
  EXPO_HALL_EXHIBITOR_LIST_ERROR: "EXPO_HALL_EXHIBITOR_LIST_ERROR",

  EXPO_HALL_EXHIBITOR_SHOW_START: "EXPO_HALL_EXHIBITOR_SHOW_START",
  EXPO_HALL_EXHIBITOR_SHOW_SUCCESS: "EXPO_HALL_EXHIBITOR_SHOW_SUCCESS",
  EXPO_HALL_EXHIBITOR_SHOW_ERROR: "EXPO_HALL_EXHIBITOR_SHOW_ERROR",

  DISCUSSION_LIST_START: "DISCUSSION_LIST_START",
  DISCUSSION_LIST_SUCCESS: "DISCUSSION_LIST_SUCCESS",
  DISCUSSION_LIST_ERROR: "DISCUSSION_LIST_ERROR",

  EXPO_HALL_SHOW_START: "EXPO_HALL_SHOW_START",
  EXPO_HALL_SHOW_SUCCESS: "EXPO_HALL_SHOW_SUCCESS",
  EXPO_HALL_SHOW_ERROR: "EXPO_HALL_SHOW_ERROR",

  FEATURED_PRODUCT_LIST_START: "FEATURED_PRODUCT_LIST_START",
  FEATURED_PRODUCT_LIST_SUCCESS: "FEATURED_PRODUCT_LIST_SUCCESS",
  FEATURED_PRODUCT_LIST_ERROR: "FEATURED_PRODUCT_LIST_ERROR",

  SEMINAR_LIST_START: "SEMINAR_LIST_START",
  SEMINAR_LIST_SUCCESS: "SEMINAR_LIST_SUCCESS",
  SEMINAR_LIST_ERROR: "SEMINAR_LIST_ERROR",

  MAIN_SEMINAR_START: "MAIN_SEMINAR_START",
  MAIN_SEMINAR_SUCCESS: "MAIN_SEMINAR_SUCCESS",
  MAIN_SEMINAR_ERROR: "MAIN_SEMINAR_ERROR",

  EXHIBITOR_PRODUCT_LIST_START: "EXHIBITOR_PRODUCT_LIST_START",
  EXHIBITOR_PRODUCT_LIST_SUCCESS: "EXHIBITOR_PRODUCT_LIST_SUCCESS",
  EXHIBITOR_PRODUCT_LIST_ERROR: "EXHIBITOR_PRODUCT_LIST_ERROR",

  EXHIBITOR_PRODUCT_SHOW_START: "EXHIBITOR_PRODUCT_SHOW_START",
  EXHIBITOR_PRODUCT_SHOW_SUCCESS: "EXHIBITOR_PRODUCT_SHOW_SUCCESS",
  EXHIBITOR_PRODUCT_SHOW_ERROR: "EXHIBITOR_PRODUCT_SHOW_ERROR",

  EXHIBITOR_SHOW_START: "EXHIBITOR_SHOW_START",
  EXHIBITOR_SHOW_SUCCESS: "EXHIBITOR_SHOW_SUCCESS",
  EXHIBITOR_SHOW_ERROR: "EXHIBITOR_SHOW_ERROR",

  EXHIBITOR_EXPO_LIST_START: "EXHIBITOR_EXPO_LIST_START",
  EXHIBITOR_EXPO_LIST_SUCCESS: "EXHIBITOR_EXPO_LIST_SUCCESS",
  EXHIBITOR_EXPO_LIST_ERROR: "EXHIBITOR_EXPO_LIST_ERROR",

  EXHIBITOR_SPEAKER_DETAILS_START: "EXHIBITOR_SPEAKER_DETAILS_START",
  EXHIBITOR_SPEAKER_DETAILS_SUCCESS: "EXHIBITOR_SPEAKER_DETAILS_SUCCESS",
  EXHIBITOR_SPEAKER_DETAILS_ERROR: "EXHIBITOR_SPEAKER_DETAILS_ERROR",
};

export const getGuestPartnerDetails = (partner_id) => {
  return (dispatch) => {
    dispatch({ type: GUEST.PARTNER_SHOW_START });
    let formData = new FormData();
    formData.append("partner_id",partner_id)
  
    let requestOption = {
     method: "POST", 
     headers: getAuthHeader(), 
     body: formData
    }

    fetch(API_GUEST_PARTNER_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.PARTNER_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.PARTNER_SHOW_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExpoHallExhibitorList = (params, value, slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_LIST_START });
    let formData = new FormData();
    formData.append("slug", slug);

    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 1000);
    }

    if (params) {
      if (params.keyword) {
        formData.append("keyword", params.keyword);
      }
      if (params.category_id) {
        formData.append("category_id", params.category_id);
      }
      if (params.region) {
        formData.append("region", params.region);
      }
      if (params.industry) {
        formData.append("industry", params.industry);
      }
      if (params.product_name) {
        formData.append("product_name", params.product_name);
      }
      if (params.merchant_name) {
        formData.append("merchant_name", params.merchant_name);
      }
    }

    if(value){
      formData.append("sort_alphabetically", value);
    } else if (value === 0){
      formData.append("sort_alphabetically", value);
    }

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_EXPO_HALL_EXHIBITOR_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExpoHallExhibitorShow = () => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_SHOW_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GUEST_EXPO_HALL_EXHIBITOR_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXPO_HALL_EXHIBITOR_SHOW_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestDiscussionList = () => {
  return (dispatch) => {
    dispatch({ type: GUEST.DISCUSSION_LIST_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GUEST_DISCUSSION_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.DISCUSSION_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.DISCUSSION_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExpoHallShow = (slug) => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXPO_HALL_SHOW_START });
    let formData = new FormData();
    formData.append("slug", slug);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };

    fetch(API_GUEST_EXPO_HALL_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXPO_HALL_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXPO_HALL_SHOW_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestFeaturedProductList = (params, slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: GUEST.FEATURED_PRODUCT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 1000);
    }

    formData.append("page", 1);
    formData.append("slug", slug);
    if (params) {
      if (params.keyword) {
        formData.append("keyword", params.keyword);
      }
      if (params.category_id === 1 || params.category_id === 0) {
        formData.append("category_id", params.category_id);
      }
      if (params.price) {
        formData.append("price", params.price);
      }
    }

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_FEATURED_PRODUCT_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.FEATURED_PRODUCT_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.FEATURED_PRODUCT_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestSeminarList = (slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: GUEST.SEMINAR_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 1000);
    }
    formData.append("page", 1);
    formData.append("slug", slug);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_SEMINAR_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.SEMINAR_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.SEMINAR_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestMainSeminar = (slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: GUEST.MAIN_SEMINAR_START });
    let formData = new FormData();
    formData.append("per_page", per_page);
    formData.append("page", 1);
    formData.append("slug", slug);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_MAIN_SEMINAR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.MAIN_SEMINAR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.MAIN_SEMINAR_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExhibitorProductList = (params, exhibitor_id, per_page) => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXHIBITOR_PRODUCT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 1000);
    }

    formData.append("page", 1);
    formData.append("exhibitor_id", exhibitor_id);
    if (params) {
      if (params.keyword) {
        formData.append("keyword", params.keyword);
      }
      if (params.types === 1 || params.types === 0) {
        formData.append("types", params.types);
      }
      if (params.price) {
        formData.append("price", params.price);
      }
    }

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_EXHIBITOR_PRODUCT_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXHIBITOR_PRODUCT_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXHIBITOR_PRODUCT_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExhibitorProductShow = (product_id) => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXHIBITOR_PRODUCT_SHOW_START });
    let formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("include", "images");
    formData.append("include", "exhibitor");
    // formData.append("include", "bankdetails");

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_EXHIBITOR_PRODUCT_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXHIBITOR_PRODUCT_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXHIBITOR_PRODUCT_SHOW_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExhibitorShow = (exhibitor_id) => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXHIBITOR_SHOW_START });
    let formData = new FormData();
    formData.append("exhibitor_id", exhibitor_id);
    formData.append("include", 'gallery');

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };

    fetch(API_GUEST_EXHIBITOR_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXHIBITOR_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXHIBITOR_SHOW_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestExhibitorExpoList = () => {
  return (dispatch) => {
    dispatch({ type: GUEST.EXHIBITOR_EXPO_LIST_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GUEST_EXHIBITOR_EXPO_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXHIBITOR_EXPO_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXHIBITOR_EXPO_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getGuestSpeakerDetails = (speaker_id) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("speaker_id", speaker_id);
    formData.append("include", "expo");
    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GUEST_SPEAKER_DETAILS, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GUEST.EXHIBITOR_SPEAKER_DETAILS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GUEST.EXHIBITOR_SPEAKER_DETAILS_ERROR, payload: getError(error) });
      });
  };
};