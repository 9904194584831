import React from "react";
import styled from "@emotion/styled";

function CardWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default CardWrapper;

const StyledDiv = styled.div({
  ".ant-card": {
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    borderRadius: "18px",
    overflow: 'hidden',
  },
  ".ant-card-body": {
    padding: "0px !important",
  },
  ".card": {
    ".card-img": {
      paddingTop: '100%',

      "img": {
        objectFit: "cover",
        objectPosition: "center",
      },

      ".ant-skeleton, .ant-skeleton-avatar": {
        width: '100%',
        height: '100%',
      }
    },
    ".card-content": {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      ".card-title": {
        color: "#1B1B1B",
        // fontSize: "1.5rem;",
        lineHeight: "2rem;",
        fontWeight: "700",
        textTransform: "capitalize",
      },

      ".card-subtitle": {
        color: "#D63D4B",
        // fontSize: "1.125rem;",
        fontSize: "1rem;",
        lineHeight: "1rem;",
        fontWeight: "400",
        fontStyle: "italic",
        textAlign: "center",
      },

      ".card-body": {
        // marginTop: "1rem",
        width: "100%",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',

        ".card-body-row": {
          flexDirection: "row",
        },

        ".card-body-col": {
          flexDirection: "col",
        },

        ".flex-justify-center": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        },

        ".card-body-icon": {
          color: "#D63D4B",
          fontSize: "1.5rem;",
          lineHeight: "2rem;",
        },
        ".card-body-content": {
          color: "#4E4E4E",
          fontWeight: "300",
        },

        ".btn": {
          marginTop: '1.5rem',
          fontSize: "1.1em",
          borderRadius: '10px',
          border: "0",
          letterSpacing: "1px",
          padding: "0 25px",
          whiteSpace: "nowrap",
          height: '50px',
        },
        ".btn-red": {
          background: "#D63D4B",
          color: "#FFFFFF",
        },
        ".btn-blue": {
          background: "#00539a",
          color: "#FFFFFF",
        },
      },
    },
  },


});
