import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormCategory from "../components/FormCategory";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import {
  createCategory,
  editCategory,
  readCategory,
} from "../actions";

function AddEditTag() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector((state) => state);
  const { categoryDetails } = state.category;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(readCategory(id));
    }
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-crop content-bg my-5">
          <FormCategory
            createCategory={createCategory}
            editCategory={editCategory}
            categoryDetails={categoryDetails}
            id={id}
          />
        </div>
      </div>
    </StandardContainer>
  );
}
export default AddEditTag;