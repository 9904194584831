import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, notification, Input } from 'antd';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';
import { inquiryCreate } from '../actions';

function FormInquireModal(props) {
  const {
    exhibitor_id,
    isShowModal,
    setIsShowModal,
    expoDetails,
    productDetails,
    userDetails,
    productList,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      firstname: userDetails['firstname'] || '',
      lastname: userDetails['lastname'] || '',
      email: userDetails['email'] || '',
      contact_number: userDetails['contact_number'] || '',
    });
  }, [userDetails]);

  const onFinish = (values) => {
    values.inquiry_type = userDetails?.type || '';
    dispatch(inquiryCreate(values, productList?.[0]?.id || 1, 1, callback));
    setIsShowModal(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'PRODUCT_INQUIRY') {
        notification.success({ message: `${response.msg}` });
      } else {
        notification.error({
          message: `${response.msg} ${response?.hint || ''}`,
        });
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }
    window.scrollTo(0, 0);
  };

  return (
    <Modal
      footer={false}
      closable={false}
      title={<h1 className="text-red-20 font-semibold px-3">INQUIRY FORM</h1>}
      width={800}
      visible={isShowModal}
    >
      <FormWrapper>
        <Form
          name="form-profile"
          className="form-flex"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="flex flex-col lg:flex-row lg:flex-wrap w-full">
            <div className="relative w-full lg:w-1/2 px-3 my-1">
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name!',
                  },
                  {
                    pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                    message: 'Invalid format',
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <i className="fas fa-user text-red-20 form-icon pl-2"></i>
            </div>
            <div className="relative w-full lg:w-1/2 px-3 my-1">
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: 'Please input your last name!',
                  },
                  {
                    pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                    message: 'Invalid format',
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <i className="fas fa-user text-red-20 form-icon pl-2"></i>
            </div>
            <div className="relative w-full lg:w-1/2 px-3 my-1">
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
              <i className="fas fa-envelope text-red-20 form-icon pl-2"></i>
            </div>
            <div className="relative w-full lg:w-1/2 px-3 my-1">
              <Form.Item
                label="Contact Number"
                name="contact_number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your contact number!',
                  },
                  {
                    pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                    message: 'Please input the correct contact number!',
                  },
                  {
                    pattern: new RegExp(/[^a-z\s]/g),
                    message: 'Invalid format',
                  },
                ]}
              >
                <Input type="tel" placeholder="Contact Number" />
              </Form.Item>
              <i className="fas fa-phone text-red-20 form-icon pl-2"></i>
            </div>
            <div className="relative w-full px-3 my-1">
              <Form.Item
                label="Inquiry Details"
                name="inquiry_message"
                rules={[
                  {
                    required: true,
                    message: 'Please input your inquiry details!',
                  },
                ]}
              >
                <Input.TextArea placeholder="Description Here" rows={6} />
              </Form.Item>
              <i className="fas fa-list-alt text-red-20 form-icon pl-2"></i>
            </div>
          </div>
          <p className="w-full px-3 mb-5">
            One of our team will contact you shortly to accommodate your
            request.
          </p>
          <div className="flex flex-col lg:flex-row w-full lg:w-auto">
            <Button className="btn my-1 lg:mr-5" htmlType="submit">
              <i className="fas fa-check-circle mr-1"></i>
              <span>SUBMIT</span>
            </Button>
            <Button
              className="btn btn-white my-1"
              style={{ boxShadow: '0px 3px 6px #00000029' }}
              onClick={() => setIsShowModal(false)}
            >
              <i className="fas fa-times-circle mr-1"></i>
              <span>CANCEL</span>
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </Modal>
  );
}

export default FormInquireModal;
