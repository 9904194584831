import React from 'react';
import { Link } from 'react-router-dom';
import { filter, iteratee } from 'lodash';
import logo from '../../../../shared/assets/otop/Sellet/logo-1.svg';
import { Divider } from 'antd';

export default function TransactionDetails(props) {
  const { transactionDetails, products } = props;

  const renderDetails = () => {
    if (
      transactionDetails &&
      transactionDetails.data &&
      transactionDetails.included
    ) {
      let product = filter(
        transactionDetails.included,
        iteratee({ type: 'product' })
      );
      let item = filter(
        transactionDetails.included,
        iteratee({ type: 'item' })
      );
      let payment = filter(
        transactionDetails.included,
        iteratee({ type: 'payment' })
      );
      let status = filter(
        transactionDetails.included,
        iteratee({ type: 'status' })
      );
      return (
        <div>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-brown-10 lg:text-lg mt-5">
                Order #000{transactionDetails.data.id}
              </h3>
              <code>{renderStatus(status)}</code>
            </div>
            <div>
              <Link to=".">
                <i className="fas fa-chevron-left"></i> Back
              </Link>
            </div>
          </div>
          <h3 className="text-brown-10 lg:text-lg mt-5">Order Details</h3>
          {renderOrderDetails(item)}
          <Divider />
          <h3 className="text-brown-10 lg:text-lg mt-5">Product Details</h3>
          {renderProductDetails(product)}
          <Divider />
          <h3 className="text-brown-10 lg:text-lg mt-5">Payment Details</h3>
          {renderPaymentDetails(payment)}
        </div>
      );
    } else {
      return '';
    }
  };

  const renderOrderDetails = (data) => {
    if (data && data[0]) {
      return (
        <ul className="flex flex-wrap items-center w-full">
          <li className="w-full lg:w-1/6">Price</li>
          <li className="w-full lg:w-5/6">
            &#8369;{data[0].attributes.price.toLocaleString()}
          </li>
          <li className="w-full lg:w-1/6">Quantity</li>
          <li className="w-full lg:w-5/6">{data[0].attributes.quantity}</li>
        </ul>
      );
    }
  };

  const renderProductDetails = (data) => {
    if (data && data[0]) {
      return (
        <>
          {renderImg(data[0].id)}
          <p>{data[0].attributes.name}</p>
          <p>
            {data[0].attributes.price} / {data[0].attributes.quantity_type}
          </p>
        </>
      );
    }
  };

  const renderImg = (id) => {
    let productImage = filter(
      products.included,
      iteratee({ type: 'product_image' })
    );
    if (productImage && id) {
      let filtered = productImage.filter(
        (item) => parseInt(item.id) === parseInt(id)
      );
      if (filtered && filtered[0]) {
        return (
          <img
            src={filtered[0].attributes.path}
            alt="OTOP Hub Product"
            className="object-contain object-center w-64 h-64"
          />
        );
      }
    }
    return (
      <img
        src={logo}
        alt="OTOP Hub Product"
        className="object-contain object-center w-96 h-96"
      />
    );
  };

  const renderPaymentDetails = (data) => {
    if (data && data[0]) {
      return (
        <ul className="flex flex-wrap items-center w-full">
          <li className="w-full lg:w-1/6">Payment Option</li>
          <li className="w-full lg:w-5/6">
            {data[0].attributes.cash_on_delivery ? 'COD' : 'Paymaya'}
          </li>
          <li className="w-full lg:w-1/6">Payment Status</li>
          <li className="w-full lg:w-5/6">{data[0].attributes.status}</li>
        </ul>
      );
    }
  };

  const renderStatus = (data) => {
    if (data && data[0]) {
      return data[0].attributes.name;
    }
  };

  return renderDetails();
}
