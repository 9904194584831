import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "antd";

import SearchFeatured from "../components/SearchForm";
import ListFeaturedProducts from "../components/ListFeaturedProducts";
import banner from "../../../../shared/assets/otop/otop-hub banner.png";
import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import ListStore from "../../stores/components/ListStores";
import ListCategory from "../components/ListCategory";
import { getOtopProductList, getOtopTagLlist, getOtopCategoryList } from "../actions";
import { getOtopStoreList } from "../../stores/actions";

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products, loading, categories, tags } = state.otopProduct;
  const { stores } = state.otopSeller;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOtopProductList());
    dispatch(getOtopStoreList());
    dispatch(getOtopTagLlist());
    dispatch(getOtopCategoryList());
  }, []);

  // const contentStyle = {
  //   height: '160px',
  //   color: '#fff',
  //   lineHeight: '160px',
  //   textAlign: 'center',
  //   background: '#364d79',
  // };

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="banner-bg">&nbsp;</div>
        <div style={{ marginTop: "-31rem" }} className="content-bg">
          <div className="content-banner">
            <Carousel infinite={true} dots={false} autoplay>
              <img src={banner} className="banner-img" />
              <img src={banner} className="banner-img" />
              <img src={banner} className="banner-img" />
            </Carousel>
          </div>
          <div className="w-full overflow-auto bg-brown-10">
            <ListCategory
              categories={categories}
              getOtopProductList={getOtopProductList}
            />
          </div>
          <div className="flex justify-between items-center py-10">
            <div className="w-full lg:w-1/2">
              <p className="content-header-left mb-5">Featured Products</p>
            </div>
            <div className="w-full lg:w-1/2 flex justify-end">
              <SearchFeatured
                loading={loading}
                tags={tags}
                products={products}
                getOtopProductList={getOtopProductList}
              />
            </div>
          </div>

          <ListFeaturedProducts products={products} loading={loading} type={"all"} />
          <div className="my-10">
            <p className="content-header-left mb-5">OTOP Hub Centers</p>
            <ListStore loading={loading} stores={stores} />
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;