import React, { useEffect } from 'react';
import { Select, Form, Input, Button, notification } from 'antd';
import { addMultipleItemsToCart, getCartList } from '../../cart/actions';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';

function FormLogin(props) {
  const dispatch = useDispatch();
  const { login, history, loginExhibitor, fromCheckout, cartData } = props;
  const hasToken = localStorage.getItem('token');

  const onFinish = (values) => {
    if (values.type === 'exhibitor') {
      dispatch(loginExhibitor(values, callback));
    } else {
      dispatch(login(values, callback));
    }
  };

  useEffect(() => {
    if (hasToken) {
      dispatch(addMultipleItemsToCart(callback));
      dispatch(getCartList());
    }
  }, [hasToken]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      const { data } = response;
      if (statusCode === 'LOGIN_SUCCESS') {
        notification.success({
          message: `${response.msg} ${data.firstname} ${data.lastname}`,
        });
        let redirectProps = {
          pathname: `${response.data.type}/profile`,
        };
        if (fromCheckout) {
          redirectProps = { ...redirectProps, state: { cartData } };
        }
        history.push(redirectProps);
      } else if (statusCode === 'UNAUTHORIZED') {
        notification.warning({ message: `${response.msg}` });
      } else if (statusCode === 'ACCOUNT_NOT_VERIFIED') {
        notification.warning({ message: `${response.msg}` });
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        name="login"
        className="form-flex"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="w-full lg:w-1/2 lg:pr-2">
          <div className="relative w-full my-3">
            <Form.Item
              label="Account Type"
              name="type"
              value=""
              rules={[
                { required: true, message: 'Please select account type!' },
              ]}
            >
              <Select
                placeholder="Select account type"
                dropdownAlign={{ offset: [-42, 0] }}
                value=""
              >
                <Select.Option value="participant">PARTICIPANT</Select.Option>
                <Select.Option value="exhibitor">EXHIBITOR</Select.Option>
              </Select>
            </Form.Item>
            <i className="fas fa-user text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Email Address"
              name="email"
              value=""
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Invalid email format!' },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
            <i className="fas fa-envelope text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Password"
              name="password"
              value=""
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <i className="fas fa-lock text-red-20 form-icon"></i>
          </div>
        </div>
        <div className="w-full lg:w-1/2 lg:pl-2">
          <div className="flex flex-col w-full items-center justify-center lg:mt-5">
            <div className="flex flex-col lg:flex-row w-full lg:w-auto lg:ml-28 xl:ml-36">
              <Button className="btn-main" htmlType="submit">
                <i className="fas fa-sign-in-alt mr-1"></i>
                <span>Login</span>
              </Button>
              <div className="flex items-center mx-auto pl-6 mt-10 lg:mt-0">
                <Link
                  to={{ pathname: '/forgot-password' }}
                  className="text-red-10 font-bold"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            <p className="text-red-10 font-bold p-10">OR</p>
            <div className="flex flex-col lg:flex-row w-full lg:w-auto">
              <Button className="btn-main my-1 lg:mx-1">
                <Link
                  to={{
                    pathname: 'register',
                    state: { cartData, fromCheckout },
                  }}
                  className="text-red-10 font-bold"
                >
                  <i className="fas fa-file-signature mr-1"></i>
                  <span>Sign Up</span>
                </Link>
              </Button>
              <Button className="btn-main my-1 lg:mx-1">
                <a href="/expo" className="text-red-10 font-bold">
                  <i className="fa fa-globe mr-1"></i>
                  <span>EXPO</span>
                </a>
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </FormWrapper>
  );
}

export default FormLogin;
