import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, notification } from 'antd';
import { useDispatch } from 'react-redux';

const TableAddress = (props) => {
  const dispatch = useDispatch();
  const { addresses, loading, deleteAddress, getAddressList } = props;
  const [deleteProductID, setDeleteProductID] = useState(null);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 900,
      render: (data, rowData) =>
        rowData.attributes.firstname + ' ' + rowData.attributes.lastname,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 900,
      render: (data, rowData) => rowData.attributes.email,
    },
    {
      title: 'Phone',
      dataIndex: 'name',
      key: 'name',
      width: 900,
      render: (data, rowData) => rowData.attributes.phone,
    },
    {
      title: 'Default shipping address',
      dataIndex: 'default_shipping',
      key: 'default_shipping',
      width: 900,
      render: (data, rowData) =>
        rowData.attributes.default_shipping ? 'Yes' : 'No',
    },
    {
      title: 'Default billing address',
      dataIndex: 'default_billing',
      key: 'default_billing',
      width: 900,
      render: (data, rowData) =>
        rowData.attributes.default_billing ? 'Yes' : 'No',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectIdToDelete = (data) => {
    setDeleteProductID(data);
  };

  const onConfirm = () => {
    dispatch(deleteAddress(deleteProductID, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code, msg } = response;
      notification.success({ message: 'My address is successfully removed!' });
    } else {
      notification.error({
        message: 'Error',
      });
    }
    dispatch(getAddressList(null, 0));
    window.scrollTo(0, 0);
  };

  const renderAction = (data, rowData) => {
    return (
      <div className="flex items-center">
        <Link to={`/otop-hub/my-addresses/${rowData.id}/edit`}>
          <button className="btn">
            <i className="far fa-edit"></i>
          </button>
        </Link>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={onConfirm}
          onClick={() => onSelectIdToDelete(rowData.id)}
        >
          <button className="btn">
            <i className="far fa-trash-alt"></i>
          </button>
        </Popconfirm>
      </div>
    );
  };

  const renderExpandedRow = (rowData) => {
    return (
      <ul className="flex flex-wrap">
        <li className="w-1/5">First Name</li>
        <li className="w-4/5">{rowData.attributes.firstname}</li>
        <li className="w-1/5">Last Name</li>
        <li className="w-4/5">{rowData.attributes.lastname}</li>
        <li className="w-1/5">Email Address</li>
        <li className="w-4/5">{rowData.attributes.email}</li>
        <li className="w-1/5">Phone Number</li>
        <li className="w-4/5">{rowData.attributes.phone}</li>
        <li className="w-1/5">Province</li>
        <li className="w-4/5">{rowData.attributes.state}</li>
        <li className="w-1/5">City/Municipality</li>
        <li className="w-4/5">{rowData.attributes.city}</li>
        <li className="w-1/5">Address Line 1</li>
        <li className="w-4/5">{rowData.attributes.address_line1}</li>
        <li className="w-1/5">Address Line 2</li>
        <li className="w-4/5">{rowData.attributes.address_line2}</li>
        <li className="w-1/5">Zip Code</li>
        <li className="w-4/5">{rowData.attributes.zipcode}</li>
        <li className="w-1/5">Default Shipping Address</li>
        <li className="w-4/5">
          {rowData.attributes.default_shipping ? 'Yes' : 'No'}
        </li>
        <li className="w-1/5">Default Billing Address</li>
        <li className="w-4/5">
          {rowData.attributes.default_billing ? 'Yes' : 'No'}
        </li>
      </ul>
    );
  };

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={addresses}
        expandable={{
          expandedRowRender: (rowData) => renderExpandedRow(rowData),
        }}
      />
    </div>
  );
};

export default TableAddress;
