import { getAuthHeader, getError} from "../../../shared/utils";
import {
  API_OTOP_LOGIN,
  API_OTOP_LOGOUT,
  API_OTOP_ADMIN_SHOW
} from "../../../shared/constant/url";

export const AUTH = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT_START: "LOGOUT_START",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  SHOW_ADMIN_START: "SHOW_ADMIN_START",
  SHOW_ADMIN_SUCCESS: "SHOW_ADMIN_SUCCESS",
  SHOW_ADMIN_ERROR: "SHOW_ADMIN_ERROR",
};

export const loginOtop = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.LOGIN_START });
    let formData = new FormData();
    formData.append("email", params.email);
    formData.append("password", params.password);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_OTOP_LOGIN, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.LOGIN_SUCCESS, payload: response.data },
          response.data && localStorage.setItem("otopToken", response.token),
          response.data && localStorage.setItem("userId", response.data.data.id),
          response.data && localStorage.setItem("userType", response.data.data.attributes.type),
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: AUTH.LOGIN_ERROR, payload: getError(error) });
      });
  };
};

export const logoutOtop = (callback=null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.LOGOUT_START });
    localStorage.clear();
    let reqOptions = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_LOGOUT, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.LOGOUT_SUCCESS, payload: response.data },
          response.data && localStorage.setItem("false", true)
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: AUTH.LOGOUT_ERROR, payload: getError(error) });
      });
  };
};

