import { getError, getAuthHeader } from "../../../../shared/utils";
import { API_OTOP_CREATE_SELLER, API_OTOP_SELLER_LIST, API_OTOP_SELLER_DELETE } from "../../../../shared/constant/url";

export const CREATE = {
  CREATE_OTOP_SELLER_START: "CREATE_OTOP_SELLER_START",
  CREATE_OTOP_SELLER_SUCCESS: "CREATE_OTOP_SELLER_SUCCESS",
  CREATE_OTOP_SELLER_ERROR: "CREATE_OTOP_SELLER_ERROR",
 
  OTOP_SELLER_LIST_START: "OTOP_SELLER_LIST_START",
  OTOP_SELLER_LIST_SUCCESS: "OTOP_SELLER_LIST_SUCCESS",
  OTOP_SELLER_LIST_ERROR: "OTOP_SELLER_LIST_ERROR",

  OTOP_SELLER_DELETE_START: "OTOP_SELLER_DELETE_START",
  OTOP_SELLER_DELETE_SUCCESS: "OOTOP_SELLER_DELETE_SUCCESS",
  OTOP_SELLER_DELETE_ERROR: "OTOP_SELLER_DELETE_ERROR"
};


export const createOtopSeller = (params, slug, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CREATE.CREATE_OTOP_SELLER_START });
    let formData = new FormData();
    formData.append("type", params.type); 
    formData.append("firstname", params.firstname); 
    formData.append("lastname", params.lastname);
    formData.append("contact_number", params.contact_number);
    formData.append("email", params.email);
    formData.append("password", params.password);
    formData.append("password_confirmation", params.password_confirmation);
    if (slug) {
      formData.append("slug", slug);
    }

    let reqOptions = {
      method: "POST",
      body: formData,
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_CREATE_SELLER, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: CREATE.CREATE_OTOP_SELLER_SUCCESS, payload: response.data }
          // localStorage.setItem("token", response.token)
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: CREATE.CREATE_OTOP_SELLER_ERROR, payload: getError(error) });
      });
  };
};

export const getOtopSellerList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: CREATE.OTOP_SELLER_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_SELLER_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response)
        dispatch({
          type: CREATE.OTOP_SELLER_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE.OTOP_SELLER_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteOtopSeller = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CREATE.OTOP_SELLER_DELETE_START });

    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_SELLER_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CREATE.OTOP_SELLER_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: CREATE.OTOP_SELLER_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

