import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Button,
  Upload,
  notification,
  Input,
  Radio,
  Modal,
  Space,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import FormWrapper from '../../../shared/components/FormWrapper';
import { PayMaya, PayNow } from '../components/PaymentModal';
import { transactionCreate } from '../actions';
import { getPaymentOptionList } from '../../payment-option/actions';
import { Spin } from 'antd';
import paymaya from '../../../shared/assets/images/logo/nobg/paymaya.png';
import { cartProductRemove } from '../actions';

function intersect(a, b) {
  var setB = new Set(b);
  return [...new Set(a)].filter((x) => setB.has(x));
}

function FormShippingDetails(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listPayment } = state.payment;
  const { list, history, total, exhibitor } = props;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState('cod');
  const [fileList, updateFileList] = useState([]);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(null);
  const [isSameAddress, setIsSameAddress] = useState(null);
  const [cartProd, setCartProd] = useState([]);
  const [checkProd, setCheckProd] = useState([]);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [value, setValue] = useState('');
  const checkoutList = JSON.parse(sessionStorage.getItem('checkout'));

  const getCartProduct = () => {
    const data = sessionStorage.getItem('cartProducts');
    if (data) {
      return setCartProd(JSON.parse(data));
    } else {
      return [];
    }
  };

  // useEffect(() => {
  //   if (list && list[0]) {
  //     const { data } = list[0].bankdetails;
  //     setBankDetails(data);
  //   }
  //   getCartProduct()
  // }, [list]);

  useEffect(() => {
    if (list && list[0]) {
      const data = list[0]['product']['data']['bankdetails'];
      setBankDetails(data);
    }
    getCartProduct();
  }, [list]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const openModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const onFinish = (values) => {
    startTimer();
    setValue(values);
  };

  useEffect(() => {
    renderPaymentOption();
    if (seconds < 4) {
    } else if (seconds === 5) {
      let proof = [];
      for (const [key, val] of Object.entries(value)) {
        if (key.includes('images')) {
          let len = key.length;
          let id = key.slice(7, len - 1);
          proof.push({ exhibitor_id: id, upload: val });
        }
      }
      value['proof'] = proof;
      value['payment_type'] = modeOfPayment ? modeOfPayment : 'COD';
      dispatch(transactionCreate(value, list, callback));
      setIsDisabled(true);
    }
  }, [seconds]);

  const startTimer = () => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const deleteItem = () => {
    let arr = [];
    let arr1 = [];
    checkProd.map((item) => {
      return arr.push(item.name);
    });
    cartProd.map((item) => {
      arr1.push(item.name);
      if (arr1) {
        dispatch(cartProductRemove(arr, callback));
      }
    });
  };

  const get_payment_options_as_array = (item) => {
    return new Promise((resolve, reject) => {
      let arr = item.paymentoption.data;
      let new_arr = [];

      if (arr.length == 0) {
        resolve([]);
      }

      let index = 0;

      arr.map((obj) => {
        index++;
        if (obj.status === '1') {
          new_arr.push(obj.name);
          if (index == arr.length) {
            resolve(new_arr);
          }
        }
      });
    });
  };

  const intersect_arrays = (arrays = []) => {
    return new Promise((resolve, reject) => {
      if (arrays.length == 0) {
        resolve([]);
      }

      let index = 0;
      let current_arr = [];
      arrays.map((arr) => {
        //arr is like ["Cod", "Pay Now"]
        index++;
        if (index == 1) {
          current_arr = arr;
        }

        current_arr = intersect(current_arr, arr);

        if (index == arrays.length) {
          resolve(current_arr);
        }
      });
    });
  };

  const renderPaymentOption = () => {
    let index2 = 0;
    let payment_methods_array = [];
    checkoutList.map(async (item, index) => {
      index2++;
      let payment_names_array = await get_payment_options_as_array(item);
      payment_methods_array.push(payment_names_array);

      if (index2 == checkoutList.length) {
        let intersected_array = await intersect_arrays(payment_methods_array);
        setAvailablePaymentOptions(intersected_array);

        if (intersected_array.length == 0) {
          //alert the user to try to checkout to each merchant
          notification.warn({
            message: 'No Payment methods available',
            description:
              'Try to checkout per merchant to see their available payment methods',
          });
        }
      }
    });
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (response.data) {
        notification.success({
          message: `Your order # ${response.data.attributes.code} is successfully added`,
          description: 'Check your profile for transaction status!',
        });
        history.push('/expo');
        // sessionStorage.clear();
        deleteItem();
      } else if (statusCode === 'INVALID_DATA') {
        notification.warn({ message: `${response.msg}` });
      } else if (response.redirectUrl) {
        notification.success({
          message: 'Transaction will proceed to paymaya...',
        });
        window.location.replace(response.redirectUrl);
        deleteItem();
        // sessionStorage.clear();
      } else if (statusCode === 'TRANSACTION_CREATED') {
        notification.success({
          message: `Your order has been sent to exhibitor`,
          description: 'Check your profile for transaction status!',
        });
        history.push('/expo');
        // sessionStorage.clear();
        deleteItem();
      }
    } else {
      // notification.error({ message: `${error.msg}` });
    }
    window.scrollTo(0, 0);
  };

  const onChange = (e) => {
    console.log(`radio checked:${e.target.value}`);
    setModeOfPayment(e.target.value);
  };

  // const propsFile = {
  //   fileList,
  //   multiple: false,
  //   beforeUpload: (file) => {
  //     // if (
  //     //   file.type !== "image/png" ||
  //     //   file.type !== "image/jpeg" ||
  //     //   file.type !== "image/jpg"
  //     // ) {
  //     //   message.error(`${file.name} is not an image file`);
  //     // }
  //     return file.type === "image/png" ||
  //       file.type === "image/jpeg" ||
  //       file.type === "image/jpg"
  //       ? true
  //       : Upload.LIST_IGNORE;
  //   },
  //   onChange: (info) => {
  //     // console.log(info.fileList);
  //     // file.status is empty when beforeUpload return false
  //     updateFileList(info.fileList.filter((file) => !!file.status));
  //   },
  // };

  // const renderBankDetails = (data) => {
  //   return data.map((item) => (
  //     <div className="w-full lg:w-1/3">
  //       <ul className="flex flex-wrap w-full">
  //         <li className="w-1/3">
  //           <h1>Account Number</h1>
  //         </li>
  //         <li className="w-2/3">
  //           <p>{item.account_number}</p>
  //         </li>
  //         <li className="w-1/3">
  //           <h1>Account Name</h1>
  //         </li>
  //         <li className="w-2/3">
  //           <p>{item.account_name}</p>
  //         </li>
  //         <li className="w-1/3">
  //           <h1>Payment Name</h1>
  //         </li>
  //         <li className="w-2/3">
  //           <p>{item.payment_name}</p>
  //         </li>
  //       </ul>
  //     </div>
  //   ));
  // };

  const renderBankDetails = (data) => {
    return data.map((item) =>
      item.status === 'active' ? (
        <div className="w-full lg:w-1/3">
          <ul className="flex flex-wrap w-full">
            <li className="w-1/3">
              <h1>Account Number</h1>
            </li>
            <li className="w-2/3">
              <p>{item.account_number}</p>
            </li>
            <li className="w-1/3">
              <h1>Account Name</h1>
            </li>
            <li className="w-2/3">
              <p>{item.account_name}</p>
            </li>
            <li className="w-1/3">
              <h1>Payment Name</h1>
            </li>
            <li className="w-2/3">
              <p>{item.payment_name}</p>
            </li>
          </ul>
        </div>
      ) : (
        ''
      )
    );
  };

  const renderOnPayNow = () => {
    if (!isEmpty(exhibitor)) {
      return exhibitor.map((item, index) => {
        index = index + 1;
        const data = item['product']['data']['bankdetails']['data'];
        let firstname = item['product']['data']['exhibitor']['firstname'];
        let id = item['product']['data']['exhibitor']['id'];
        let lastname = item['product']['data']['exhibitor']['lastname'];
        let company_name = item['product']['data']['exhibitor']['company_name'];

        return (
          <div className="mt-5">
            <h1 className="text-red-20 font-bold">
              {index}. {firstname} {lastname}
            </h1>
            <code>{company_name}</code>
            <div className="flex flex-wrap mt-5">{renderBankDetails(data)}</div>
            {renderUploadProofOfPayment(item, index)}
          </div>
        );
      });
    } else {
      return <div>&nbsp;</div>;
    }
  };

  const renderUploadProofOfPayment = (item, index) => {
    return (
      <div className="flex mt-5">
        {/* <Form.Item
        name={`image.${item.id}`}
        rules={[
          {
            required: modeOfPayment === "pay_now" ? true : false,
            message: "Please upload your proof of payment!",
          },
        ]}
      >
        <Upload {...propsFile}>
          <Button icon={<UploadOutlined />}>
            Upload Proof of Payment
          </Button>
        </Upload>
      </Form.Item> */}
        <Form.Item
          name={`images[${item['product']['data']['exhibitor']['data']['id']}]`}
          rules={[
            {
              required: true,
              message: 'Please upload proof of payment!',
            },
          ]}
        >
          <Upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
            <Button icon={<UploadOutlined />}>Upload Proof of Payment</Button>
          </Upload>
        </Form.Item>
      </div>
    );
  };

  const onSameAddressChange = (e) => {
    setIsSameAddress(e.target.value);
  };

  return (
    <>
      {seconds >= 1 ? (
        <div className="flex justify-center h-screen fixed top-0 items-center left-0 opacity-30 w-full bg-black">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <h1 className="text-red-10 text-2xl font-semibold mt-20 mb-10 uppercase font-semibold">
            PAYMENT / DELIVERY DETAILS
          </h1>
          <FormWrapper>
            <Form
              name="searchFilterExpo"
              form={form}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="w-full rounded-lg shadow mt-5 bg-white">
                <div className="flex flex-col lg:flex-row lg:items-center px-10 py-10">
                  <h3 className="text-red-20 text-sm mr-5 lg:text-xl">
                    Payment Options
                  </h3>

                  <Form.Item
                    name="payment_type"
                    defaultValue="cod"
                    rules={[
                      {
                        required: true,
                        message: 'Please select payment type!',
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} className="mt-5 flex">
                      {availablePaymentOptions.includes('Cod') && (
                        <Radio.Button
                          value={'cod'}
                          className="w-64 flex justify-center font-bold lg:text-xl text-red-10"
                        >
                          <i className="fas fa-wallet"></i>&nbsp;Cash On
                          Delivery
                        </Radio.Button>
                      )}
                      {availablePaymentOptions.includes('Paymaya') && (
                        <Radio.Button
                          value={'paymaya'}
                          className="w-64 flex justify-center font-bold lg:text-xl text-red-10"
                        >
                          <i className="fas fa-wallet"></i>&nbsp;Paymaya
                        </Radio.Button>
                      )}
                      {availablePaymentOptions.includes('Pay_Now') && (
                        <Radio.Button
                          value={'pay_now'}
                          className="w-64 flex justify-center font-bold lg:text-xl text-red-10"
                        >
                          <i className="fas fa-wallet"></i>&nbsp;Pay Now
                        </Radio.Button>
                      )}
                    </Radio.Group>
                  </Form.Item>

                  {/* <button className="btn btn-blue py-3 mt-5 lg:mt-0">
                
                <span className="uppercase text-xs">CASH ON DELIVERY</span>
              </button>
              <button className="border-2 border-gray-30 text-blue-20 py-3 px-5 mx-3 rounded-lg mt-5 lg:mt-0 flex items-center justify-center opacity-25 cursor-not-allowed">
                <img
                  src={paymaya}
                  alt="Virtual Trade Expo Ph"
                  className="w-auto h-5 mr-5 object-center"
                />
                <span className="uppercase text-xs">PAYMAYA</span>
              </button> */}
                  {/* <button className="border-2 border-gray-30 text-blue-20 py-3 px-5 mx-3 rounded-lg mt-5 lg:mt-0 opacity-25 cursor-not-allowed">
            <i className="fas fa-university"></i>&nbsp;
            <span className="uppercase text-xs">BANK DEPOSIT</span>
          </button> */}
                </div>
                {modeOfPayment === 'pay_now' && (
                  <>
                    <div className="border-dashed border-b-2 border-gray-20"></div>
                    <div className="m-10">
                      <h1 className="text-xl text-red-10">Bank Details</h1>
                      <code>
                        Choose any available bank account / payment type below
                        provided by exhibitor for you to pay your order.
                      </code>
                      <div className="mb-10">{renderOnPayNow()}</div>
                    </div>
                  </>
                )}
                <div className="border-dashed border-b-2 border-gray-20"></div>
                <div className="flex flex-col lg:flex-row p-10">
                  <div className="w-full lg:w-1/2 pr-5">
                    <h3 className="text-sm lg:text-lg text-red-20 mb-5">
                      Shipping Address Details
                    </h3>

                    <div className="flex flex-wrap -mx-2">
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="First Name"
                          name="shipping_firstname"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your first name!',
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Last Name"
                          name="shipping_lastname"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your last name!',
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Contact Number"
                          name="shipping_phone"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your contact number!',
                            },
                            {
                              len: 11,
                              message: 'Contact Number must be 11 digits.',
                            },
                            {
                              pattern: new RegExp(/[^a-z\s]/g),
                              message: 'Invalid format',
                            },
                          ]}
                        >
                          <Input placeholder="Contact Number" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Email Address"
                          name="shipping_email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your email address!',
                            },
                            { type: 'email', message: 'Invalid email format!' },
                          ]}
                        >
                          <Input placeholder="Email Address" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Address Line 1"
                          name="shipping_address_line1"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your address line 1!',
                            },
                          ]}
                        >
                          <Input placeholder="Address Line 1" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Address Line 2"
                          name="shipping_address_line2"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your address line 2!',
                            },
                          ]}
                        >
                          <Input placeholder="Address Line 2" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="City"
                          name="shipping_city"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your city!',
                            },
                          ]}
                        >
                          <Input placeholder="City" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full lg:w-1/2 px-2 my-3">
                        <Form.Item
                          label="Zip Code"
                          name="shipping_zipcode"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your zip code!',
                            },
                            {
                              pattern: new RegExp(/^\d+$/g),
                              message: 'Invalid format',
                            },
                          ]}
                        >
                          <Input placeholder="Zip Code" />
                        </Form.Item>
                      </div>
                      <div className="relative w-full px-2 my-3">
                        <Form.Item
                          label="Is Billing Address same with Shipping Address?"
                          name="same_address"
                          rules={[
                            {
                              required: true,
                              message: 'Field is required!',
                            },
                          ]}
                        >
                          <Radio.Group onChange={onSameAddressChange}>
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>

                    {isSameAddress === 'no' && (
                      <>
                        <h3 className="text-sm lg:text-lg text-red-20 mb-5">
                          Billing Address Details
                        </h3>

                        <div className="flex flex-wrap -mx-2">
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="First Name"
                              name="billing_firstname"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your first name!',
                                },
                              ]}
                            >
                              <Input placeholder="First Name" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Last Name"
                              name="billing_lastname"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your last name!',
                                },
                              ]}
                            >
                              <Input placeholder="Last Name" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Contact Number"
                              name="billing_phone"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your contact number!',
                                },
                                {
                                  min: 11,
                                  message: 'Contact Number must be 11 digits.',
                                },
                                {
                                  x: 11,
                                  message: 'Contact Number must be 11 digits.',
                                },
                                {
                                  pattern: new RegExp(/[^a-z\s]/g),
                                  message: 'Invalid format',
                                },
                              ]}
                            >
                              <Input placeholder="Contact Number 09xxxxxxxxx" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Email Address"
                              name="billing_email"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your email address!',
                                },
                                {
                                  type: 'email',
                                  message: 'Invalid email format!',
                                },
                              ]}
                            >
                              <Input placeholder="Email Address" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Address Line 1"
                              name="billing_address_line1"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your address line 1!',
                                },
                              ]}
                            >
                              <Input placeholder="Address Line 1" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Address Line 2"
                              name="billing_address_line2"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your address line 2!',
                                },
                              ]}
                            >
                              <Input placeholder="Address Line 2" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="City"
                              name="billing_city"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your city!',
                                },
                              ]}
                            >
                              <Input placeholder="City" />
                            </Form.Item>
                          </div>
                          <div className="relative w-full lg:w-1/2 px-2 my-3">
                            <Form.Item
                              label="Zip Code"
                              name="billing_zipcode"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your zipcode!',
                                },
                                {
                                  pattern: new RegExp(/^\d+$/g),
                                  message: 'Invalid format',
                                },
                              ]}
                            >
                              <Input placeholder="Zip Code" />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2 pl-5 lg:text-lg text-sm">
                    <h3 className="text-red-20 mb-5">Order Total</h3>
                    <div className="text-red-20">
                      <div className="flex justify-between my-3">
                        <p>Subtotal</p>
                        <p>PHP {total ? total : '0.00'}</p>
                      </div>
                      <div className="flex justify-between my-3">
                        <p className="text-blue-20">
                          Note: Shipping fee is not included, please coordinate
                          with the Exhibitor/Seller
                        </p>
                        {/* <p>PHP 0.00</p> */}
                      </div>
                      <div className="flex text-sm lg:text-xl justify-between bg-red-20 text-white rounded-lg p-3">
                        <p>Grand Total</p>
                        <p>PHP {total ? total.toLocaleString() : '0'}.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10">
                <Button
                  onClick={() => history.push('/cart')}
                  className="btn btn-red text-center w-32 block mx-2"
                >
                  <i className="fas fa-times-circle text-white pr-2"></i>
                  <span>Cancel</span>
                </Button>

                {isDisabled ? (
                  <Button
                    className="btn btn-red text-center w-32 block ml-2"
                    htmlType="submit"
                    disabled
                  >
                    <i className="fas fa-check-circle text-white pr-2"></i>
                    <span>Proceed</span>
                  </Button>
                ) : (
                  <Button
                    className="btn btn-red text-center w-32 block ml-2"
                    htmlType="submit"
                  >
                    <i className="fas fa-check-circle text-white pr-2"></i>
                    <span>Proceed</span>
                  </Button>
                )}
              </div>
            </Form>
            <Modal
              title="PayMaya"
              footer={null}
              visible={isModalVisible}
              onCancel={handleCancel}
            >
              <PayMaya />
            </Modal>
            <Modal
              title="Proof of Payment"
              footer={null}
              visible={isVisible}
              onCancel={handleClose}
            >
              <PayNow />
            </Modal>
          </FormWrapper>
        </>
      )}
    </>
  );
}

export default FormShippingDetails;
