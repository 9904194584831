import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_SPEAKER_LIST,
  API_SPEAKER_SHOW,
  API_EXHIBITOR_SHOW_SPEAKER,
} from "../../shared/constant/url";

export const SPEAKER = {
  SPEAKER_LIST_START: "SPEAKER_LIST_START",
  SPEAKER_LIST_SUCCESS: "SPEAKER_LIST_SUCCESS",
  SPEAKER_LIST_ERROR: "SPEAKER_LIST_ERROR",

  SPEAKER_SHOW_START: "SPEAKER_SHOW_START",
  SPEAKER_SHOW_SUCCESS: "SPEAKER_SHOW_SUCCESS",
  SPEAKER_SHOW_ERROR: "SPEAKER_SHOW_ERROR",

  SPEAKER_SHOW_EXHIBITOR_START: "SPEAKER_SHOW_EXHIBITOR_START",
  SPEAKER_SHOW_EXHIBITOR_SUCCESS: "SPEAKER_SHOW_EXHIBITOR_SUCCESS",
  SPEAKER_SHOW_EXHIBITOR_ERROR: "SPEAKER_SHOW_EXHIBITOR_ERROR",
};

export const getSpeakerList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: SPEAKER.SPEAKER_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 15);
    }

    formData.append("page", 1);

    if (params) {
      formData.append("keyword", params.keyword);
    }

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SPEAKER_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: SPEAKER.SPEAKER_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type: SPEAKER.SPEAKER_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const showSpeakerDetails = (speaker_id) => {
  return (dispatch) => {
    dispatch({ type: SPEAKER.SPEAKER_SHOW_START });
    let formData = new FormData();
    formData.append("speaker_id", speaker_id);
    formData.append("include", "expo");
    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SPEAKER_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: SPEAKER.SPEAKER_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: SPEAKER.SPEAKER_SHOW_ERROR, payload: error });
      });
  };
};

export const showSpeakerDetailsExhibitor = (speaker_id) => {
  return (dispatch) => {
    dispatch({ type: SPEAKER.SPEAKER_SHOW_EXHIBITOR_START });
    let formData = new FormData();
    formData.append("speaker_id", speaker_id);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_SHOW_SPEAKER, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: SPEAKER.SPEAKER_SHOW_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SPEAKER.SPEAKER_SHOW_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};