import React from "react";
import { Link } from "react-router-dom";
import { List, Card } from "antd";

import expo1 from "../../../shared/assets/images/img/expo/expo1.png";
import CardWrapper from "../../../shared/components/CardWrapper";

function ListProduct(props) {
  const { products, loadingExpo, slug, isExhibitor } = props;

  const renderPrice = (data) => {
    if (data.discount_rate) {
      return (
        <div className="flex">
          <p className="text-gray-20 line-through mr-2">{data.display_price}</p>
          <p>{data.selling_price.toFixed()}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>{data.display_price}</p>
        </div>
      );
    }

  };

  return (
    <>
      <h1 className="mt-10 content-header-left">All Franchise Model</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 5,
          xl: 5,
          xxl: 5,
        }}
        pagination={true}
        dataSource={products}
        renderItem={(item) => (
          <List.Item>
            <CardWrapper>
              <Card
                bordered={false}
                className="card my-5"
                loading={loadingExpo}
              >
                <Link to={`/expo/${slug}/products/${item.id}`}>
                  <img
                    src={item.thumbnail ? item.thumbnail.full_path : expo1}
                    alt="Virtual Trade Expo Ph"
                    className="h-56 lg:h-56 w-full card-img"
                  />
                  <div className="card-content">
                    <h3 className="card-title text-control-1">{item.name}</h3>
                    <div className="card-body">
                      <div className="card-body-row">
                        <div className="flex justify-center items-center text-red-30 mt-2">
                          <i className="fas fa-tag text-2xl"></i>&nbsp;&nbsp;
                          <span className="font-semibold">
                            {renderPrice(item)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Card>
            </CardWrapper>
          </List.Item>
        )}
      />
    </>
  );
}

export default ListProduct;