import React, { useEffect, useState } from "react";
import { Form, Button, notification, Upload, Radio } from "antd";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";

import FormWrapper from "../../../../../shared/components/FormWrapper";

function FormWayBill(props) {
  const dispatch = useDispatch();
  const {
    editOrder,
    status,
    statusData,
    handleCancelToShip,
    getOrderList,
  } = props;
  const [form] = Form.useForm();
  const [fileList, updateFileList] = useState([]);
  const [hasFile, setHasFile] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    values["status_id"] = status;
    dispatch(
      editOrder(values, statusData.id, callback)
    );
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    console.log("Response here", response);
    if (response) {
      const { data } = response;
      if (data.type === "order") {
        notification.success({ message: "Payment status has been succesfully updated!" });
        handleCancelToShip();
        dispatch(getOrderList());
        form.resetFields();
        updateFileList([]);
      } else {
        notification.warning({ message: "Warning" });
      }
    }
  };

  const propsFile = {
    fileList,
    multiple: false,
    onChange: (info) => {
      // console.log(info.fileList);
      // file.status is empty when beforeUpload return false
      updateFileList(info.fileList.filter((file) => !!file.status));
    },
  };

  const onChange = (e) => {
    setHasFile(e.target.value);
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="form-payment"
          layout="vertical"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="flex flex-wrap w-full">
            <div className="w-full">
              <label>Do you want to upload your way bill?</label>
              <Form.Item
                name="hasFile"
                rules={[
                  {
                    required: true,
                    message: "Please select option!",
                  },
                ]}
              >
                <Radio.Group onChange={onChange}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {hasFile === "yes" && (
              <Form.Item
                name="remarks_image"
                className="w-full"
                rules={[
                  {
                    required: hasFile === "yes" ? true : false,
                    message: "Please upload file!",
                  },
                ]}
              >
                <Upload {...propsFile}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            )}
          </div>
          <div className="flex flex-col lg:flex-row items-center mt-5">
            <div className="flex mt-5 lg:mt-0">
              <Button className="btn btn-red w-40" htmlType="submit">
                Proceed
              </Button>
            </div>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormWayBill;