import React from 'react';
import { Card } from 'antd';
import CardWrapper from '../../../../shared/components/otop/CardWrapper';
import item1 from '../../../../shared/assets/otop/product/item1.png';
import item2 from '../../../../shared/assets/otop/product/item2.png';

const details = [
  {
    image: item1,
    title: 'OTOP MERCHANDISE',
    subtitle: 'by: OTOP HUB',
    price: 'P 250.00',
    rate: '5.0',
  },
  {
    image: item2,
    title: 'OTOP MERCHANDISE',
    subtitle: 'by: OTOP HUB',
    price: 'P 250.00',
    rate: '5.0',
  },
];

const products = ({ click }) => {
  return (
    <>
      {details.map((data) => (
        <CardWrapper>
          <Card onClick={click} className="card w-80 mr-5">
            <div className="flex justify-center">
              <img
                src={data.image}
                alt=""
                className="object-cover object-center h-72 w-full"
              />
            </div>

            <div className="card-content">
              <p className="card-title">{data.title}</p>
              <p className="card-subtitle">{data.subtitle}</p>
              <div className="flex items-center justify-between mt-5">
                <div>
                  <p className="card-price">{data.price}</p>
                  <p className="card-subtitle">{data.rate}</p>
                </div>
                <i className="fas fa-cart-plus text-3xl"></i>
              </div>
            </div>
          </Card>
        </CardWrapper>
      ))}
    </>
  );
};

export default products;
