import React from 'react';
import { Card, List } from 'antd';
import { Link } from 'react-router-dom';
import CardWrapper from '../../../../shared/components/otop/CardWrapper';
// import {otopAddtoCart} from '../../cart/actions'
import { filter, iteratee } from 'lodash';
import otop from '../../../../shared/assets/otop/Sellet/logo-1.svg';

const ListFeaturedProducts = (props) => {
  const { products, loading, productsFromStore, type } = props;

  const renderImg = (val) => {
    const { included } = products;
    let productImage = filter(included, iteratee({ type: 'product_image' }));

    if (productImage && val && val[0]) {
      let filtered = productImage.filter(
        (item) => parseInt(item.id) === parseInt(val[0].id)
      );
      if (filtered && filtered[0]) {
        return (
          <img
            src={filtered[0].attributes.path}
            alt="OTOP Hub Product"
            className="object-contain object-center w-full h-48"
          />
        );
      }
    }
    return (
      <img
        src={otop}
        alt="OTOP Hub Product"
        className="object-contain object-center w-full h-48"
      />
    );
  };
  // const renderAddToCart = (values) => {
  //   dispatch(otopAddtoCart(values))
  // }

  return (
    <CardWrapper>
      <List
        grid={{
          gutter: 12,
          xs: 5,
          sm: 5,
          md: 5,
          lg: 5,
          xl: 5,
          xxl: 6,
        }}
        pagination={true}
        dataSource={type === 'all' ? products.data : productsFromStore}
        renderItem={(item) => (
          <List.Item>
            <Link to={`/otop-hub/products/${item.id}`}>
              <Card
                loading={loading}
                hoverable
                bordered={false}
                className="card"
              >
                {renderImg(item['relationships']['images']['data'])}
                <div className="card-content">
                  <p className="card-title text-control-2">
                    {item.attributes.name}
                  </p>
                  <div className="flex items-center justify-between mt-5">
                    <div>
                      <p className="text-brown-10 lg:text-xl">
                        &#8369;{item.attributes.price}
                      </p>
                      {/* <p>{item.rate}</p> */}
                    </div>
                    {/* <button onClick={() => {renderAddToCart(item)}} className="btn">
                      <i className="fas fa-cart-plus card-body-icon"></i>
                    </button> */}
                  </div>
                </div>
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </CardWrapper>
  );
};

export default ListFeaturedProducts;
