import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_BANK_DETAILS_LIST,
  API_BANK_DETAILS_CREATE,
  API_BANK_DETAILS_READ,
  API_BANK_DETAILS_UPDATE,
  API_BANK_DETAILS_DELETE,
  API_BANK_DETAILS_UPDATE_STATUS,
} from "../../shared/constant/url";

export const BANK_DETAILS = {
  BANK_DETAILS_LIST_START: "BANK_DETAILS_LIST_START",
  BANK_DETAILS_LIST_SUCCESS: "BANK_DETAILS_LIST_SUCCESS",
  BANK_DETAILS_LIST_ERROR: "BANK_DETAILS_LIST_ERROR",

  BANK_DETAILS_READ_START: "BANK_DETAILS_READ_START",
  BANK_DETAILS_READ_SUCCESS: "BANK_DETAILS_READ_SUCCESS",
  BANK_DETAILS_READ_ERROR: "BANK_DETAILS_READ_ERROR",

  BANK_DETAILS_ADD_EDIT_START: "BANK_DETAILS_ADD_EDIT_START",
  BANK_DETAILS_ADD_EDIT_SUCCESS: "BANK_DETAILS_ADD_EDIT_SUCCESS",
  BANK_DETAILS_ADD_EDIT_ERROR: "BANK_DETAILS_ADD_EDIT_ERROR",

  BANK_DETAILS_DELETE_START: "BANK_DETAILS_DELETE_START",
  BANK_DETAILS_DELETE_SUCCESS: "BANK_DETAILS_DELETE_SUCCESS",
  BANK_DETAILS_DELETE_ERROR: "BANK_DETAILS_DELETE_ERROR",

  BANK_DETAILS_UPDATE_STATUS_START: "BANK_DETAILS_UPDATE_STATUS_START",
  BANK_DETAILS_UPDATE_STATUS_SUCCESS: "BANK_DETAILS_UPDATE_STATUS_SUCCESS",
  BANK_DETAILS_UPDATE_STATUS_ERROR: "BANK_DETAILS_UPDATE_STATUS_ERROR",
};

export const getBankDetailsList = () => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_LIST_START });
    let formData = new FormData();

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readBankDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_READ_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createBankDetails = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("account_number", params.account_number);
    formData.append("account_name", params.account_name);
    formData.append("payment_name", params.payment_name);
 
    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editBankDetails = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("account_number", params.account_number);
    formData.append("account_name", params.account_name);
    formData.append("payment_name", params.payment_name);
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_UPDATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteBankDetails = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_DELETE_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const updateStatusBankDetails = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: BANK_DETAILS.BANK_DETAILS_UPDATE_STATUS_START });
    let formData = new FormData();
    formData.append("bank_id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_UPDATE_STATUS, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_UPDATE_STATUS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: BANK_DETAILS.BANK_DETAILS_UPDATE_STATUS_ERROR,
          payload: getError(error),
        });
      });
  }
}