import React from 'react'
import Products from '../containers/Products'
import StandardContainer from '../../../../../shared/components/otop/StandardContainer';
import HeaderTitle from '../../../layouts/components/HeaderTitle';

const Landing = () => {
    return (
        <StandardContainer>
            <div className="main-container">
                <div className="content-bg mb-5">
                    <HeaderTitle />
                    <Products />
                </div>
            </div>
        </StandardContainer>
    )
}

export default Landing
