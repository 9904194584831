import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input, Button, Form, Checkbox, notification, Radio } from 'antd';
import FormWrapper from '../../../../shared/components/otop/FormWrapper';

export const AdminLogin = ({ login, loginOtop }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(loginOtop(values, callback));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    // console.log("RESPONSE", response)
    // console.log("ERROR HERE", error)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'LOGIN_SUCCESS') {
        notification.success({ message: `${response.msg}` });
        history.push('/otop-hub/admin');
      } else if (statusCode === 'UNAUTHORIZED') {
        notification.warning({ message: `${response.msg}` });
      } else if (statusCode === 'ACCOUNT_NOT_VERIFIED') {
        notification.warning({ message: `${response.msg}` });
      } else if (response.message === 'Invalid Login Credentials') {
        notification.warning({ message: `${response.message}` });
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Invalid email format!' },
          ]}
        >
          <Input placeholder="Email Address Here" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <div className="flex justify-between mb-5">
          <div className="flex items-center">
            <Checkbox className="mr-2" />
            <p>Remember Me</p>
          </div>
          <p>Forgot Password?</p>
        </div>
        <div className="flex items-center">
          <Button htmlType="submit" className="flex items-center mr-5">
            <i className="fas fa-sign-in-alt text-lg"></i>
            Sign In
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
};
