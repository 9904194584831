import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { List, Card, Image, Skeleton } from 'antd';

import CardWrapper from '../../../shared/components/CardWrapper';
import ModalUser from '../../general/components/ModalUser';

function ListExhibitor(props) {
  const { regions, exhibitors, loadingExpo } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pageSize = 10;

  return (
    <>
      <h1 className="mt-10 content-header-left">OUR EXHIBITORS</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        pagination={true}
        dataSource={exhibitors}
        renderItem={(item) => (
          <List.Item>
            <CardWrapper>
              <Card
                bordered={false}
                className="card my-5"
                loading={loadingExpo}
                key={item.id}
              >
                <Link to={`/booth/exhibitors/${item.id}`}>
                  <div className="relative card-img">
                    <div
                      className={`absolute h-full w-full top-0 left-0 ${
                        !item.thumbnail.filename ? 'card-img-placeholder' : ''
                      }`}
                    >
                      {item.thumbnail.filename && (
                        <Image
                          preview={false}
                          placeholder={
                            <Skeleton.Avatar shape="square" active />
                          }
                          src={item.thumbnail.full_path}
                          alt="Virtual Trade Expo Ph"
                          width={'100%'}
                          height={'100%'}
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-content">
                    <h3 className="card-title text-control-1 lg:text-xl">
                      {item.company_name}
                    </h3>
                    <div className="card-body">
                      <div className="card-body-row">
                        <div className="flex items-center">
                          <div className="flex items-center my-3 text-control-1">
                            <i className="fas fa-map text-red-20 text-2xl mr-2"></i>
                            <span className="text-gray-40 text-xl truncate">
                              {regions?.find(
                                (region) =>
                                  region.code === item.business_info.region
                              )?.name ?? ''}
                            </span>
                          </div>
                          {/* <div className="flex items-center my-3 text-control-1 w-1/2">
                            <i className="fas fa-list-alt text-red-20 text-2xl mr-2"></i>
                            <span className="text-gray-40 text-xl truncate">
                              {item.business_info.category ?? ''}
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Card>
            </CardWrapper>
          </List.Item>
        )}
      />
      <ModalUser
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default ListExhibitor;
