import React, { useEffect, useState } from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from './containers/PageHeader';
import PageFooter from './containers/PageFooter';
import PageContent from './containers/PageContent';
import OtopMainLanding from '../otop/layouts/Landing';
import PageContentWrapper from '../../shared/components/PageContentWrapper';
import { readProfile, readProfileExhibitor } from '../profile/actions';
import PrivateRoute from '../otop/auth/PrivateRoute';
import { showExpoDetails, getExpoList } from '../expo/actions';
function Landing(props) {
  const { slug } = props.match.params;
  const { path } = props.match;
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { profileDetails, profileDetailsExhibitor } = state.profile;
  const { expo, totalExpo, expoDetails } = state.expo;
  let token = localStorage.getItem('token');
  let otopToken = localStorage.getItem('otopToken');
  let isExhibitor = localStorage.getItem('isExhibitor');
  const [isAuth, setIsAuth] = useState(false);
  const [otopScreen, setOtopScreen] = useState(false);
  const { pathname } = history.location;

  let vte = sessionStorage.getItem('vte');
  let otop = sessionStorage.getItem('otop');

  useEffect(() => {
    if (isExhibitor === 'true' && token) {
      dispatch(readProfileExhibitor(callbackExhibitor));
    }
  }, [isExhibitor, readProfileExhibitor, token]);

  useEffect(() => {
    if (isExhibitor === 'false' && token) {
      dispatch(readProfile(callback));
    }
  }, [isExhibitor, readProfile, token]);

  const callback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'ACCOUNT_LOGOUT') {
        history.push('/login');
        localStorage.clear();
        // sessionStorage.clear();
      }
    }
  };

  useEffect(() => {
    if (slug) {
      if (pathname === `/expo/${expo.slug}`) {
        history.push(`/expo${expo.slug}`);
        setOtopScreen(false);
      }
    }
  }, []);

  const callbackExhibitor = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'ACCOUNT_LOGOUT') {
        history.push('/login');
        localStorage.clear();
      }
    }
  };

  function click() {
    setOtopScreen(true);
    sessionStorage.clear();
    if (otopToken) {
      history.push('/otop-hub/admin');
    } else {
      history.push('/otop-hub/products');
    }
  }

  function toVte() {
    setOtopScreen(false);
    history.push('/');
  }
  useEffect(() => {
    if (vte) {
      setOtopScreen(false);
    }
  }, []);

  function toVte() {
    setOtopScreen(false);
    sessionStorage.setItem('vte', true);
    history.push('/');
  }

  const renderPrivateRoute = () => {
    return <PrivateRoute />;
  };

  // useEffect(() => {
  //   if(renderPrivateRoute){
  //     setOtopScreen(true);
  //     renderPrivateRoute();
  //   } else if(renderPrivateRoute){
  //     setOtopScreen(false);
  //     renderPrivateRoute();
  //   }
  // }, [])

  useEffect(() => {
    switch (pathname) {
      case '/':
        history.push('/');
        setOtopScreen(false);
        break;
      case '/expo':
        history.push('/expo');
        setOtopScreen(false);
        break;
      case '/expo/create':
        history.push('/expo/create');
        setOtopScreen(false);
        break;
      case `/expo/${expoDetails.slug}`:
        history.push(`/expo/${expoDetails.slug}`);
        setOtopScreen(false);
        break;
      case `/expo/${expo.slug}/products/${expo.id}`:
        history.push(`/expo/${expo.slug}/products/${expo.id}`);
        setOtopScreen(false);
        break;
      case '/exhibitor/profile':
        history.push('/exhibitor/profile');
        setOtopScreen(false);
        break;
      case '/exhibitor/expo':
        history.push('/exhibitor/expo');
        setOtopScreen(false);
        break;
      case '/login':
        history.push('/login');
        setOtopScreen(false);
        break;
      case '/exhibitor/activities':
        history.push('/exhibitor/activities');
        setOtopScreen(false);
        break;
      case '/exhibitor/products':
        history.push('/exhibitor/products');
        setOtopScreen(false);
        break;
      case '/exhibitor/products/create':
        history.push('/exhibitor/products/create');
        setOtopScreen(false);
        break;
      case '/exhibitor/my-orders':
        history.push('/exhibitor/my-orders');
        setOtopScreen(false);
        break;
      case '/exhibitor/sales-record':
        history.push('/exhibitor/sales-record');
        setOtopScreen(false);
        break;
      case '/exhibitor/sales-record/create':
        history.push('/exhibitor/sales-record/create');
        setOtopScreen(false);
        break;
      case '/exhibitor/bank-details':
        history.push('/exhibitor/bank-details');
        setOtopScreen(false);
        break;
      case '/exhibitor/bank-details/create':
        history.push('/exhibitor/bank-details/create');
        setOtopScreen(false);
        break;
      case '/cart':
        history.push('/cart');
        setOtopScreen(false);
        break;
      case '/cart/checkout':
        history.push('/cart/checkout');
        setOtopScreen(false);
        break;
      case '/participant/profile':
        history.push('/participant/profile');
        setOtopScreen(false);
        break;
      case '/participant/expo':
        history.push('/participant/expo');
        setOtopScreen(false);
        break;
      case '/participant/activities':
        history.push('/participant/activities');
        setOtopScreen(false);
        break;
      case '/participant/my-purchases':
        history.push('/participant/my-purchases');
        setOtopScreen(false);
        break;
      // otop
      case '/otop-hub/transactions':
        history.push('/otop-hub/transactions');
        setOtopScreen(true);
        break;
      case '/otop-hub/products':
        history.push('/otop-hub/products');
        setOtopScreen(true);
        break;
      case '/otop-hub/cart':
        history.push('/otop-hub/cart');
        setOtopScreen(true);
        break;
      case '/otop-hub/my-addresses':
        history.push('/otop-hub/my-addresses');
        setOtopScreen(true);
        break;
      case '/otop-hub/login':
        history.push('/otop-hub/login');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin':
        history.push('/otop-hub/admin');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/manage-seller':
        history.push('/otop-hub/admin/manage-seller');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/account':
        history.push('/otop-hub/admin/account');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/products':
        history.push('/otop-hub/admin/products');
        setOtopScreen(true);
      case '/otop-hub/admin/order-management':
        history.push('/otop-hub/admin/order-management');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/finance':
        history.push('/otop-hub/admin/finance');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/tags':
        history.push('/otop-hub/admin/tags');
        setOtopScreen(true);
        break;
      case '/otop-hub/admin/categories':
        history.push('/otop-hub/admin/categories');
        setOtopScreen(true);
        break;
    }
  }, []);

  useEffect(() => {
    if (expoDetails && expoDetails.slug && otopScreen === false) {
      switch (pathname) {
        case `/expo/${expoDetails.slug}`:
          history.push(`/expo/${expoDetails.slug}`);
          setOtopScreen(false);
          break;
        default:
          return pathname;
      }
    }
  }, [expoDetails]);

  // useEffect(() => {
  //  switch(vte){
  //    case "true":
  //      setOtopScreen(false)
  //      break;
  //   case "false":
  //       setOtopScreen(true);
  //       break;
  //  }
  // }, [])

  // otopScreen ? (
  //   <OtopMainLanding
  //     click={click}
  //     toVte={toVte}
  //     isAuth={isAuth}
  //     setIsAuth={setIsAuth}
  //     setOtopScreen={setOtopScreen}
  //     renderPrivateRoute={renderPrivateRoute}
  //   />
  // ) :

  return otopScreen ? (
    <OtopMainLanding
      click={click}
      toVte={toVte}
      isAuth={isAuth}
      setIsAuth={setIsAuth}
      setOtopScreen={setOtopScreen}
      renderPrivateRoute={renderPrivateRoute}
    />
  ) : (
    <>
      <PageHeader
        history={history}
        isExhibitor={isExhibitor}
        profileDetails={profileDetails}
        profileDetailsExhibitor={profileDetailsExhibitor}
        click={click}
      />
      <PageContentWrapper>
        <PageContent click={click} renderPrivateRoute={renderPrivateRoute} />
      </PageContentWrapper>
      <PageFooter />
    </>
  );
}

export default Landing;
