import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import StandardLanding from "../../../shared/components/StandardLanding";
import FormSearchFilterSpeaker from "../components/FormSearchFilterSpeaker";
import { getSpeakerList } from "../actions";
import { readProfile } from "../../profile/actions";
import ListSpeaker from "../components/ListSpeaker";

function Landing(props) {
  const dispatch = useDispatch();
  const { slug } = props.match.params;
  const state = useSelector((state) => state);
  const { speakers, total } = state.speaker;
  const loadingSpeaker = state.speaker.loading;
  const [expoSpeakers, setExpoSpeakers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSpeakerList(null, total));
    readProfile();
  }, []);

  useEffect(() => {
    if(slug && speakers) {
      let filterSpeakerByExpo = speakers.filter(item => item["expo-slug"] === slug)
      setExpoSpeakers(filterSpeakerByExpo)
    }
  }, [slug, speakers])

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO SPEAKERS
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <FormSearchFilterSpeaker
              getSpeakerList={getSpeakerList}
              total={total}
            />
            <ListSpeaker
              speakers={slug ? expoSpeakers : speakers}
              loadingExpo={loadingSpeaker}
            />
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default Landing;