import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../../modules/auth/containers/Login';
import ForgotPassword from '../../modules/auth/containers/ForgotPassword';
import ResetPassword from '../../modules/auth/containers/ResetPassword';
import MainLanding from '../../modules/layout/Landing';
import { isUserLoggedIn } from '../utils';
import Register2 from '../../modules/auth/containers/Register2';

class AppRouter extends PureComponent {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register2} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/" component={MainLanding} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("here", state)
  return {
    isUserLoggedIn: isUserLoggedIn(state.auth.user),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
