import React from 'react';
import { Button } from 'antd';

export default function HeaderTitle(props) {
  const { onGenerateSale } = props;
  let userType = localStorage.getItem('userType');
  return (
    <div className="flex items-center justify-between py-10">
      <div className="flex w-1/2 items-center">
        <p className="content-subheader-left mr-5">
          {userType === 'admin' && 'Admin'} {userType === 'seller' && 'Seller'}{' '}
          {userType === 'user' && 'Super Admin'} Dashboard
        </p>
      </div>
      <Button className="flex items-center">
        <i className="fas fa-chart-bar text-lg mr-2"></i>
        Generate Report
      </Button>
    </div>
  );
}
