import React from "react";
import styled from "@emotion/styled";

function CardWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default CardWrapper;

const StyledDiv = styled.div({
  "select": {
    width: "100%",
    height: "2.5rem",
    border: "1px solid #d9d9d9 !important",
   color: "#4E4E4E",
   border: "none",
   borderRadius: "1rem",
   padding: "5px 0px 5px 0px",
   zIndex: "5", 
   backgroundColor: "transparent",
 },
  ".ant-card": {
    // boxShadow:
    //   "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    borderRadius: "0",
  },
  ".ant-card-body": {
    padding: "0px !important",
  },
  ".ant-select": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "none",
    borderRadius: "0.5rem",
    padding: "5px 0px 5px 0px",
    zIndex: "5", 
  },
  ".search-input": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    background: "#e7e6e6",
    borderRadius: "2rem",
    padding: "0px 10px 0px 10px",
  },
  ".ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    border: "0"
  }, 
  ".card": {
    ".card-img": {
      objectFit: "cover",
      objectPosition: "center",
    },
    ".card-content": {
      padding: "20px",

      ".card-title": {
        color: "#363636",
        fontSize: ".8rem;",
        lineHeight: "1.5rem;",
        fontWeight: "600",
        textTransform: "capitalize",
      },

      ".lg-title": {
        color: "#c96a5e ",
        fontSize: "2rem;",
        lineHeight: "1.5rem;",
        fontWeight: "500",
        textTransform: "capitalize",
      },

      ".md-title": {
        color: "#c96a5e ",
        fontSize: "1.2rem;",
        lineHeight: "1.5rem;",
        fontWeight: "500",
        textTransform: "capitalize",
      },

      ".sm-title": {
        color: "#c96a5e ",
        fontSize: "12px;",
        lineHeight: ".9rem;",
        fontWeight: "600",
        textTransform: "capitalize",
      },

      ".card-subtitle": {
        fontSize: "12px;",
        lineHeight: "1.5rem;",
        fontWeight: "400",
      },

      ".card-price": {
        fontSize: ".8rem",
        lineHeight: "1rem;",
        fontWeight: "600",
        color: "#c96a5e",
      },

      ".card-body": {
        // marginTop: "1rem",
        width: "100%",

        ".card-body-row": {
          flexDirection: "row",
        },

        ".card-body-col": {
          flexDirection: "col",
        },

        ".flex-justify-center": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        },
        ".card-body-icon": {
          color: "#c96a5e",
          fontSize: "1.8rem;",
          lineHeight: "2rem;",
        },
        ".card-body-content": {
          color: "#4E4E4E",
          fontWeight: "300",
        },
      },
    },
  },
  ".ant-btn": {
    background: "#c96a5e",
    color: "#FFF",
    fontSize: "1.1em",
    borderRadius: "5px",
    border: "0",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  ".ant-btn:focus": {
    borderColor: "none",
  },
  ".ant-btn:hover": {
    borderColor: "none",
    background: "#E42F30 !important",
    outline: "0",
  },

  ".btn": {
    color: "#363636",
    fontSize: "1.1em",
    borderRadius: "40px",
    border: "0",
    width: "28px",
    padding: "2px"
  },
  ".btn:focus": {
    borderColor: "none",
    outline: "0",
  },
  ".btn:hover": {
    borderColor: "none",
    background: "#c96a5e !important",
    outline: "0",
    color: "#fff",
    width: "28px",
    opacity: "70%",
    padding: "2px"
  },

});
