import { authHeader, getAuthHeader, getError, axios } from "../../../../shared/utils";
import {
  API_OTOP_ORDER_LIST,
  API_OTOP_ORDER_CREATE,
  API_OTOP_ORDER_EDIT,
  API_OTOP_ORDER_DELETE,
  API_OTOP_ORDER_READ,
} from "../../../../shared/constant/url";

export const ORDER = {
  ORDER_LIST_START: "ORDER_LIST_START",
  ORDER_LIST_SUCCESS: "ORDER_LIST_SUCCESS",
  ORDER_LIST_ERROR: "ORDER_LIST_ERROR",

  ORDER_READ_START: "ORDER_READ_START",
  ORDER_READ_SUCCESS: "ORDER_READ_SUCCESS",
  ORDER_READ_ERROR: "ORDER_READ_ERROR",

  ORDER_ADD_EDIT_START: "ORDER_ADD_EDIT_START",
  ORDER_ADD_EDIT_SUCCESS: "ORDER_ADD_EDIT_SUCCESS",
  ORDER_ADD_EDIT_ERROR: "ORDER_ADD_EDIT_ERROR",

  ORDER_DELETE_START: "ORDER_DELETE_START",
  ORDER_DELETE_SUCCESS: "ORDER_DELETE_SUCCESS",
  ORDER_DELETE_ERROR: "ORDER_DELETE_ERROR",

  ORDER_STATUS_LIST_START: "ORDER_STATUS_LIST_START",
  ORDER_STATUS_LIST_SUCCESS: "ORDER_STATUS_LIST_SUCCESS",
  ORDER_STATUS_LIST_ERROR: "ORDER_STATUS_LIST_ERROR",

  ORDER_STATUS_READ_START: "ORDER_STATUS_READ_START",
  ORDER_STATUS_READ_SUCCESS: "ORDER_STATUS_READ_SUCCESS",
  ORDER_STATUS_READ_ERROR: "ORDER_STATUS_READ_ERROR",

  ORDER_STATUS_ADD_EDIT_START: "ORDER_STATUS_ADD_EDIT_START",
  ORDER_STATUS_ADD_EDIT_SUCCESS: "ORDER_STATUS_ADD_EDIT_SUCCESS",
  ORDER_STATUS_ADD_EDIT_ERROR: "ORDER_STATUS_ADD_EDIT_ERROR",

  ORDER_STATUS_DELETE_START: "ORDER_STATUS_DELETE_START",
  ORDER_STATUS_DELETE_SUCCESS: "ORDER_STATUS_DELETE_SUCCESS",
  ORDER_STATUS_DELETE_ERROR: "ORDER_STATUS_DELETE_ERROR",
};

export const getOrderList = () => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_ORDER_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ORDER.ORDER_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readOrder = (id) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ORDER_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ORDER.ORDER_READ_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editOrder = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("status_id", params.status_id);
   
    params.payment_status && formData.append("payment_status", params.payment_status);
    params.remarks && formData.append("remarks", params.remarks);

    if(params.remarks_image) {
      formData.append("remarks_image", params.remarks_image.fileList[0].originFileObj);
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_OTOP_ORDER_EDIT}/${id}?_method=PUT`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ORDER.ORDER_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ORDER.ORDER_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

// export const editOrder = (params, id, callback = null) => {
//   return (dispatch) => {
//     dispatch({ type: ORDER.ORDER_ADD_EDIT_START });
  
//     axios
//       .put(`${API_OTOP_ORDER_EDIT}/${id}`, params, authHeader())
//       .then((response) => {
//         callback(response, null);
//         dispatch({
//           type: ORDER.ORDER_ADD_EDIT_SUCCESS,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         callback(null, error);
//         dispatch({
//           type: ORDER.ORDER_ADD_EDIT_ERROR,
//           payload: getError(error),
//         });
//       });
//   };
// };

export const deleteOrder = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ORDER_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ORDER.ORDER_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ORDER.ORDER_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

// ORDER STATUS
export const getOrderStatusList = () => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_STATUS_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_ORDER_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ORDER.ORDER_STATUS_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_STATUS_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readOrderStatus = (id) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_STATUS_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ORDER_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ORDER.ORDER_STATUS_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_STATUS_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createOrderStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_STATUS_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("name", params.name);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_OTOP_ORDER_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ORDER.ORDER_STATUS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ORDER.ORDER_STATUS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editOrderStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_STATUS_ADD_EDIT_START });
  
    axios
      .put(`${API_OTOP_ORDER_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ORDER.ORDER_STATUS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ORDER.ORDER_STATUS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteOrderStatus = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ORDER.ORDER_STATUS_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ORDER_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ORDER.ORDER_STATUS_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ORDER.ORDER_STATUS_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

