import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, notification, Switch } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import FormWrapper from '../../../../shared/components/otop/FormWrapper';

const FormAddress = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { createAddress, editAddress, addressDetails, id } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [form] = Form.useForm();
  let isEdit = id;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isEdit && addressDetails) {
      const { attributes } = addressDetails;
      form.setFieldsValue({
        line_1: attributes.address_line1 ? attributes.address_line1 : '',
        line_2: attributes.address_line2 ? attributes.address_line2 : '',
        city: attributes.city ? attributes.city : '',
        state: attributes.state ? attributes.state : '',
        zip_code: attributes.zipcode ? attributes.zipcode : '',
        firstname: attributes.firstname ? attributes.firstname : '',
        lastname: attributes.lastname ? attributes.lastname : '',
        email: attributes.email ? attributes.email : '',
        phone: attributes.phone ? attributes.phone : '',
        default_billing: attributes.default_billing
          ? attributes.default_billing
          : '',
        default_shipping: attributes.default_shipping
          ? attributes.default_shipping
          : '',
      });
    }
  }, [addressDetails]);

  const onFinish = (values, e) => {
    // console.log("values", values)
    if (isEdit) {
      dispatch(editAddress(values, id, callback));
      setIsDisabled(true);
    } else {
      dispatch(createAddress(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const { status } = response;
      if (status === 200) {
        notification.success({
          message: 'My address is successfully updated!',
        });
        history.push('/otop-hub/my-addresses');
      }
    } else {
      notification.success({ message: 'Something went wrong!' });
    }
  };

  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
        name="formAddress"
        className="shadow p-10 bg-white"
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
      >
        <div className="w-full">
          <h1 className="lg:text-lg">{isEdit ? 'Edit' : 'New'} Address</h1>
        </div>
        <div className="flex flex-wrap mt-5">
          <div className="w-1/2 pr-5">
            <p className="xs-title">First Name</p>
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="w-1/2 pl-5">
            <p className="xs-title">Last Name</p>
            <Form.Item
              name="lastname"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <div className="w-1/2 pr-5">
            <p className="xs-title">Email Address</p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Invalid email format!' },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          </div>
          <div className="w-1/2 pl-5">
            <p className="xs-title">Phone Number</p>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Please input your contact number!',
                },
                {
                  min: 11,
                  message: 'Contact Number must be 11 digits.',
                },
                {
                  max: 11,
                  message: 'Contact Number must be 11 digits.',
                },
                {
                  pattern: new RegExp(/[^a-z\s]/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </div>
          <div className="w-1/2 pr-5">
            <p className="xs-title">Province</p>
            <Form.Item
              name="state"
              rules={[
                {
                  required: true,
                  message: 'Please input your province!',
                },
              ]}
            >
              <Input placeholder="Province" />
            </Form.Item>
          </div>
          <div className="w-1/2 pl-5">
            <p className="xs-title">City/Municipality</p>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: 'Please input your city!',
                },
              ]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </div>
          <div className="w-1/2 pr-5">
            <p className="xs-title">Address Line 1</p>
            <Form.Item
              name="line_1"
              rules={[
                {
                  required: true,
                  message: 'Please input your address line 1!',
                },
              ]}
            >
              <Input placeholder="Address Line 1" />
            </Form.Item>
          </div>
          <div className="w-1/2 pl-5">
            <p className="xs-title">Address Line 2</p>
            <Form.Item
              name="line_2"
              rules={[
                {
                  required: true,
                  message: 'Please input your address line 2!',
                },
              ]}
            >
              <Input placeholder="Address Line 2" />
            </Form.Item>
          </div>
          <div className="w-1/2 pr-5">
            <p className="xs-title">Zip Code</p>
            <Form.Item
              name="zip_code"
              rules={[
                {
                  required: true,
                  message: 'Please input your zip code!',
                },
                {
                  pattern: new RegExp(/^\d+$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="Zip Code" />
            </Form.Item>
          </div>
          <div className="w-full">
            <p className="xs-title">Set as default shipping address</p>
            <Form.Item
              name="default_billing"
              rules={[
                {
                  required: false,
                  message: 'Please select!',
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </div>
          <div className="w-full">
            <p className="xs-title">Set as default billing address</p>
            <Form.Item
              name="default_shipping"
              rules={[
                {
                  required: false,
                  message: 'Please select!',
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
        <div className="border-b-2">&nbsp;</div>
        <div className="py-10 flex">
          {isEdit ? (
            <Button htmlType="submit" className="mr-5 flex items-center">
              Edit Address
            </Button>
          ) : (
            <Button
              htmlType="submit"
              disabled={isDisabled}
              className="mr-5 flex items-center"
            >
              Add Address
            </Button>
          )}
          <Link to="/otop-hub/my-addresses">
            <Button className="flex items-center">Cancel</Button>
          </Link>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default FormAddress;
