import { getAuthHeader, getError } from '../../shared/utils';
import { API_PARTNER_LIST, API_PARTNER_SHOW, API_PARTNER_SHOW_EXHIBITOR } from '../../shared/constant/url';

export const PARTNER = {
 PARTNER_LIST_START: "PARTNER_LIST_START", 
 PARTNER_LIST_SUCCESS: "PARTNER_LIST_SUCCESS", 
 PARTNER_LIST_ERROR: "PARTNER_LIST_ERROR",

 PARTNER_SHOW_START: "PARTNER_SHOW_START", 
 PARTNER_SHOW_SUCCESS: "PARTNER_SHOW_SUCCESS", 
 PARTNER_SHOW_ERROR: "PARTNER_SHOW_ERROR",

 PARTNER_SHOW_EXHIBITOR_START: "PARTNER_SHOW_EXHIBITOR_START", 
 PARTNER_SHOW_EXHIBITOR_SUCCESS: "PARTNER_SHOW_EXHIBITOR_SUCCESS", 
 PARTNER_SHOW_EXHIBITOR_ERROR: "PARTNER_SHOW_EXHIBITOR_ERROR",
}

export const getPartnerList = (params, per_page) => {
 return (dispatch) => {
  dispatch({ type: PARTNER.PARTNER_LIST_START });
  let formData = new FormData();
  if (per_page) {
    formData.append("per_page", per_page);
  } else {
    formData.append("per_page", 1000);
  }
  formData.append("page", 1)
  if(params) {
   formData.append("keyword",params.keyword)
  }

  let requestOption = {
   method: 'POST', 
   headers: getAuthHeader(), 
   body: formData
  }
  fetch(API_PARTNER_LIST, requestOption)
   .then(response => response.json())
   .then(response => { dispatch({ type: PARTNER.PARTNER_LIST_SUCCESS, payload: response })})
   .catch(error => { dispatch({ type: PARTNER.PARTNER_LIST_ERROR, payload: getError(error) }) })
 }
}

export const showPartnerDetails = (partner_id) => {
 return (dispatch) => {
  dispatch({ type: PARTNER.PARTNER_SHOW_START });
  let formData = new FormData();
  formData.append("partner_id",partner_id)

  let requestOption = {
   method: "POST", 
   headers: getAuthHeader(), 
   body: formData
  }
  fetch(API_PARTNER_SHOW, requestOption)
   .then(response => response.json())
   .then(response => { dispatch({ type: PARTNER.PARTNER_SHOW_SUCCESS, payload: response.data })})
   .catch(error => { dispatch({ type:PARTNER.PARTNER_SHOW_ERROR, payload: error })})
 }
}

export const showPartnerDetailsExhibitor = (partner_id) => {
  return (dispatch) => {
   dispatch({ type: PARTNER.PARTNER_SHOW_EXHIBITOR_START });
   let formData = new FormData();
   formData.append("partner_id",partner_id)
 
   let requestOption = {
    method: "POST", 
    headers: getAuthHeader(), 
    body: formData
   }
   fetch(API_PARTNER_SHOW_EXHIBITOR, requestOption)
    .then(response => response.json())
    .then(response => { dispatch({ type: PARTNER.PARTNER_SHOW_EXHIBITOR_SUCCESS, payload: response.data })})
    .catch(error => { dispatch({ type:PARTNER.PARTNER_SHOW_EXHIBITOR_ERROR, payload: error })})
  }
 }