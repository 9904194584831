import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { joinExpo, joinExpoExhibitor, getExpoList } from '../actions';

export default function ModalJoinExpo(props) {
  const {
    isModalVisibleExpo,
    handleOkExpo,
    handleCancelExpo,
    data,
    totalExpo,
  } = props;
  const dispatch = useDispatch();
  let isExhibitor = localStorage.getItem('isExhibitor');
  let token = localStorage.getItem('token');

  const handleJoinExpo = () => {
    if (isExhibitor === 'true') {
      dispatch(joinExpoExhibitor(data.slug, callback));
    } else {
      dispatch(joinExpo(data.slug, callback));
    }
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'EXPO_JOINED') {
        notification.success({ message: `${response.msg}` });
      } else if (status_code === 'ALREADY_MEMBER') {
        notification.warning({ message: `${response.msg}` });
      } else if (status_code === 'TOKEN_NOT_PROVIDED') {
        notification.warning({
          message: 'You need to login to join this expo',
        });
      } else if (status_code === 'APPLICATION_SUBMITTED') {
        notification.success({ message: `${response.msg}` });
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }

    handleOkExpo();
    dispatch(getExpoList(null, totalExpo));
  };

  return (
    <Modal
      title="Join Expo"
      visible={isModalVisibleExpo}
      onOk={handleOkExpo}
      onCancel={handleCancelExpo}
      footer={''}
    >
      <p className="text-red-20 lg:text-xl">Welcome to {data.name}!</p>
      <p className="my-5">{data.description}</p>
      {token ? (
        <div
          className="btn btn-red text-center cursor-pointer"
          onClick={handleJoinExpo}
        >
          <i className="fas fa-share"></i>&nbsp;
          <span className="text-xs">JOIN</span>
        </div>
      ) : (
        <div className="w-full flex">
          <Link
            to={{
              pathname: '/login',
              state: { data: data },
            }}
            className="w-full text-center btn btn-red mr-2"
          >
            <i className="fas fa-share"></i>&nbsp;
            <span className="text-xs">LOGIN AS PARTICIPANT</span>
          </Link>
          <Link
            to={{
              pathname: '/login',
              state: { data: data },
            }}
            className="w-full text-center btn btn-red ml-2"
          >
            <i className="fas fa-share"></i>&nbsp;
            <span className="text-xs">LOGIN AS EXHIBITOR</span>
          </Link>
        </div>
      )}
    </Modal>
  );
}
