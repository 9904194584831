import React from "react";
import styled from "@emotion/styled";

function ExhibitorPaginationWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default ExhibitorPaginationWrapper;

const StyledDiv = styled.div({
  ".ant-list-pagination": {
    marginTop: "-20px !important",
    position: "absolute",
    top: 0,
    left: 0,

    ".ant-pagination-item": {
      background: "#d63d4b !important",
      borderColor: "#FFF !important",
    },
    ".ant-pagination-item-active": {
      background: "#cd1f30 !important",
      borderColor: "#FFF !important",
    }
  },
  ".ant-pagination a": {
    color: "#b9b9b9 !important",
  },
});
