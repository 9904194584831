import { getAuthHeader, getError } from '../../shared/utils';
import {
  API_ACCEPT_PAYMAYA,
  API_DECLINE_PAYMAYA,
  API_PROFILE_READ,
  API_PROFILE_UPDATE,
  API_PROFILE_UPDATE_PASSWORD,
  API_PROFILE_UPDATE_PHOTO,
  API_PROFILE_EXPO,
  API_PROFILE_ACTIVITY,
  API_PROFILE_READ_EXHIBITOR,
  API_PROFILE_UPDATE_EXHIBITOR,
  API_PROFILE_UPDATE_PASSWORD_EXHIBITOR,
  API_PROFILE_UPDATE_PHOTO_EXHIBITOR,
  API_PROFILE_EXPO_EXHIBITOR,
  API_TRANSACTION_LIST,
  API_TRANSACTION_SHOW,
  API_TRANSACTION_LIST_MERCHANT,
  API_TRANSACTION_DETAILS_MERCHANT,
  API_TRANSACTION_UPDATE_STATUS_MERCHANT,
  API_BANK_DETAILS_LIST,
  API_BANK_DETAILS_CREATE,
  API_BANK_DETAILS_READ,
  API_BANK_DETAILS_UPDATE,
  API_BANK_DETAILS_DELETE,
  API_TRANSACTION_UPDATE_STATUS,
  API_PROFILE_ACTIVITY_EXHIBITOR,
  API_POLICY_UPDATE_EXHIBITOR,
  API_TRANSACTION_UPDATE_PAYMENT_STATUS_MERCHANT,
} from '../../shared/constant/url';

export const PROFILE = {
  PROFILE_READ_START: 'PROFILE_READ_START',
  PROFILE_READ_SUCCESS: 'PROFILE_READ_SUCCESS',
  PROFILE_READ_ERROR: 'PROFILE_READ_ERROR',

  POLICY_UPDATE_START: 'POLICY_UPDATE_START',
  POLICY_UPDATE_SUCCESS: 'POLICY_UPDATE_SUCCESS',
  POLICY_UPDATE_ERROR: 'POLICY_UPDATE_ERROR',

  PAYMAYA_ACCEPT_START: 'PAYMAYA_ACCEPT_START',
  PAYMAYA_ACCEPT_SUCCESS: 'PAYMAYA_ACCEPT_SUCCESS',
  PAYMAYA_ACCEPT_ERROR: 'PAYMAYA_ACCEPT_ERROR',

  PAYMAYA_DECLINE_START: 'PAYMAYA_DECLINE_START',
  PAYMAYA_DECLINE_SUCCESS: 'PAYMAYA_DECLINE_SUCCESS',
  PAYMAYA_DECLINE_ERROR: 'PAYMAYA_DECLINE_ERROR',

  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',

  PROFILE_UPDATE_PASSWORD_START: 'PROFILE_UPDATE_PASSWORD_START',
  PROFILE_UPDATE_PASSWORD_SUCCESS: 'PROFILE_UPDATE_PASSWORD_SUCCESS',
  PROFILE_UPDATE_PASSWORD_ERROR: 'PROFILE_UPDATE_PASSWORD_ERROR',

  PROFILE_UPDATE_PHOTO_START: 'PROFILE_UPDATE_PHOTO_START',
  PROFILE_UPDATE_PHOTO_SUCCESS: 'PROFILE_UPDATE_PHOTO_SUCCESS',
  PROFILE_UPDATE_PHOTO_ERROR: 'PROFILE_UPDATE_PHOTO_ERROR',

  PROFILE_EXPO_START: 'PROFILE_EXPO_START',
  PROFILE_EXPO_SUCCESS: 'PROFILE_EXPO_SUCCESS',
  PROFILE_EXPO_ERROR: 'PROFILE_EXPO_ERROR',

  PROFILE_ACTIVITY_START: 'PROFILE_ACTIVITY_START',
  PROFILE_ACTIVITY_SUCCESS: 'PROFILE_ACTIVITY_SUCCESS',
  PROFILE_ACTIVITY_ERROR: 'PROFILE_ACTIVITY_ERROR',

  PROFILE_TRANSACTION_LIST_START: 'PROFILE_TRANSACTION_LIST_START',
  PROFILE_TRANSACTION_LIST_SUCCESS: 'PROFILE_TRANSACTION_LIST_SUCCESS',
  PROFILE_TRANSACTION_LIST_ERROR: 'PROFILE_TRANSACTION_LIST_ERROR',

  PROFILE_TRANSACTION_SHOW_START: 'PROFILE_TRANSACTION_SHOW_START',
  PROFILE_TRANSACTION_SHOW_SUCCESS: 'PROFILE_TRANSACTION_SHOW_SUCCESS',
  PROFILE_TRANSACTION_SHOW_ERROR: 'PROFILE_TRANSACTION_SHOW_ERROR',

  PROFILE_READ_EXHIBITOR_START: 'PROFILE_READ_EXHIBITOR_START',
  PROFILE_READ_EXHIBITOR_SUCCESS: 'PROFILE_READ_EXHIBITOR_SUCCESS',
  PROFILE_READ_EXHIBITOR_ERROR: 'PROFILE_READ_EXHIBITOR_ERROR',

  PROFILE_UPDATE_EXHIBITOR_START: 'PROFILE_UPDATE_EXHIBITOR_START',
  PROFILE_UPDATE_EXHIBITOR_SUCCESS: 'PROFILE_UPDATE_EXHIBITOR_SUCCESS',
  PROFILE_UPDATE_EXHIBITOR_ERROR: 'PROFILE_UPDATE_EXHIBITOR_ERROR',

  PROFILE_UPDATE_EXHIBITOR_PASSWORD_START:
    'PROFILE_UPDATE_EXHIBITOR_PASSWORD_START',
  PROFILE_UPDATE_EXHIBITOR_PASSWORD_SUCCESS:
    'PROFILE_UPDATE_EXHIBITOR_PASSWORD_SUCCESS',
  PROFILE_UPDATE_EXHIBITOR_PASSWORD_ERROR:
    'PROFILE_UPDATE_EXHIBITOR_PASSWORD_ERROR',

  PROFILE_UPDATE_EXHIBITOR_PHOTO_START: 'PROFILE_UPDATE_EXHIBITOR_PHOTO_START',
  PROFILE_UPDATE_EXHIBITOR_PHOTO_SUCCESS:
    'PROFILE_UPDATE_EXHIBITOR_PHOTO_SUCCESS',
  PROFILE_UPDATE_EXHIBITOR_PHOTO_ERROR: 'PROFILE_UPDATE_EXHIBITOR_PHOTO_ERROR',

  PROFILE_EXPO_EXHIBITOR_START: 'PROFILE_EXPO_EXHIBITOR_START',
  PROFILE_EXPO_EXHIBITOR_SUCCESS: 'PROFILE_EXPO_EXHIBITOR_SUCCESS',
  PROFILE_EXPO_EXHIBITOR_ERROR: 'PROFILE_EXPO_EXHIBITOR_ERROR',

  PROFILE_TRANSACTION_LIST_EXHIBITOR_START:
    'PROFILE_TRANSACTION_LIST_EXHIBITOR_START',
  PROFILE_TRANSACTION_LIST_EXHIBITOR_SUCCESS:
    'PROFILE_TRANSACTION_LIST_EXHIBITOR_SUCCESS',
  PROFILE_TRANSACTION_LIST_EXHIBITOR_ERROR:
    'PROFILE_TRANSACTION_LIST_EXHIBITOR_ERROR',

  PROFILE_TRANSACTION_SHOW_EXIBITOR_START:
    'PROFILE_TRANSACTION_SHOW_EXIBITOR_START',
  PROFILE_TRANSACTION_SHOW_EXIBITOR_SUCCESS:
    'PROFILE_TRANSACTION_SHOW_EXIBITOR_SUCCESS',
  PROFILE_TRANSACTION_SHOW_EXIBITOR_ERROR:
    'PROFILE_TRANSACTION_SHOW_EXIBITOR_ERROR',

  PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_START:
    'PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_START',
  PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_SUCCESS:
    'PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_SUCCESS',
  PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_ERROR:
    'PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_ERROR',

  BANK_DETAILS_LIST_START: 'BANK_DETAILS_LIST_START',
  BANK_DETAILS_LIST_SUCCESS: 'BANK_DETAILS_LIST_SUCCESS',
  BANK_DETAILS_LIST_ERROR: 'BANK_DETAILS_LIST_ERROR',

  BANK_DETAILS_READ_START: 'BANK_DETAILS_READ_START',
  BANK_DETAILS_READ_SUCCESS: 'BANK_DETAILS_READ_SUCCESS',
  BANK_DETAILS_READ_ERROR: 'BANK_DETAILS_READ_ERROR',

  BANK_DETAILS_ADD_EDIT_START: 'BANK_DETAILS_ADD_EDIT_START',
  BANK_DETAILS_ADD_EDIT_SUCCESS: 'BANK_DETAILS_ADD_EDIT_SUCCESS',
  BANK_DETAILS_ADD_EDIT_ERROR: 'BANK_DETAILS_ADD_EDIT_ERROR',

  BANK_DETAILS_DELETE_START: 'BANK_DETAILS_DELETE_START',
  BANK_DETAILS_DELETE_SUCCESS: 'BANK_DETAILS_DELETE_SUCCESS',
  BANK_DETAILS_DELETE_ERROR: 'BANK_DETAILS_DELETE_ERROR',

  PROFILE_TRANSACTION_UPDATE_STATUS_START:
    'PROFILE_TRANSACTION_UPDATE_STATUS_START',
  PROFILE_TRANSACTION_UPDATE_STATUS_SUCCESS:
    'PROFILE_TRANSACTION_UPDATE_STATUS_SUCCESS',
  PROFILE_TRANSACTION_UPDATE_STATUS_ERROR:
    'PROFILE_TRANSACTION_UPDATE_STATUS_ERROR',

  PROFILE_ACTIVITY_EXHIBITOR_START: 'PROFILE_ACTIVITY_EXHIBITOR_START',
  PROFILE_ACTIVITY_EXHIBITOR_SUCCESS: 'PROFILE_ACTIVITY_EXHIBITOR_SUCCESS',
  PROFILE_ACTIVITY_EXHIBITOR_ERROR: 'PROFILE_ACTIVITY_EXHIBITOR_ERROR',
};

export const readProfile = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_READ_START });

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(API_PROFILE_READ, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: PROFILE.PROFILE_READ_ERROR, payload: error });
      });
  };
};

export const updateProfile = (params, callback = null) => {
  return (dispatch) => {
    if (params.file) dispatch(updatePhoto(params, callback));

    dispatch({ type: PROFILE.PROFILE_UPDATE_START });
    let formData = new FormData();
    formData.append('firstname', params.firstname);
    formData.append('lastname', params.lastname);
    formData.append('email', params.email);
    formData.append('contact_number', params.contact_number);
    formData.append('address', params.address);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_UPDATE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({ type: PROFILE.PROFILE_UPDATE_SUCCESS, payload: response });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: PROFILE.PROFILE_UPDATE_ERROR, payload: error });
      });
  };
};

export const updatePassword = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_UPDATE_PASSWORD_START });
    let formData = new FormData();
    formData.append('current_password', params.current_password);
    formData.append('password', params.password);
    formData.append('password_confirmation', params.password_confirmation);
    formData.append('include', 'info');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_UPDATE_PASSWORD, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_PASSWORD_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePhoto = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_UPDATE_PHOTO_START });
    let formData = new FormData();
    formData.append('file', params.file.fileList[0].originFileObj);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_UPDATE_PHOTO, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback?.(response, null);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_PHOTO_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback?.(null, error);
        dispatch({ type: PROFILE.PROFILE_UPDATE_PHOTO_ERROR, payload: error });
      });
  };
};

export const getExpoList = (per_page) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_EXPO_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_EXPO, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: PROFILE.PROFILE_EXPO_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: PROFILE.PROFILE_EXPO_ERROR, payload: error });
      });
  };
};

export const getActivityList = (params) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_ACTIVITY_START });
    let formData = new FormData();

    if (params) {
      formData.append('keyword', params.keyword);
    }

    formData.append('per_page', 10000);
    formData.append('include', 'product');
    formData.append('include', 'participant');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_ACTIVITY, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: PROFILE.PROFILE_ACTIVITY_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: PROFILE.PROFILE_ACTIVITY_ERROR, payload: error });
      });
  };
};

export const getTransactionList = (per_page) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_LIST_START });

    let formData = new FormData();
    // formData.append("include[]", "payments");
    // formData.append("include[]", "detail");
    formData.append('include', 'detail.product');
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 0);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_LIST_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionShow = (transaction_id) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_SHOW_START });

    let formData = new FormData();
    formData.append('transaction_id', transaction_id);
    formData.append('include', 'detail.product');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_SHOW_ERROR,
          payload: error,
        });
      });
  };
};

// EXHIBITOR

export const readProfileExhibitor = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_READ_EXHIBITOR_START });

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(API_PROFILE_READ_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_READ_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_READ_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const updateProfileExhibitor = (params, prov_id, callback = null) => {
  return (dispatch) => {
    if (params.file) dispatch(updatePhotoExhibitor(params, callback));

    dispatch({ type: PROFILE.PROFILE_UPDATE_EXHIBITOR_START });
    let formData = new FormData();
    formData.append('firstname', params.firstname);
    formData.append('lastname', params.lastname);
    formData.append('email', params.email);
    formData.append('contact_number', params.contact_number);
    formData.append('address', params.address);
    formData.append('company_name', params.company_name);
    formData.append('position', params.position);
    formData.append('business_description', params.business_description);

    if (params.link && params.link !== '') {
      if (!params.link.includes('https://')) {
        formData.append('link', 'https://' + params.link);
      } else {
        formData.append('link', params.link);
      }
    }
    if (params.yt_link && params.yt_link !== '') {
      if (!params.yt_link.includes('https://')) {
        formData.append('yt_link', 'https://' + params.yt_link);
      } else {
        formData.append('yt_link', params.yt_link);
      }
    }
    if (params.website_link && params.website_link !== '') {
      if (!params.website_link.includes('https://')) {
        formData.append('website_link', 'https://' + params.website_link);
      } else {
        formData.append('website_link', params.website_link);
      }
    }
    if (params.fb_link && params.fb_link !== '') {
      if (!params.fb_link.includes('https://')) {
        formData.append('fb_link', 'https://' + params.fb_link);
      } else {
        formData.append('fb_link', params.fb_link);
      }
    }
    if (params.twitter_link && params.twitter_link !== '') {
      if (!params.twitter_link.includes('https://')) {
        formData.append('twitter_link', 'https://' + params.twitter_link);
      } else {
        formData.append('twitter_link', params.twitter_link);
      }
    }
    if (params.gmail_link && params.gmail_link !== '') {
      if (!params.gmail_link.includes('https://')) {
        formData.append('gmail_link', 'https://' + params.gmail_link);
      } else {
        formData.append('gmail_link', params.gmail_link);
      }
    }
    if (params.instagram_link && params.instagram_link !== '') {
      if (!params.instagram_link.includes('https://')) {
        formData.append('instagram_link', 'https://' + params.instagram_link);
      } else {
        formData.append('instagram_link', params.instagram_link);
      }
    }
    if (params.linkedin_link && params.linkedin_link !== '') {
      if (!params.linkedin_link.includes('https://')) {
        formData.append('linkedin_link', 'https://' + params.linkedin_link);
      } else {
        formData.append('linkedin_link', params.linkedin_link);
      }
    }
    if (params.facetime_link && params.facetime_link !== '') {
      if (!params.facetime_link.includes('https://')) {
        formData.append('facetime_link', 'https://' + params.facetime_link);
      } else {
        formData.append('facetime_link', params.facetime_link);
      }
    }
    if (params.zoom_link && params.zoom_link !== '') {
      if (!params.zoom_link.includes('https://')) {
        formData.append('zoom_link', 'https://' + params.zoom_link);
      } else {
        formData.append('zoom_link', params.zoom_link);
      }
    }
    if (params.viber_link && params.viber_link !== '') {
      if (!params.viber_link.includes('https://')) {
        formData.append('viber_link', 'https://' + params.viber_link);
      } else {
        formData.append('viber_link', params.viber_link);
      }
    }

    if (params.selling_rate) {
      formData.append('selling_rate', params.selling_rate);
    }

    formData.append('province', params?.province || prov_id);

    if (params.industry) {
      formData.append('industry', params.industry);
    }
    if (params.region) {
      formData.append('region', params.region);
    }
    formData.append('company_info', params.company_info);
    formData.append('time_frame', params.time_frame);
    formData.append('shipping_info', params.shipping_info);
    formData.append('terms_condition', params.terms_condition);
    formData.append('refund_policy', params.refund_policy);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };

    console.log('cc-requestOption', requestOption);
    // return;
    fetch(API_PROFILE_UPDATE_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePolicy = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.POLICY_UPDATE_START });
    let formData = new FormData();
    formData.append('refund_policy', params.refund_policy);
    formData.append('terms_condition', params.terms_condition);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_POLICY_UPDATE_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.POLICY_UPDATE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.POLICY_UPDATE_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePasswordExhibitor = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_START });
    let formData = new FormData();
    formData.append('current_password', params.current_password);
    formData.append('password', params.password);
    formData.append('password_confirmation', params.password_confirmation);
    formData.append('include', 'info');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_UPDATE_PASSWORD_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePhotoExhibitor = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_START });
    let formData = new FormData();
    formData.append('file', params.file.fileList[0].originFileObj);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_UPDATE_PHOTO_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback?.(response, null);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback?.(null, error);
        dispatch({
          type: PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_ERROR,
          payload: error,
        });
      });
  };
};

export const getExpoListExhibitor = (per_page) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_EXPO_EXHIBITOR_START });

    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_EXPO_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_EXPO_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_EXPO_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const getTransactionListExhibitor = (per_page) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_START });

    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 0);
    }
    formData.append('include', 'detail.product');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_LIST_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionShowExhibitor = (transaction_id) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_START });

    let formData = new FormData();
    formData.append('transaction_id', transaction_id);
    formData.append('include', 'detail.product,participant');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_DETAILS_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionUpdateStatusExhibitor = (
  status,
  transaction_id,
  callback = null,
  params
) => {
  return (dispatch) => {
    dispatch({
      type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_START,
    });

    let formData = new FormData();
    formData.append('status', status);
    formData.append('transaction_id', transaction_id);

    if (params && params.file) {
      formData.append('file', params.file.fileList[0].originFileObj);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_UPDATE_STATUS_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const getBankDetailsList = () => {
  return (dispatch) => {
    dispatch({ type: PROFILE.BANK_DETAILS_LIST_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.BANK_DETAILS_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.BANK_DETAILS_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readBankDetails = (product_id) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.BANK_DETAILS_READ_START });
    let formData = new FormData();
    formData.append('product_id', product_id);
    formData.append('include', 'images');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.BANK_DETAILS_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.BANK_DETAILS_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createBankDetails = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.BANK_DETAILS_ADD_EDIT_START });
    let formData = new FormData();
    formData.append('account_number', params.account_number);
    formData.append('account_name', params.account_name);
    formData.append('payment_name', params.payment_name);

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.BANK_DETAILS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.BANK_DETAILS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editBankDetails = (params, product_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.BANK_DETAILS_ADD_EDIT_START });
    let formData = new FormData();
    formData.append('account_number', params.account_number);
    formData.append('account_name', params.account_name);
    formData.append('payment_name', params.payment_name);

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_UPDATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.BANK_DETAILS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.BANK_DETAILS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteBankDetails = (product_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PRODUCT_DELETE_START });
    let formData = new FormData();
    formData.append('product_id', product_id);

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_BANK_DETAILS_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PRODUCT_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PRODUCT_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const transactionUpdateStatus = (
  status,
  transaction_id,
  callback = null
) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_START });

    let formData = new FormData();
    formData.append('status', status);
    formData.append('transaction_id', transaction_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_UPDATE_STATUS, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_ERROR,
          payload: error,
        });
      });
  };
};

export const getActivityListExhibitor = (params) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_ACTIVITY_EXHIBITOR_START });
    let formData = new FormData();
    if (params) {
      formData.append('keyword', params.keyword);
    }
    formData.append('per_page', 10000);
    formData.append('include', 'product');
    formData.append('include', 'participant');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PROFILE_ACTIVITY_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PROFILE.PROFILE_ACTIVITY_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROFILE.PROFILE_ACTIVITY_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionUpdatePaymentStatus = (
  status,
  reason,
  transaction_id,
  callback = null
) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_START });

    let formData = new FormData();
    formData.append('status', status);
    if (reason) {
      formData.append('reason', reason);
    }
    formData.append('transaction_id', transaction_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_TRANSACTION_UPDATE_PAYMENT_STATUS_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionAcceptPaymaya = (
  status,
  transaction_id,
  callback = null
) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PAYMAYA_ACCEPT_START });

    let formData = new FormData();
    formData.append('transaction_status', status);
    formData.append('transaction_id', transaction_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ACCEPT_PAYMAYA, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PAYMAYA_ACCEPT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PAYMAYA_ACCEPT_ERROR,
          payload: error,
        });
      });
  };
};

export const transactionDeclinePaymaya = (
  status,
  transaction_id,
  callback = null
) => {
  return (dispatch) => {
    dispatch({ type: PROFILE.PAYMAYA_DECLINE_START });

    let formData = new FormData();
    formData.append('transaction_status', status);
    formData.append('transaction_id', transaction_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DECLINE_PAYMAYA, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PROFILE.PAYMAYA_DECLINE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PROFILE.PAYMAYA_DECLINE_ERROR,
          payload: error,
        });
      });
  };
};
