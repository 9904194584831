import React, { useEffect, useState } from "react";
import { Card, List } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import placeholder from "../../../shared/assets/images/placeholder.jpg";
import StandardLanding from "../../../shared/components/StandardLanding";
import { getPartnerList } from "../../partner/actions";
import { readProfile } from "../../profile/actions";
import CardWrapper from "../../../shared/components/CardWrapper";
import ModalUser from "../../general/components/ModalUser";

function Landing() {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const state = useSelector((state) => state);
  const { partners, total } = state.partner;
  const loadingParner = state.partner.loading;
  const token = localStorage.getItem("token");
  const isExhibitor = localStorage.getItem("isExhibitor");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPartnerList(null, total));
    readProfile();
  }, [getPartnerList, total]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO PARTNERS
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <h1 className="content-header-left">OUR PARTNERS</h1>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 5,
                xl: 4,
                xxl: 5,
              }}
              pagination={true}
              dataSource={partners}
              renderItem={(item) => (
                <List.Item>
                  <CardWrapper>
                    <Card
                      bordered={false}
                      className="card my-5"
                      loading={loadingParner}
                    >
                      <Link to={`/booth/${isExhibitor === 'false' ? 'partners' : 'exhibitors'}/${item.id}`}>
                        <img
                          src={
                            item.thumbnail.filename
                              ? item.thumbnail.full_path
                              : placeholder
                          }
                          alt="Virtual Trade Expo Ph"
                          className="h-56 lg:h-56 w-full card-img"
                        />
                        <div className="card-content">
                          <h3 className="card-title text-control-1">
                            {item.name}
                          </h3>
                          <div className="card-body">
                            <div className="flex justify-center mt-2">
                              <div className="btn btn-red">
                                <i className="fas fa-share mr-1"></i>
                                <span>PARTNER PROFILE</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Card>
                  </CardWrapper>
                </List.Item>
              )}
            />
          </div>
        </div>
      </StandardLanding>
      <ModalUser
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default Landing;