import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Button } from 'antd';

import StandardContainer from '../../../../../shared/components/otop/StandardContainer';
import CardWrapper from '../../../../../shared/components/otop/CardWrapper';
import TableUser from '../components/TableUser';
import { getUserList, deleteUser } from '../actions';

export default function LandingUser() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { users, userDetails, loading } = state.user;
  let userType = localStorage.getItem('userType');
  console.log('userType', userDetails);

  useEffect(() => {
    switch (userType) {
      case 'user':
        dispatch(getUserList('admin'));
        break;
      case 'admin':
        dispatch(getUserList('seller'));
        break;
      default:
        dispatch(getUserList());
        break;
    }
  }, []);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg my-5 content-crop">
          <CardWrapper>
            <Card className="card">
              <div className="flex justify-between card-content">
                <p>
                  {userType === 'admin' && 'Region Admin'}
                  {userType === 'user' && 'All Users'} are listed here
                </p>
                <Link to="/otop-hub/admin/manage-users/add">
                  <Button className="flex items-center">
                    <i className="fas fa-edit mr-2 text-lg"></i>
                    Add {userType === 'admin' && 'OTOP Hub Admin'}
                    {userType === 'user' && 'Region Admin'}
                  </Button>
                </Link>
              </div>
              <div className="card-content">
                <div className="overflow-x-auto">
                  <TableUser
                    users={users}
                    loading={loading}
                    getUserList={getUserList}
                    deleteUser={deleteUser}
                  />
                </div>
              </div>
            </Card>
          </CardWrapper>
        </div>
      </div>
    </StandardContainer>
  );
}
