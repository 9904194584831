import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { isEmpty } from 'lodash';

import StandardLanding from '../../../shared/components/StandardLanding';
import placeholder from '../../../shared/assets/images/placeholder.png';
import logoPlaceholder from '../../../shared/assets/images/icon/booth/exhibitor_icon_placeholder.png';
import {
  showExhibitorDetails,
  getExhibitorProductList,
  getExhibitorProductListMerchant,
  showExhibitorDetailsMerchant,
  sendLove,
} from '../actions';
import {
  getGuestExhibitorShow,
  getGuestExhibitorProductList,
} from '../../guest/actions';
import { getCategoryList, getRegionList } from '../../general/actions';
import FormSearchFilterProduct from '../components/FormSearchFilterProduct';
import ListProduct from '../components/ListProduct';
import FormInquireModal from '../../cart/components/FormInquireModal';

function ExhibitorShow(props) {
  const { history } = props;
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { profileDetails, profileDetailsExhibitor } = state.profile;
  const {
    exhibitorDetails,
    exhibitorProducts,
    exhibitorProductMerchant,
    totalProducts,
    totalProductMerchant,
    exhibitorDetailsMerchant,
  } = state.exhibitor;
  const { exhibitorDetailsGuest, exhibitorProductsGuest, totalProductGuest } =
    state.guest;
  const { categories, regions } = state.general;
  const loadingExhibitor = state.exhibitor.loading;
  const [deets, setDeets] = useState({});
  const [productList, setProductList] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  let isExhibitor = localStorage.getItem('isExhibitor');
  let token = localStorage.getItem('token');

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getRegionList());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === 'false') {
      dispatch(showExhibitorDetails(id));
      dispatch(getExhibitorProductList(null, id, totalProducts));
    }
  }, [id]);

  useEffect(() => {
    if (isExhibitor === 'true') {
      dispatch(showExhibitorDetailsMerchant(id));
      dispatch(getExhibitorProductListMerchant(null, id, totalProductMerchant));
    }
  }, [id]);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestExhibitorShow(id));
      dispatch(getGuestExhibitorProductList(null, id, totalProductGuest));
    }
  }, [id]);

  useEffect(() => {
    if (isExhibitor === 'false' && !isEmpty(exhibitorDetails)) {
      setDeets(exhibitorDetails);
    }

    if (isExhibitor === 'true' && !isEmpty(exhibitorDetailsMerchant)) {
      setDeets(exhibitorDetailsMerchant);
    }

    if (!token && !isEmpty(exhibitorDetailsGuest)) {
      setDeets(exhibitorDetailsGuest);
    }
  }, [exhibitorDetails, exhibitorDetailsMerchant, exhibitorDetailsGuest]);

  useEffect(() => {
    if (isExhibitor === 'false') {
      !isEmpty(exhibitorProducts)
        ? setProductList(exhibitorProducts)
        : setProductList([]);
    }
  }, [exhibitorProducts, exhibitorDetailsMerchant]);

  useEffect(() => {
    if (isExhibitor === 'true') {
      !isEmpty(exhibitorProductMerchant)
        ? setProductList(exhibitorProductMerchant)
        : setProductList([]);
    }
  }, [exhibitorProductMerchant]);

  useEffect(() => {
    if (!token) {
      !isEmpty(exhibitorProductsGuest)
        ? setProductList(exhibitorProductsGuest)
        : setProductList([]);
    }
  }, [exhibitorProductsGuest, exhibitorDetails, exhibitorDetailsMerchant]);

  const renderLinks = () => {
    return (
      <>
        {deets?.fb_link && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={deets.fb_link}
            className="facebook mr-2 rounded-lg"
          >
            <i className="fa" style={{ fontSize: '2em' }}>
              &#xf09a;
            </i>
            {/* <i className="fab fa-facebook fa-2x"></i> */}
          </a>
        )}
        {deets?.twitter_link && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={deets.twitter_link}
            className="twitter mr-2 rounded-lg"
          >
            <i className="fa" style={{ fontSize: '2em' }}>
              &#xf099;
            </i>
            {/* <i className="fab fa-twitter fa-2x"></i> */}
          </a>
        )}
        {deets?.gmail_link && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={deets.gmail_link}
            className="gmail mr-2 rounded-lg"
          >
            <i className="fa" style={{ fontSize: '2em' }}>
              &#xf1a0;
            </i>
            {/* <i className="fab fa-google fa-2x"></i> */}
          </a>
        )}
        {deets?.instagram_link && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={deets.instagram_link}
            className="instagram mr-2 rounded-lg"
          >
            <i className="fa" style={{ fontSize: '2em' }}>
              &#xf16d;
            </i>
            {/* <i className="fab fa-instagram fa-2x"></i> */}
          </a>
        )}
        {deets?.contact_number && (
          <div className="contact rounded-lg bg-red-20">
            <i className="fas fa-phone mr-1"></i>
            <span className="lg-title" style={{ margin: 0 }}>
              {deets.contact_number}
            </span>
          </div>
        )}
      </>
    );
  };

  const sendLoveFunc = () => {
    const userDetails =
      isExhibitor === 'false' ? profileDetails : profileDetailsExhibitor || {};
    dispatch(sendLove(userDetails.id, id, userDetails.type, 1, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'SUCCESS') {
        if (isExhibitor === 'false') dispatch(showExhibitorDetails(id));
        if (isExhibitor === 'true') dispatch(showExhibitorDetailsMerchant(id));
        if (!token) dispatch(getGuestExhibitorShow(id));
        notification.success({ message: `${response.msg}` });
      } else if (statusCode === 'ALREADY_LIKE') {
        notification.error({ message: `${response.msg}` });
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }
  };

  const renderExhibitor = () => {
    return (
      <div className="flex flex-col lg:flex-row">
        <div className="w-full relative">
          <div className="flex flex-col lg:flex-row">
            <div
              className="border rounded-xl overflow-hidden bg-white w-full lg:w-1/2 mb-10 lg:mb-0 lg:mr-3"
              style={{ aspectRatio: '16/9' }}
            >
              <img
                src={deets?.thumbnail?.full_path || placeholder}
                alt="Virtual Trade Expo Ph"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="w-full lg:w-1/2 lg:ml-3">
              <div className="flex">
                <div className="w-60 md:w-40 lg:w-52">
                  <img
                    src={
                      deets?.business_info?.logo?.full_path || logoPlaceholder
                    }
                    alt="Virtual Trade Expo Ph"
                    className="h-full w-full object-contain object-center"
                  />
                </div>
                <div>
                  <h1 className="pl-5 text-4xl text-blue-20 uppercase">
                    {deets?.company_name ?? ''}
                  </h1>
                  <h1 className="pl-5 text-xl">
                    {deets?.business_info?.address ?? ''}
                  </h1>
                  <p className="pl-5">
                    {regions?.find(
                      (region) => region.code === deets?.business_info?.region
                    )?.name ?? ''}
                  </p>
                </div>
              </div>
              <div className="flex my-5">{renderLinks()}</div>
              <p>
                {deets?.business_info?.description?.replace(
                  /<\/?[^>]+(>|$)/g,
                  ''
                )}
              </p>
              <div className="flex flex-col md:flex-row w-full md:w-auto my-5">
                {deets?.merchant_url && (
                  <div
                    className="btn btn-red hover:text-white rounded-lg h-14 px-4 mb-2 md:mr-2"
                    onClick={() => window.open(deets.merchant_url, '_blank')}
                    disabled
                  >
                    <i className="fa fa-shopping-cart mr-1"></i>
                    <span>ORDER NOW!</span>
                  </div>
                )}
                {!!token && isExhibitor === 'false' && !!productList.length && (
                  <div
                    className="btn btn-red rounded-lg h-14 px-4 mb-2 md:mr-2"
                    onClick={() => setIsShowModal((v) => !v)}
                  >
                    <i className="fa fa-comments mr-1"></i>
                    <span>SEND INQUIRY</span>
                  </div>
                )}
                {!!token && (
                  <div
                    className="btn btn-red rounded-lg h-14 mb-2 px-4"
                    onClick={() => sendLoveFunc()}
                  >
                    <i
                      className={`fa ${deets?.love?.status ? 'fa-heart' : 'fa-heart-o'
                        } fa-2x`}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXHIBITOR PROFILE
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            {deets && renderExhibitor()}
          </div>
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="mb-20">
              <FormSearchFilterProduct
                categories={categories}
                getExhibitorProductList={getExhibitorProductList}
                getExhibitorProductListMerchant={
                  getExhibitorProductListMerchant
                }
                getGuestExhibitorProductList={getGuestExhibitorProductList}
                exhibitor_id={id}
                totalProducts={totalProducts}
                totalProductMerchant={totalProductMerchant}
                totalProductGuest={totalProductGuest}
              />
            </div>
            <ListProduct
              productList={productList}
              loadingExhibitor={loadingExhibitor}
            />
          </div>
        </div>
      </StandardLanding>
      <FormInquireModal
        exhibitor_id={id}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        productList={productList}
        userDetails={
          isExhibitor === 'false'
            ? profileDetails
            : profileDetailsExhibitor || {}
        }
      />
    </React.Fragment>
  );
}
export default ExhibitorShow;
