import { GALLERY } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  listGallery: [],
};

const gallery = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case GALLERY.LIST_GALLERY_START:
      return { ...state, loading: true, error: "" };
    case GALLERY.LIST_GALLERY_SUCCESS:
      return { ...state, loading: false, listGallery: action.payload.data };
    case GALLERY.LIST_GALLERY_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default gallery;