import { PRODUCT } from "./action";

const INITIALSTATE = {
  loading: false,
  error: "",
  summary: [],
  sale: {}
};

const otopDashboard = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case PRODUCT.OTOP_SUMMARY_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_SUMMARY_SUCCESS:
      let {data} = action.payload;
      return { ...state, loading: false, summary: data };
    case PRODUCT.OTOP_SUMMARY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_SALE_GENERATE_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_SALE_GENERATE_SUCCESS:
      console.log("sale here",action.payload)
      return { ...state, loading: false, sale: action.payload };
    case PRODUCT.OTOP_SALE_GENERATE_ERROR:
      return { ...state, loading: false, error: action.payload };

      default:
      return state;
  }
};

export default otopDashboard;