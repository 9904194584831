import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { getOtopSellerList, deleteOtopSeller } from '../action';
import { render } from '@testing-library/react';

const SellerList = (props) => {
  const { sellerList, loading } = props;
  const dispatch = useDispatch();
  const { data } = sellerList;
  const [deleteSellerID, setDeleteSellerID] = useState(null);
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (data, rowData) => rendertype(data, rowData),
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (data, rowData) => rendertName(data, rowData),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (data, rowData) => renderLastName(data, rowData),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (data, rowData) => renderEmail(data, rowData),
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact_number',
      key: 'contact_number',
      render: (data, rowData) => renderContactNumber(data, rowData),
    },
    {
      title: 'Street',
      dataIndex: 'street_name',
      key: 'street_name',
      render: (data, rowData) => renderStreetName(data, rowData),
    },
    {
      title: 'Barangay',
      dataIndex: 'barangay',
      key: 'barangay',
      render: (data, rowData) => renderBrgy(data, rowData),
    },
    {
      title: 'Town',
      dataIndex: 'town',
      key: 'town',
      render: (data, rowData) => renderTown(data, rowData),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      render: (data, rowData) => renderRegion(data, rowData),
    },
    // {
    //     title: "Date Created",
    //     dataIndex: "date_created",
    //     key: "date_created",
    //     render: (data, rowData) => renderDate(data, rowData)
    // },
    // {
    //     title: "Time Created",
    //     dataIndex: "time_passed",
    //     key: "time_passed",
    //     render: (data, rowData) => renderTime(data, rowData)
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  // const renderDate = (data, rowData) => {
  //     return rowData.date_created.date_db;
  //   };

  // const renderTime = (data, rowData) => {
  // return rowData.date_created.time_passed;
  // };

  const rendertype = (data, rowData) => {
    return rowData.attributes.type;
  };

  const rendertName = (data, rowData) => {
    return rowData.attributes.firstname;
  };

  const renderLastName = (data, rowData) => {
    return rowData.attributes.lastname;
  };

  const renderEmail = (data, rowData) => {
    return rowData.attributes.email;
  };

  const renderContactNumber = (data, rowData) => {
    return rowData.attributes.contact_number;
  };

  const renderRegion = (data, rowData) => {
    return rowData.attributes.region;
  };

  const renderTown = (data, rowData) => {
    return rowData.attributes.town;
  };

  const renderBrgy = (data, rowData) => {
    return rowData.attributes.barangay;
  };

  const renderUnit = (data, rowData) => {
    return rowData.attributes.unit;
  };

  const renderStreetName = (data, rowData) => {
    return rowData.attributes.street_name;
  };

  const onSelectIdToDelete = (data) => {
    setDeleteSellerID(data);
  };

  const onConfirm = () => {
    dispatch(deleteOtopSeller(deleteSellerID, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code, msg } = response;
      if (status_code === 'PRODUCT_DELETED') {
        notification.success({ message: `${msg}` });
      } else {
        notification.warning({ message: `${msg}` });
      }
    } else {
      notification.error({
        message: 'Error',
        // description: error.msg
      });
    }
    dispatch(getOtopSellerList(null, 0));
    window.scrollTo(0, 0);
  };

  const renderAction = (data, rowData) => {
    return (
      <div className="flex items-center">
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={onConfirm}
          onClick={() => onSelectIdToDelete(rowData.id)}
        >
          <button className="btn">
            <i className="far fa-trash-alt"></i>
          </button>
        </Popconfirm>
      </div>
    );
  };

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
      />
    </div>
  );
};

export default SellerList;
