import React from "react";
import { Form, Input, Button, notification } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import FormWrapper from "../../../shared/components/FormWrapper";

function FormForgotPassword(props) {
  const dispatch = useDispatch();
  const { forgotPassword, history, forgotPasswordExhibitor } = props;
  const { pathname } = props.history.location;
  const onFinish = (values) => {
    // console.log('Success:', values);
    if (pathname === "/exhibitor/forgot-password") {
      dispatch(forgotPasswordExhibitor(values, callback));
    } else {
      dispatch(forgotPassword(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const callback = (response, error) => {
    // console.log("RESPONSE", response)
    // console.log("ERROR HERE", error)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === "RESET_TOKEN_SENT") {
        notification.success({ message: `${response.msg}`, description: "We have sent an email to reset your password." });
      } else if (statusCode === "UNAUTHORIZED") {
        notification.warning({ message: `${response.msg}` });
      } else if (statusCode === "EMAIL_NOT_FOUND" || statusCode === "ACCOUNT_NOT_FOUND") {
        notification.warning({ message: `${response.msg}` });
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        name="forgot-password"
        className="form-flex"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="relative w-full lg:w-1/2 pr-2 my-3">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Invalid email format!" },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <i className="fas fa-envelope text-blue-20 form-icon"></i>
        </div>

        <div className="flex w-full mt-5">
          <Button className="btn-main" htmlType="submit">
            <i className="fas fa-sign-in-alt mr-1"></i>
            <span>Submit</span>
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
}

export default FormForgotPassword;