import { AUTH } from './actions';

const INITIAL_STATE = {
    loading: false, 
    error: '', 
    user: {},
    region: [],
    isLoggedIn: false,
    token: undefined,

    loadingRegister: false, 
    errorRegister: '', 
};


const auth = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case AUTH.GOOGLE_LOGIN_START:
            return { ...state, loading: true, error: '',isLoggedIn: false }
        case AUTH.GOOGLE_LOGIN_SUCCESS:
            return { ...state, loading: false, user: action.payload, isLoggedIn: true }
        case AUTH.GOOGLE_LOGIN_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case AUTH.LOGIN_START:
            return { ...state, loading: true, error: '',isLoggedIn: false }
        case AUTH.LOGIN_SUCCESS:
            const payloadData = action.payload;
            payloadData.token = localStorage.getItem('token')
            let holder = action.payload;
            const { permission } = holder.meta;
            return { ...state, loading: false, user: payloadData, isLoggedIn: true }
        case AUTH.LOGIN_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }
        
        case AUTH.REGISTER_START:
            return { ...state, loading: true, error: '' }
        case AUTH.REGISTER_SUCCESS:
            const payloadDataReg = action.payload;
            payloadDataReg.token = localStorage.getItem('token')
            return { ...state, loading: false, user: payloadDataReg }
        case AUTH.REGISTER_ERROR:
            return { ...state, loading: false, error: action.payload }

        case AUTH.LOGIN_EXHIBITOR_START:
            return { ...state, loading: true, error: '',isLoggedIn: false }
        case AUTH.LOGIN_EXHIBITOR_SUCCESS:
            const payloadDataExhibitor = action.payload;
            payloadDataExhibitor.token = localStorage.getItem('token')
            return { ...state, loading: false, user: payloadDataExhibitor, isLoggedIn: true }
        case AUTH.LOGIN_EXHIBITOR_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }
        
        case AUTH.REGISTER_EXHIBITOR_START:
            return { ...state, loading: true, error: '' }
        case AUTH.REGISTER_EXHIBITOR_SUCCESS:
            const payloadDataRegExhibitor = action.payload;
            payloadDataRegExhibitor.token = localStorage.getItem('token')
            return { ...state, loading: false, user: payloadDataRegExhibitor }
        case AUTH.REGISTER_EXHIBITOR_ERROR:
            return { ...state, loading: false, error: action.payload }
    
        case AUTH.LOGOUT: 
            return { ...state, user: {}, isLoggedIn: false }

        case AUTH.FORGOT_PASSWORD_START:
            return { ...state, loading: true, error: '' }
        case AUTH.FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, user: action.payload }
        case AUTH.FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, error: action.payload }
            
        case AUTH.REGION_START:
            return { ...state, loading: true, error: '' }
        case AUTH.REGION_SUCCESS:
            return { ...state, loading: false, region: action.payload }
        case AUTH.REGION_ERROR:
            return { ...state, loading: false, error: action.payload }

        default: 
            return state;
    }
}

export default auth;