import React, { useEffect, useState } from "react";
import { Form, Select, Button, Input, Radio } from "antd";
import { useDispatch } from "react-redux";
import IdleTimer from 'react-idle-timer';

import FormWrapper from "../../../shared/components/FormWrapper";

const { Option } = Select;

function FormSearchFilterProduct(props) {
  const {
    showExpoFeatureProductList,
    showExpoFeatureProductExibitorList,
    getGuestFeaturedProductList,
    slug,
    categories,
    totalProducts,
    totalExhibitorProducts,
    totalGuestProducts,
    isExhibitor,
    products
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const getUserData = () => {
    const data = localStorage.getItem("switch");
    if (data) {
      return setValue(JSON.parse(data));
    } else {
      return null;
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const onFinish = (values) => {
    if (isExhibitor === "true") {
      dispatch(
        showExpoFeatureProductExibitorList(values, value, slug, totalExhibitorProducts)
      );
    } else if (isExhibitor === "false") {
      dispatch(showExpoFeatureProductList(values, value, slug, totalProducts));
    } else {
      dispatch(getGuestFeaturedProductList(values, slug, totalGuestProducts));
    }
  };

  const onChange = e => {
    setValue(e.target.value);
    localStorage.setItem("switch", e.target.value);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const renderCategories = () => {
    return (
      <>
        <Option value={1}>Food</Option>
        <Option value={0}>Non-food</Option>
      </>
    );
  };

  useEffect(() => {
    if (isExhibitor === "true") {
      dispatch(showExpoFeatureProductExibitorList(null, value, slug, 0));
    } else if (isExhibitor === "false") {
      dispatch(showExpoFeatureProductList(null, value, slug, 0));
    } else {
      dispatch(getGuestFeaturedProductList(null, slug, 0));
    }
  }, [value]);

  const onResetField = () => {
    console.log("val", value);
    form.resetFields();
    if (isExhibitor === "true") {
      dispatch(showExpoFeatureProductExibitorList(null, value, slug, 0));
    } else if (isExhibitor === "false") {
      dispatch(showExpoFeatureProductList(null, value, slug, 0));
    } else {
      dispatch(getGuestFeaturedProductList(null, slug, 0));
    }
  };



  return (
    <>
      <h1 className="content-subheader">Search Franchise Model / Product</h1>
      <FormWrapper>
        <Form
          name="searchFilterExpo"
          form={form}
          className="form-flex"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="relative w-full lg:w-1/5 mx-2">
            <Form.Item
              label="Keyword"
              name="keyword"
              rules={[{ required: false, message: "Please input keyword!" }]}
            >
              <Input placeholder="Item Keyword" />
            </Form.Item>
            <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i>
          </div>
          <div className="relative w-full lg:w-1/5 mx-2">
            <Form.Item
              label="Item Type"
              name="category_id"
              rules={[{ required: false, message: "Please select item type!" }]}
            >
              <Select placeholder="Item Type">{renderCategories()}</Select>
            </Form.Item>
            <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i>
          </div>

          <div className="relative w-full lg:w-1/5 mx-2">
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: false, message: "Please select price!" }]}
            >
              <Select placeholder="Price">
                <Option value="desc">Highest to Lowest</Option>
                <Option value="asc">Lowest To Highest</Option>
              </Select>
            </Form.Item>
            <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i>
          </div>

          {/* <div className="w-full lg:w-1/4 ml-3"> */}
          <Button className="btn-main mx-2" htmlType="submit">
            <span>Apply Filter</span>
          </Button>
          <Button className="btn-main ml-2" onClick={() => onResetField()}>
            <span>Reset Filter</span>
          </Button>
          {/* <div className="p-3">
            <Radio.Group name="sort_alphabetically" onChange={onChange} value={value} className="">
              <Radio value={1}>Alphabetical</Radio>
              <Radio value={0}>Random</Radio>
            </Radio.Group>
          </div> */}
          {/* </div> */}
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormSearchFilterProduct;