import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Dropdown, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { addMultipleItemsToCart } from '../../cart/actions';

// import logo from '../../../shared/assets/images/logo/logo.png';
import WomenbizPHWhite from '../../../shared/assets/images/logo/WomenbizPH-white.png';
import WomenbizPHBlack from '../../../shared/assets/images/logo/WomenbizPH-black.png';
import { logout } from '../../auth/actions';

function PageHeader(props) {
  const {
    profileDetails,
    profileDetailsExhibitor,
    isExhibitor,
    // history,
    click,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const hasToken = localStorage.getItem('token');
  // const otopToken = localStorage.getItem('otopToken');
  const [isShow, setIsShow] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  useEffect(() => {
    setIsTransparent(history.location.pathname === '/');
  }, [history.location.pathname]);

  const handleNavigation = useCallback((e) => {
    const _window = e.currentTarget;
    if (history.location.pathname === '/') {
      setIsTransparent(_window.pageYOffset <= 50);
    }
  }, []);

  useMemo(() => {
    window.addEventListener('scroll', handleNavigation);

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const onMenuClick = () => {
    setIsShow(true);
  };

  const onMenuCloseClick = () => {
    setIsShow(false);
    dispatch(addMultipleItemsToCart());
  };

  const onLogout = () => {
    dispatch(logout(callback));
  };

  const callback = () => {
    notification.success({ message: 'Logout Successfully!' });
  };

  const menu = (
    <Menu className="p-2 mt-5 w-40">
      {/* <Menu.Item>
        <Link
          to={
            isExhibitor === 'true'
              ? '/exhibitor/profile'
              : '/participant/profile'
          }
        >
          MY PROFILE
        </Link>
      </Menu.Item> */}
      <Menu.Item onClick={onLogout}>
        <Link to={'/login'}>LOGOUT</Link>
      </Menu.Item>
    </Menu>
  );

  const renderAuth = () => {
    let userDetails = null;
    let userAvatar = null;

    if (
      hasToken &&
      profileDetails &&
      Object.keys(profileDetails || {}).length > 0 &&
      isExhibitor === 'false'
    ) {
      userDetails = profileDetails;
      userAvatar = profileDetails.avatar;
    }

    if (
      hasToken &&
      profileDetailsExhibitor &&
      Object.keys(profileDetailsExhibitor || {}).length > 0 &&
      isExhibitor === 'true'
    ) {
      userDetails = profileDetailsExhibitor;
      userAvatar = profileDetailsExhibitor.thumbnail;
    }

    return (
      <Dropdown overlay={menu}>
        <div className="flex items-center" style={{ cursor: 'pointer' }}>
          <div className="flex items-center uppercase text-blue-20 mr-2">
            <span className="avatar mr-2">
              {userAvatar?.thumb_path ? (
                <img className="avatar" src={userAvatar?.thumb_path} alt="" />
              ) : (
                <i className="fas fa-user"></i>
              )}
            </span>
            WELCOME, {userDetails?.firstname}
          </div>
          <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const getClassByPathName = (pathname) => {
    if (pathname.toString().indexOf('/*') > -1) {
      const newPath = pathname.slice(0, -2);
      if (new RegExp(newPath).test(history?.location?.pathname)) {
        return 'text-red-20';
      } else return 'text-gray-60';
    }

    return history?.location?.pathname === pathname
      ? history?.location?.pathname === '/' && isTransparent
        ? 'text-white'
        : 'text-red-20'
      : 'text-gray-60';
  };

  const navData = [
    {
      path: '/',
      label: 'HOME',
      onClick: onMenuClick,
      icon: 'fas fa-home',
      className: getClassByPathName('/'),
    },
    {
      path: '/expo',
      label: 'EXPO',
      onClick: onMenuClick,
      icon: 'fas fa-ticket-alt',
      className: getClassByPathName('/expo'),
    },
    {
      path: '/auditorium',
      label: 'AUDITORIUM',
      onClick: onMenuClick,
      icon: 'fas fa-tv',
      className: getClassByPathName('/auditorium'),
    },
    {
      // path: `/booth/${isExhibitor === 'false' ? 'partners' : 'exhibitors'}`,
      path: '/booth/exhibitors',
      label: 'BOOTH',
      onClick: onMenuClick,
      icon: 'fas fa-store',
      className: getClassByPathName('/booth/*'),
    },
    {
      path: '/inquiries',
      label: 'INQUIRY',
      onClick: onMenuClick,
      icon: 'fas fa-pen',
      className: getClassByPathName('/inquiries'),
    },
  ];

  const exhibitorNavData = {
    path: '/exhibitor/profile',
    label: 'MSME PROFILE',
    onClick: onMenuClick,
    icon: 'fas fa-store-alt',
    className: getClassByPathName('/exhibitor/profile/*'),
  };

  const buyerNavData = {
    path: '/participant/profile',
    label: 'BUYER PROFILE',
    onClick: onMenuClick,
    icon: 'fas fa-store-alt',
    className: getClassByPathName('/participant/profile/*'),
  };

  if (
    hasToken &&
    (Object.keys(profileDetails || {}).length ||
      Object.keys(profileDetailsExhibitor || {}).length)
  ) {
    const index = navData.length - 1;
    if (isExhibitor === 'true') navData.splice(index, 0, exhibitorNavData);
    else navData.splice(index, 0, buyerNavData);
  }

  const loginButton = {
    path: '/login',
    label: 'LOGIN',
    onClick: onMenuCloseClick,
    icon: 'fas fa-sign-in-alt',
    className: getClassByPathName('/login'),
  };

  if (
    !hasToken ||
    (isExhibitor === 'false' && !Object.keys(profileDetails || {}).length) ||
    (isExhibitor === 'true' &&
      !Object.keys(profileDetailsExhibitor || {}).length)
  ) {
    const index = navData.length;
    navData.splice(index, 0, loginButton);
  }

  const bgNavBar = isTransparent ? 'bg-transparent' : 'bg-white';
  const WomenbizPH = isTransparent ? WomenbizPHWhite : WomenbizPHBlack;

  return (
    <nav
      className={`${
        history?.location?.pathname === '/' ? 'fixed w-full' : 'sticky'
      } top-0 z-50`}
    >
      <div
        id="nav"
        className={`w-full flex justify-between px-5 py-5 lg:px-20 font-semibold border-b border-white z-20 transition-all ease delay-100 ${bgNavBar}`}
      >
        <Link onClick={onMenuCloseClick} to="/">
          <img
            src={WomenbizPH}
            alt="Women's Business Council Ph"
            className="navbar-expo-brand -my-4 w-16 h-16 lg:w-28 lg:h-28"
          />
        </Link>
        <ul className="lg:hidden block">
          {isShow ? (
            <i
              className="fas fa-times text-red-20 text-2xl"
              onClick={onMenuCloseClick}
            ></i>
          ) : (
            <i
              className="fas fa-bars text-red-20 text-2xl"
              onClick={onMenuClick}
            ></i>
          )}
        </ul>
        <ul className="hidden lg:flex flex-col md:flex-row items-center">
          {navData.map((_data, idx) => (
            <li
              key={idx}
              className={`mx-5 whitespace-nowrap ${_data.className}`}
            >
              <Link onClick={_data.onClick} to={_data.path}>
                <i className={_data.icon}></i>&nbsp; {_data.label}
              </Link>
            </li>
          ))}
          {hasToken && renderAuth()}
        </ul>
      </div>
      {isShow && (
        <React.Fragment>
          <div className="bg-white w-full flex lg:hidden">
            <ul className="px-5 py-5 w-full">
              {navData.map((_data, idx) => (
                <li
                  key={idx}
                  className={`pb-5 border-b border-gray-200 whitespace-nowrap ${_data.className}`}
                >
                  <Link onClick={_data.onClick} to={_data.path}>
                    <i className={_data.icon}></i>&nbsp; {_data.label}
                  </Link>
                </li>
              ))}
              {hasToken && (
                <>
                  {/* <li
                    className={`py-5 border-b border-gray-200 whitespace-nowrap ${
                      history &&
                      history.location &&
                      history.location.pathname === '/exhibitor/login'
                        ? 'text-red-20'
                        : 'text-gray-60 '
                    }`}
                  >
                    <Link
                      onClick={onMenuCloseClick}
                      to={
                        isExhibitor === 'true'
                          ? '/exhibitor/profile'
                          : '/participant/profile'
                      }
                    >
                      <i className="fas fa-user"></i>&nbsp; MY PROFILE
                    </Link>
                  </li> */}
                  <li
                    className={`py-5 border-b border-gray-200 whitespace-nowrap ${
                      history &&
                      history.location &&
                      history.location.pathname === '/exhibitor/login'
                        ? 'text-red-20'
                        : 'text-gray-60 '
                    }`}
                    onClick={onLogout}
                  >
                    <Link onClick={onMenuCloseClick}>
                      <i className="fa fa-sign"></i>&nbsp; LOGOUT
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </React.Fragment>
      )}
    </nav>
  );
}

export default PageHeader;
