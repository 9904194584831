import { PARTNER } from './actions';

const INITIAL_STATE = {
  loading: false, 
  error: '', 
  total: 0,
  partners: [], 
  partnerDetails: {},
  partnerDetailsExhibitor: {},
};

const partner = (state = INITIAL_STATE, action) => {
 switch(action.type) {
  case PARTNER.PARTNER_LIST_START:
   return {...state, loading: true, error: ''};
  case PARTNER.PARTNER_LIST_SUCCESS:
    let temp = action.payload;
    let { total } = temp;
   return {...state, loading: false, partners: action.payload.data, total};
  case PARTNER.PARTNER_LIST_ERROR:
   return {...state, loading: false, error: action.payload};
      
  case PARTNER.PARTNER_SHOW_START:
   return {...state, loading: true, error: ''};
  case PARTNER.PARTNER_SHOW_SUCCESS:
   return {...state, loading: false, partnerDetails: action.payload};
  case PARTNER.PARTNER_SHOW_ERROR:
   return {...state, loading: false, error: action.payload};

  case PARTNER.PARTNER_SHOW_EXHIBITOR_START:
    return {...state, loading: true, error: ''};
  case PARTNER.PARTNER_SHOW_EXHIBITOR_SUCCESS:
    return {...state, loading: false, partnerDetailsExhibitor: action.payload};
  case PARTNER.PARTNER_SHOW_EXHIBITOR_ERROR:
    return {...state, loading: false, error: action.payload};

  default: 
   return state;
 }
}

export default partner;