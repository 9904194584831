import { PRODUCT } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  productList: [],
  productDetails: "",
  product: {},
  productDelete: {},
  productImageList: [],
  productImageDelete: {},
};

const product = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case PRODUCT.PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, productList: action.payload };
    case PRODUCT.PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.PRODUCT_READ_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_READ_SUCCESS:
      return { ...state, loading: false, productDetails: action.payload };
    case PRODUCT.PRODUCT_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.PRODUCT_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, product: action.payload };
    case PRODUCT.PRODUCT_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.PRODUCT_DELETE_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_DELETE_SUCCESS:
      return { ...state, loading: false, productDelete: action.payload };
    case PRODUCT.PRODUCT_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.PRODUCT_IMAGE_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_IMAGE_LIST_SUCCESS:
      return { ...state, loading: false, productImageList: action.payload };
    case PRODUCT.PRODUCT_IMAGE_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case PRODUCT.PRODUCT_IMAGE_DELETE_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.PRODUCT_IMAGE_DELETE_SUCCESS:
      return { ...state, loading: false, productImageDelete: action.payload };
    case PRODUCT.PRODUCT_IMAGE_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };


    default:
      return state;
  }
};

export default product;