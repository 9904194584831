import React from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import FormWrapper from '../../../../shared/components/otop/FormWrapper';

const SearchFeatured = (props) => {
  const dispatch = useDispatch();
  const { tags, getOtopProductList } = props;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(getOtopProductList(values));
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const renderTag = () => {
    if (!isEmpty(tags)) {
      const { data } = tags;
      return data.map((tag) => (
        <option key={tag.id} value={tag.attributes.name}>
          {tag.attributes.name}
        </option>
      ));
    } else {
      return <option key="no data">There is no tag</option>;
    }
  };

  const onResetField = () => {
    dispatch(getOtopProductList());
    form.resetFields();
  };

  return (
    <FormWrapper>
      <Form
        name="form-filter"
        form={form}
        className="form-flex"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex justify-end">
          <Form.Item name="tags">
            <div className="search-input w-52 mr-2">
              <select placeholder="Filter">{renderTag()}</select>
            </div>
          </Form.Item>
          <div>
            <button
              className="text-white py-2 px-5 bg-brown-10"
              htmltype="submit"
            >
              <i className="fas fa-search"></i>
            </button>
            <button
              onClick={onResetField}
              className="text-white py-2 px-5 bg-brown-10 ml-2"
              htmltype="submit"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default SearchFeatured;
