import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decode } from 'he';
import ReactPlayer from 'react-player';
import { Card, notification } from 'antd';
import { isEmpty, find } from 'lodash';

import StandardLanding from '../../../shared/components/StandardLanding';
import programList from '../../../shared/assets/images/icon/seminar/programList.png';
import holder from '../../../shared/assets/images/icon/seminar/holder.png';
import screen from '../../../shared/assets/images/icon/seminar/screen.png';
import welcomeMessage from '../../../shared/assets/images/icon/seminar/welcomeMessage.png';
import noStream from '../../../shared/assets/images/banner/noStream.png';
import avatar from '../../../shared/assets/images/avatar.png';
import expo from '../../../shared/assets/images/expo.jpg';
import {
  getExpoList,
  getSeminarList,
  getSeminarMain,
  getSeminarMainExhibitor,
  getSeminarListExhibitor,
  getDiscussionList,
  discussionCreate,
} from '../actions';
import { getSpeakerList } from '../../speaker/actions';
import { getGuestSeminarList, getGuestMainSeminar } from '../../guest/actions';
import CardWrapperNoBg from '../../../shared/components/CardWrapperNoBg';
import FormComment from '../components/FormComment';
import moment from 'moment';

function ExpoSeminarLanding(props) {
  // const { slug } = props.match.params;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { speakers, total } = state.speaker;
  const {
    expo,
    seminars,
    seminarExhibitor,
    mainSeminar,
    mainSeminarExhibitor,
    discussionList,
    loading,
    totalExpo,
  } = state.expo;
  const { mainSeminarDetails, seminarsGuest } = state.guest;
  const [mainSeminarDeets, setMainSeminarDeets] = useState({});
  let isExhibitor = localStorage.getItem('isExhibitor');
  let token = localStorage.getItem('token');
  const [seminarSpeakers, setSeminarSpeakers] = useState([]);
  const [seminarList, setSeminarList] = useState([]);
  const [otherSeminars, setOtherSeminars] = useState([]);
  const [showOtherSeminars, setShowOtherSeminars] = useState(false);
  const [showing, setShowing] = useState({});
  const [slug, setSlug] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExpoList(null, totalExpo));
  }, []);

  useEffect(() => {
    if (!isEmpty(expo)) {
      const featured = find(expo, 'is_featured');
      setSlug(featured?.slug || '');
    }

    return () => setSlug('');
  }, [expo]);

  useEffect(() => {
    if (slug) {
      if (isExhibitor === 'false') {
        dispatch(getSeminarList(slug, 0));
      } else {
        dispatch(getSeminarListExhibitor(slug, 0));
      }
    }
  }, [slug]);

  useEffect(() => {
    if (!isEmpty(showing)) {
      const { id } = showing;
      dispatch(getDiscussionList(id));
    }
  }, [showing]);

  // useEffect(() => {
  //   if (isExhibitor === 'false') {
  //     dispatch(getSeminarList(slug, 0));
  //     dispatch(getSeminarMain(slug, 0));
  //     if (mainSeminar) {
  //       const { id } = mainSeminar;
  //       dispatch(getDiscussionList(id));
  //     }
  //   } else {
  //     dispatch(getSeminarMainExhibitor(slug, 0, callback));
  //     dispatch(getSeminarListExhibitor(slug, 0));
  //   }

  //   dispatch(getSpeakerList(null, total));
  // }, [slug]);

  // useEffect(() => {
  //   if (!token) {
  //     dispatch(getGuestSeminarList(slug, 0));
  //     dispatch(getGuestMainSeminar(slug, 0));
  //   }
  // }, [slug]);

  useEffect(() => {
    !isEmpty(mainSeminar) && setShowing(mainSeminar);
    !isEmpty(mainSeminarExhibitor) && setShowing(mainSeminarExhibitor);
    !isEmpty(mainSeminarDetails) && setShowing(mainSeminarDetails);
  }, [mainSeminar, mainSeminarExhibitor, mainSeminarDetails]);

  useEffect(() => {
    !isEmpty(seminars) && setShowing(seminars);
    !isEmpty(seminarExhibitor) && setShowing(seminarExhibitor);
    !isEmpty(seminarsGuest) && setShowing(seminarsGuest);
  }, [seminars, seminarExhibitor, seminarsGuest]);

  // useEffect(() => {
  //   if (!isEmpty(seminarList && mainSeminarDeets)) {
  //     let otherSeminar = (seminarList || []).filter(
  //       (item) => item.id !== mainSeminarDeets.id
  //     );
  //     setOtherSeminars(otherSeminar);
  //   }
  // }, [seminarList]);

  // useEffect(() => {
  //   if (token === null) {
  //     notification.warning({
  //       message: "Could not access.",
  //       description: "To view this, you need to login first!",
  //     });
  //     return props.history.push("/expo");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (speakers) {
  //     let filtered = speakers.filter((item) => item['expo-slug'] === slug);
  //     setSeminarSpeakers(filtered);
  //   }
  // }, [slug, speakers]);

  const callback = (response, error) => {
    // if (response) {
    //   const { status_code } = response;
    //   if (status_code === 'UNREGISTERED_PARTICIPANT') {
    //     notification.warning({
    //       message: 'Could not access.',
    //       description:
    //         'Unable to proceed. You are not approved as exhibitor to this expo yet.!',
    //     });
    //     return props.history.push(`/expo/${slug}`);
    //   }
    // }
  };

  // const renderUpcomingAct = () => {
  //   if (!isEmpty(otherSeminars)) {
  //     return otherSeminars.map(
  //       (item) =>
  //         `"${item.title}" - will starts on ${moment(item.start_at).format(
  //           'MMMM Do YYYY, h:mm:ss'
  //         )} | `
  //     );
  //   }
  // };

  const renderScreenPlay = (mainSeminarDeets) => {
    switch (mainSeminarDeets.link_type) {
      case 'facebook':
        return (
          <div className="player-wrapper">
            <ReactPlayer
              url={mainSeminarDeets.link}
              className="react-player"
              width="100%"
              height="100%"
              controls
            />
          </div>
        );
      case 'youtube':
        return (
          <div className="player-wrapper">
            <ReactPlayer
              url={mainSeminarDeets.link}
              className="react-player"
              width="100%"
              height="100%"
            />
          </div>
        );
      default:
        <div></div>;
    }
  };

  // const renderDesc = (desc) => {
  //   if (desc) {
  //     let holder = desc.replace(/<\/?[^>]+(>|$)/g, '');
  //     let temp = holder;
  //     let temp2 = decode(temp);

  //     return temp2;
  //   }
  // };

  // const onClickJoin = () => {
  //   setShowOtherSeminars(!showOtherSeminars);
  // };

  // const renderOtherSeminars = () => {
  //   if (otherSeminars) {
  //     return otherSeminars.map((item) => (
  //       <div
  //         className="text-control-1 cursor-pointer"
  //         onClick={() => setShowing(item)}
  //       >
  //         {item.title}
  //       </div>
  //     ));
  //   }
  // };

  // const renderMainSeminar = () => {
  //   return (
  //     <StandardLanding>
  //       <CardWrapperNoBg>
  //         <Card loading={loading} bordered={false}>
  //           {(showing && showing.id === 0) || showing === undefined ? (
  //             <div className="bg-main-seminar content-bg content-crop">
  //               <div className="flex justify-center items-center h-full w-full">
  //                 <div className="w-4/6 mx-10 h-5/6 relative">
  //                   <img
  //                     src={screen}
  //                     alt="PLDT Home Biz"
  //                     className="w-full h-full"
  //                   />
  //                   <img
  //                     src={noStream}
  //                     alt="PLDT Home Biz"
  //                     className="w-full h-full object-center object-contain absolute top-0 left-0 p-5"
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           ) : (
  //             <div className="bg-main-seminar content-bg content-crop">
  //               <div className="flex flex-col justify-center h-full w-full">
  //                 <div className="flex items-center h-full w-full">
  //                   <div className="w-1/6 h-4/6">
  //                     <div className="flex items-center w-full h-full">
  //                       <img
  //                         src={holder}
  //                         alt="PLDT Home Biz"
  //                         className="h-full w-1/5"
  //                       />
  //                       <div className="relative h-full w-4/5">
  //                         <img
  //                           src={programList}
  //                           alt="PLDT Home Biz"
  //                           className="h-full w-full"
  //                         />
  //                         <div className="absolute top-0 left-0 w-full h-full py-5">
  //                           <div className="flex justify-center items-center w-full h-full">
  //                             <div className="flex justify-center items-center w-full h-full px-5 overflow-x-auto">
  //                               <img
  //                                 src={
  //                                   showing.filename ? showing.full_path : expo
  //                                 }
  //                               />
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="w-4/6 mx-10 h-5/6 relative">
  //                     <img
  //                       src={screen}
  //                       alt="PLDT Home Biz"
  //                       className="w-full h-full"
  //                     />
  //                     {renderScreenPlay(showing)}
  //                   </div>
  //                   <div className="w-1/6 h-4/6">
  //                     <div className="flex items-center w-full h-full">
  //                       <div className="relative h-full w-4/5">
  //                         <img
  //                           src={welcomeMessage}
  //                           alt="PLDT Home Biz"
  //                           className="h-full w-full"
  //                         />
  //                         <div className="absolute top-0 left-0 w-full h-full py-10">
  //                           <div className="w-full h-full lg:px-5 overflow-x-auto">
  //                             <h1 className="lg:text-xl">{showing.title}</h1>
  //                             <h1 className="text-gray-20">
  //                               {renderDesc(showing.description)}
  //                             </h1>
  //                             <p className="pt-2">Start Time</p>
  //                             <p>
  //                               {moment(showing.start_at).format(
  //                                 "MMMM Do YYYY, h:mm"
  //                               )}
  //                             </p>
  //                             <p>End Time:</p>
  //                             <p>
  //                               {moment(showing.ends_at).format(
  //                                 "MMMM Do YYYY, h:mm"
  //                               )}
  //                             </p>
  //                             <h2 className="mt-5 text-red-10 font-bold">
  //                               Speakers:
  //                             </h2>
  //                             <ul>
  //                               {!isEmpty(seminarSpeakers) &&
  //                                 seminarSpeakers.map((item) => (
  //                                   <li className="capitalize">{item.name}</li>
  //                                 ))}
  //                             </ul>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <img
  //                         src={holder}
  //                         alt="PLDT Home Biz"
  //                         className="h-full w-1/5"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="flex items-end w-full h-full text-xl">
  //                   <div className="bg-white flex items-center text-blue-10 font-bold ml-10 whitespace-nowrap h-full rounded-t-lg cursor-pointer">
  //                     <h3 className="pl-5 pr-10" onClick={onClickJoin}>
  //                       <i className="fas fa-sort-down"></i> JOIN THE DISCUSSION
  //                       BELOW <i className="fas fa-sort-down"></i>
  //                     </h3>
  //                   </div>
  //                   <div className="bg-red-20 text-white whitespace-nowrap h-full -ml-5 flex items-center pl-5">
  //                     <i className="fas fa-calendar-alt"></i>
  //                     <p className="mx-3">UPCOMING ACTIVITES</p>
  //                     <span className="font-bold"> |</span>
  //                   </div>
  //                   <div className="bg-red-20 upcoming w-full h-full text-white">
  //                     <h3>{renderUpcomingAct()}</h3>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           )}
  //         </Card>
  //       </CardWrapperNoBg>
  //     </StandardLanding>
  //   );
  // };

  const renderMainSeminar = () => {
    return (
      <StandardLanding>
        <div
          className="bg-main-seminar content-bg relative"
          style={{ paddingTop: '50.25%' }}
        >
          {showing && (
            <div className="flex justify-center h-full w-full">
              <div
                style={{
                  position: 'absolute',
                  top: '6%',
                  width: '76%',
                  height: '65%',
                  marginLeft: '2.5%',
                }}
              >
                {renderScreenPlay(showing)}
              </div>
              {showing?.title && (
                <h1 className="absolute top-3/4 text-white text-xl lg:text-2xl xl:text-3xl uppercase">
                  Welcome to the: {showing.title}
                </h1>
              )}
            </div>
          )}
        </div>
      </StandardLanding>
    );
  };

  const renderCommentList = () => {
    if (!isEmpty(discussionList)) {
      return discussionList.map((comment) => {
        return (
          <div className="border-2 border-dashed border-gray rounded-xl my-5 lg:mr-10">
            <div className="py-3 px-5">
              <p className="font-semibold">{comment.comment}</p>
              <div className="flex items-center mt-2">
                <img
                  src={
                    comment.participant.data.avatar.filename
                      ? comment.participant.data.avatar.full_path
                      : avatar
                  }
                  alt="PLDT Home Biz"
                  className="h-12 w-12 object-center object-cover rounded-lg"
                />
                <h4 className="text-red-20 pl-2 font-semibold">
                  Written by: {comment.participant.data.firstname}{' '}
                  {comment.participant.data.lastname} |{' '}
                  {comment.date_created.time_passed}
                </h4>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className="seminar-landing">{renderMainSeminar()}</div>

      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:py-0 lg:px-20">
            <div className="flex justify-between items-center">
              <h1 className="mt-10 mb-5 text-red-10 text-3xl uppercase">
                WOMENBIZ DISCUSSION
              </h1>
            </div>
            {console.log('cc-showing', showing)}
            {!isEmpty(showing) && showing?.id !== 0 ? (
              <React.Fragment>
                <h1 className="mt-10 mb-5 text-red-10 text-xl">
                  Leave a comment
                </h1>
                <div className="w-full">
                  <FormComment
                    mainSeminar={showing}
                    getDiscussionList={getDiscussionList}
                    discussionCreate={discussionCreate}
                  />
                </div>

                <div className="flex">
                  <div className="w-full h-96 overflow-y-auto">
                    {renderCommentList()}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div>There is no seminar at this moment</div>
            )}
          </div>
        </div>
      </StandardLanding>
    </>
  );
}

export default ExpoSeminarLanding;
