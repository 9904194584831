import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Summary from "../components/Summary";
import OrderChart from "../containers/OrderChart";
import ProductPreview from "./ProductPreview";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import HeaderTitle from "../../../layouts/components/HeaderTitle";
import { getOtopSummary, getOtopSale } from '../action'

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { summary } = state.otopDashboard;

  useEffect(() => {
   dispatch(getOtopSummary())
  }, [])

  const onGenerateSale = (values) => {
    dispatch(getOtopSale(values))
  }

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
            <HeaderTitle onGenerateSale={onGenerateSale}/>
            <Summary summary={summary}/>
            <div className="flex my-10">
              <div className="w-1/2 mr-5">
                <OrderChart summary={summary} />
              </div>
              <div className="w-1/2 ml-5">
                <ProductPreview />
              </div>
            </div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;