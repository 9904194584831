import React from 'react';
import styled from '@emotion/styled';

function TableWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default TableWrapper;

const StyledDiv = styled.div({
  width: '100%',
  overflowX: 'auto',
  '.ant-table': {
    background: 'transparent',
  },
  '.ant-table-thead .ant-table-cell': {
    color: '#cd1f30',
    fontSize: '20px',
    background: 'transparent',
    border: 'none',
  },
  '.ant-table-thead .ant-table-cell:before': {
    display: 'none',
  },
  '.ant-table-tbody': {
    padding: '2.5rem',
    borderRadius: '0.75rem',
    background: '#fff',
    '--tw-shadow':
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    boxShadow:
      'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
    overflow: 'hidden',
  },
  '.ant-table-pagination': {
    display: 'flex',
    justifyContent: 'flex-start',
    '.ant-pagination, li, button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    'li, button': {
      width: '40px',
      height: '50px',
      borderRadius: '8px',
      border: 'none',
    },
    '.ant-pagination-item': {
      a: {
        color: '#AE1C27',
      },
      '&-active': {
        backgroundColor: '#AE1C27',
        a: {
          color: '#fff',
        },
      },
    },
    '.ant-pagination-prev, .ant-pagination-next': {
      button: {
        color: '#fff',
        backgroundColor: '#AE1C27',
      },
    },
  },
});
