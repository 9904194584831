import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { List, Popconfirm, notification } from 'antd';
import { filter, iteratee } from 'lodash';
import logo from '../../../../../shared/assets/otop/Sellet/logo-1.svg';

const ListProducts = (props) => {
  const dispatch = useDispatch();
  const { products, loading, deleteOtopProduct, getOtopProductList } = props;
  const { data, included } = products;
  const [deleteProductID, setDeleteProductID] = useState(null);

  const renderImg = (id) => {
    let productImage = filter(included, iteratee({ type: 'product_image' }));
    if (productImage && id) {
      let filtered = productImage.filter(
        (item) => parseInt(item.id) === parseInt(id)
      );
      if (filtered && filtered[0]) {
        return (
          <img
            src={filtered[0].attributes.path}
            alt="OTOP Hub Product"
            className="object-contain object-center w-12 h-12"
          />
        );
      }
    }
    return (
      <img
        src={logo}
        alt="OTOP Hub Product"
        className="object-contain object-center w-12 h-12"
      />
    );
  };

  const onSelectIdToDelete = (data) => {
    setDeleteProductID(data);
  };

  const onConfirm = () => {
    dispatch(deleteOtopProduct(deleteProductID, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code, msg } = response;
      if (status_code === 'PRODUCT_DELETED') {
        notification.success({ message: `${msg}` });
      } else {
        notification.warning({ message: `${msg}` });
      }
    } else {
      notification.error({
        message: 'Error',
        // description: error.msg
      });
    }
    dispatch(getOtopProductList(null, 0));
    window.scrollTo(0, 0);
  };

  return (
    <List
      itemLayout="horizontal"
      loading={loading}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center">
              <div className="mr-5">{renderImg(item.id)}</div>
              <div>
                <p>{item.attributes.name}</p>
                <p className="text-control-1">{item.attributes.description}</p>
              </div>
            </div>
            <div>
              <Link to={`/otop-hub/admin/products/${item.id}/edit`}>
                <button className="btn">
                  <i className="far fa-edit"></i>
                </button>
              </Link>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={onConfirm}
                onClick={() => onSelectIdToDelete(item.id)}
              >
                <button className="btn">
                  <i className="far fa-trash-alt"></i>
                </button>
              </Popconfirm>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
};

export default ListProducts;
