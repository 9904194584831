import { getAuthHeader, authHeader, getError, axios } from "../../../../shared/utils";
import {
  API_OTOP_PRODUCT_LIST,
  API_OTOP_PRODUCT_READ,
  API_OTOP_PRODUCT_EDIT,
  API_OTOP_PRODUCT_CREATE,
  API_OTOP_PRODUCT_DELETE,
} from "../../../../shared/constant/url";

export const PRODUCT = {
  OTOP_PRODUCT_LIST_START: "OTOP_PRODUCT_LIST_START",
  OTOP_PRODUCT_LIST_SUCCESS: "OTOP_PRODUCT_LIST_SUCCESS",
  OTOP_PRODUCT_LIST_ERROR: "OTOP_PRODUCT_LIST_ERROR",

  OTOP_PRODUCT_READ_START: "OTOP_PRODUCT_READ_START",
  OTOP_PRODUCT_READ_SUCCESS: "OTOP_PRODUCT_READ_SUCCESS",
  OTOP_PRODUCT_READ_ERROR: "OTOP_PRODUCT_READ_ERROR",

  OTOP_PRODUCT_ADD_EDIT_START: "OTOP_PRODUCT_ADD_EDIT_START",
  OTOP_PRODUCT_ADD_EDIT_SUCCESS: "OTOP_PRODUCT_ADD_EDIT_SUCCESS",
  OTOP_PRODUCT_ADD_EDIT_ERROR: "OTOP_PRODUCT_ADD_EDIT_ERROR",

  OTOP_PRODUCT_DELETE_START: "OTOP_PRODUCT_DELETE_START",
  OTOP_PRODUCT_DELETE_SUCCESS: "OTOP_PRODUCT_DELETE_SUCCESS",
  OTOP_PRODUCT_DELETE_ERROR: "OTOP_PRODUCT_DELETE_ERROR",
};

export const getOtopProductList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PRODUCT_LIST_START });
    let formData = new FormData();
    formData.append("per_page", per_page);
    formData.append("page", 1);
    formData.append("included", "images");

    if (params) {
      formData.append("keyword", params.keyword);
    }

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_PRODUCT_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readOtopProduct = (id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PRODUCT_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_PRODUCT_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_READ_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createOtopProduct = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("name", params.name);
    formData.append("price", params.price);
    formData.append("quantity", params.quantity);
    formData.append("quantity_type", params.quantity_type);
    formData.append("description", params.description);
    if(params.images && params.images[0]) {
      formData.append("images[]", params.images[0].originFileObj);
    }
    if(params.tags && params.tags[0]) {
      params.tags.forEach(tag => {
        formData.append("tags[][name]", tag);
      });
    }
    if(params.categories && params.categories[0]) {
      params.categories.forEach(category => {
        formData.append("categories[][name]", category);
      });
    }
    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_OTOP_PRODUCT_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editOtopProduct = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_START });
    axios
      .put(`${API_OTOP_PRODUCT_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error.response, error);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteOtopProduct = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PRODUCT_DELETE_START });

    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_PRODUCT_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.OTOP_PRODUCT_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};