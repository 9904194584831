import React from "react";
import styled from "@emotion/styled";

function FormWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FormWrapper;

const StyledDiv = styled.div({
  width: "100%",
  ".shadow": {
    boxShadow:
  "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",boxShadow:
  "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  },

    ".inlined-form": {
     width: "80rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },

  ".xl-title": {
    fontSize: "1.5rem",
    fontWeight: "700",
  },  
 
  "input": {
    color: "#4E4E4E",
    border: "none",
    borderRadius: "1rem",
    padding: "10px 10px 10px 10px",
    background: "#e7e6e6",
  },

  "input:focus": {
    outline: 'none',
  },

  "select": {
    width: "100%",
    height: "2.5rem",
    border: "1px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "none",
    borderRadius: "1rem",
    padding: "10px 10px 10px 20px",
    zIndex: "5", 
    backgroundColor: "transparent",
 },

 "select:focus": {
  outline: 'none',
},

  ".search-input": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    background: "#e7e6e6",
    borderRadius: ".5rem",
    padding: "0px 20px 0px 20px",
  },

  ".ant-input": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "none",
    borderRadius: "2rem",
    padding: "10px 10px 10px 40px",
    marginTop: "1rem",
  },
  
  ".ant-input-password": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "2px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "10px 10px 10px 40px",
  },
  ".ant-select": {
     border: "1px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "none",
    borderRadius: "1rem",
    padding: "5px 0px 5px 0px",
    zIndex: "5", 
    backgroundColor: "#e7e6e6 !important",
  },
  
  ".ant-form-item-control-input-content": {
    background: "trasparent !important",
    borderRadius: "2rem",
  },
  ".ant-picker": {
    // border: "0px solid #d9d9d9 !important",
    color: "#4E4E4E",
    border: "2px #C5C5C5 solid !important",
    borderRadius: "0.5rem",
    padding: "10px 10px 10px 40px",
    zIndex: "5", 
    width: '100%'
  },
  "textarea.ant-input": {
    background: "#e7e6e6 !important",
  },
  ".ant-select-selector": {
    backgroundColor: "#e7e6e6 !important",
    margin: "0 10px",
  },
  ".ant-input-number": {
    color: "#4E4E4E",
    border: "2px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "10px 10px 10px 40px",
  },
  ".ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    border: "0"
  }, 
  ".ant-form-item-label": {
    marginTop: "-17px !important",
    position: "absolute !important",
    top: "0 !important",
    zIndex: "10",
    marginLeft: "10px",
    padding: "0px 5px",
  },
  ".ant-form-item .ant-form-item-label > label": {
    color: "#00539A",
    background: "#FFF",
    height: "20px !important", 
    fontSize: "16px"
  },
  ".ant-btn": {
    background: "#c96a5e",
    color: "#FFF",
    fontSize: "1.1em",
    borderRadius: "1rem",
    border: "0",
    letterSpacing: "1px",
    height: "45px",
  },
  ".ant-btn:focus": {
    borderColor: "none",
  },
  ".ant-btn:hover": {
    borderColor: "none",
    background: "#E42F30 !important",
    outline: "0",
  },
  ".lg-title": {
    color: "#CD1F30",
    fontSize: "16px;",
    lineHeight: "2rem;",
    fontWeight: "700",
  }
});
