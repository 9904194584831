import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Table, notification, Card, Button, Form, Radio } from 'antd';
import { filter, iteratee } from 'lodash';
import { getCartOtopProductList } from '../actions';
import CardWrapper from '../../../../shared/components/otop/CardWrapper';
import { useHistory } from 'react-router-dom';
import { cartCheckout, createOrder, cartOtopProductRemove } from '../actions';
import { getAddressList } from '../../addresses/actions';
import paymaya from '../../../../shared/assets/images/logo/nobg/paymaya.png';
import logo from '../../../../shared/assets/otop/Sellet/logo-1.svg';

const CartTable = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const state = useSelector((state) => state);
  const { checkout, cartProducts } = state.otopCart;
  const { profileDetails } = state.profile;
  const { addresses } = state.address;
  const [proceed, setProceed] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState({});
  const [defaultBillingAddress, setDefaultBillingAddress] = useState({});
  let isExhibitor = localStorage.getItem('isExhibitor');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCartOtopProductList());
    dispatch(getAddressList(getAddressList()));
  }, []);

  useEffect(() => {
    if (addresses) {
      let defaultShippingAddress = addresses.filter(
        (item) => item.attributes.default_shipping
      );
      let defaultBillingAddress = addresses.filter(
        (item) => item.attributes.default_billing
      );
      setDefaultShippingAddress(defaultShippingAddress[0]);
      setDefaultBillingAddress(defaultBillingAddress[0]);
    }
  }, [addresses]);

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (data, rowData) => renderProduct(data, rowData),
    },
    {
      title: 'Store Name',
      dataIndex: 'name',
      key: 'name',
      render: (data, rowData) => renderStoreName(data, rowData),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (data, rowData) => renderQuantity(data, rowData),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (data, rowData) => renderPrice(data, rowData),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '',
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  useEffect(() => {
    if (cartProducts) {
      const filteredArr = cartProducts.reduce(
        (cart, current, currentIndex, array) => {
          const holder = cart.find(
            (item) => parseInt(item.data.id) === parseInt(current.data.id)
          );
          // const holderQuantity = array.filter((el) => el.id === current.id);
          // let count = holderQuantity.length;
          if (!holder) {
            return cart.concat([current]);
          } else {
            // holder.quantity = count;
            // holder.total = holder.price * count;
            return cart;
          }
        },
        []
      );
      setList(filteredArr);

      // let arr = [];
      // cartProducts.forEach((element) => {
      //   arr.push(element.total);
      // });
      // let total = arr.reduce((a, b) => a + b, 0);
      // setTotal(total.toLocaleString());
    }
  }, [cartProducts]);

  const renderStoreName = (data, rowData) => {
    if (rowData && rowData.data && rowData.included) {
      const { attributes } = rowData.data;
      let store = filter(rowData.included, iteratee({ type: 'store' }));
      if (attributes && store && store[0]) {
        return (
          <div className="flex items-center">
            <img
              src={
                store[0].attributes.logo_path
                  ? store[0].attributes.logo_path
                  : logo
              }
              alt="OTOP Hub Product"
              className="object-cover object-center w-12 h-12"
            />
            <div className="pl-3">
              <h1>{store[0].attributes.name}</h1>
              <p>
                {attributes.firstname} {attributes.lastname}
              </p>
            </div>
          </div>
        );
      }
    }
  };

  const renderPrice = (data, rowData) => {
    return (
      <div className="text-brown-10 font-semibold">
        ₱&nbsp;{rowData.data.attributes.price}
      </div>
    );
  };

  const renderProduct = (data, rowData) => {
    if (rowData && rowData.data && rowData.included) {
      const { attributes } = rowData.data;
      let product_image = filter(
        rowData.included,
        iteratee({ type: 'product_image' })
      );
      if (attributes && product_image && product_image[0]) {
        return (
          <div className="flex flex-col items-center">
            <img
              src={
                product_image[0].attributes.path
                  ? product_image[0].attributes.path
                  : logo
              }
              alt="OTOP Hub Product"
              className="object-cover object-center w-48 h-48"
            />
            <div className="pt-3">
              <p>{attributes.name}</p>
            </div>
          </div>
        );
      }
    }
  };

  const renderQuantity = (data, rowData) => {
    return (
      <p>
        {data} {rowData.data.attributes.quantity_type}
      </p>
    );
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    let products = [];
    selectedRowKeys.forEach((element) => {
      let productSelected = list.filter(
        (item) => parseInt(item.data.id) === parseInt(element.id)
      );
      products.push(productSelected[0]);
    });

    let total = 0;
    for (let i = 0; i < products.length; i++) {
      total += Number(products[i].total);
    }
    setTotal(total);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onCheckout = (keys) => {
    if (isExhibitor === 'false') {
      if (!keys.length) {
        notification.warning({
          message:
            'Cart is empty or You have not yet selected item(s) to checkout.',
        });
      } else {
        dispatch(cartCheckout(list));
        setProceed(true);
      }
    } else {
      history.push({
        pathname: '/login',
        state: { isFromCheckoutOtop: true, list },
      });
    }
  };

  const onFinish = (values) => {
    if (checkout && profileDetails) {
      values['status_id'] = '1';
      values['participant_id'] = profileDetails.id;
      values['shipping_address_id'] = parseInt(defaultShippingAddress.id);

      let products = [];
      checkout.forEach((element) => {
        products.push({
          product_id: parseInt(element.data.id),
          quantity: element.quantity,
        });
      });
      values['products'] = products;
    }
    // console.log("values", values);
    dispatch(createOrder(values, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = (response, error) => {
    console.log('response', response);
    if (response) {
      const { data } = response;
      if (!isEmpty(data.checkoutId)) {
        notification.success({
          message: 'Transaction will proceed to paymaya...',
        });
        window.location.replace(data.redirectUrl);
        sessionStorage.clear();
      } else if (data.data.type === 'order') {
        notification.success({
          message: `Your order is successfully sent.`,
          description: 'Check your profile for transaction status!',
        });
        history.push('/otop-hub/products');
        sessionStorage.clear();
      } else if (response.status_code === 'SERVER_ERROR') {
        notification.error({
          message: response.msg,
        });
      }
    } else if (error) {
      notification.error({ message: `${error.msg}` });
    }
    window.scrollTo(0, 0);
  };

  const renderAction = (data, rowData) => {
    return (
      <button onClick={() => onSelectIdToDelete(parseInt(rowData.data.id))}>
        <i className="fas fa-trash text-gray-10"></i>
      </button>
    );
  };

  const onSelectIdToDelete = (data) => {
    setProductIdToDelete(data);
    onDelete();
  };

  const onDelete = () => {
    list.filter((item) => {
      if (parseInt(item.data.id) === productIdToDelete) {
        dispatch(cartOtopProductRemove(productIdToDelete, callback));
      }
    });
  };

  const renderShippingAddress = () => {
    if (!isEmpty(defaultShippingAddress)) {
      return (
        <ul>
          <li>
            {defaultShippingAddress.attributes.firstname}{' '}
            {defaultShippingAddress.attributes.lastname} |{' '}
            {defaultShippingAddress.attributes.phone}
          </li>
          <li>{defaultShippingAddress.attributes.address_line1}</li>
          <li>{defaultShippingAddress.attributes.address_line2}</li>
          <li>
            {defaultShippingAddress.attributes.city},{' '}
            {defaultShippingAddress.attributes.state}
          </li>
          <li>{defaultShippingAddress.attributes.zipcode}</li>
        </ul>
      );
    }
  };

  const renderBillingAddress = () => {
    if (!isEmpty(defaultBillingAddress)) {
      return (
        <ul>
          <li>
            {defaultBillingAddress.attributes.firstname}{' '}
            {defaultBillingAddress.attributes.lastname} |{' '}
            {defaultBillingAddress.attributes.phone}
          </li>
          <li>{defaultBillingAddress.attributes.address_line1}</li>
          <li>{defaultBillingAddress.attributes.address_line2}</li>
          <li>
            {defaultBillingAddress.attributes.city},{' '}
            {defaultBillingAddress.attributes.state}
          </li>
          <li>{defaultBillingAddress.attributes.zipcode}</li>
        </ul>
      );
    }
  };

  return (
    <div className="flex">
      <div className="w-4/6 mr-5">
        <Table
          pagination={false}
          rowKey="data"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={list}
        />
      </div>
      <div className="w-1/4 ml-5">
        <CardWrapper>
          <Card className="w-96">
            <div className="card">
              <div className="card-content">
                <p className="sm-title border-b">ORDER SUMMARY</p>
                <div className="flex justify-between my-2">
                  <p className="card-title">Subtotal</p>
                  <p className="card-title">
                    ₱&nbsp;
                    {!isEmpty(selectedRowKeys) ? total.toLocaleString() : '0'}
                    .00
                  </p>
                </div>
                <div className="flex justify-between my-2">
                  <p className="card-title">Shiping Fee</p>
                  <p className="card-title">₱ 0.00</p>
                </div>
                <div className="flex justify-between my-2">
                  <p className="card-title">Total</p>
                  <p className="card-title">
                    <p className="card-title">
                      ₱&nbsp;
                      {!isEmpty(selectedRowKeys) ? total.toLocaleString() : '0'}
                      .00
                    </p>
                  </p>
                </div>
                <Button
                  onClick={() => onCheckout(selectedRowKeys)}
                  className="w-full mt-10"
                >
                  PROCEED TO CHECKOUT
                </Button>

                <div className="border-b w-full">&nbsp;</div>
                <div
                  className={proceed ? 'h-full mt-5' : 'h-0 overflow-hidden'}
                >
                  <div className="w-full rounded-lg shadow p-5 bg-white my-5">
                    <div className="flex flex-col">
                      <h3 className="text-red-20 text-sm mr-5">
                        Shipping Address and Billing Address
                      </h3>
                      <p className="mt-3">
                        Shipping Address <code>[Default]</code>{' '}
                      </p>
                      {renderShippingAddress()}
                      <Link
                        to="/otop-hub/my-addresses"
                        className="text-brown-10"
                      >
                        [Update Shipping Address]
                      </Link>

                      <p className="mt-3">
                        Billing Address <code>[Default]</code>{' '}
                      </p>
                      {renderBillingAddress()}
                      <Link
                        to="/otop-hub/my-addresses"
                        className="text-brown-10"
                      >
                        [Update Billing Address]
                      </Link>
                    </div>
                  </div>
                  <Form
                    name="otopCheckout"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <div className="w-full rounded-lg shadow p-5 bg-white">
                      <div className="flex flex-col items-center">
                        <h3 className="text-red-20 text-sm mr-5">
                          Payment Options
                        </h3>
                        <Form.Item
                          name="payment_method"
                          defaultValue="cod"
                          rules={[
                            {
                              required: true,
                              message: 'Please select payment type!',
                            },
                          ]}
                        >
                          <Radio.Group className="flex flex-col mt-5">
                            <Radio.Button
                              value="cod"
                              className="flex justify-center font-bold text-red-10 whitespace-nowrap"
                            >
                              <i className="fas fa-wallet"></i>&nbsp;CASH ON
                              DELIVERY
                            </Radio.Button>
                            <Radio.Button
                              value="paymaya"
                              style={{ color: 'black' }}
                              className="font-bold flex justify-center my-3"
                            >
                              <div className="flex items-center text-red-10">
                                <img src={paymaya} className="h-7" />
                                PAYMAYA
                              </div>
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    {!isEmpty(defaultShippingAddress) &&
                    !isEmpty(defaultBillingAddress) ? (
                      <Button className="w-full mt-10" htmlType="submit">
                        PLACE ORDER
                      </Button>
                    ) : (
                      <Button
                        className="w-full mt-10"
                        disabled
                        htmlType="submit"
                      >
                        PLACE ORDER
                      </Button>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Card>
        </CardWrapper>
      </div>
    </div>
  );
};

export default CartTable;
