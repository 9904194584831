import axios from 'axios'
import { authHeader, getError } from "../../shared/utils";
import {
  API_GOOGLE_AUTH,
  API_LOGIN,
  API_REGISTER,
  API_LOGIN_EXHIBITOR,
  API_REGISTER_EXHIBITOR,
  API_FORGOT_PASSWORD,
  API_FORGOT_PASSWORD_EXHIBITOR,
  API_RESET_PASSWORD,
  API_RESET_PASSWORD_EXHIBITOR,
  API_REGION,
} from "../../shared/constant/url";

export const AUTH = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",

  REGION_START: "REGION_START",
  REGION_SUCCESS: "REGION_SUCCESS",
  REGION_ERROR: "REGION_ERROR",

  GOOGLE_LOGIN_START: "GOOGLE_LOGIN_START",
  GOOGLE_LOGIN_SUCCESS: "GOOGLE_LOGIN_SUCCESS",
  GOOGLE_LOGIN_ERROR: "GOOGLE_LOGIN_ERROR",
  GOOGLE_LOGOUT: "LOGOUT",

  REGISTER_START: "REGISTER_START",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  LOGIN_EXHIBITOR_START: "LOGIN_EXHIBITOR_START",
  LOGIN_EXHIBITOR_SUCCESS: "LOGIN_EXHIBITOR_SUCCESS",
  LOGIN_EXHIBITOR_ERROR: "LOGIN_EXHIBITOR_ERROR",
  LOGOUT_EXHIBITOR: "LOGOUT_EXHIBITOR",

  REGISTER_EXHIBITOR_START: "REGISTER_EXHIBITOR_START",
  REGISTER_EXHIBITOR_SUCCESS: "REGISTER_EXHIBITOR_SUCCESS",
  REGISTER_EXHIBITOR_ERROR: "REGISTER_EXHIBITOR_ERROR",

  FORGOT_PASSWORD_EXHIBITOR_START: "FORGOT_PASSWORD_EXHIBITOR_START",
  FORGOT_PASSWORD_EXHIBITOR_SUCCESS: "FORGOT_PASSWORD_EXHIBITOR_SUCCESS",
  FORGOT_PASSWORD_EXHIBITOR_ERROR: "FORGOT_PASSWORD_EXHIBITOR_ERROR",

  RESET_PASSWORD_EXHIBITOR_START: "RESET_PASSWORD_EXHIBITOR_START",
  RESET_PASSWORD_EXHIBITOR_SUCCESS: "RESET_PASSWORD_EXHIBITOR_SUCCESS",
  RESET_PASSWORD_EXHIBITOR_ERROR: "RESET_PASSWORD_EXHIBITOR_ERROR",
};

export const login = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.LOGIN_START });
    let formData = new FormData();
    formData.append("email", params.email);
    formData.append("password", params.password);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_LOGIN, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.LOGIN_SUCCESS, payload: response.data },
          response.data && localStorage.setItem("token", response.token),
          response.data && localStorage.setItem("isExhibitor", false)
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: AUTH.LOGIN_ERROR, payload: getError(error) });
      });
  };
};


export const googleLogin = (token, callback = null) => {
  let params = {
    "access_token": token,
  }
  return (dispatch) => {
    dispatch({ type: AUTH.GOOGLE_LOGIN_START });
    axios
      .post(`${API_GOOGLE_AUTH}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch(
          {
            type: AUTH.GOOGLE_LOGIN_SUCCESS,
            payload: response.data,
          },
          localStorage.setItem("token", response.data.token),
          localStorage.setItem("access token", token),
          localStorage.setItem("isExhibitor", false));
      })
      .catch((error) => {
        dispatch({
          type: AUTH.GOOGLE_LOGIN_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const register = (params, slug, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.REGISTER_START });
    let formData = new FormData();
    formData.append("firstname", params.firstname);
    formData.append("lastname", params.lastname);
    formData.append("email", params.email);
    formData.append("password", params.password);
    formData.append("password_confirmation", params.password_confirmation);
    formData.append("contact_number", params.contact_number);
    if (slug) {
      formData.append("slug", slug);
    }

    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_REGISTER, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.REGISTER_SUCCESS, payload: response.data }
          // localStorage.setItem("token", response.token)
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: AUTH.REGISTER_ERROR, payload: getError(error) });
      });
  };
};

export const logout = (callback = null) => {
  return (dispatch) => {
    callback();
    dispatch({ type: AUTH.LOGOUT });
    localStorage.clear();
    // sessionStorage.clear();
  };
};

export const forgotPassword = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.FORGOT_PASSWORD_START });
    let formData = new FormData();
    formData.append("email", params.email);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_FORGOT_PASSWORD, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.FORGOT_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.FORGOT_PASSWORD_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const resetPassword = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.RESET_PASSWORD_START });
    let formData = new FormData();
    formData.append("id", params.id);
    formData.append("email", params.email);
    formData.append("password", params.password);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_RESET_PASSWORD, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.RESET_PASSWORD_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const loginExhibitor = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.LOGIN_EXHIBITOR_START });
    let formData = new FormData();
    formData.append("email", params.email);
    formData.append("password", params.password);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_LOGIN_EXHIBITOR, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.LOGIN_EXHIBITOR_SUCCESS, payload: response.data },
          response.data && localStorage.setItem("token", response.token),
          response.data && localStorage.setItem("isExhibitor", true)
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.LOGIN_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const registerExhibitor = (params, slug, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.REGISTER_EXHIBITOR_START });
    let formData = new FormData();
    formData.append("firstname", params.firstname);
    formData.append("lastname", params.lastname);
    formData.append("email", params.email);
    formData.append("password", params.password);
    formData.append("password_confirmation", params.password_confirmation);
    formData.append("contact_number", params.contact_number);
    formData.append("company_name", params.company_name);
    formData.append("position", params.position);
    formData.append("business_description", params.business_description);
    formData.append("province_id", params.province_id);
    formData.append("region", params.region);
    if (slug) {
      formData.append("slug", slug);
    }

    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_REGISTER_EXHIBITOR, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch(
          { type: AUTH.REGISTER_EXHIBITOR_SUCCESS, payload: response.data }
        );
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.REGISTER_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const forgotPasswordExhibitor = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.FORGOT_PASSWORD_EXHIBITOR_START });
    let formData = new FormData();
    formData.append("email", params.email);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_FORGOT_PASSWORD_EXHIBITOR, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.FORGOT_PASSWORD_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.FORGOT_PASSWORD_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const resetPasswordExhibitor = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.RESET_PASSWORD_EXHIBITOR_START });
    let formData = new FormData();
    formData.append("id", params.id);
    formData.append("email", params.email);
    formData.append("password", params.password);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_RESET_PASSWORD_EXHIBITOR, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.RESET_PASSWORD_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.RESET_PASSWORD_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const region = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.REGION_START });
    let formData = new FormData();
    formData.append("region_id", params.id);
    let reqOptions = {
      method: "POST",
      body: formData,
    };
    fetch(API_REGION, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.REGION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.REGION_ERROR,
          payload: getError(error),
        });
      });
  };
};