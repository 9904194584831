import React, { useEffect, useState } from 'react';
import { Tabs, notification } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Profile from '../components/Profile';
import Inquiry from '../../inquiries/containers/LandingInquiry';
import StandardLanding from '../../../shared/components/StandardLanding';
import EditProfile from '../containers/EditProfile';
import EditPassword from '../containers/EditPassword';
import EditAvatar from '../containers/EditAvatar';
import TabsWrapper from '../../../shared/components/TabsWrapper';

import {
  updateProfile,
  updatePassword,
  updatePhoto,
  readProfile,
} from '../actions';
import { logout } from '../../auth/actions';

const { TabPane } = Tabs;

function ParticipantProfile() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { pathname } = history.location;
  const state = useSelector((state) => state);
  const [tab, setTab] = useState('2');
  const { profileDetails, loading } = state.profile;
  let match = useRouteMatch();
  let { path } = match;

  useEffect(() => {
    if (pathname === '/participant/profile') setTab('2');
  }, [pathname]);

  const getUserData = () => {
    const data = localStorage.getItem('google_account');
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  };

  const onLogout = () => {
    dispatch(logout(callback));
  };

  const callback = () => {
    history.push('/login');
    notification.success({ message: 'Logout Successfully!' });
  };

  console.log('cc-profileDetails', profileDetails);

  const renderProfile = () => {
    switch (path) {
      case '/participant/profile':
        return (
          <Profile
            profileDetails={profileDetails}
            getUserData={getUserData}
            updatePhoto={updatePhoto}
            loading={loading}
          />
        );
      case '/participant/profile/:id/edit':
        return (
          <EditProfile
            profileDetails={profileDetails}
            readProfile={readProfile}
            updateProfile={updateProfile}
            path={path}
          />
        );
      default:
        <Profile
          profileDetails={profileDetails}
          updateProfile={updateProfile}
          updatePassword={updatePassword}
          updatePhoto={updatePhoto}
        />;
        break;
    }
  };

  const onTabClick = (key, event) => {
    event.preventDefault();
    switch (key) {
      case '3': {
        history.push(`/participant/profile/${profileDetails.id}/edit-password`);
        setTab(key);
        break;
      }
      case '4': {
        onLogout();
        break;
      }
      default: {
        setTab(key);
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <StandardLanding>
        <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
          <div className="flex justify-center items-center px-5 lg:px-20 h-full">
            <h1 className="text-white font-medium text-3xl z-10">
              {tab === '3' ? 'CHANGE PASSWORD' : 'USER PROFILE'}
            </h1>
          </div>
          <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
        </div>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            {tab !== '3' ? (
              <TabsWrapper>
                <Tabs onTabClick={onTabClick} defaultActiveKey={tab}>
                  <TabPane tab="PROFILE MENU" key="1" disabled />
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-user mr-2"></i>
                        MY PROFILE
                      </>
                    }
                    key="2"
                  >
                    {renderProfile()}
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-lock mr-2"></i>
                        CHANGE PASSWORD
                      </>
                    }
                    key="3"
                  />
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-sign-out-alt mr-2"></i>
                        LOGOUT
                      </>
                    }
                    key="4"
                  />
                </Tabs>
              </TabsWrapper>
            ) : (
              <EditPassword
                profileDetails={profileDetails}
                readProfile={readProfile}
                updatePassword={updatePassword}
                path={path}
              />
            )}
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default ParticipantProfile;
