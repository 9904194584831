import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Button } from 'antd';

import StandardContainer from '../../../../shared/components/otop/StandardContainer';
import CardWrapper from '../../../../shared/components/otop/CardWrapper';
import TableAddress from '../components/TableAddress';
import { getAddressList, deleteAddress } from '../actions';

export default function LandingAddress() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addresses, loading } = state.address;

  useEffect(() => {
    dispatch(getAddressList());
  }, []);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg my-5">
          <CardWrapper>
            <Card className="card">
              <div className="flex justify-between card-content">
                <p>My Addresses are listed here</p>
                <Link to="/otop-hub/my-addresses/add">
                  <Button className="flex items-center">
                    <i className="fas fa-edit mr-2 text-lg"></i>
                    Add Address
                  </Button>
                </Link>
              </div>
              <div className="card-content">
                <TableAddress
                  addresses={addresses}
                  loading={loading}
                  getAddressList={getAddressList}
                  deleteAddress={deleteAddress}
                />
              </div>
            </Card>
          </CardWrapper>
        </div>
      </div>
    </StandardContainer>
  );
}
