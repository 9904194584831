import { GENERAL } from "./actions";

const INITIAL_STATE = {
  loading: false,
  error: "",
  regions: [],
  categories: [],
  industries: [],
  contact: {},
  request: {},
  message: {}
};

const general = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERAL.REGION_LIST_START:
      return { ...state, loading: true, error: "" };
    case GENERAL.REGION_LIST_SUCCESS:
      return { ...state, loading: false, regions: action.payload };
    case GENERAL.REGION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GENERAL.CATEGORY_LIST_START:
      return { ...state, loading: true, error: "" };
    case GENERAL.CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case GENERAL.CATEGORY_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GENERAL.INDUSTRY_LIST_START:
      return { ...state, loading: true, error: "" };
    case GENERAL.INDUSTRY_LIST_SUCCESS:
      return { ...state, loading: false, industries: action.payload };
    case GENERAL.INDUSTRY_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GENERAL.CONTACT_US_SEND_START:
      return { ...state, loading: true, error: "" };
    case GENERAL.CONTACT_US_SEND_SUCCESS:
      return { ...state, loading: false, contact: action.payload };
    case GENERAL.CONTACT_US_SEND_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GENERAL.EXPO_REQUEST_START:
      return { ...state, loading: true, error: "" };
    case GENERAL.EXPO_REQUEST_SUCCESS:
      return { ...state, loading: false, request: action.payload };
    case GENERAL.EXPO_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

      case GENERAL.WELCOME_MESSAGE_START:
        return { ...state, loading: true, error: "" };
      case GENERAL.WELCOME_MESSAGE_SUCCESS:
        return { ...state, loading: false, message: action.payload };
      case GENERAL.WELCOME_MESSAGE_ERROR:
        return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default general;