import React from 'react'
import { Route, Switch } from "react-router-dom";

import Login from '../../auth/containers/Login'
import Dashboard from '../../admin/dashboard/containers/Landing'
import MyAccount from '../../admin/my-account/containers/Landing'
import MyProducts from '../../admin/my-products/containers/Landing'
import AddEditProducts from '../../admin/my-products/containers/AddEditProducts'
import OrderManagement from '../../admin/order-management/containers/Landing'
import OrderDetails from '../../admin/order-management/containers/OrderDetails';
import ShowOrderManagement from "../../admin/order-management/containers/ShowOrderManagement";
import AddSeller from '../../admin/manage-seller/containers/AddEditSeller';
import MyFinance from '../../admin/manage-finance/containers/Landing';
import LandingTag from "../../admin/tags/containers/LandingTag";
import AddEditTag from "../../admin/tags/containers/AddEditTag";
import LandingCategory from '../../admin/categories/containers/LandingCategory';
import AddEditCategory from '../../admin/categories/containers/AddEditCategory';
import LandingUser from "../../admin/users/containers/LandingUser";
import AddEditUser from "../../admin/users/containers/AddEditUser";

import LandingTransaction from '../../transactions/containers/LandingTransaction';
import ShowTransaction from '../../transactions/containers/ShowTransaction';
import ShowStore from "../../stores/containers/StoreShow";
import PrivacyPolicy from "../../../general/components/PrivacyPolicy";
import TermsAndConditions from "../../../general/components/TermsAndConditions";
import ContactUs from "../../../contact-us/containers/Landing";
import LandingAddress from '../../addresses/containers/LandingAddress';
import AddEditAddress from '../../addresses/containers/AddEditAddress';
import Products from '../../products/containers/Landing'
import ProductShow from "../../products/containers/ProductShow";
import Cart from "../../cart/containers/Landing";
import _404 from "../../../result/components/_404";

export default function PageContent(props) {

    let otopToken = localStorage.getItem("otopToken");
    return ( 
            <Switch>
                {otopToken ? 
                <>
                    <Route exact path="/otop-hub/admin">
                        <Dashboard {...props}/>
                    </Route>
                    <Route exact path="/otop-hub/admin/account">
                        <MyAccount {...props} />
                    </Route>
                    <Route exact path="/otop-hub/admin/products">
                        <MyProducts {...props} />
                    </Route>
                    <Route exact path="/otop-hub/admin/finance">
                        <MyFinance {...props} />
                    </Route>
                    <Route exact path="/otop-hub/admin/products/add" component={AddEditProducts} />
                    <Route exact path="/otop-hub/admin/products/:id/edit" component={AddEditProducts} />
                    <Route exact path="/otop-hub/admin/order-management" component={OrderManagement} />
                    <Route exact path="/otop-hub/admin/order-management/:id" component={ShowOrderManagement} />
                    <Route exact path="/otop-hub/admin/order-management/unpaid-orders/order-details" component={OrderDetails} />
                    <Route exact path="/otop-hub/admin/manage-seller" component={AddSeller} />
                    <Route exact path="/otop-hub/admin/manage-users" component={LandingUser} />
                    <Route exact path="/otop-hub/admin/manage-users/add" component={AddEditUser} />
                    <Route exact path="/otop-hub/admin/manage-users/:id/edit" component={AddEditUser} />
                    <Route exact path="/otop-hub/admin/tags" component={LandingTag} />
                    <Route exact path="/otop-hub/admin/tags/add" component={AddEditTag} />
                    <Route exact path="/otop-hub/admin/tags/:id/edit" component={AddEditTag} />
                    <Route exact path="/otop-hub/admin/categories" component={LandingCategory} />
                    <Route exact path="/otop-hub/admin/categories/add" component={AddEditCategory} />
                    <Route exact path="/otop-hub/admin/categories/:id/edit" component={AddEditCategory} />
                </> :
                <>
                    <Route exact path="/otop-hub/products">
                        <Products {...props}/>
                    </Route>
                    <Route exact path="/otop-hub/products/:id" component={ProductShow} />
                    <Route exact path="/otop-hub/cart" component={Cart} />
                    <Route exact path="/otop-hub/transactions" component={LandingTransaction} />
                    <Route exact path="/otop-hub/transactions/:id" component={ShowTransaction} />
                    <Route exact path="/otop-hub/my-addresses" component={LandingAddress} />
                    <Route exact path="/otop-hub/my-addresses/add" component={AddEditAddress} />
                    <Route exact path="/otop-hub/my-addresses/:id/edit" component={AddEditAddress} />
                    <Route exact path="/otop-hub/store/:id" component={ShowStore} />
                    <Route exact path="/otop-hub/login">
                        <Login {...props}/>
                    </Route>
                    <Route exact path="/otop-hub/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/otop-hub/terms-and-condition" component={TermsAndConditions} />
                    <Route exact path="/otop-hub/contact-us" component={ContactUs} />
                </>
                }
                <Route path="*" component={_404} />
            </Switch>
            
    )
}
