import React, { useRef, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { logout } from '../../auth/actions';
import AppRouter from '../../../shared/constant/AppRouter';
import { notification } from 'antd';

function IdleTimerContainer() {
  const dispatch = useDispatch();

  const idleTimerRef = useRef(null);

  const onIdle = () => {
    dispatch(logout(callback));
  };

  const callback = () => {
    window.location.replace('/login');
    notification.success({ message: 'Your token has expired' });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <IdleTimer ref={idleTimerRef} timeout={900 * 1000} onIdle={onIdle}>
      <AppRouter />
    </IdleTimer>
  );
}

export default IdleTimerContainer;
