import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "antd";
import { decode } from "he";
import { isEmpty } from "lodash";
import ReactImageMagnify from "@vorld/react-image-magnify";

import StandardLanding from "../../../shared/components/StandardLanding";
import expo1 from "../../../shared/assets/images/img/expo/expo1.png";
import {
  showExhibitorProductDetails,
  showExhibitorProductDetailsMerchant,
} from "../../exhibitor/actions";
import { showExpoDetails } from "../../expo/actions";
import { getGuestExhibitorProductShow } from "../../guest/actions";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";
import FormQuantity from "../../cart/components/FormQuantity";
import FormInquire from "../../cart/components/FormInquire";
import { renderDetails } from "../../../shared/utils";

function ProductLanding(props) {
  const state = useSelector((state) => state);
  const { exhibitorProduct, exhibitorProductShow, loadingProduct } =
    state.exhibitor;
  const { expoDetails } = state.expo;
  const { exhibitorProductDetails } = state.guest;
  const { slug, id } = props.match.params;
  const dispatch = useDispatch();
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");
  const [productDetails, setProductDetails] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === "false") {
      dispatch(showExhibitorProductDetails(id));
      dispatch(showExpoDetails(slug));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "true") {
      dispatch(showExhibitorProductDetailsMerchant(id));
    }
  }, []);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestExhibitorProductShow(id));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      !isEmpty(exhibitorProduct) && setProductDetails(exhibitorProduct);
    }

    if (isExhibitor === "true") {
      !isEmpty(exhibitorProductShow) && setProductDetails(exhibitorProductShow);
    }

    if (!token) {
      !isEmpty(exhibitorProductDetails) &&
        setProductDetails(exhibitorProductDetails);
    }

    return () => {
      setProductDetails([]);
    };
  }, [exhibitorProduct, exhibitorProductShow, exhibitorProductDetails]);

  // useEffect(() => {
  //   if (token === null) {
  //     notification.warning({
  //       message: "Could not access.",
  //       description: "To view this, you need to login first!",
  //     });
  //     return props.history.push("/expo");
  //   }
  // }, []);

  const renderProduct = () => {
    return (
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full lg:w-1/3 h-56 lg:h-96">
                <div className="w-full h-full rounded-lg">
                  <CardWrapperNoBg>
                    <Card bordered={false} loading={loadingProduct}>
                      {/* <img
                        src={
                          productDetails.thumbnail
                            ? productDetails.thumbnail.thumb_path
                            : expo1
                        }
                        alt="Virtual Trade Expo Ph"
                        className="h-64 lg:h-96 w-full object-cover object-center"
                      /> */}
                      <ReactImageMagnify
                        className="z-40"
                        {...{
                          smallImage: {
                            alt: 'Expo product',
                            isFluidWidth: true,
                            src: productDetails.thumbnail ? productDetails.thumbnail.thumb_path : expo1,
                          },
                          largeImage: {
                            src: productDetails.thumbnail ? productDetails.thumbnail.thumb_path : expo1,
                            // width: 1200,
                            // height: 1800,
                            width: 1000,
                            height: 800,
                          }
                        }} />
                    </Card>
                  </CardWrapperNoBg>
                </div>
              </div>
              <div className="w-full lg:w-2/3 lg:ml-10 mt-10 lg:mt-0">
                <CardWrapperNoBg>
                  <Card bordered={false} loading={loadingProduct}>
                    <h1 className="lg:text-3xl text-blue-20 font-semibold uppercase">
                      {productDetails.name}{" "}
                    </h1>
                    {/* <h3 className="lg:text-xl text-red-20 mt-10">
                      Product Description
                    </h3> */}
                    <div className="text-gray-900 font-medium" style={{ margin: '1rem 0 3rem 0' }}>
                      {renderDetails(productDetails.description)}
                    </div>
                    <div className="w-full lg:w-1/2 mt-5 lg:mt-5 lg:-ml-0 mr-5 lg:mr-5" >
                      {isExhibitor === "true" ? (
                        null
                      ) : <FormInquire productDetails={productDetails} />}
                    </div>
                  </Card>
                </CardWrapperNoBg>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
    );
  };

  return (
    <>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO PRODUCTS
        </h1>
      </div>
      {renderProduct()}
    </>
  );
}

export default ProductLanding;