import React, { useEffect } from "react";
import { Form, Select, Button, Input } from "antd";
import { useDispatch } from "react-redux";

import FormWrapper from "../../../shared/components/FormWrapper";

const { Option } = Select;

function FormSearchFilterProduct(props) {
  const {
    getExhibitorProductList,
    getGuestExhibitorProductList,
    getExhibitorProductListMerchant,
    exhibitor_id,
    categories,
    totalProducts,
    totalProductGuest,
    totalProductMerchant
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");

  const onFinish = (values) => {
    if (isExhibitor === "false") {
      dispatch(getExhibitorProductList(values, exhibitor_id));
    } else if (isExhibitor === "true") {
      dispatch(getExhibitorProductListMerchant(values, exhibitor_id));
    } else if (!token) {
      dispatch(getGuestExhibitorProductList(values, exhibitor_id, totalProductGuest))
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  // const renderCategories = () => {
  //   return categories && categories.map((item) => (
  //     <Option key={item.id} value={item.id}>
  //       {item.name}
  //     </Option>
  //   ));
  // };

  useEffect(() => {
    onResetField();
  }, []);

  const onResetField = () => {
    form.resetFields();
    if (isExhibitor === "false") {
      dispatch(getExhibitorProductList(null, exhibitor_id));
    } else if (isExhibitor === "true") {
      dispatch(getExhibitorProductListMerchant(null, exhibitor_id));
    } else if (!token) {
      dispatch(getGuestExhibitorProductList(null, exhibitor_id))
    }
  };

  return (
    <>
      <h1 className="text-lg text-red-20 mb-7 font-bold">Search Filters</h1>
      <FormWrapper>
        <Form
          name="searchFilterExpo"
          form={form}
          className="form-flex"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="relative w-full lg:w-1/5 lg:mr-4">
            <Form.Item
              label="Keyword"
              name="keyword"
              rules={[{ required: false, message: 'Please input keyword!' }]}
            >
              <Input placeholder="Item Keyword" />
            </Form.Item>
            <i className="fas fa-search text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full lg:w-1/5 lg:mr-4">
            <Form.Item
              label="Item Type"
              name="types"
              rules={[{ required: false, message: 'Please select item type!' }]}
            >
              <Select placeholder="Item Type">
                <Option value={1}>Food</Option>
                <Option value={0}>Non-Food</Option>
              </Select>
            </Form.Item>
            <i className="fas fa-box text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full lg:w-1/5 lg:mr-4">
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: false, message: 'Please select price!' }]}
            >
              <Select placeholder="Price">
                <Option value="asc">Lowest to Highest</Option>
                <Option value="desc">Highest to Lowest</Option>
              </Select>
            </Form.Item>
            <i className="fas fa-tag text-red-20 form-icon"></i>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <Button className="btn-main w-1/2 lg:w-auto mr-1" htmlType="submit">
              <span>Apply Filter</span>
            </Button>
            <Button
              className="btn-main w-1/2 lg:w-auto ml-1"
              onClick={() => onResetField()}
            >
              <span>Reset Filter</span>
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormSearchFilterProduct;