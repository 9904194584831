import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Radio } from "antd";
import { useDispatch } from "react-redux"
import FormWrapper from "../../../shared/components/FormWrapper";
var regions = require("../../../shared/data/region.json");


const { Option } = Select;
function FormSearchExhibitor(props) {
  const dispatch = useDispatch();
  const {
    getExpoHallExhibitorList,
    getExpoHallExhibitorListMerchant,
    getGuestExpoHallExhibitorList,
    totalExhibitors,
    totalMerchants,
    totalGuestExhibitors,
    slug,
    industries,
  } = props;
  const [form] = Form.useForm();
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");
  const [search, setSearch] = useState("")
  const [value, setValue] = useState(0);

  const onFinish = (values) => {
    console.log('Success:', values);
    setSearch(values)
    if(isExhibitor === "false") {
      dispatch(getExpoHallExhibitorList(values, value, slug, totalExhibitors, null));
    } else if(isExhibitor === "true") {
      console.log("val", values)
      dispatch(
        getExpoHallExhibitorListMerchant(values, value, slug, totalMerchants, null)
      );
    } else if(!token) {
      dispatch(getGuestExpoHallExhibitorList(values, value, slug, totalGuestExhibitors))
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
   if(search === ""){
    onResetField()
   }
  }, [totalExhibitors])


  const onResetField = () => {
    if (isExhibitor === "false") {
      dispatch(getExpoHallExhibitorList(null, value, slug, 100));
    } else if (isExhibitor === "true") {
      dispatch(getExpoHallExhibitorListMerchant(null, value, slug, 100));
    } else if(!token) {
      dispatch(getGuestExpoHallExhibitorList(null, value, slug, 100));
    }
    sessionStorage.setItem("page", 1)
    form.resetFields();
  };

  const getUserData = () => {
    const data = localStorage.getItem("switch");
    if(data){
        return setValue(JSON.parse(data))
    } else {
        return null
    }
}

useEffect(() => {
    getUserData()
}, [])

  const onChange = e => {
    setValue(e.target.value);
    localStorage.setItem("switch", e.target.value)
  };

  const renderRegions = () => {
    let sortedRegions = regions.RECORDS.sort((a, b) =>
      a.regDesc > b.regDesc ? 1 : -1
    );

    return sortedRegions.map((region) => (
      <Option value={region.code} key={region.regCode}>
        {region.regDesc}
      </Option>
    ));
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="searchExpo"
          form={form}
          className="flex w-full"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="keyword"
            className="text-black bg-gray-10 px-3 py-3 w-72 border-gray-200 border-r-2"
            rules={[{ required: false, message: "Please enter keyword!" }]}
          >
            <Input placeholder="Search Exhibitor Booth" />
          </Form.Item>
          {
          isExhibitor === 'true' ? "" :
          <Form.Item
            name="product_name"
            className="text-black bg-gray-10 px-3 py-3 w-72 border-gray-200 border-r-2"
            rules={[{ required: false, message: "Please enter product name!" }]}
          >
            <Input placeholder="Search Product" />
          </Form.Item>
          }
          <Form.Item
            name="region"
            className="text-black bg-gray-10 px-3 py-3 w-72 mr-5 border-gray-200 border-r-2"
            rules={[{ required: false, message: "Please select region!" }]}
          >
            <Select placeholder="e.g Region/Province">
            {renderRegions()}
            </Select>
          </Form.Item>
          <Radio.Group name="sort_alphabetically" onChange={onChange} value={value} size="large" className="mt-3">
              <Radio.Button className="bg-red-20 text-white border-0" value={1}>Alphabetical</Radio.Button>
              <Radio.Button className="bg-red-20 text-white border-0" value={0}>Random</Radio.Button>
          </Radio.Group>
          {/* <Form.Item
            name="industry"
            className="text-black bg-gray-10 px-3 py-3 w-1/4 border-gray-200 border-r-2"
            rules={[{ required: false, message: "Please select keyword!" }]}
          >
            <Select placeholder="Industry">
              {
                industries && industries.map(item => <Option key={item.code} value={item.name}>{item.name}</Option>)
              }
            </Select>
          </Form.Item> */}
          <div className="flex mt-3 ml-5">
          <Button
            className="btn-red flex items-center px-2 lg:px-5"
            htmlType="submit"
          >
            <i className="fas fa-search pr-1 lg:pr-3"></i>
            <span className="text-xs">SEARCH</span>
          </Button>
          <Button className="btn-red px-5 ml-2" onClick={() => onResetField()}>
          <i className="fas fa-times pr-1 lg:pr-3"></i>
          <span className="text-xs">CLEAR</span>
            </Button>
          </div>
          
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormSearchExhibitor;
