import React from "react";
import { Card } from "antd";

export default function SellerProfile(props) {
  const { sellerDetails, loading } = props;
  const renderDetails = () => {
    if (sellerDetails && sellerDetails.data && sellerDetails.data.attributes) {
      const { attributes } = sellerDetails.data;
      return (
        <Card className="w-full lg:w-1/2 py-5" loading={loading}>
          <h1 className="lg:text-lg">Profile Details</h1>
          <div className="flex items-center text-gray-20">
            <ul>
              <li className="my-1">
                {attributes.firstname} {attributes.lastname}
              </li>
              <li className="my-1">{attributes.email}</li>
              <li className="my-1">+63 {attributes.contact_number}</li>
            </ul>
          </div>
        </Card>
      );
    }
  };
  return renderDetails();
}
