import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FormWrapper from '../../../shared/components/FormWrapper';

function EditPassword(props) {
  const {
    updatePassword,
    updatePasswordExhibitor,
    readProfile,
    readProfileExhibitor,
  } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const isExhibitor = localStorage.getItem('isExhibitor');
  const toProfile = `/${
    isExhibitor === 'true' ? 'exhibitor' : 'participant'
  }/profile`;

  const onFinish = (values) => {
    if (values.current_password === values.password) {
      notification.error({
        message: 'New password cannot be the same as your current password.',
        duration: '5',
      });
      return false;
    }

    const Password =
      isExhibitor === 'true' ? updatePasswordExhibitor : updatePassword;

    dispatch(Password(values, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      notification.success({ message: `${response.msg}` });
      if (isExhibitor === 'true') {
        dispatch(readProfileExhibitor(profileCallback));
      } else {
        dispatch(readProfile(profileCallback));
      }

      history.push(toProfile);
    }
  };

  const profileCallback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'ACCOUNT_LOGOUT') {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/login');
      }
    }
  };

  return (
    <>
      <h1 className="text-red-10 text-3xl mb-10 uppercase">
        UDPATE YOUR PASSWORD
      </h1>
      <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
        <FormWrapper>
          <Form
            name="form-profile"
            className="form-flex"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="w-full lg:w-1/2 lg:pr-2">
              <div className="relative w-full my-3">
                <Form.Item
                  label="Current Password"
                  name="current_password"
                  className="relative w-full my-3 mb-6"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your current password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Current Password" />
                </Form.Item>
                <i className="fas fa-lock text-red-20 form-icon"></i>
              </div>
              <div className="relative w-full my-3">
                <Form.Item
                  label="New Password"
                  name="password"
                  className="relative w-full my-3 mb-6"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your new password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="New Password" />
                </Form.Item>
                <i className="fas fa-lock text-red-20 form-icon"></i>
              </div>
              <div className="relative w-full my-3">
                <Form.Item
                  label="Confirm Password"
                  name="password_confirmation"
                  className="relative w-full my-3 mb-6"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your confirm password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password Confirmation" />
                </Form.Item>
                <i className="fas fa-lock text-red-20 form-icon"></i>
              </div>
            </div>
            <div
              className="w-full lg:w-1/2 lg:pl-2 lg:my-2"
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'end',
              }}
            >
              <div className="flex flex-col lg:flex-row w-full lg:w-auto">
                <Button className="btn my-1 lg:mr-5" htmlType="submit">
                  <i className="fas fa-check-circle mr-1"></i>
                  <span>SUBMIT</span>
                </Button>
                <Button
                  className="btn btn-white my-1"
                  style={{ boxShadow: '0px 3px 6px #00000029' }}
                >
                  <Link to={toProfile}>
                    <i className="fas fa-times-circle mr-1"></i>
                    <span>CANCEL</span>
                  </Link>
                </Button>
              </div>
            </div>
          </Form>
        </FormWrapper>
      </div>
    </>
  );
}

export default EditPassword;
