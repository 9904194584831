import { getAuthHeader, getError } from "../../shared/utils";
import {
    API_EXHIBITOR_LIST_GALLERY,
    API_ADD_GALLERY,
    API_DELETE_GALLERY,
    API_UPDATE_GALLERY
} from "../../shared/constant/url";

export const GALLERY = {
  LIST_GALLERY_START: "LIST_GALLERY_START",
  LIST_GALLERY_SUCCESS: "LIST_GALLERY_SUCCESS",
  LIST_GALLERY_ERROR: "LIST_GALLERY_ERROR",

  ADD_GALLERY_START: "ADD_GALLERY_START",
  ADD_GALLERY_SUCCESS: "ADD_GALLERY_SUCCESS",
  ADD_GALLERY_ERROR: "ADD_GALLERY_ERROR",

  DELETE_GALLERY_START: "DELETE_GALLERY_START",
  DELETE_GALLERY_SUCCESS: "DELETE_GALLERY_SUCCESS",
  DELETE_GALLERY_ERROR: "DELETE_GALLERY_ERROR",

  UPDATE_GALLERY_START: "UPDATE_GALLERY_START",
  UPDATE_GALLERY_SUCCESS: "UPDATE_GALLERY_SUCCESS",
  UPDATE_GALLERY_ERROR: "UPDATE_GALLERY_ERROR",
};


export const getGalleryList = (per_page) => {
  return (dispatch) => {
    dispatch({ type: GALLERY.LIST_GALLERY_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    }
    formData.append("include", 'exhibitor');

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_LIST_GALLERY, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GALLERY.LIST_GALLERY_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: GALLERY.LIST_GALLERY_ERROR, payload: error });
      });
  };
};


export const createGallery = (params, callback=null) => {
  return (dispatch) => {
    dispatch({ type: GALLERY.ADD_GALLERY_START });
    let formData = new FormData();
    formData.append("title", params.title);
    formData.append("video", params.video);

    if (params.file) {
      params.file.fileList.forEach(element => {
        formData.append("file", element.originFileObj);
      });
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_GALLERY, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GALLERY.ADD_GALLERY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: GALLERY.ADD_GALLERY_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteGallery = (id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: GALLERY.DELETE_GALLERY_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DELETE_GALLERY, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GALLERY.DELETE_GALLERY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: GALLERY.DELETE_GALLERY_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const updateGallery = (params, id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: GALLERY.UPDATE_GALLERY_START });
    let formData = new FormData();
    formData.append("id", id);
    formData.append("title", params.title);
    formData.append("video", params.video);

    if (params.file) {
      params.file.fileList.forEach(element => {
        formData.append("file", element.originFileObj);
      });
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_UPDATE_GALLERY, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GALLERY.UPDATE_GALLERY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: GALLERY.UPDATE_GALLERY_ERROR,
          payload: getError(error),
        });
      });
  };
};

