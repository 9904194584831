import React from 'react';
import styled from '@emotion/styled';

function FormWrapperNoMargin(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FormWrapperNoMargin;

const StyledDiv = styled.div({
  '.ant-form-item': {
    marginBottom: '0px !important',
  },
  '.ant-input': {
    background: '#f0f0f1',
    border: 'none !important',
    color: '#101010',
    boxShadow: 'none !important',
  },
  '.ant-form-item': {
    background: '#f0f0f1',
    height: '100%',
    marginBottom: '0px !important',
  },
  '.ant-btn': {
    background: '#cd1f30',
    color: '#FFF',
    fontSize: '1.1em',
    borderRadius: '1rem',
    border: '0',
    letterSpacing: '1px',
    height: '45px',
  },
  '.ant-btn:focus': {
    borderColor: 'none',
  },
  '.ant-btn:hover': {
    borderColor: 'none',
    background: '#E42F30 !important',
    outline: '0',
  },
});
