import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, notification } from "antd";
import { decode } from "he";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import placeholder from "../../../shared/assets/images/placeholder.jpg";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";
import StandardLanding from "../../../shared/components/StandardLanding";
import { showSpeakerDetails, showSpeakerDetailsExhibitor } from "../actions";
import { getGuestSpeakerDetails } from "../../guest/actions";
import { renderDetails } from "../../../shared/utils";

function SpeakerShow(props) {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { speakerDetails, speakerDetailsExhibitor } = state.speaker;
  const { speakerDetailsGuest } = state.guest;
  const loadingSpeaker = state.speaker.loading;
  const [deets, setDeets] = useState({});
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isExhibitor === "true") {
      dispatch(showSpeakerDetailsExhibitor(id));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      dispatch(showSpeakerDetails(id));
    }
  }, []);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestSpeakerDetails(id));
    }
  }, []);

  useEffect(() => {
    !isEmpty(speakerDetails) && setDeets(speakerDetails);
    !isEmpty(speakerDetailsExhibitor) && setDeets(speakerDetailsExhibitor);
    !isEmpty(speakerDetailsGuest) && setDeets(speakerDetailsGuest);

    return () => {
      setDeets([]);
    };
  }, [speakerDetails, speakerDetailsExhibitor, speakerDetailsGuest]);


  // useEffect(() => {
  //   if (token === null) {
  //     notification.warning({
  //       message: "Could not access.",
  //       description: "To view this, you need to login first!",
  //     });
  //     return props.history.push("/speakers");
  //   }
  // }, []);

  const renderSpeaker = () => {
    return (
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="flex flex-col lg:flex-row justify-center items-center">
              <div className="w-full lg:w-1/3 h-full">
                <div className="w-full h-full rounded-lg">
                  <CardWrapperNoBg>
                    <Card bordered={false} loading={loadingSpeaker}>
                      <img
                        src={
                          deets.thumbnail
                            ? deets.thumbnail.full_path
                            : placeholder
                        }
                        alt="Virtual Trade Expo Ph"
                        className="lg:h-auto w-full object-cover object-center rounded"
                      />
                    </Card>
                  </CardWrapperNoBg>
                </div>
              </div>
              <div className="w-full lg:w-2/3 lg:ml-10 mt-10 lg:mt-0">
                <CardWrapperNoBg>
                  <Card bordered={false} loading={loadingSpeaker}>
                    <h1 className="lg:text-4xl text-blue-20 font-bold">
                      {deets.name}
                    </h1>
                    <h4 className="text-gray-40 lg:text-lg font-bold">
                      {deets.work}
                    </h4>
                    <h4 className="text-gray-20">
                    {deets.email}
                    </h4>
                   
                    <div className="text-gray-900 my-5 font-medium">
                      {deets.profile
                        ? renderDetails(deets.profile)
                        : ""}
                      {/* {speaker.profile ? speaker.profile.replace( /<\/?[^>]+(>|$)/g, '') : ''} */}
                    </div>
                    {/* <div className="flex items-center my-5">
                      <h1 className="text-blue-20 font-semibold">
                        SOCIAL MEDIA
                      </h1>
                      <button className="rounded bg-blue-20 py-2 px-4 mx-3">
                        <i className="fab fa-facebook text-lg text-white"></i>
                      </button>
                      <button className="rounded bg-blue-10 py-2 px-4 mx-3">
                        <i className="fab fa-twitter text-lg text-white"></i>
                      </button>
                      <button className="rounded bg-red-20 py-2 px-4 mx-3">
                        <i className="fab fa-google text-lg text-white"></i>
                      </button>
                      <button className="rounded bg-red-10 py-2 px-4 mx-3">
                        <i className="fab fa-instagram text-lg text-white"></i>
                      </button>
                    </div> */}
                    {deets.expo && deets.expo.data ? (
                      <Link
                        to={`/expo/${deets.expo.data.slug}/seminar`}
                        className="btn btn-red text-white py-2"
                      >
                        Go to seminar
                      </Link>
                    ) : null}
                  </Card>
                </CardWrapperNoBg>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
    );
  };

  return (
    <>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO SPEAKERS
        </h1>
      </div>
      {renderSpeaker()}
    </>
  );
}

export default SpeakerShow;