import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  notification,
  Select,
  Modal,
  Checkbox,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { filter } from 'lodash';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';

import FormWrapper from '../../../shared/components/FormWrapper';
var regions = require('../../../shared/data/region.json');
var provinces = require('../../../shared/data/province.json');

const { Option } = Select;

function FormRegisterExhibitor(props) {
  let dispatch = useDispatch();
  let history = useHistory();
  const { registerExhibitor } = props;
  const [form] = Form.useForm();
  const [description, setDescription] = useState('');
  const [provinceList, setProvinceList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disable, setDisable] = useState('');
  const [details, setDetails] = useState('');
  const [disablepolicy, setDisablePolicy] = useState('');
  const [modalpolicy, setModalPolicy] = useState(false);

  function onChange(e) {
    setDisable(e.target.checked);
  }

  function onChangePolicy(e) {
    setDisablePolicy(e.target.checked);
  }

  const showModal = (value) => {
    setIsModalVisible(true);
    setDetails(value);
  };

  const showModalPolicy = () => {
    setModalPolicy(true);
    setIsModalVisible(false);
  };

  const handleOkPolicy = () => {
    setModalPolicy(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancelPolicy = () => {
    setModalPolicy(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    if (values) {
      showModal(values);
      setDetails(values);
    }
  };

  const onRegister = () => {
    if (description) {
      details['business_description'] = description;
    }
    dispatch(registerExhibitor(details, null, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    // console.log("Response here", error)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'REGISTERED') {
        notification.success({ message: `${response.msg}` });
        history.push('/login');
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
        if (response.errors.password) {
          form.setFields([
            {
              name: 'password',
              errors: [`${response.errors.password}`],
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstname: '',
      lastname: '',
      region: '',
      province_id: '',
      email: '',
      contact_number: '',
      password: '',
      password_confirmation: '',
      company_name: '',
      position: '',
    });
  }, []);

  const renderRegions = () => {
    let sortedRegions = regions.RECORDS.sort((a, b) =>
      a.regDesc > b.regDesc ? 1 : -1
    );

    return sortedRegions.map((region) => (
      <Option value={region.code} key={region.regCode}>
        {region.regDesc}
      </Option>
    ));
  };

  const cascadeProvince = (val, option) => {
    let regCode = option.key;
    let provinceList = filter(
      provinces.RECORDS,
      (item) => item.regCode === regCode
    );
    setProvinceList(provinceList);
    form.setFieldsValue({
      province_id: '',
    });
  };

  const renderProvinces = () => {
    let sortedProvinces = provinceList.sort((a, b) =>
      a.provDesc > b.provDesc ? 1 : -1
    );

    return sortedProvinces.map((prov) => (
      <Option value={prov.id.toString()} key={prov.id}>
        {prov.provDesc}
      </Option>
    ));
  };

  return (
    <FormWrapper>
      <Form
        name="registerExhibitor"
        className="form-flex"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              { required: true, message: 'Please input your first name!' },
              {
                pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                message: 'Invalid format',
              },
            ]}
          >
            <Input placeholder="Exhibitor First Name" />
          </Form.Item>
          <i className="fas fa-user text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              { required: true, message: 'Please input your last name!' },
              {
                pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                message: 'Invalid format',
              },
            ]}
          >
            <Input placeholder="Exhibitor Last Name" />
          </Form.Item>
          <i className="fas fa-user text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Region"
            name="region"
            rules={[{ required: true, message: 'Please input your region!' }]}
            style={{ zIndex: '90 !important' }}
          >
            <Select onChange={cascadeProvince} value="" placeholder="Region">
              {renderRegions()}
            </Select>
          </Form.Item>
          <i className="fas fa-map text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Province Name"
            name="province_id"
            rules={[
              { required: true, message: 'Please input your province name!' },
            ]}
            style={{ zIndex: '90 !important' }}
          >
            <Select value="" placeholder="Province">
              {renderProvinces()}
            </Select>
          </Form.Item>
          <i className="fas fa-map text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Invalid format' },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <i className="fas fa-envelope text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Contact Number"
            name="contact_number"
            rules={[
              {
                required: true,
                message: 'Please input your contact number!',
              },
              {
                pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                message: 'Please input the correct contact number!',
              },
              {
                pattern: new RegExp(/[^a-z\s]/g),
                message: 'Invalid format',
              },
            ]}
          >
            <Input type="tel" placeholder="Contact Number" />
          </Form.Item>
          <i className="fas fa-phone text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 8, message: 'Password must be minimum of 8 characters.' },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <i className="fas fa-lock text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Confirm Password"
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: 'Please input your password confirmation!',
              },
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <i className="fas fa-lock text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Company Name / Business Name"
            name="company_name"
            rules={[
              {
                required: true,
                message: 'Please input your company name or business name!',
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <i className="fas fa-envelope text-red-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Position"
            name="position"
            rules={[{ required: true, message: 'Please input your position!' }]}
          >
            <Input placeholder="Position" />
          </Form.Item>
          <i className="fas fa-user-tag text-red-20 form-icon"></i>
        </div>

        <div className="relative w-full my-3 px-2">
          <h4 className="text-red-20 pr-1 text-lg">Business Description</h4>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
              console.log({ event, editor, data });
            }}
          />
        </div>

        <div className="flex justify-between items-center mt-5 mx-3">
          <div className="flex">
            <Button className="btn-main" htmlType="submit">
              <i className="fas fa-sign-in-alt mr-1"></i>
              <span>Register</span>
            </Button>
            <div className="flex items-center pl-3">
              <h4 className="text-gray-20 pr-1">Have an account?</h4>
              <Link to="/login" className="text-red-10 font-bold">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        footer={false}
        width={1000}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="mt-7 p-5 overflow-auto" style={{ height: '30rem' }}>
          <TermsAndConditions />
        </div>
        <div className="flex flex-col justify-center items-center mt-5">
          <Checkbox className="text-xs mb-5" onChange={onChange}>
            I agree to terms and conditions
          </Checkbox>
          <Button
            className="w-96 border-none bg-red-20 rounded-md text-white font-bold hover:bg-red-600 hover:text-white"
            disabled={disable ? false : true}
            onClick={showModalPolicy}
          >
            PROCEED
          </Button>
        </div>
      </Modal>
      <Modal
        footer={false}
        width={1000}
        visible={modalpolicy}
        onOk={handleOkPolicy}
        onCancel={handleCancelPolicy}
      >
        <div className="mt-7 p-5 overflow-auto" style={{ height: '30rem' }}>
          <PrivacyPolicy />
        </div>
        <div className="flex flex-col justify-center items-center mt-5">
          <Checkbox className="text-xs mb-5" onChange={onChangePolicy}>
            I agree to privacy policy
          </Checkbox>
          <Button
            className="w-96 border-none bg-red-20 rounded-md text-white font-bold hover:bg-red-600 hover:text-white"
            disabled={disablepolicy ? false : true}
            onClick={onRegister}
          >
            PROCEED
          </Button>
        </div>
      </Modal>
    </FormWrapper>
  );
}

export default FormRegisterExhibitor;
