import { EXPO } from "./actions";

const INITIAL_STATE = {
  loading: false,
  error: "",
  totalExpo: 0,
  totalProducts: 0,
  totalExhibitorProducts: 0,
  totalExhibitors: 0,
  totalMerchants: 0,
  expo: [],
  exhibitors: [],
  offers: [],
  products: [],
  expoDetails: {},
  expoHallDetails: {},
  expoUpcoming: [],
  expoJoin: {},
  seminars: [],
  mainSeminar: [],
  expoJoinExhibitor: {},
  exhibitorExpoDetails: {},
  exhibitorProducts: [],
  merchants: [],
  seminarExhibitor: [],
  mainSeminarExhibitor: {},
  discussionList: [], 
  discussion: {},
};

const expo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXPO.EXPO_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_LIST_SUCCESS:
      let temp = action.payload;
      let { total } = temp;
      return { ...state, loading: false, expo: action.payload.data, totalExpo: total };
    case EXPO.EXPO_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SHOW_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SHOW_SUCCESS:
      return { ...state, loading: false, expoDetails: action.payload };
    case EXPO.EXPO_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_HALL_EXHIBITOR_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_HALL_EXHIBITOR_LIST_SUCCESS:
      let temp2 = action.payload;
      let totalExhibitors = temp2.total;
      return { ...state, loading: false, exhibitors: action.payload.data, totalExhibitors };
    case EXPO.EXPO_HALL_EXHIBITOR_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_HALL_SHOW_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_HALL_SHOW_SUCCESS:
      return { ...state, loading: false, expoHallDetails: action.payload };
    case EXPO.EXPO_HALL_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_SUCCESS:
      return { ...state, loading: false, offers: action.payload };
    case EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_SUCCESS:
      let holder = action.payload;
      let totalProducts = holder.total;
      return { ...state, loading: false, products: action.payload.data, totalProducts };
    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_UPCOMING_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_UPCOMING_LIST_SUCCESS:
      return { ...state, loading: false, expoUpcoming: action.payload };
    case EXPO.EXPO_UPCOMING_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_JOIN_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_JOIN_SUCCESS:
      return { ...state, loading: false, expoJoin: action.payload };
    case EXPO.EXPO_JOIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SEMINAR_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SEMINAR_LIST_SUCCESS:
      return { ...state, loading: false, seminars: action.payload };
    case EXPO.EXPO_SEMINAR_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SEMINAR_MAIN_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SEMINAR_MAIN_SUCCESS:
      return { ...state, loading: false, mainSeminar: action.payload };
    case EXPO.EXPO_SEMINAR_MAIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_JOIN_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_JOIN_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, expoJoinExhibitor: action.payload };
    case EXPO.EXPO_JOIN_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SHOW_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SHOW_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, exhibitorExpoDetails: action.payload };
    case EXPO.EXPO_SHOW_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_SUCCESS:
      let holder2 = action.payload;
      let totalExhibitorProducts = holder2.total;
      return { ...state, loading: false, exhibitorProducts: action.payload.data, totalExhibitorProducts };
    case EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_SUCCESS:
      let temp3 = action.payload;
      let totalMerchants = temp3.total;
      return { ...state, loading: false, merchants: action.payload.data, totalMerchants };
    case EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, seminarExhibitor: action.payload };
    case EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, mainSeminarExhibitor: action.payload };
    case EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXPO.EXPO_DISCUSSION_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_DISCUSSION_LIST_SUCCESS:
      return { ...state, loading: false, discussionList: action.payload };
    case EXPO.EXPO_DISCUSSION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case EXPO.EXPO_DISCUSSION_CREATE_START:
      return { ...state, loading: true, error: "" };
    case EXPO.EXPO_DISCUSSION_CREATE_SUCCESS:
      return { ...state, loading: false, discussion: action.payload };
    case EXPO.EXPO_DISCUSSION_CREATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default expo;