import { SPEAKER } from "./actions";

const INITIAL_STATE = {
  loading: false,
  error: "",
  total: 0,
  speakers: [],
  speakerDetails: {},
  speakerDetailsExhibitor: {},
};

const speaker = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SPEAKER.SPEAKER_LIST_START:
      return { ...state, loading: true, error: "" };
    case SPEAKER.SPEAKER_LIST_SUCCESS:
      let temp = action.payload;
      let { total } = temp;
      return { ...state, loading: false, speakers: action.payload.data, total };
    case SPEAKER.SPEAKER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SPEAKER.SPEAKER_SHOW_START:
      return { ...state, loading: true, error: "" };
    case SPEAKER.SPEAKER_SHOW_SUCCESS:
      return { ...state, loading: false, speakerDetails: action.payload };
    case SPEAKER.SPEAKER_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SPEAKER.SPEAKER_SHOW_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case SPEAKER.SPEAKER_SHOW_EXHIBITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        speakerDetailsExhibitor: action.payload,
      };
    case SPEAKER.SPEAKER_SHOW_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default speaker;