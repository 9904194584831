import { combineReducers } from 'redux';
import admin from '../../modules/otop/auth/reducer';
import gallery from '../../modules/gallery/reducer';
import address from '../../modules/otop/addresses/reducer';
import auth from '../../modules/auth/reducer';
import bankDetails from '../../modules/bank-details/reducer';
import buyerTransaction from '../../modules/otop/transactions/reducers';
import cart from '../../modules/cart/reducer';
import category from '../../modules/otop/admin/categories/reducer';
import disbursement from '../../modules/disbursements/reducer';
import exhibitor from '../../modules/exhibitor/reducer';
import expo from '../../modules/expo/reducer';
import guest from '../../modules/guest/reducer';
import general from '../../modules/general/reducer';
import live from '../../modules/live/reducer';
import myProducts from '../../modules/otop/admin/my-products/reducer';
import manageSeller from '../../modules/otop/admin/manage-seller/reducer';
import otopProduct from '../../modules/otop/products/reducer';
import otopDashboard from '../../modules/otop/admin/dashboard/reducer';
import order from '../../modules/otop/admin/order-management/reducer';
import otopCart from '../../modules/otop/cart/reducer';
import partner from '../../modules/partner/reducer';
import product from '../../modules/product/reducer';
import profile from '../../modules/profile/reducer';
import salesRecord from '../../modules/sales-record/reducer';
import speaker from '../../modules/speaker/reducer';
import tag from '../../modules/otop/admin/tags/reducer';
import user from '../../modules/otop/admin/users/reducers';
import otopSeller from '../../modules/otop/stores/reducer';
import payment from '../../modules/payment-option/reducer';

const rootReducer = combineReducers({
    payment,
    admin,
    address,
    auth, 
    bankDetails,
    buyerTransaction,
    cart,
    category,
    disbursement,
    exhibitor,
    expo,
    general,
    guest,
    live,
    myProducts,
    manageSeller,
    otopProduct,
    otopCart,
    otopDashboard,
    otopSeller,
    order,
    partner,
    product,
    profile,
    salesRecord,
    speaker, 
    tag,
    user,
    gallery
})

export default rootReducer;