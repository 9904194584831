import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Profile from "../components/Profile";
// import OrderSummary from "../components/OrderSummary";
// import Transaction from "../../my-account/containers/Transaction";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import { readUser } from "../../users/actions";

const Landing = (props) => {
  const dispatch = useDispatch();
  let userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(readUser(userId))
  }, [userId])

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg content-crop">
          <div className="my-10">
            <Profile {...props} />
          </div>
       
          {/* <div className="flex mt-10 justify-between">
              <div className="w-1/3 mr-5">
                <OrderSummary />
              </div>
              <div className="w-2/3 ml-5">
                <Transaction />
              </div>
            </div> */}
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;
