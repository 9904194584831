import { TAG } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  tags: [],
  tagDetails: "",
  tag: {},
  tagDelete: {},
  publicTags: [],
};

const tag = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case TAG.TAG_LIST_START:
      return { ...state, loading: true, error: "" };
    case TAG.TAG_LIST_SUCCESS:
      return { ...state, loading: false, tags: action.payload };
    case TAG.TAG_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case TAG.TAG_READ_START:
      return { ...state, loading: true, error: "" };
    case TAG.TAG_READ_SUCCESS:
      return { ...state, loading: false, tagDetails: action.payload };
    case TAG.TAG_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case TAG.TAG_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case TAG.TAG_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, tag: action.payload };
    case TAG.TAG_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case TAG.TAG_DELETE_START:
      return { ...state, loading: true, error: "" };
    case TAG.TAG_DELETE_SUCCESS:
      return { ...state, loading: false, tagDelete: action.payload };
    case TAG.TAG_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

      case TAG.PUBLIC_TAG_LIST_START:
      return { ...state, loading: true, error: "" };
    case TAG.PUBLIC_TAG_LIST_SUCCESS:
      return { ...state, loading: false, publicTags: action.payload };
    case TAG.PUBLIC_TAG_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default tag;