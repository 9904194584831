import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { reject } from 'lodash'

import StandardLanding from "../../../shared/components/StandardLanding";
import { getCartAll, getCartProductList, getCartList, addMultipleItemsToCart, cartProductRemove } from "../actions";
import CartTable from "../components/CartTable";

function Landing(props) {
  const state = useSelector(state => state)
  const dispatch = useDispatch();
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");
  const { cartProducts, cartList } = state.cart
  const { history } = props;

  useEffect(() => {
    if(token){  
      dispatch(getCartList())
  } else {
    dispatch(getCartProductList());
  }
    dispatch(getCartAll());

    if(token){
      renderCartList()
    }
  }, []);

const renderCartList = () => {
  if(cartList){
    cartList.map(item => {
      renderCartProducts(item.product_id)
    })
  }
}

const renderCartProducts = (id) => {
  cartProducts.find(item => {
    if(item.id === parseInt(id)){
      dispatch(cartProductRemove(parseInt(item.id), callback))
      dispatch(getCartList())
    } 
  })
}

  useEffect(() => {
    window.scrollTo(0, 0);  
  }, [])


  const callback = () => {
    // notification.success({ message: "Loggedin" });
  };

  useEffect(() => {
    if (isExhibitor === "true") {
      notification.warning({
        message: "Could not access.",
      });
      return history.push("/");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
          <h1 className="text-white text-center lg:text-4xl uppercase">MY CART</h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <h1 className="content-header-left">MY CART</h1>
            <CartTable cartProducts={cartProducts} cartList={cartList} getCartProductList={getCartProductList} history={history} />
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default Landing;