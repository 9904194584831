import { BANK_DETAILS } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  bankDetailsList: [],
  bankDetails: "",
  bankDetailsData: {},
  bankDetailsDelete: {},
};

const bankDetails = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case BANK_DETAILS.BANK_DETAILS_LIST_START:
      return { ...state, loading: true, error: "" };
    case BANK_DETAILS.BANK_DETAILS_LIST_SUCCESS:
      return { ...state, loading: false, bankDetailsList: action.payload };
    case BANK_DETAILS.BANK_DETAILS_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BANK_DETAILS.BANK_DETAILS_READ_START:
      return { ...state, loading: true, error: "" };
    case BANK_DETAILS.BANK_DETAILS_READ_SUCCESS:
      return { ...state, loading: false, bankDetails: action.payload };
    case BANK_DETAILS.BANK_DETAILS_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BANK_DETAILS.BANK_DETAILS_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case BANK_DETAILS.BANK_DETAILS_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, bankDetailsData: action.payload };
    case BANK_DETAILS.BANK_DETAILS_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BANK_DETAILS.BANK_DETAILS_DELETE_START:
      return { ...state, loading: true, error: "" };
    case BANK_DETAILS.BANK_DETAILS_DELETE_SUCCESS:
      return { ...state, loading: false, bankDetailsDelete: action.payload };
    case BANK_DETAILS.BANK_DETAILS_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default bankDetails;