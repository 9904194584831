import React from 'react';
import { Table } from 'antd';
import TableWrapper from './TableWrapper';
import { renderDetails } from '../../../shared/utils';

function TableInquiry(props) {
  const { activities, loading, activity, rowSelected } = props;

  const columns = [
    {
      title: 'SHOP NAME',
      // dataIndex: 'shop_name',
      key: 'shop_name',
      width: 100,
      render: (data, rowData) => renderShopName(data, rowData),
    },
    {
      title: 'INQUIRY DATE',
      // dataIndex: 'inquiry_date',
      key: 'inquiry_date',
      width: 100,
      render: (data, rowData) => renderDate(data, rowData),
    },
    {
      title: 'SHOP DETAILS',
      // dataIndex: 'shop_details',
      key: 'shop_details',
      width: 100,
      render: (data, rowData) => renderShopDetails(data, rowData),
    },
    {
      title: 'INQUIRED ITEM/PRODUCT',
      // dataIndex: 'products',
      key: 'products',
      width: 200,
      render: (data, rowData) => renderItemInquired(data, rowData),
      // onClick: (data, rowData) => rowSelected(data, rowData),
    },
    {
      title: '',
      // dataIndex: 'products',
      key: '',
      width: 10,
      render: (data, rowData) => renderView(data, rowData),
      className: 'text-center',
    },
  ];

  const renderItemInquired = (data, rowData) => {
    return <p className="text-control-1">{renderDetails(rowData.inquiry)}</p>;
  };

  const renderShopName = (data, rowData) => {
    return rowData?.product?.data?.exhibitor?.data?.company_name;
  };

  const renderShopDetails = (data, rowData) => {
    return (
      <p className="text-control-1">
        {renderDetails(
          rowData?.product?.data?.exhibitor?.data?.business_info?.description
        )}
      </p>
    );
  };

  const renderDate = (data, rowData) => {
    return rowData.inquiry_date.date_db;
  };

  const renderView = (data, rowData) => {
    return (
      <div
        className="text-red-20 cursor-pointer"
        onClick={() => rowSelected(rowData)}
      >
        <i className="fas fa-eye"></i>
      </div>
    );
  };

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <TableWrapper>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={activities}
        onChange={onChange}
        pagination={true}
      />
    </TableWrapper>
  );
}

export default TableInquiry;
