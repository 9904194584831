import { axios, getError } from "../../../shared/utils";
import {
  API_PUBLIC_SELLER_LIST,
  API_PUBLIC_SELLER_SHOW,
  API_PUBLIC_SELLER_STORE_LIST,
  API_PUBLIC_SELLER_STORE_SHOW,
} from "../../../shared/constant/url";

export const SELLER = {
  OTOP_PUBLIC_SELLER_LIST_START: "OTOP_PUBLIC_SELLER_LIST_START",
  OTOP_PUBLIC_SELLER_LIST_SUCCESS: "OTOP_PUBLIC_SELLER_LIST_SUCCESS",
  OTOP_PUBLIC_SELLER_LIST_ERROR: "OTOP_PUBLIC_SELLER_LIST_ERROR",

  OTOP_PUBLIC_SELLER_READ_START: "OTOP_PUBLIC_SELLER_READ_START",
  OTOP_PUBLIC_SELLER_READ_SUCCESS: "OTOP_PUBLIC_SELLER_READ_SUCCESS",
  OTOP_PUBLIC_SELLER_READ_ERROR: "OTOP_PUBLIC_SELLER_READ_ERROR",

  OTOP_PUBLIC_STORE_LIST_START: "OTOP_PUBLIC_STORE_LIST_START",
  OTOP_PUBLIC_STORE_LIST_SUCCESS: "OTOP_PUBLIC_STORE_LIST_SUCCESS",
  OTOP_PUBLIC_STORE_LIST_ERROR: "OTOP_PUBLIC_STORE_LIST_ERROR",

  OTOP_PUBLIC_STORE_READ_START: "OTOP_PUBLIC_STORE_READ_START",
  OTOP_PUBLIC_STORE_READ_SUCCESS: "OTOP_PUBLIC_STORE_READ_SUCCESS",
  OTOP_PUBLIC_STORE_READ_ERROR: "OTOP_PUBLIC_STORE_READ_ERROR",
};

export const getOtopSellerList = (params, meta) => {
  return (dispatch) => {
    dispatch({ type: SELLER.OTOP_PUBLIC_SELLER_LIST_START });
    axios
      .get(`${API_PUBLIC_SELLER_LIST}?q=${params ? params.search : ''}`)
      .then((response) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_SELLER_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_SELLER_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const otopSellerRead = (id) => {
  return (dispatch) => {
    dispatch({ type: SELLER.OTOP_PUBLIC_SELLER_READ_START });
    axios
      .get(`${API_PUBLIC_SELLER_SHOW}/${id}`)
      .then((response) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_SELLER_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_SELLER_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getOtopStoreList = (params, meta) => {
  return (dispatch) => {
    dispatch({ type: SELLER.OTOP_PUBLIC_STORE_LIST_START });
    axios
      .get(`${API_PUBLIC_SELLER_STORE_LIST}?q=${params ? params.search : ''}`)
      .then((response) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_STORE_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_STORE_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const otopStoreRead = (id) => {
  return (dispatch) => {
    dispatch({ type: SELLER.OTOP_PUBLIC_STORE_READ_START });
    axios
      .get(`${API_PUBLIC_SELLER_STORE_SHOW}/${id}`)
      .then((response) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_STORE_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SELLER.OTOP_PUBLIC_STORE_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};