import { PRODUCT } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  products: [],
  productDetails: "",
  product: {},
  productDelete: {},
};

const myProducts = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case PRODUCT.OTOP_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, products: action.payload };
    case PRODUCT.OTOP_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PRODUCT_READ_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PRODUCT_READ_SUCCESS:
      return { ...state, loading: false, productDetails: action.payload };
    case PRODUCT.OTOP_PRODUCT_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PRODUCT_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PRODUCT_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, product: action.payload };
    case PRODUCT.OTOP_PRODUCT_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PRODUCT_DELETE_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PRODUCT_DELETE_SUCCESS:
      return { ...state, loading: false, productDelete: action.payload };
    case PRODUCT.OTOP_PRODUCT_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default myProducts;