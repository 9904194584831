import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import Products from '../components/products';
import StandardLanding from '../../../../shared/components/StandardLanding';

const Landing = ({ click }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <StandardLanding>
      <div className="content-bg bg-otop-main">
        <div className="content-space content-crop flex justify-between items-center">
          <div className="flex w-1/2 mt-32 h-96 justify-center">
            <Button
              onClick={click}
              // onClick={showModal}
              className="rounded-lg btn-red mt-96 h-12 px-10"
            >
              <i className="fas fa-globe text-lg mr-2"></i>
              Visit the OTOP Hub
            </Button>
          </div>

          <div className="flex items-center">
            {/* <p className="content-header">WELCOME TO THE OTOP HUB</p> */}
          </div>
          <div className="flex items-center">
            <Products click={click} />
          </div>
        </div>
      </div>
      <Modal
        title="OTOP HUB!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h3 className="text-brown-10 text-xl">
          Our Hub operators are uploading their products, please come back to
          support our stores . Otop Hub Atin to!
        </h3>
      </Modal>
    </StandardLanding>
  );
};

export default Landing;
