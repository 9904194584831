import { axios, getError } from "../../../shared/utils";
import {
  API_PUBLIC_PRODUCT_LIST,
  API_PUBLIC_PRODUCT_READ,
  API_PUBLIC_CATEGORY_LIST,
  API_PUBLIC_CATEGORY_READ,
  API_PUBLIC_TAG_LIST,
  API_PUBLIC_TAG_READ,
} from "../../../shared/constant/url";

export const PRODUCT = {
  OTOP_PUBLIC_PRODUCT_LIST_START: "OTOP_PUBLIC_PRODUCT_LIST_START",
  OTOP_PUBLIC_PRODUCT_LIST_SUCCESS: "OTOP_PUBLIC_PRODUCT_LIST_SUCCESS",
  OTOP_PUBLIC_PRODUCT_LIST_ERROR: "OTOP_PUBLIC_PRODUCT_LIST_ERROR",

  OTOP_PUBLIC_PRODUCT_READ_START: "OTOP_PUBLIC_PRODUCT_READ_START",
  OTOP_PUBLIC_PRODUCT_READ_SUCCESS: "OTOP_PUBLIC_PRODUCT_READ_SUCCESS",
  OTOP_PUBLIC_PRODUCT_READ_ERROR: "OTOP_PUBLIC_PRODUCT_READ_ERROR",

  OTOP_PUBLIC_CATEGORY_LIST_START: "OTOP_PUBLIC_CATEGORY_LIST_START",
  OTOP_PUBLIC_CATEGORY_LIST_SUCCESS: "OTOP_PUBLIC_CATEGORY_LIST_SUCCESS",
  OTOP_PUBLIC_CATEGORY_LIST_ERROR: "OTOP_PUBLIC_CATEGORY_LIST_ERROR",

  OTOP_PUBLIC_CATEGORY_READ_START: "OTOP_PUBLIC_CATEGORY_READ_START",
  OTOP_PUBLIC_CATEGORY_READ_SUCCESS: "OTOP_PUBLIC_CATEGORY_READ_SUCCESS",
  OTOP_PUBLIC_CATEGORY_READ_ERROR: "OTOP_PUBLIC_CATEGORY_READ_ERROR",

  OTOP_PUBLIC_TAG_LIST_START: "OTOP_PUBLIC_TAG_LIST_START",
  OTOP_PUBLIC_TAG_LIST_SUCCESS: "OTOP_PUBLIC_TAG_LIST_SUCCESS",
  OTOP_PUBLIC_TAG_LIST_ERROR: "OTOP_PUBLIC_TAG_LIST_ERROR",

  OTOP_PUBLIC_TAG_READ_START: "OTOP_PUBLIC_TAG_READ_START",
  OTOP_PUBLIC_TAG_READ_SUCCESS: "OTOP_PUBLIC_TAG_READ_SUCCESS",
  OTOP_PUBLIC_TAG_READ_ERROR: "OTOP_PUBLIC_TAG_READ_ERROR",
};

export const getOtopProductList = (params) => {
  let cat = "",tag = "", seller_id = "";
  if(params && params.categories) {
    cat = `&categories[]=${params.categories}`
  }
  if(params && params.tags) {
    tag = `&tags[]=${params.tags}`
  }
  if(params && params.tags) {
    tag = `&tags[]=${params.tags}`
  }
  if(params && params.seller_id) {
    tag = `&seller_id=${params.seller_id}`
  }
  
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_START });
    axios
      .get( params ? `${API_PUBLIC_PRODUCT_LIST}?q=${params && params.q ? params.q : ''}${cat ? cat : ""+tag ? tag : ""+seller_id ? seller_id : ""}` : API_PUBLIC_PRODUCT_LIST)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const otopPublicProductRead = (id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_PRODUCT_READ_START });
    axios
      .get(`${API_PUBLIC_PRODUCT_READ}/${id}`)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_PRODUCT_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_PRODUCT_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};


export const getOtopCategoryList = () => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_START });
    axios
      .get(`${API_PUBLIC_CATEGORY_LIST}`)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const otopCategoryRead = (id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_CATEGORY_READ_START });
    axios
      .get(`${API_PUBLIC_CATEGORY_READ}/${id}`)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_CATEGORY_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_CATEGORY_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};


export const getOtopTagLlist = () => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_TAG_LIST_START });
    axios
      .get(`${API_PUBLIC_TAG_LIST}`)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_TAG_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_TAG_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const otopTagRead = (id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_PUBLIC_TAG_READ_START });
    axios
      .get(`${API_PUBLIC_TAG_READ}/${id}`)
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_TAG_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_PUBLIC_TAG_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};
