import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "antd";
import { getProductList, deleteProduct } from "../../product/actions";
import TableProduct from "../../product/components/TableProduct";

function ManageProduct() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { productList, loading } = state.product;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProductList(null, 0));
  },[getProductList]);

  return (
    <Card className="rounded-2xl p-1 lg:p-10 mt-5 w-full">
      <TableProduct
        productList={productList}
        loading={loading}
        getProductList={getProductList}
        deleteProduct={deleteProduct}
      />
    </Card>
  );
}

export default ManageProduct;