import React, { useEffect } from 'react';
import StandardLanding from '../../../../shared/components/StandardLanding'
import { AdminLogin } from '../components/FormLogin'
import { loginOtop } from "../actions";
 
const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <StandardLanding className="overflow-hidden">
            <div className="content-bg content bg-main-login">
                <div className="content-space content-crop px-32 flex justify-center items-center">
                    <div className="w-1/2 p-10 text-center bg-white">
                        <p className="content-header">OTOPHUB CENTER LOGIN</p>
                        <p className="text-lg font-semibold text-red-10 mb-10">Sign in with your otop account</p>
                        <AdminLogin loginOtop={loginOtop}/>
                    </div>
                </div>
            </div>
        </StandardLanding>
    )
}

export default Login
