import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Input, notification } from "antd";
import { useHistory, Link } from "react-router-dom";
import FormWrapper from "../../../../../shared/components/otop/FormWrapper";

const FormTag = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { createTag, editTag, tagDetails, id } =props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [form] = Form.useForm();
  let isEdit = id;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isEdit && tagDetails) {
      const { attributes } = tagDetails;
      form.setFieldsValue({
        name: attributes.name ? attributes.name : "",
      });
    }
  }, [tagDetails]);

  const onFinish = (values, e) => {
    // console.log("values", values)
    if (isEdit) {
      dispatch(editTag(values, id, callback));
      setIsDisabled(true);
    } else {
      dispatch(createTag(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code } = response;
      const { data } = response;
      if (data) {
        notification.success({ message: `${response.data.msg}` });
        history.push('/otop-hub/admin/tags');
      } else if (status_code === "PRODUCT_UPDATE") {
        notification.success({ message: `${response.data.msg}` });
        history.push('/otop-hub/admin/tags');
      } else if (status_code === "INVALID_DATA") {
        if (response.errors.name) {
          form.setFields([
            {
              name: "name",
              errors: [`${response.errors.name}`],
            },
          ]);
        }
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
        name="formTag"
        className="shadow pt-10 px-10"
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
      >
        <div className="flex w-5/6 mt-5">
          <div className="w-1/2 mr-10 pr-5">
            <div>
              <p className="xs-title">Tag Name</p>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your tag name!",
                  },
                ]}
              >
                <Input placeholder="Tag Name" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="border-b-2">&nbsp;</div>
        <div className="py-10 flex">
          {isEdit ? (
            <Button htmlType="submit" className="mr-5 flex items-center">
              Edit Tag
            </Button>
          ) : (
            <Button
              htmlType="submit"
              disabled={isDisabled}
              className="mr-5 flex items-center"
            >
              Add Tag
            </Button>
          )}
          <Link to="/otop-hub/admin/tags">
            <Button className="flex items-center">Cancel</Button>
          </Link>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default FormTag;
