import React, { useEffect, useState } from 'react';
import { Space, Button, Tabs, notification } from 'antd';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import Profile from '../components/Profile';
// import Expo from '../components/Expo';
import ManageProduct from '../components/ManageProduct';
import StandardLanding from '../../../shared/components/StandardLanding';
import ListProduct from '../../exhibitor/components/ListProduct';
import AddEditProduct from '../../product/containers/AddEditProduct';
import EditProfile from '../containers/EditProfile';
import EditPassword from '../containers/EditPassword';
// import EditAvatar from '../containers/EditAvatar';
import TabsWrapper from '../../../shared/components/TabsWrapper';
import airspeed from '../../../shared/assets/images/logo/partners/airspeed.png';
import dti from '../../../shared/assets/images/logo/partners/dti.png';
import hs from '../../../shared/assets/images/logo/partners/hs.png';
import ilo from '../../../shared/assets/images/logo/partners/ilo.png';
import sm_cebu from '../../../shared/assets/images/logo/partners/sm_cebu.png';
import sm_mega from '../../../shared/assets/images/logo/partners/sm_mega.png';
import wsn from '../../../shared/assets/images/logo/partners/wsn.png';
import apdec from '../../../shared/assets/images/logo/partners/apdec.png';
import pal from '../../../shared/assets/images/logo/partners/pal.png';
// import Inquiry from '../../inquiries/containers/LandingInquiry';
// import LandingBankDetails from '../../bank-details/containers/LandingBankDetails';
// import AddEditBankDetails from '../../bank-details/containers/AddEditBankDetails';
// import AddEditSalesRecord from '../../sales-record/containers/AddEditSalesRecord';
// import LandingSalesRecord from '../../sales-record/containers/LandingSalesRecord';
// import ShowOrder from '../../orders/containers/ShowOrder';
// import LandingOrder from '../../orders/containers/LandingOrder';
// import AddEditLive from '../../live/containers/AddEditLive';
// import LandingLive from '../../live/containers/LandingLive';
// import LandingPolicy from '../../policy/containers/LandingPolicy';
// import LandingDisbursement from '../../disbursements/containers/LandingDisbursement';
// import AddEditDisbursement from '../../disbursements/containers/AddEditDisbursement';
// import LandingPaymentOption from '../../payment-option/containers/Landing';
// import LandingRefund from '../../refund/containers/Landing';

import {
  readProfileExhibitor,
  updateProfileExhibitor,
  // updatePhotoExhibitor,
  updatePasswordExhibitor,
} from '../actions';
import {
  showExhibitorDetails,
  getExhibitorProductList,
  getExhibitorProductListMerchant,
  showExhibitorDetailsMerchant,
} from '../../exhibitor/actions';
import {
  getGuestExhibitorShow,
  getGuestExhibitorProductList,
} from '../../guest/actions';
import { deleteProduct, deleteProductImage } from '../../product/actions';
import { logout } from '../../auth/actions';
// import { getIndustryList } from '../../general/actions';
// import LandingGallery from '../../gallery/containers/LandingGallery';

const { TabPane } = Tabs;

function ExhibitorProfile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const state = useSelector((state) => state);
  const [tab, setTab] = useState('2');
  const [productList, setProductList] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const { profileDetailsExhibitor, loading } = state.profile;
  const {
    exhibitorDetails,
    exhibitorProducts,
    exhibitorProductMerchant,
    totalProducts,
    totalProductMerchant,
    exhibitorDetailsMerchant,
  } = state.exhibitor;
  const { exhibitorProductsGuest, totalProductGuest } = state.guest;
  const { industries } = state.general;
  const match = useRouteMatch();
  const { path } = match;
  const isExhibitor = localStorage.getItem('isExhibitor');
  const token = localStorage.getItem('token');

  const dispatchProductList = () => {
    if (profileDetailsExhibitor?.id && isExhibitor === 'false') {
      dispatch(showExhibitorDetails(profileDetailsExhibitor?.id));
      dispatch(
        getExhibitorProductList(
          null,
          profileDetailsExhibitor?.id,
          totalProducts
        )
      );
    } else if (profileDetailsExhibitor?.id && isExhibitor === 'true') {
      dispatch(showExhibitorDetailsMerchant(profileDetailsExhibitor?.id));
      dispatch(
        getExhibitorProductListMerchant(
          null,
          profileDetailsExhibitor?.id,
          totalProductMerchant
        )
      );
    }
  };

  useEffect(() => {
    if (
      path === '/exhibitor/profile/:id/edit' ||
      path === '/exhibitor/products/create'
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
      dispatchProductList();
    }
  }, [path]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchProductList();
  }, [path, profileDetailsExhibitor?.id]);

  useEffect(() => {
    if (profileDetailsExhibitor?.id && !token) {
      dispatch(getGuestExhibitorShow(profileDetailsExhibitor?.id));
      dispatch(
        getGuestExhibitorProductList(
          null,
          profileDetailsExhibitor?.id,
          totalProductGuest
        )
      );
    }
  }, [profileDetailsExhibitor?.id]);

  useEffect(() => {
    if (isExhibitor === 'false') {
      !isEmpty(exhibitorProducts)
        ? setProductList(exhibitorProducts)
        : setProductList([]);
    }
  }, [exhibitorProducts, exhibitorDetailsMerchant]);

  useEffect(() => {
    if (isExhibitor === 'true') {
      !isEmpty(exhibitorProductMerchant)
        ? setProductList(exhibitorProductMerchant)
        : setProductList([]);
    }
  }, [exhibitorProductMerchant]);

  useEffect(() => {
    if (!token) {
      !isEmpty(exhibitorProductsGuest)
        ? setProductList(exhibitorProductsGuest)
        : setProductList([]);
    }
  }, [exhibitorProductsGuest, exhibitorDetails, exhibitorDetailsMerchant]);

  useEffect(() => {
    if (pathname === '/exhibitor/profile') setTab('2');
  }, [pathname]);

  const deleteProductItem = (item) => {
    dispatch(
      deleteProduct(item.id, (response, error) => {
        if (response) {
          const statusCode = response.status_code;
          if (statusCode === 'PRODUCT_DELETED') {
            notification.success({ message: `${response.msg}`, duration: '1' });
            dispatchProductList();
          } else if (statusCode === 'PRODUCT_NOT_FOUND') {
            notification.error({ message: `${response.msg}`, duration: '1' });
          }
        } else if (error) {
          notification.error({ message: `${error.msg}`, duration: '1' });
        }
      })
    );

    item?.images?.data?.map((image) => {
      dispatch(deleteProductImage(item.id, image.id));
    });
  };

  const onLogout = () => {
    dispatch(logout(callback));
  };

  const callback = () => {
    history.push('/login');
    notification.success({ message: 'Logout Successfully!' });
  };

  const onTabClick = (key, event) => {
    event.preventDefault();
    switch (key) {
      case '3': {
        history.push(
          `/exhibitor/profile/${profileDetailsExhibitor?.id}/edit-password`
        );
        setTab(key);
        break;
      }
      case '4': {
        onLogout();
        break;
      }
      default: {
        setTab(key);
        break;
      }
    }
  };

  const renderProfile = () => {
    switch (path) {
      case '/exhibitor/profile':
        return (
          <Profile
            profileDetailsExhibitor={profileDetailsExhibitor}
            loading={loading}
          />
        );
      case '/exhibitor/profile/:id/edit':
        return (
          <EditProfile
            path={path}
            productList={productList}
            exhibitor_id={profileDetailsExhibitor?.id}
            profileDetailsExhibitor={profileDetailsExhibitor}
            readProfileExhibitor={readProfileExhibitor}
            updateProfileExhibitor={updateProfileExhibitor}
            industries={industries}
            loading={loading}
          />
        );
      case '/exhibitor/products/create':
      case '/exhibitor/products/:id/edit':
        return <AddEditProduct />;
      default:
        <Profile profileDetailsExhibitor={profileDetailsExhibitor} />;
        break;
    }
  };

  const ContentSpace = ({ header, subHeader, content, contentClassName }) => {
    return (
      <div className="container m-auto content-space px-5 py-20 flex flex-col">
        <h1 className="content-header">{header}</h1>
        {!!subHeader && (
          <h2
            className="content-subheader text-center"
            style={{ color: '#1B1B1B', fontSize: '1.875rem' }}
          >
            {subHeader}
          </h2>
        )}

        <div
          className={`flex items-center justify-center ${contentClassName}`}
          style={{ color: '#1B1B1B', fontSize: '1.875rem' }}
        >
          {content}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <StandardLanding>
        <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
          <div className="flex justify-center items-center px-5 lg:px-20 h-full">
            <h1 className="text-white font-medium text-3xl z-10">
              {tab === '3' ? 'CHANGE PASSWORD' : 'USER PROFILE'}
            </h1>
          </div>
          <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
        </div>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            {tab !== '3' ? (
              <TabsWrapper>
                <Tabs onTabClick={onTabClick} defaultActiveKey={tab}>
                  <TabPane tab="PROFILE MENU" key="1" disabled />
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-user mr-2"></i>
                        MY PROFILE
                      </>
                    }
                    key="2"
                  >
                    {renderProfile()}
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-lock mr-2"></i>
                        CHANGE PASSWORD
                      </>
                    }
                    key="3"
                  />
                  <TabPane
                    tab={
                      <>
                        <i className="fas fa-sign-out-alt mr-2"></i>
                        LOGOUT
                      </>
                    }
                    key="4"
                  />
                </Tabs>
              </TabsWrapper>
            ) : (
              <EditPassword
                profileDetailsExhibitor={profileDetailsExhibitor}
                readProfileExhibitor={readProfileExhibitor}
                updatePasswordExhibitor={updatePasswordExhibitor}
                path={path}
              />
            )}
          </div>
          {isExhibitor === 'true' &&
            (path === '/exhibitor/profile' ||
              path === '/exhibitor/profile/:id/edit' ||
              path === '/exhibitor/products/create') && (
              <div className="content-space px-5 pb-10 lg:px-20">
                <ListProduct
                  header="MY PRODUCTS"
                  productList={productList || []}
                  loadingExhibitor={loading}
                  isEditable={isEditable}
                  onDelete={deleteProductItem}
                />
                <div className="w-full flex flex-col lg:flex-row lg:justify-center pt-3">
                  <Space className="justify-center">
                    {/* <Button className="btn btn-red rounded py-3 px-5 h-auto">
                      <i className="fas fa-list-alt mr-1"></i>
                      <span>VIEW ALL</span>
                    </Button> */}
                    {isEditable && path === '/exhibitor/profile/:id/edit' && (
                      <Button
                        className="btn btn-white text-red-20 rounded py-3 px-5 h-auto"
                        style={{ boxShadow: '0px 3px 6px #00000029' }}
                      >
                        <Link to={'/exhibitor/products/create'}>
                          <i className="fas fa-plus mr-1"></i>
                          <span>ADD NEW</span>
                        </Link>
                      </Button>
                    )}
                  </Space>
                </div>
              </div>
            )}
          {path === '/exhibitor/profile' && (
            <ContentSpace
              header={'PARTNERS'}
              content={
                <div className="flex flex-wrap w-full lg:w-3/4">
                  <img
                    src={airspeed}
                    alt="Airspeed"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={dti}
                    alt="DTI Philippines"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={hs}
                    alt="Highly Succeed"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={ilo}
                    alt="International Labour Organization"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={sm_cebu}
                    alt="SM Cebu City"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={sm_mega}
                    alt="SM Mega Mall"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={wsn}
                    alt="Women Strong Network"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={apdec}
                    alt="Amazing Philippines Digital Economy Corporation"
                    className="h-40 p-5 m-auto"
                  />
                  <img
                    src={pal}
                    alt="Philippine Airlines"
                    className="h-40 p-5 m-auto"
                  />
                </div>
              }
            />
          )}
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default ExhibitorProfile;
