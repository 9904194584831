// Production
// export const BASE_URL = 'https://staging.women-biz-be.highlysucceed.com';
// export const API_URL = 'https://staging.women-biz-be.highlysucceed.com/api';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = `${BASE_URL}/api`;

export const API_LOGIN = `${API_URL}/auth/login.json`;
export const API_REGISTER = `${API_URL}/auth/register.json`;
export const API_PRE_REGISTER = `${API_URL}/auth/pre-register.json`;
export const API_FORGOT_PASSWORD = `${API_URL}/auth/forgotpasswordparticipant.json`;
export const API_RESET_PASSWORD = `${API_URL}/auth/reset-password.json`;
export const API_REFRESH_TOKEN = `${API_URL}/auth/refresh-token.json`;

export const API_PROFILE_READ = `${API_URL}/profile/show.json`;
export const API_PROFILE_UPDATE = `${API_URL}/profile/edit-profile.json`;
export const API_PROFILE_UPDATE_PASSWORD = `${API_URL}/profile/edit-password.json`;
export const API_PROFILE_UPDATE_PHOTO = `${API_URL}/profile/edit-avatar.json`;
export const API_PROFILE_EXPO = `${API_URL}/profile/expo.json`;
export const API_PROFILE_ACTIVITY = `${API_URL}/profile/my-activity.json`;

export const API_EXPO_LIST = `${API_URL}/expo/all.json`;
export const API_EXPO_SHOW = `${API_URL}/expo/show.json`;
export const API_EXPO_PARTNER_LIST = `${API_URL}/expo/partners.json`;
export const API_FEATURED_OFFER_LIST = `${API_URL}/expo/featured-offers.json`;
export const API_FEATURED_PRODUCT_LIST = `${API_URL}/expo/featured-products.json`;
export const API_EXPO_HALL_EXHIBITOR = `${API_URL}/expo/hall/exhibitors.json`;
export const API_EXPO_HALL_SHOW = `${API_URL}/expo/hall/show.json`;
export const API_EXPO_UPCOMING_LIST = `${API_URL}/expo/upcoming.json`;
export const API_EXPO_JOIN = `${API_URL}/expo/join.json`;
// export const API_EXPO_SEMINAR_LIST = `${API_URL}/expo/seminars.json`;
export const API_EXPO_SEMINAR_LIST = `${API_URL}/expo/auditorium.json`;
export const API_EXPO_SEMINAR_MAIN = `${API_URL}/expo/main-event.json`;
export const API_EXPO_SEMINAR_DISCUSSION_LIST = `${API_URL}/expo/discussion/all.json`;
export const API_EXPO_SEMINAR_DISCUSSION_CREATE = `${API_URL}/expo/discussion/create.json`;

export const API_EXHIBITOR_LIST = `${API_URL}/merchant/all.json`;
export const API_EXHIBITOR_SHOW = `${API_URL}/merchant/show.json`;
export const API_EXHIBITOR_EXPO_LIST = `${API_URL}/merchant/attended-expo.json`;
export const API_EXHIBITOR_PRODUCT_LIST = `${API_URL}/merchant/products/all.json`;
export const API_EXHIBITOR_PRODUCT_SHOW = `${API_URL}/merchant/products/show.json`;
export const API_PRODUCT_INQUIRY_CREATE = `${API_URL}/merchant/products/inquire.json`;
export const API_PRODUCT_TRANSACTION_CREATE = `${API_URL}/merchant/products/transaction/create.json`;

export const API_TRANSACTION_LIST = `${API_URL}/transaction/all.json`;
export const API_TRANSACTION_SHOW = `${API_URL}/transaction/show.json`;
export const API_TRANSACTION_UPDATE_STATUS = `${API_URL}/transaction/update-status.json`;

export const API_PARTNER_LIST = `${API_URL}/partner/all.json`;
export const API_PARTNER_SHOW = `${API_URL}/partner/show.json`;

export const API_SPEAKER_LIST = `${API_URL}/speaker/all.json`;
export const API_SPEAKER_SHOW = `${API_URL}/speaker/show.json`;

export const API_GENERAL_LIKE = `${API_URL}/general/like.json`;
export const API_GENERAL_REGION_LIST = `${API_URL}/general/region.json`;
export const API_GENERAL_CATEGORY_LIST = `${API_URL}/general/category.json`;
export const API_GENERAL_INDUSTRY_LIST = `${API_URL}/general/industry.json`;
export const API_GENERAL_CONTACT_SEND = `${API_URL}/general/contact-us.json`;
export const API_EXPO_REQUEST = `${API_URL}/general/expo-request.json`;
export const API_EXPO_SUBSCRIBE = `${API_URL}/general/subscribe.json`;

// Exhibitors
export const API_LOGIN_EXHIBITOR = `${API_URL}/exhibitor/auth/login.json`;
export const API_REGISTER_EXHIBITOR = `${API_URL}/exhibitor/auth/register.json`;
export const API_PRE_REGISTER_EXHIBITOR = `${API_URL}/exhibitor/auth/pre-register.json`;
export const API_FORGOT_PASSWORD_EXHIBITOR = `${API_URL}/exhibitor/auth/forgotpasswordexhibitor.json`;
export const API_RESET_PASSWORD_EXHIBITOR = `${API_URL}/exhibitor/auth/reset-password.json`;
export const API_REFRESH_TOKEN_EXHIBITOR = `${API_URL}/exhibitor/auth/refresh-token.json`;
export const API_LOGOUT_EXHIBITOR = `${API_URL}/exhibitor/auth/logout.json`;

export const API_PROFILE_READ_EXHIBITOR = `${API_URL}/exhibitor/profile/show.json`;
export const API_PROFILE_UPDATE_EXHIBITOR = `${API_URL}/exhibitor/profile/edit-profile.json`;
export const API_POLICY_UPDATE_EXHIBITOR = `${API_URL}/exhibitor/profile/policy.json`;
export const API_PROFILE_UPDATE_PASSWORD_EXHIBITOR = `${API_URL}/exhibitor/profile/edit-password.json`;
export const API_PROFILE_UPDATE_PHOTO_EXHIBITOR = `${API_URL}/exhibitor/profile/edit-avatar.json`;
export const API_PROFILE_EXPO_EXHIBITOR = `${API_URL}/exhibitor/profile/expo.json`;

export const API_PRODUCT_LIST = `${API_URL}/exhibitor/products/all.json`;
export const API_PRODUCT_READ = `${API_URL}/exhibitor/products/show.json`;
export const API_PRODUCT_CREATE = `${API_URL}/exhibitor/products/create.json`;
export const API_PRODUCT_EDIT = `${API_URL}/exhibitor/products/edit.json`;
export const API_PRODUCT_DELETE = `${API_URL}/exhibitor/products/delete.json`;

export const API_PRODUCT_IMAGE_LIST = `${API_URL}/exhibitor/products/image/all.json`;
export const API_PRODUCT_IMAGE_DELETE = `${API_URL}/exhibitor/products/image/delete.json`;

export const API_EXPO_SHOW_EXHIBITOR = `${API_URL}/exhibitor/expo/show.json`;
export const API_FEATURED_PRODUCT_EXHIBITOR_LIST = `${API_URL}/exhibitor/expo/featured-products.json`;
export const API_EXPO_JOIN_EXHIBITOR = `${API_URL}/exhibitor/expo/join.json`;

export const API_EXPO_HALL_EXHIBITOR_MERCHANT = `${API_URL}/exhibitor/expo/hall/exhibitors.json`;

export const API_PARTNER_SHOW_EXHIBITOR = `${API_URL}/exhibitor/partner/show.json`;

export const API_EXHIBITOR_SHOW_MERCHANT = `${API_URL}/exhibitor/merchant/show.json`;
export const API_EXHIBITOR_SHOW_SPEAKER = `${API_URL}/exhibitor/speaker/show.json`;

export const API_EXHIBITOR_PRODUCT_LIST_MERCHANT = `${API_URL}/exhibitor/merchant/products/all.json`;
export const API_EXHIBITOR_PRODUCT_SHOW_MERCHANT = `${API_URL}/exhibitor/merchant/products/show.json`;
export const API_EXHIBITOR_EXPO_LIST_MERCHANT = `${API_URL}/exhibitor/merchant/attended-expo.json`;

// export const API_EXPO_SEMINAR_LIST_MERCHANT = `${API_URL}/exhibitor/expo/seminars.json`;
export const API_EXPO_SEMINAR_LIST_MERCHANT = `${API_URL}/expo/auditorium.json`;
export const API_EXPO_SEMINAR_MAIN_MERCHANT = `${API_URL}/exhibitor/expo/main-event.json`;

export const API_TRANSACTION_LIST_MERCHANT = `${API_URL}/exhibitor/transaction/all.json`;
export const API_TRANSACTION_DETAILS_MERCHANT = `${API_URL}/exhibitor/transaction/show.json`;
export const API_TRANSACTION_UPDATE_STATUS_MERCHANT = `${API_URL}/exhibitor/transaction/update-status.json`;
export const API_TRANSACTION_UPDATE_PAYMENT_STATUS_MERCHANT = `${API_URL}/exhibitor/transaction/update-payment-status.json`;

export const API_BANK_DETAILS_LIST = `${API_URL}/exhibitor/profile/bank-account/all.json`;
export const API_BANK_DETAILS_CREATE = `${API_URL}/exhibitor/profile/bank-account/add.json`;
export const API_BANK_DETAILS_READ = `${API_URL}/exhibitor/profile/bank-account/show.json`;
export const API_BANK_DETAILS_UPDATE = `${API_URL}/exhibitor/profile/bank-account/edit.json`;
export const API_BANK_DETAILS_DELETE = `${API_URL}/exhibitor/profile/bank-account/delete.json`;
export const API_BANK_DETAILS_UPDATE_STATUS = `${API_URL}/exhibitor/profile/bank-account/update-status.json`;

export const API_SALES_RECORD_LIST = `${API_URL}/exhibitor/sales-record/all.json`;
export const API_SALES_RECORD_CREATE = `${API_URL}/exhibitor/sales-record/store.json`;
export const API_SALES_RECORD_READ = `${API_URL}/exhibitor/sales-record/show.json`;
export const API_SALES_RECORD_UPDATE = `${API_URL}/exhibitor/sales-record/edit.json`;
export const API_SALES_RECORD_DELETE = `${API_URL}/exhibitor/sales-record/delete.json`;

export const API_LIVE_LIST = `${API_URL}/exhibitor/exhibitor-live/index.json`;
export const API_LIVE_CREATE = `${API_URL}/exhibitor/exhibitor-live/store.json`;
export const API_LIVE_READ = `${API_URL}/exhibitor/exhibitor-live/show.json`;
export const API_LIVE_UPDATE = `${API_URL}/exhibitor/exhibitor-live/edit.json`;
export const API_LIVE_DELETE = `${API_URL}/exhibitor/exhibitor-live/delete.json`;

export const API_DISBURSEMENT_LIST = `${API_URL}/exhibitor/disbursement/index.json`;
export const API_DISBURSEMENT_CREATE = `${API_URL}/exhibitor/disbursement/store.json`;
export const API_DISBURSEMENT_READ = `${API_URL}/exhibitor/disbursement/show.json`;
export const API_DISBURSEMENT_UPDATE = `${API_URL}/exhibitor/disbursement/update.json`;
export const API_DISBURSEMENT_DELETE = `${API_URL}/exhibitor/disbursement/delete.json`;
export const API_DISBURSEMENT_TRANSACTION_LIST = `${API_URL}/exhibitor/disbursement/paymaya-transaction.json`;

export const API_PROFILE_ACTIVITY_EXHIBITOR = `${API_URL}/exhibitor/profile/my-activity.json`;

//OTOP

export const API_OTOP_LOGIN = `${API_URL}/login.json`;
export const API_OTOP_LOGOUT = `${API_URL}/logout.json`;

// PUBLIC
export const API_PUBLIC_PRODUCT_LIST = `${API_URL}/product/index.json`;
export const API_PUBLIC_PRODUCT_READ = `${API_URL}/product/show.json`;

export const API_PUBLIC_SELLER_LIST = `${API_URL}/otophub/sellers/index.json`;
export const API_PUBLIC_SELLER_SHOW = `${API_URL}/otophub/sellers/show.json`;

export const API_PUBLIC_SELLER_STORE_LIST = `${API_URL}/otophub/sellers/store/index.json`;
export const API_PUBLIC_SELLER_STORE_SHOW = `${API_URL}/otophub/sellers/store/show.json`;

export const API_PUBLIC_CATEGORY_LIST = `${API_URL}/otophub/category/index.json`;
export const API_PUBLIC_CATEGORY_READ = `${API_URL}/otophub/category/show.json`;

export const API_PUBLIC_TAG_LIST = `${API_URL}/otophub/tag/index.json`;
export const API_PUBLIC_TAG_READ = `${API_URL}/otophub/tag/show.json`;

// AUTH ADMINS: SUPER ADMIN, REGIONAL ADMIN/OTOP HUB ADMIN, SELLER ADMIN
export const API_OTOP_ADMIN_SHOW = `${API_URL}/otophub/auth/show.json`;
export const API_OTOP_SUMMARY = `${API_URL}/otophub/product/order/dashboard?year=`;
export const API_OTOP_SALES_GENERATE = `${API_URL}/otophub/product/order/export-sales`;

export const API_OTOP_PRODUCT_LIST = `${API_URL}/otophub/product/index.json`;
export const API_OTOP_PRODUCT_READ = `${API_URL}/otophub/product/show.json`;
export const API_OTOP_PRODUCT_CREATE = `${API_URL}/otophub/product/create.json`;
export const API_OTOP_PRODUCT_EDIT = `${API_URL}/otophub/product/update.json`;
export const API_OTOP_PRODUCT_DELETE = `${API_URL}/otophub/product/delete.json`;

export const API_OTOP_CREATE_SELLER = `${API_URL}/otophub/auth/register.json`;
export const API_OTOP_SELLER_EDIT = `${API_URL}/otophub/auth/update.json`;
export const API_OTOP_SELLER_LIST = `${API_URL}/otophub/auth/index.json`;
export const API_OTOP_SELLER_DELETE = `${API_URL}/otophub/auth/delete.json`;

export const API_OTOP_TAG_LIST = `${API_URL}/otophub/product/tag/index.json`;
export const API_OTOP_TAG_CREATE = `${API_URL}/otophub/product/tag/create.json`;
export const API_OTOP_TAG_EDIT = `${API_URL}/otophub/product/tag/update.json`;
export const API_OTOP_TAG_DELETE = `${API_URL}/otophub/product/tag/delete.json`;
export const API_OTOP_TAG_READ = `${API_URL}/otophub/product/tag/show.json`;

export const API_OTOP_CATEGORY_LIST = `${API_URL}/otophub/product/category/index.json`;
export const API_OTOP_CATEGORY_CREATE = `${API_URL}/otophub/product/category/create.json`;
export const API_OTOP_CATEGORY_EDIT = `${API_URL}/otophub/product/category/update.json`;
export const API_OTOP_CATEGORY_DELETE = `${API_URL}/otophub/product/category/delete.json`;
export const API_OTOP_CATEGORY_READ = `${API_URL}/otophub/product/category/show.json`;

export const API_OTOP_USER_LIST = `${API_URL}/otophub/auth/index.json`;
export const API_OTOP_USER_CREATE = `${API_URL}/otophub/auth/register.json`;
export const API_OTOP_USER_EDIT = `${API_URL}/otophub/auth/update.json`;
export const API_OTOP_USER_DELETE = `${API_URL}/otophub/auth/register.json`;
export const API_OTOP_USER_READ = `${API_URL}/otophub/auth/show.json`;

export const API_OTOP_ORDER_LIST = `${API_URL}/otophub/product/order/index.json`;
export const API_OTOP_ORDER_CREATE = `${API_URL}/otophub/product/order/register.json`;
export const API_OTOP_ORDER_EDIT = `${API_URL}/otophub/product/order/update.json`;
export const API_OTOP_ORDER_DELETE = `${API_URL}/otophub/product/order/register.json`;
export const API_OTOP_ORDER_READ = `${API_URL}/otophub/product/order/show.json`;

export const API_OTOP_ORDER_EXPORT_SALES = `${API_URL}/otophub/product/order/export-sales.json`;
export const API_OTOP_ORDER_DASHBOARD = `${API_URL}/otophub/product/order/dashboard.json`;

export const API_OTOP_ORDER_STATUS_LIST = `${API_URL}/otophub/product/status/index.json`;
export const API_OTOP_ORDER_STATUS_CREATE = `${API_URL}/otophub/product/status/register.json`;
export const API_OTOP_ORDER_STATUS_EDIT = `${API_URL}/otophub/product/status/update.json`;
export const API_OTOP_ORDER_STATUS_DELETE = `${API_URL}/otophub/product/status/register.json`;
export const API_OTOP_ORDER_STATUS_READ = `${API_URL}/otophub/product/status/show.json`;

// AUTH BUYER/PARTICIPANT
export const API_OTOP_TRANSACTION_ADD = `${API_URL}/order/create.json`;
export const API_OTOP_TRANSACTION_READ = `${API_URL}/order/show.json`;
export const API_OTOP_TRANSACTION_LIST = `${API_URL}/order/index.json`;
export const API_OTOP_TRANSACTION_EDIT = `${API_URL}/order/update.json`;

export const API_OTOP_ADDRESS_LIST = `${API_URL}/address/index`;
export const API_OTOP_ADDRESS_CREATE = `${API_URL}/address/create`;
export const API_OTOP_ADDRESS_EDIT = `${API_URL}/address/update`;
export const API_OTOP_ADDRESS_DELETE = `${API_URL}/address/delete`;
export const API_OTOP_ADDRESS_READ = `${API_URL}/address/show`;

// GUEST
export const API_GUEST_PARTNER_SHOW = `${API_URL}/guest/partner/show.json`;
export const API_GUEST_EXPO_HALL_EXHIBITOR_LIST = `${API_URL}/guest/expo/hall/exhibitors.json`;
export const API_GUEST_EXPO_HALL_EXHIBITOR_SHOW = `${API_URL}/guest/expo/hall/show.json`;
export const API_GUEST_DISCUSSION_LIST = `${API_URL}/guest/expo/discussion/all.json`;
export const API_GUEST_EXPO_HALL_SHOW = `${API_URL}/guest/expo/show.json`;
export const API_GUEST_FEATURED_PRODUCT_LIST = `${API_URL}/guest/expo/featured-products.json`;
// export const API_GUEST_SEMINAR_LIST = `${API_URL}/guest/expo/seminars.json`;
export const API_GUEST_SEMINAR_LIST = `${API_URL}/guest/expo/auditorium.json`;
export const API_GUEST_MAIN_SEMINAR = `${API_URL}/guest/expo/main-event.json`;
export const API_GUEST_EXHIBITOR_PRODUCT_LIST = `${API_URL}/guest/merchant/products/all.json`;
export const API_GUEST_EXHIBITOR_PRODUCT_SHOW = `${API_URL}/guest/merchant/products/show.json`;
export const API_GUEST_EXHIBITOR_SHOW = `${API_URL}/guest/merchant/show.json`;
export const API_GUEST_EXHIBITOR_EXPO_LIST = `${API_URL}/guest/merchant/attended-expo.json`;
export const API_GUEST_SPEAKER_DETAILS = `${API_URL}/guest/speaker/show.json`;

// Google Authentication
export const API_GOOGLE_AUTH = `${API_URL}/login/google`;

// DTI click event counter
export const API_CLICK_COUNTER = `${API_URL}/dtiincominguser/incominguser.json`;

export const API_EXPO_HALL_PRODUCTS = `${API_URL}/expo/hall/products.json`;

export const API_REGION = `${API_URL}/province/province.json`;

export const API_CART_INDEX = `${API_URL}/cart/index.json`;
export const API_ADD_TO_CART = `${API_URL}/cart/store.json`;
export const API_DELETE_TO_CART = `${API_URL}/cart/delete.json`;
export const API_ADD_MULTIPLE_ITEMS_TO_CART = `${API_URL}/cart/multiple.json`;

export const API_ACCEPT_PAYMAYA = `${API_URL}/exhibitor/transaction-status/accept.json`;
export const API_DECLINE_PAYMAYA = `${API_URL}/exhibitor/transaction-status/decline.json`;
export const API_EXHIBITOR_LIST_GALLERY = `${API_URL}/exhibitor/gallery/index.json`;
export const API_ADD_GALLERY = `${API_URL}/exhibitor/gallery/store.json`;
export const API_DELETE_GALLERY = `${API_URL}/exhibitor/gallery/delete.json`;
export const API_UPDATE_GALLERY = `${API_URL}/exhibitor/gallery/update.json`;

export const API_EXHIBITOR_LIST_PAYMENT = `${API_URL}/exhibitor/payment/index.json`;
export const API_EXHIBITOR_CREATE_PAYMENT = `${API_URL}/exhibitor/payment/store.json`;
export const API_EXHIBITOR_UPDATE_PAYMENT = `${API_URL}/exhibitor/payment/update.json`;
export const API_EXHIBITOR_DELETE_PAYMENT = `${API_URL}/exhibitor/payment/delete.json`;

export const API_WELCOME_MESSAGE = `${API_URL}/message/welcomemessage.json`;
