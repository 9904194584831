import { CART } from './actions';
import { reject } from 'lodash'

const INITIAL_STATE = {
  loading: false, 
  error: '', 
  totalCart: 0,
  totalCartProduct: 0,
  qty: 0,
  cartProducts: [],
  cartProduct: {},
  cartRemove: 0,
  checkout: [],
  inquiry: {},
  transaction: {},
  checkout_list: [],
  cartList: [],
  cart: [],
};

const cart = (state = INITIAL_STATE, action) => {
 switch(action.type) {

  case CART.CART_ALL: 
    let totalCart;
    if(state.cartProducts) {
      totalCart = state.cartProducts.length
    } 
    return {...state, totalCart};

  case CART.CART_ADD:
    let total = action.payload;
    return {...state, qty: total};

   
  case CART.CART_PRODUCT_LIST:
    sessionStorage.getItem("cartProducts")
    let list = JSON.parse(sessionStorage.getItem("cartProducts"))
    return {...state, cartProducts: list}

  case CART.CART_PRODUCT_ADD:
    sessionStorage.setItem('cartProduct', JSON.stringify(action.payload));
    let currentList = state.cartProducts ? state.cartProducts : []
    // let holder = action.payload;
    // holder.quantity = state.qty
    // holder.total = holder.quantity * holder.price;
    currentList.push(action.payload)
    sessionStorage.setItem('cartProducts', JSON.stringify(currentList));
    return {...state, cartProduct: action.payload, cartProducts:currentList}

    // let price = parseInt(action.payload.price);
   
    //   let cartItem = [{
    //     'id': action.payload.id, 
    //     'bankdetails': action.payload.bankdetails, 
    //     'category_id': action.payload.category_id, 
    //     'color': action.payload.color, 
    //     'description': action.payload.description, 
    //     'display_price': action.payload.display_price,
    //     'exhibitor': action.payload.exhibitor,
    //     'exhibitor_id': action.payload.exhibitor_id,
    //     'images': action.payload.images,
    //     'price': price,
    //     'size_chart': action.payload.size_chart,
    //     'sizes': action.payload.chart,
    //     'thumbnail': action.payload.thumbnail,
    //     'quantity': action.payload.quantity,
    //     'total': price
    //   }]

    //   let cartProd = {
    //     'id': action.payload.id, 
    //     'bankdetails': action.payload.bankdetails, 
    //     'category_id': action.payload.category_id, 
    //     'color': action.payload.color, 
    //     'description': action.payload.description, 
    //     'display_price': action.payload.display_price,
    //     'exhibitor': action.payload.exhibitor,
    //     'exhibitor_id': action.payload.exhibitor_id,
    //     'images': action.payload.images,
    //     'price': price,
    //     'size_chart': action.payload.size_chart,
    //     'sizes': action.payload.chart,
    //     'thumbnail': action.payload.thumbnail,
    //     'quantity': action.payload.quantity,
    //     'total': price
    //   }

    //   const item = cartItem.find(
    //     (product) => product.id === action.payload.id
    //   );

    //   const inCart = state.cartProducts.find((item) =>
    //   item.id === action.payload.id ? true : false
    // );

    //   sessionStorage.setItem("cartProduct", JSON.stringify(action.payload))
    //   sessionStorage.setItem("cartProducts", JSON.stringify(state.cartProducts))
    //   let cart = JSON.parse(sessionStorage.getItem("cartProducts"))
      
    //  if(cart.length === 0){
    //   sessionStorage.setItem("cartProducts", JSON.stringify(cartItem))
    //  }

    //   return {
    //     ...state,
    //     cartProducts: inCart
    //       ? state.cartProducts.map((item) =>
    //           item.id === action.payload.id
    //             ? { ...item , 
    //               'quantity': action.payload.quantity + item.quantity, 'total': price + price * item.quantity }
    //             : item
    //         )
    //       : [...state.cartProducts, cartProd],
    //   };




  case CART.CART_PROD_REMOVE:
    let current_list = JSON.parse(sessionStorage.getItem("cartProducts"))
    let id  = action.payload;
    let row = reject(current_list, { 'id': id });
    sessionStorage.setItem('cartProducts', JSON.stringify(row));
    return {...state, cartRemove: id, cartProducts: row};

  case CART.CART_CHECKOUT_LIST:
    sessionStorage.getItem("checkout")
    let checkout_list = JSON.parse(sessionStorage.getItem("checkout"))
    return {...state, checkout_list}

  case CART.CART_CHECKOUT:
    sessionStorage.setItem('checkout', JSON.stringify(action.payload));
    let checkout = action.payload
    return {...state, checkout};

  case CART.INQUIRY_CREATE_START:
    return { ...state, loading: true, error: "" };
  case CART.INQUIRY_CREATE_SUCCESS:
    return { ...state, loading: false, inquiry: action.payload };
  case CART.INQUIRY_CREATE_ERROR:
    return { ...state, loading: false, error: action.payload };

  case CART.TRANSACTION_CREATE_START:
    return { ...state, loading: true, error: "" };
  case CART.TRANSACTION_CREATE_SUCCESS:
    return { ...state, loading: false, transaction: action.payload };
  case CART.TRANSACTION_CREATE_ERROR:
    return { ...state, loading: false, error: action.payload };

  case CART.CART_LIST_START:
    return { ...state, loading: true, error: "" };
  case CART.CART_LIST_SUCCESS:
    return { ...state, loading: false, cartList: action.payload };
  case CART.CART_LIST_ERROR:
    return { ...state, loading: false, error: action.payload };

    // case CART.CART_CREATE_START:
    //   return { ...state, loading: true, error: "" };
    // case CART.CART_CREATE_SUCCESS:
    //   return { ...state, loading: false, cartList: action.payload };
    // case CART.CART_CREATE_ERROR:
    //   return { ...state, loading: false, error: action.payload };

  default: 
   return state;
 }
}

export default cart;