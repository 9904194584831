import React from 'react'
import { useDispatch } from "react-redux";
import { Form, Button, Input, notification } from 'antd'
import { useHistory } from 'react-router-dom';

import FormWrapper from '../../../../../shared/components/otop/FormWrapper'

const FormAddSeller = (props) => {
  const history = useHistory();
    const {createOtopSeller} = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        dispatch(createOtopSeller(values, null, callback))
    }

    const onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
      };

    const callback = async (response, error) => {
        if (response) {
          const statusCode = response.status_code;
          if (statusCode === "REGISTERED") {
            notification.success({ message: `${response.msg}` });
            history.push("/otop-hub/admin");
          } else if (statusCode === "INVALID_DATA") {
            if (response.errors.email) {
              form.setFields([
                {
                  name: "email",
                  errors: [`${response.errors.email}`],
                },
              ]);
            }
            if (response.errors.password) {
              form.setFields([
                {
                  name: "password",
                  errors: [`${response.errors.password}`],
                },
              ]);
            }
            if (response.errors.contact_number) {
              form.setFields([
                {
                  name: "contact_number",
                  errors: [`${response.errors.contact_number}`],
                },
              ]);
            }
          } else if(statusCode === "APP_ERROR"){
            notification.error({ message: `${response.msg}` });
          }
        }
      };

    return (
        <FormWrapper>
            <Form
            name="createOtopSeller"
            form={form}
            initialValues={{remember: true}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
                <div className="w-full">
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: "Please input your email"}]}>
                        <Input type="email" placeholder="email"/>
                    </Form.Item>
                    <div className="flex">
                      <Form.Item name="password"
                      rules={[{required: true, message: "Please input your password"}]}
                      className="mr-5">
                          <Input placeholder="password" />
                      </Form.Item>
                      <Form.Item name="password_confirmation"
                      rules={[{required: true, message: "Please input your password"}]}>
                          <Input placeholder="confirm password" />
                      </Form.Item>
                    </div>
                    <div className="flex">
                    <Form.Item name="firstname"
                    className="mr-5">
                        <Input placeholder="first name" />
                    </Form.Item>
                    <Form.Item name="lastname">
                        <Input placeholder="last name" />
                    </Form.Item>
                    </div>
                    <Form.Item name="contact_number">
                        <Input placeholder="contact number" />
                    </Form.Item>
                </div>
                <Button htmlType="submit" className="flex items-center justify-center w-full">Create Seller Account</Button>
            </Form>
        </FormWrapper>
    )
}

export default FormAddSeller