import React from 'react'
import { Breadcrumb } from 'antd'
import UnpaidOrderDetails from '../components/UnpaidOrderDetails'
import StandardLanding from '../../../../../shared/components/otop/StandardLanding'

const OrderDetails = () => {
    return (
        <StandardLanding>
            <div className="content-bg w-3/4">
                <div className="content-space">
                    <div className="flex items-center justify-between py-10">
                        <div className="flex items-center">
                            <p className="content-subheader-left mr-5">Seller Dashboard</p>
                            <Breadcrumb>
                                <Breadcrumb.Item>OTOP HUB SELLER CENTER</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                <a href="">Dashboard</a>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                <UnpaidOrderDetails />
                </div>
            </div>
        </StandardLanding>
    )
}

export default OrderDetails
