import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, InputNumber, Button, notification } from "antd";
import { filter, iteratee, isEmpty } from "lodash";

import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import ListFeaturedProducts from "../../products/components/ListFeaturedProducts";
import logo from "../../../../shared/assets/otop/Sellet/logo-1.svg";
import { otopPublicProductRead } from "../actions";
import { getOtopProductList } from "../../products/actions";
import { otopSellerRead } from "../../stores/actions";
import { cartOtopProductAdd } from "../../cart/actions";

const ProductShow = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const state = useSelector((state) => state);
  const { productDetails, loading, products } = state.otopProduct;
  const { sellerDetails } = state.otopSeller;
  const { included } = products;
  const [qty, setQty] = useState(0);
  const [productsFromStore, setProductsFromStore] = useState([]);

  const onChange = (value) => {
    setQty(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(otopPublicProductRead(id));
      dispatch(otopSellerRead(id));
    }
    dispatch(getOtopProductList());
  }, [id]);

  useEffect(() => {
    if (productDetails && productDetails.data) {
      const { attributes } = productDetails.data;
      if (attributes) {
        const { seller_id } = attributes;
        let params = { seller_id };
        dispatch(getOtopProductList(params));
      }
    }
  }, [productDetails]);

  useEffect(() => {
    if (products && products.data) {
      setProductsFromStore(products.data);
    }
  }, [products]);

  const renderImg = () => {
    let productImage = filter(included, iteratee({ type: "product_image" }));

    if (
      productImage &&
      productDetails &&
      productDetails.data &&
      productDetails.data.relationships
    ) {
      let holder = productDetails["data"]["relationships"]["images"]["data"];
      if (!isEmpty(holder)) {
        let filtered = productImage.filter(
          (item) => parseInt(item.id) === parseInt(holder && holder[0].id)
        );
        if (filtered && filtered[0]) {
          return (
            <img
              src={filtered[0].attributes.path}
              alt="OTOP Hub Product"
              className="object-contain object-center w-96 h-96"
            />
          );
        }
      }
    }
    return (
      <img
        src={logo}
        alt="OTOP Hub Product"
        className="object-contain object-center w-96 h-96"
      />
    );
  };

  const renderCategory = () => {
    if (productDetails && productDetails.data && productDetails.included) {
      const { included } = productDetails;
      let tags = [];
      let categories = [];
      let productTag = filter(included, iteratee({ type: "tag" }));
      let productCat = filter(included, iteratee({ type: "productcategory" }));

      productTag.forEach((tag) => {
        const { name } = tag.attributes;
        tags.push(name);
      });

      productCat.forEach((tag) => {
        const { name } = tag.attributes;
        categories.push(name);
      });

      return (
        <>
          <div className="border-b flex py-5">
            <p className="content-subheader flex w-1/6">Category:&nbsp;</p>
            <ul className="flex flex-wrap items-center">
              {categories ? (
                categories.map((item) => (
                  <li key={item} className="mx-5">
                    {item}
                  </li>
                ))
              ) : (
                <li>No designated tag(s)</li>
              )}
            </ul>
          </div>
          <div className="border-b flex py-5">
            <p className="content-subheader flex w-1/6">Tags:&nbsp;</p>
            <ul className="flex flex-wrap items-center">
              {tags ? (
                tags.map((item) => (
                  <li key={item} className="mx-5">
                    {item}
                  </li>
                ))
              ) : (
                <li>No designated tag(s)</li>
              )}
            </ul>
          </div>
        </>
      );
    }
    return <p className="content-subheader">&nbsp;</p>;
  };

  const renderStoreName = () => {
    if (sellerDetails && sellerDetails.data && productDetails.included) {
      const { attributes } = sellerDetails.data;
      let store = filter(productDetails.included, iteratee({ type: "store" }));
      if (attributes && store && store[0]) {
        return (
          <div className="flex items-center">
            <img
              src={
                store[0].attributes.logo_path
                  ? store[0].attributes.logo_path
                  : logo
              }
              alt="OTOP Hub Product"
              className="object-cover object-center w-12 h-12"
            />
            <div className="pl-3">
              <h1>{store[0].attributes.name}</h1>
              <p>
                {attributes.firstname} {attributes.lastname}
              </p>
            </div>
          </div>
        );
      }
    }
  };

  const onAddToCart = async () => {
    if (qty > 0) {
      productDetails.quantity = qty;
      dispatch(cartOtopProductAdd(productDetails));
      notification.success({ message: "Added to cart!" });
    } else {
      notification.error({ message: "quantity is required!" });
    }
  };

  const renderDetails = () => {
    if (productDetails && productDetails.data) {
      const { attributes } = productDetails.data;
      return (
        <div className="bg-gray-90 py-5">
          <Card>
            <div className="flex">
              <div className="mr-5">{renderImg()}</div>
              <div className="w-3/4">
                <div className="h-42 overflow-hidden">
                  <p className="mb-5 w-full lg:text-2xl">
                    {attributes && attributes.name}
                  </p>
                  {/* <p className="text-lg font-semibold text-brown-10">
                    ₱&nbsp;{attributes && attributes.price}
                  </p> */}
                </div>
                <div className="border-b flex py-5">
                  <p className="content-subheader flex w-1/6">
                    Otop Hub Store:&nbsp;
                  </p>
                  {renderStoreName()}
                </div>
                {renderCategory()}

                {/* <div className="flex items-baseline my-5">
                  <p className="content-subheader mr-5">Quantity</p>
                  <InputNumber size="large" min={1} onChange={onChange} />
                  {qty > 0 ? (
                    <Button className="w-1/2 ml-auto" onClick={onAddToCart}>
                      <span>Add to Cart</span>
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="w-1/2 ml-auto"
                      onClick={onAddToCart}
                    >
                      <span>Add to Cart</span>
                    </Button>
                  )}
                </div> */}
                <div className="py-5">
                  <p className="content-subheader flex w-1/6">
                    Product Details
                  </p>
                  <p className="mb-5 w-full">
                    {attributes && attributes.description}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <StandardContainer>
      <div className="main-container">
        <div loading={loading} className="content-bg">
          {renderDetails()}
          <p className="content-header-left my-5">From the same store</p>
          <div className="my-5">
            <ListFeaturedProducts
              productsFromStore={productsFromStore}
              products={products}
              loading={loading}
              type={"seller"}
            />
          </div>

        </div>
      </div>
    </StandardContainer>
  );
};
export default ProductShow;