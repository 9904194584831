import React, { useEffect } from "react";
import { Form, Button, Input } from "antd";
import { useDispatch } from "react-redux";

import FormWrapper from "../../../shared/components/FormWrapper";

function FormSearchFilterSpeaker(props) {
  const { getSpeakerList } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    // console.log("Success:", values);
    dispatch(getSpeakerList(values, null));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onResetField = () => {
    form.resetFields();
    dispatch(getSpeakerList(null, 0));
  };

  useEffect(() => {
    onResetField()
  }, [])
  return (
    <>
      <h1 className="content-subheader">Search Filters</h1>
      <FormWrapper>
        <Form
          name="searchFilterSpeaker"
          form={form}
          className="form-flex"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="relative w-full lg:w-1/4 mr-2">
            <Form.Item
              label="Search"
              name="keyword"
              rules={[{ required: false, message: "Please input keyword!" }]}
            >
              <Input placeholder="Enter Keyword" />
            </Form.Item>
            <i className="fas fa-search text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i>
          </div>

          <div className="w-full lg:w-1/4">
            <Button className="btn-main" htmlType="submit">
              <span>Apply Filter</span>
            </Button>
            <Button className="btn-main ml-2" onClick={() => onResetField()}>
              <span>Reset Filter</span>
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormSearchFilterSpeaker;