import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, notification } from "antd";
import { decode } from "he";
import { isEmpty } from "lodash";

import placeholder from "../../../shared/assets/images/placeholder.jpg";
import StandardLanding from "../../../shared/components/StandardLanding";
import { showPartnerDetails, showPartnerDetailsExhibitor } from "../actions"; 
import { getGuestPartnerDetails } from "../../guest/actions";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";

function Landing(props) {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { partnerDetails, partnerDetailsExhibitor } = state.partner;
  const { partnerDetailsGuest } = state.guest;
  const loadingParner = state.partner.loading;
  const [deets, setDeets] = useState({});
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");
  console.log("deets", deets)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isExhibitor === "true") {
      dispatch(showPartnerDetailsExhibitor(id));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      dispatch(showPartnerDetails(id));
    }
  }, []);

  useEffect(() => {
      if(!token) {
        dispatch(getGuestPartnerDetails(id))
      }
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      !isEmpty(partnerDetails) && setDeets(partnerDetails);
    }

    if (isExhibitor === "true") {
      !isEmpty(partnerDetailsExhibitor) && setDeets(partnerDetailsExhibitor);
    }

    if (!token) {
      !isEmpty(partnerDetailsGuest) && setDeets(partnerDetailsGuest);
    }
  }, [partnerDetails, partnerDetailsExhibitor, partnerDetailsGuest]);

  // useEffect(() => {
  //     if(token === null) {
  //         notification.warning({  message: 'Could not access.', description: 'To view this, you need to login first!' })
  //         return props.history.push('/partners')
  //     }
  // }, [])

  const renderDetails = (desc) => {
    let holder = desc.replace(/<\/?[^>]+(>|$)/g, "");
    let temp = holder;
    let temp2 = decode(temp);

    return temp2;
  };

  const renderPartnerParticipant = () => {
    return (
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="flex flex-col lg:flex-row justify-center items-center">
              <div className="w-full lg:w-1/2 h-full">
                <div className="w-full h-full rounded-lg">
                  <CardWrapperNoBg>
                    <Card bordered={false} loading={loadingParner}>
                      <img
                        src={
                          partnerDetails.thumbnail
                            ? partnerDetails.thumbnail.full_path
                            : placeholder
                        }
                        alt="Virtual Trade Expo Ph"
                        className="lg:h-96 w-full object-contain object-center rounded"
                      />
                    </Card>
                  </CardWrapperNoBg>
                </div>
              </div>
              <div className="w-full lg:w-1/2 lg:ml-10 mt-10 lg:mt-0">
                <CardWrapperNoBg>
                  <Card bordered={false} loading={loadingParner}>
                    <h1 className="text-red-20 text-4xl uppercase">
                      {partnerDetails.name}
                    </h1>

                    <div className="flex py-5">
                      <div className="bg-blue-20 w-1 h-10 mr-3">&nbsp;</div>
                      <div className="text-gray-40 font-semibold flex items-center">
                        {partnerDetails.description
                          ? renderDetails(partnerDetails.description)
                          : ""}
                      </div>
                    </div>
                    {partnerDetails.website_link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={partnerDetails.website_link}
                        className="bg-red-20 text-white p-3 rounded-lg mt-10"
                      >
                        <i className="fas fa-globe"></i>&nbsp;
                        <span>Visit Website</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </Card>
                </CardWrapperNoBg>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
    );
  };

  const renderPartnerExhibitor = () => {
    return (
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="flex flex-col lg:flex-row justify-center items-center">
              <div className="w-full lg:w-1/2 h-full">
                <div className="w-full h-full rounded-lg">
                  <CardWrapperNoBg>
                    <Card bordered={false} loading={loadingParner}>
                      <img
                        src={
                          partnerDetailsExhibitor.thumbnail
                            ? partnerDetailsExhibitor.thumbnail.full_path
                            : placeholder
                        }
                        alt="Virtual Trade Expo Ph"
                        className="lg:h-96 w-full object-cover object-center rounded"
                      />
                    </Card>
                  </CardWrapperNoBg>
                </div>
              </div>
              <div className="w-full lg:w-1/2 lg:ml-10 mt-10 lg:mt-0">
                <CardWrapperNoBg>
                  <Card bordered={false} loading={loadingParner}>
                    <h1 className="text-red-20 text-4xl uppercase">
                      {partnerDetailsExhibitor.name}
                    </h1>

                    <div className="flex py-5">
                      <div className="bg-blue-20 w-1 h-10 mr-3">&nbsp;</div>
                      <div className="text-gray-40 font-semibold flex items-center">
                        {partnerDetailsExhibitor.description
                          ? renderDetails(partnerDetailsExhibitor.description)
                          : ""}
                      </div>
                    </div>
                    {partnerDetailsExhibitor.website_link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={partnerDetailsExhibitor.website_link}
                        className="bg-red-20 text-white p-3 rounded-lg mt-10"
                      >
                        <i className="fas fa-globe"></i>&nbsp;
                        <span>Visit Website</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </Card>
                </CardWrapperNoBg>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
    );
  };

  const renderPartner = () => {
    if (
      partnerDetails &&
      partnerDetails.description &&
      partnerDetails.thumbnail
    ) {
      return renderPartnerParticipant();
    }
    if (
      partnerDetailsExhibitor &&
      partnerDetailsExhibitor.description &&
      partnerDetailsExhibitor.thumbnail
    ) {
      return renderPartnerExhibitor();
    }
  };

  return (
    <>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO PARTNERS
        </h1>
      </div>
      {renderPartner()}
    </>
  );
}

export default Landing;