import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Skeleton, Radio } from 'antd';
import { decode } from 'he';
import { isEmpty } from 'lodash';
import ReactImageMagnify from '@vorld/react-image-magnify';

import StandardLanding from '../../../shared/components/StandardLanding';
import expo1 from '../../../shared/assets/images/img/expo/expo1.png';
import {
  showExhibitorProductDetails,
  showExhibitorProductDetailsMerchant,
} from '../../exhibitor/actions';
import { getGuestExhibitorProductShow } from '../../guest/actions';
import CardWrapperNoBg from '../../../shared/components/CardWrapperNoBg';
// import FormQuantity from '../../cart/components/FormQuantity';
import FormInquire from '../../cart/components/FormInquire';

function ExhibitorProductShow(props) {
  //  console.log("PROPS",  props)
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { exhibitorProduct, exhibitorProductShow, loadingProduct } =
    state.exhibitor;
  const { exhibitorProductDetails } = state.guest;
  const { product_id } = props.match.params;
  let isExhibitor = localStorage.getItem('isExhibitor');
  let token = localStorage.getItem('token');
  const [productDetails, setProductDetails] = useState({});
  const [activeImage, setActiveImage] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [hasColor, setHasColor] = useState(false);
  const [hasSize, setHasSize] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === 'false') {
      dispatch(showExhibitorProductDetails(product_id));
    }
  }, [product_id]);

  useEffect(() => {
    if (isExhibitor === 'true') {
      dispatch(showExhibitorProductDetailsMerchant(product_id));
    }
  }, [product_id]);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestExhibitorProductShow(product_id));
    }
  }, [product_id]);

  useEffect(() => {
    if (isExhibitor === 'false') {
      !isEmpty(exhibitorProduct)
        ? setProductDetails(exhibitorProduct)
        : setProductDetails([]);
    }
  }, [exhibitorProduct]);

  useEffect(() => {
    if (isExhibitor === 'true') {
      !isEmpty(exhibitorProductShow)
        ? setProductDetails(exhibitorProductShow)
        : setProductDetails([]);
    }
  }, [exhibitorProductShow]);

  useEffect(() => {
    if (!token) {
      !isEmpty(exhibitorProductDetails)
        ? setProductDetails(exhibitorProductDetails)
        : setProductDetails([]);
    }
  }, [exhibitorProductDetails]);

  // useEffect(() => {
  //   if (token === null) {
  //     notification.warning({
  //       message: "Could not access.",
  //       description: "To view this, you need to login first!",
  //     });
  //     return props.history.push("/exhibitors");
  //   }
  // }, []);

  useEffect(() => {
    if (productDetails) {
      if (!isEmpty(productDetails.sizes)) {
        setHasSize(true);
      } else {
        setHasSize(false);
      }
      if (!isEmpty(productDetails.color)) {
        setHasColor(true);
      } else {
        setHasColor(false);
      }
    }
  }, [productDetails]);

  useEffect(() => {
    if (productDetails && productDetails.images) {
      const { data } = productDetails.images;
      setProductImages(data);
      setActiveImage(data[0]);
    }
  }, [productDetails]);

  const handleImageClick = (itemId) => {
    if (productImages) {
      let activeImage = productImages.filter((item) => item.id === itemId);
      setActiveImage(activeImage[0]);
    }
  };

  const renderTrainingImages = () => {
    if (!isEmpty(productImages)) {
      return productImages.map((item) => (
        <img
          key={item.id}
          src={item.filename ? item.full_path : expo1}
          alt="Virtual Trade Expo Ph"
          className="h-16 lg:h-24 w-24 object-cover object-center rounded px-1"
          onClick={() => handleImageClick(item.id)}
        />
      ));
    }
  };

  const renderDisplayImages = () => {
    if (!isEmpty(productImages)) {
      return (
        <>
          {loadingProduct ? (
            <Skeleton.Image />
          ) : (
            <>
              <div>
                <ReactImageMagnify
                  className="h-56 md:h-80 lg:h-96 w-full object-cover object-center rounded"
                  {...{
                    smallImage: {
                      alt: 'Virtual Trade Expo Ph',
                      isFluidWidth: true,
                      src: activeImage.filename ? activeImage.full_path : expo1,
                    },
                    largeImage: {
                      src: activeImage.filename ? activeImage.full_path : expo1,
                      width: 1200,
                      height: 1800,
                    },
                  }}
                />
              </div>
              <div className="flex w-full overflow-auto mt-2 lg:mt-5">
                {renderTrainingImages()}
              </div>
            </>
          )}
        </>
      );
    }
  };

  const renderDetails = (desc) => {
    let holder = desc.replace(/<\/?[^>]+(>|$)/g, '');
    let temp = holder;
    let temp2 = decode(temp);

    return temp2;
  };

  const onChangeColor = (e) => {
    setSelectedColor(e.target.value);
  };

  const onChangeSize = (e) => {
    setSelectedSize(e.target.value);
  };

  const renderProduct = () => {
    let sizes = productDetails.sizes && productDetails.sizes.split(',');
    let colors = productDetails.color && productDetails.color.split(',');
    return (
      <div className="bg-main-content content-bg">
        <div className="content-space px-5 py-10 lg:p-20">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/3 z-40">
              <div className="w-full h-full rounded-lg">
                <CardWrapperNoBg>
                  <Card bordered={false} loading={loadingProduct}>
                    {/* <img
                      src={productDetails.thumbnail ? productDetails.thumbnail.thumb_path : expo1}
                      alt="Virtual Trade Expo Ph"
                      className="h-64 lg:h-auto w-full object-cover object-center"
                    /> */}
                    {renderDisplayImages()}
                  </Card>
                </CardWrapperNoBg>
              </div>
            </div>
            <div className="w-full lg:w-2/3 lg:ml-10 mt-10 lg:mt-0">
              <CardWrapperNoBg>
                <Card bordered={false} loading={loadingProduct}>
                  <h1 className="lg:text-3xl text-blue-20 font-bold uppercase">
                    {productDetails.name}{' '}
                  </h1>
                  <div className="text-gray-900 mb-10 my-5 font-medium">
                    {productDetails.description
                      ? renderDetails(productDetails.description)
                      : ''}
                  </div>
                  {/* <h3 className="lg:text-2xl text-red-20 mb-5 font-bold uppercase">
                    Php {productDetails.display_price}
                  </h3>
                  <h3 className="lg:text-lg text-red-20 mb-5 font-bold uppercase">
                    {productDetails.stock === '0' ? 'Sold out' : ""}
                  </h3>
                  <h3 className="lg:text-lg text-blue-20 font-bold uppercase">
                    NOTE: SHIPPING FEE IS NOT INCLUDED
                  </h3>
                  <div className="w-full lg:text-xl">
                    {!isEmpty(colors) && (
                      <div className="flex">
                        <div className="w-1/5">Color</div>
                        <div className="w-4/5 flex flex-wrap">
                          <Radio.Group onChange={onChangeColor} size="large">
                            {colors &&
                              colors.map((item, index) => (
                                <Radio.Button value={item} key={index} className="mx-2 px-5 my-1">{item}</Radio.Button>
                              ))}
                          </Radio.Group>
                        </div>
                      </div>
                    )}
                    {!isEmpty(sizes) && (
                      <div className="flex mt-10">
                        <div className="w-1/5">Sizes</div>
                        <div className="w-4/5 flex flex-wrap">
                          <Radio.Group onChange={onChangeSize} size="large">
                            {sizes &&
                              sizes.map((item, index) => (
                                <Radio.Button value={item} key={index} className="mx-2 px-5 my-1">{item}</Radio.Button>
                              ))}
                          </Radio.Group>
                        </div>
                      </div>
                    )}
                    {productDetails.size_chart &&
                      productDetails.size_chart.filename && (
                        <div className="flex mt-10">
                          <div className="w-1/5 mt-10">Size Chart</div>
                          <div className="w-4/5 mt-10 flex">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={productDetails.size_chart.full_path}
                              className="text-blue-20"
                            >
                              Click here to view size chart
                            </a>
                          </div>
                        </div>
                      )}
                  </div> */}
                  {/* <div className="flex flex-col lg:flex-row w-full mt-10">
                    {(isExhibitor === "false" || !token) && (
                      <div className="w-full lg:w-1/2">
                        <FormQuantity
                          productDetails={productDetails}
                          history={props.history}
                          hasColor={hasColor}
                          hasSize={hasSize}
                          selectedSize={selectedSize}
                          selectedColor={selectedColor}
                        />
                      </div>
                    )}
                    {isExhibitor === "false" && (

                    )}
                  </div> */}
                  <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:mr-5">
                    {isExhibitor === 'true' ? null : (
                      <FormInquire productDetails={productDetails} />
                    )}
                  </div>
                </Card>
              </CardWrapperNoBg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          EXPO PRODUCTS
        </h1>
      </div>
      <StandardLanding>{renderProduct()}</StandardLanding>
    </>
  );
}

export default ExhibitorProductShow;
