import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { isEmpty } from "lodash";

function Chart(props) {
  const { summary } = props;
  const [monthlySalesKey, setMonthlySalesKey] = useState([]);
  const [monthlySalesValue, setMonthlySalesValue] = useState([]);

  useEffect(() => {
    if (summary) {
      renderMonth();
    }
  }, [summary]);

  const renderMonth = () => {
    if (!isEmpty(summary) && !isEmpty(summary.monthly_sales)) {
      let salesKey = [],
        salesValue = [];
      summary.monthly_sales.map((item) => {
        for (const [key, value] of Object.entries(item)) {
          salesKey.push(key);
          salesValue.push(value);
          console.log(value)
        }
      });
      setMonthlySalesKey(salesKey);
      setMonthlySalesValue(salesValue);
    }
  };

  const data = {
    labels: monthlySalesKey,
    datasets: [
      {
        label: `Sales for ${moment().format("YYYY")}`,
        data: monthlySalesValue,
        borderColor: ["rgba(54, 162, 235, 0.2)"],
        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
        pointBorderColor: "rgba(54, 162, 235, 0.2)",
        pointBackgroundColor: "rgba(54, 162, 235, 0.2)",
      },
    ],
  };
  return <Line data={data} />;
}

export default Chart;