import React from "react";
import styled from "@emotion/styled";

function TabsWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default TabsWrapper;

const StyledDiv = styled.div({
  width: "100%",
  ".ant-tabs .ant-tabs-nav": {
   backgroundColor: '#FFF !important',
   boxShadow: "0 1px 1px -3px rgba(0, 0, 0, 0.1), 0 2px 2px -2px rgba(0, 0, 0, 0.01)",
   padding: '0px 10px 10px 20px',
  },
  ".ant-tabs-top .ant-tabs-nav::before": {
   borderBottom: "none !important",
  },
  ".ant-tabs-tab": {
   fontSize: '.9rem', 
  }
})
