import { ORDER } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  orders: [],
  orderDetails: "",
  order: {},
  orderDelete: {},
  orderStatuses: [],
  orderStatusDetails: "",
  orderStatus: {},
  orderStatusDelete: {},
};

const order = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ORDER.ORDER_LIST_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_LIST_SUCCESS:
      return { ...state, loading: false, orders: action.payload };
    case ORDER.ORDER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_READ_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_READ_SUCCESS:
      return { ...state, loading: false, orderDetails: action.payload };
    case ORDER.ORDER_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, order: action.payload };
    case ORDER.ORDER_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_DELETE_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_DELETE_SUCCESS:
      return { ...state, loading: false, orderDelete: action.payload };
    case ORDER.ORDER_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_STATUS_LIST_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_STATUS_LIST_SUCCESS:
      return { ...state, loading: false, orderStatuses: action.payload };
    case ORDER.ORDER_STATUS_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_STATUS_READ_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_STATUS_READ_SUCCESS:
      return { ...state, loading: false, orderStatusDetails: action.payload };
    case ORDER.ORDER_STATUS_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_STATUS_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_STATUS_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, orderStatus: action.payload };
    case ORDER.ORDER_STATUS_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ORDER.ORDER_STATUS_DELETE_START:
      return { ...state, loading: true, error: "" };
    case ORDER.ORDER_STATUS_DELETE_SUCCESS:
      return { ...state, loading: false, orderStatusDelete: action.payload };
    case ORDER.ORDER_STATUS_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default order;