import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import StandardLanding from '../../../shared/components/StandardLanding';
import FormSearchFilter from '../components/FormSearchFilter';
import { getExhibitorList } from '../actions';
import { readProfile } from '../../profile/actions';
import { getCategoryList, getRegionList } from '../../general/actions';
import {
  getExpoHallExhibitorList,
  getExpoHallExhibitorListMerchant,
} from '../../expo/actions';
import { getGuestExpoHallExhibitorList } from '../../guest/actions';
import ListExhibitor from '../components/ListExhibitor';

function Landing(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { slug } = props.match.params;
  const { path } = props.match;
  const exhibitorsAll = state.exhibitor.exhibitors;
  const { total } = state.exhibitor;
  const { categories, regions } = state.general;
  const loadingExhibitor = state.exhibitor.loading;
  const { exhibitors, merchants, totalExhibitors, totalMerchants } = state.expo;
  const { totalGuestExhibitors, expoHallExhibitors } = state.guest;
  const [exhibitorList, setExhibitorList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    readProfile();
    dispatch(getCategoryList());
    dispatch(getRegionList());
  }, []);

  useEffect(() => {
    dispatch(getExhibitorList(null, total));
  }, []);

  useEffect(() => {
    if (exhibitorsAll) {
      setExhibitorList(exhibitorsAll);
    }
  }, [exhibitorsAll, total]);

  return (
    <React.Fragment>
      <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
        <div className="flex justify-center items-center px-5 lg:px-20 h-full">
          <h1 className="text-white font-medium text-3xl z-10">BOOTH</h1>
        </div>
        <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="mb-20">
              <FormSearchFilter
                // industries={industries}
                categories={categories}
                regions={regions}
                getExhibitorList={getExhibitorList}
                getExpoHallExhibitorList={getExpoHallExhibitorList}
                getExpoHallExhibitorListMerchant={
                  getExpoHallExhibitorListMerchant
                }
                getGuestExpoHallExhibitorList={getGuestExpoHallExhibitorList}
                total={total}
                totalExhibitors={totalExhibitors}
                totalMerchants={totalMerchants}
                totalGuestExhibitors={totalGuestExhibitors}
                path={path}
                slug={slug}
              />
            </div>
            <ListExhibitor
              regions={regions}
              exhibitors={exhibitorList}
              loadingExpo={loadingExhibitor}
              setExhibitorList={setExhibitorList}
            />
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default Landing;
