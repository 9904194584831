import { CREATE } from './action';

const INITIAL_STATE = {
    loading: false, 
    error: '', 
    seller: {},
    sellerDetails : "",
    sellerList: []
};

const manageSeller = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CREATE.OTOP_SELLER_LIST_START:
            return { ...state, loading: true, error: "" };
        case CREATE.OTOP_SELLER_LIST_SUCCESS:
            return { ...state, loading: false, sellerList: action.payload };
        case CREATE.OTOP_SELLER_LIST_ERROR:
            return { ...state, loading: false, error: action.payload };

        case CREATE.CREATE_OTOP_SELLER_START:
            return { ...state, loading: true, error: '' }
        case CREATE.CREATE_OTOP_SELLER_SUCCESS:
            const payloadDataReg = action.payload;
            payloadDataReg.token = localStorage.getItem('token')
            return { ...state, loading: false, seller: payloadDataReg }
        case CREATE.CREATE_OTOP_SELLER_ERROR:
            return { ...state, loading: false, error: action.payload }

        default: 
            return state;
    }
}

export default manageSeller;