import { DISBURSEMENT } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  disbursements: [],
  disbursementDetails: "",
  disbursement: {},
  disbursementDelete: {},
};

const disbursement = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case DISBURSEMENT.DISBURSEMENT_LIST_START:
      return { ...state, loading: true, error: "" };
    case DISBURSEMENT.DISBURSEMENT_LIST_SUCCESS:
      return { ...state, loading: false, disbursements: action.payload };
    case DISBURSEMENT.DISBURSEMENT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DISBURSEMENT.DISBURSEMENT_READ_START:
      return { ...state, loading: true, error: "" };
    case DISBURSEMENT.DISBURSEMENT_READ_SUCCESS:
      return { ...state, loading: false, disbursementDetails: action.payload };
    case DISBURSEMENT.DISBURSEMENT_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DISBURSEMENT.DISBURSEMENT_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case DISBURSEMENT.DISBURSEMENT_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, disbursement: action.payload };
    case DISBURSEMENT.DISBURSEMENT_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DISBURSEMENT.DISBURSEMENT_DELETE_START:
      return { ...state, loading: true, error: "" };
    case DISBURSEMENT.DISBURSEMENT_DELETE_SUCCESS:
      return { ...state, loading: false, disbursementDelete: action.payload };
    case DISBURSEMENT.DISBURSEMENT_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_START:
      return { ...state, loading: true, error: "" };
    case DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_SUCCESS:
      return { ...state, loading: false, disbursementTransactions: action.payload };
    case DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default disbursement;