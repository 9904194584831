import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, Button } from 'antd';
import moment from 'moment';
import Avatar from './Avatar';
import ListProduct from '../../exhibitor/components/ListProduct';
// import ExportJsonExcel from 'js-export-excel';

// import avatar from '../../../shared/assets/images/avatar.png';
// import affi from '../../../shared/assets/images/logo/nobg/affi.png';
// import bossClub from '../../../shared/assets/images/logo/nobg/bossClub.png';
// import dti from '../../../shared/assets/images/logo/nobg/dti.png';
// import gabayGuro from '../../../shared/assets/images/logo/nobg/gabayGuro.png';
// import grab from '../../../shared/assets/images/logo/nobg/grab.png';
// import lbc from '../../../shared/assets/images/logo/nobg/lbc.png';
// import otop from '../../../shared/assets/images/logo/nobg/otop.png';
// import paymaya from '../../../shared/assets/images/logo/nobg/paymaya.png';
// import sulit from '../../../shared/assets/images/logo/nobg/sulit.png';
// import unionBank from '../../../shared/assets/images/logo/nobg/unionBank.png';
// import wbc from '../../../shared/assets/images/logo/nobg/wbc.png';
// import { renderDetails } from '../../../shared/utils';
// import { getTransactionListExhibitor, getTransactionList } from '../actions';
// import TableTransaction from '../../orders/components/TableTransaction';

function Profile(props) {
  // const dispatch = useDispatch();
  const {
    profileDetails,
    loading,
    profileDetailsExhibitor,
    productList,
    exhibitor_id,
    getUserData,
  } = props;
  // const {
  //   transactions,
  //   transactionsExhibitor,
  //   totalTransactions,
  //   totalTransactionExhibitor,
  // } = state.profile;
  const isExhibitor = localStorage.getItem('isExhibitor');

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (isExhibitor === 'true') {
  //     dispatch(getTransactionListExhibitor(totalTransactionExhibitor));
  //   } else {
  //     dispatch(getTransactionList(totalTransactions));
  //   }
  // }, [totalTransactions, totalTransactionExhibitor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const UserProfile = () => {
    const userDetails =
      isExhibitor === 'false' ? profileDetails : profileDetailsExhibitor || {};

    return (
      <div className="flex">
        <div className="w-full lg:w-3/4 flex flex-col lg:flex-row">
          <Avatar
            autoSave={true}
            className="w-full lg:w-auto lg:mr-10"
            profileDetails={profileDetails}
            profileDetailsExhibitor={profileDetailsExhibitor}
          />
          <div className="w-full lg:w-2/3 flex flex-col">
            <div className="flex items-center">
              <h1 className="text-blue-20 text-3xl mr-3">
                {isExhibitor === 'false' ? (
                  <>
                    {userDetails?.firstname} {userDetails?.lastname}
                  </>
                ) : (
                  userDetails?.company_name
                )}
              </h1>
              {isExhibitor === 'false' && (
                <div
                  className="inline btn rounded py-3 px-5 mr-5"
                  style={{ backgroundColor: '#52c41a', color: '#fff' }}
                >
                  ACTIVE
                </div>
              )}
            </div>
            <div className="flex flex-col">
              {/* {isExhibitor === 'false' && (
                <div className="flex items-center my-3 lg:text-xl">
                  <i className="text-red-20 fas fa-bullhorn mr-2"></i>
                  <h3 className="text-red-20">
                    Joined:{' '}
                    {userDetails?.date_created?.date_db
                      ? moment(userDetails?.date_created?.date_db).format(
                          'MMMM D, YYYY'
                        )
                      : '---/---/'}
                  </h3>
                </div>
              )} */}
              <div className="flex items-center my-3 lg:text-xl">
                <i className="text-red-20 fas fa-home mr-2"></i>
                <h3 className="text-red-20">
                  {isExhibitor === 'false'
                    ? userDetails?.address || '---/---/'
                    : userDetails.business_info?.address || '---/---/'}
                </h3>
              </div>
              <div className="lg:flex">
                {isExhibitor === 'false' && (
                  <div className="flex items-center my-3 lg:text-xl lg:mr-10">
                    <i className="text-red-20 fas fa-envelope mr-2"></i>
                    <h3 className="text-red-20">
                      {userDetails?.email ?? '---/---/'}
                    </h3>
                  </div>
                )}
                <div className="flex items-center my-3 lg:text-xl">
                  <i className="text-red-20 fas fa-phone mr-2"></i>
                  <h3 className="text-red-20">
                    {userDetails?.contact_number ?? '---/---/'}
                  </h3>
                </div>
              </div>
              {isExhibitor === 'true' && (
                <div className="flex mb-10">
                  {userDetails?.fb_link && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={userDetails.fb_link}
                      className="facebook mx-1 rounded-lg"
                    >
                      <i className="fa" style={{ fontSize: '2em' }}>
                        &#xf09a;
                      </i>
                      {/* <i className="fab fa-facebook fa-2x"></i> */}
                    </a>
                  )}
                  {userDetails?.twitter_link && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={userDetails.twitter_link}
                      className="twitter mx-1 rounded-lg"
                    >
                      <i className="fa" style={{ fontSize: '2em' }}>
                        &#xf099;
                      </i>
                      {/* <i className="fab fa-twitter fa-2x"></i> */}
                    </a>
                  )}
                  {userDetails?.gmail_link && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={userDetails.gmail_link}
                      className="gmail mx-1 rounded-lg"
                    >
                      <i className="fa" style={{ fontSize: '2em' }}>
                        &#xf1a0;
                      </i>
                      {/* <i className="fab fa-google fa-2x"></i> */}
                    </a>
                  )}
                  {userDetails?.instagram_link && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={userDetails.instagram_link}
                      className="instagram mx-1 rounded-lg"
                    >
                      <i className="fa" style={{ fontSize: '2em' }}>
                        &#xf16d;
                      </i>
                      {/* <i className="fab fa-instagram fa-2x"></i> */}
                    </a>
                  )}
                  {userDetails?.contact_number && (
                    <div className="contact rounded-lg bg-red-20 ml-1">
                      <i className="fas fa-phone mr-1"></i>
                      <span className="lg-title" style={{ margin: 0 }}>
                        {userDetails.contact_number}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 flex flex-col lg:flex-row lg:justify-end pt-3">
          <div>
            <Link
              to={`/${
                isExhibitor === 'true' ? 'exhibitor' : 'participant'
              }/profile/${userDetails.id}/edit`}
              className="inline btn btn-red rounded py-3 px-5 mr-5"
            >
              <i className="fas fa-pen mr-2"></i>
              Edit Profile
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card loading={loading} className="rounded-2xl p-1 lg:p-10 mt-5 w-full">
        <UserProfile />
      </Card>
    </>
  );
}

export default Profile;
