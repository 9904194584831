import React from 'react';
import styled from '@emotion/styled';

function StandardLanding(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default StandardLanding;

const StyledDiv = styled.div({
  '.content-bg': {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    '.content-space': {
      '.content-header': {
        color: '#CD1F30',
        fontSize: '2.5rem',
        lineHeight: '2.25rem',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: '2rem',
        textTransform: 'uppercase',
      },
      '.content-header-left': {
        color: '#CD1F30',
        fontSize: '2.5rem',
        lineHeight: '2.25rem',
        fontWeight: '500',
        textAlign: 'left',
        marginBottom: '2rem',
        textTransform: 'uppercase',
      },
      '.content-header-white': {
        color: '#FFFFFF',
        fontSize: '2.5rem',
        lineHeight: '2.25rem',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: '2rem',
        textTransform: 'uppercase',
      },
      '.content-subheader': {
        color: '#CD1F30',
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: '500',
        marginBottom: '2rem',
      },
      '.lg-title': {
        fontSize: '1rem',
        lineHeight: '2rem',
        fontWeight: '500',
        marginBottom: '2rem',
      },
      '.md-title': {
        fontSize: '0.9rem',
        lineHeight: '2rem',
        fontWeight: '500',
        marginBottom: '2rem',
      },
      '.sm-title': {
        fontSize: '0.7rem',
        lineHeight: '2rem',
        fontWeight: '500',
        marginBottom: '2rem',
      },
      '.xs-title': {
        fontSize: '0.5rem',
        lineHeight: '2rem',
        fontWeight: '500',
        marginBottom: '2rem',
      },
    },
  },
  '.content-crop': {
    height: 'calc(100vh - 80px) !important',
  },
  '.ant-list-pagination': {
    textAlign: 'left',
    display: 'flex',
    '.ant-pagination, li, button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    'li, button': {
      width: '40px',
      height: '50px',
      borderRadius: '8px',
      border: 'none',
    },
    '.ant-pagination-item': {
      a: {
        color: '#AE1C27',
      },
      '&-active': {
        backgroundColor: '#AE1C27',
        a: {
          color: '#fff',
        },
      },
    },
    '.ant-pagination-prev, .ant-pagination-next': {
      button: {
        color: '#fff',
        backgroundColor: '#AE1C27',
      },
    },
  },
});
