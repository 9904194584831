import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { register, registerExhibitor } from '../actions';
import PageHeader from '../../layout/containers/PageHeader';
import PageFooter from '../../layout/containers/PageFooter';
import PageContentWrapper from '../../../shared/components/PageContentWrapper';
import FormRegister from '../components/FormRegister';
import FormRegisterExhibitor from '../components/FormRegisterExhibitor';

function Register2(props) {
  const { history } = props;
  const { state } = props.location;
  const store = useSelector((state) => state);
  const { region } = store.auth;
  const [cartData, setCartData] = useState([]);
  const [fromCheckout, setFromCheckout] = useState(null);
  const [accountType, setAccountType] = useState('participant');
  const hasToken = localStorage.getItem('token');

  useEffect(() => {
    if (!isEmpty(state)) {
      const { cartData, fromCheckout } = props.location.state;
      setCartData(cartData);
      setFromCheckout(fromCheckout);
    }
  }, [state]);

  // if (hasToken) window.location.replace('/');

  return (
    <React.Fragment>
      <PageHeader profileDetails={{}} />
      <PageContentWrapper>
        <div>
          <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
            <div className="flex justify-center items-center px-5 lg:px-20 h-full">
              <h1 className="text-white font-medium text-3xl z-10">SIGN UP</h1>
            </div>
            <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
          </div>
          <div className="bg-main-content bg-center bg-cover bg-no-repeat">
            <div className="px-5 py-20 lg:p-20 text-blue-20">
              <h1 className="text-red-10 text-3xl font-semibold mb-10 uppercase font-semibold">
                SIGN UP AS{' '}
                <Select
                  value={accountType}
                  onChange={setAccountType}
                  className="text-red-10 text-3xl font-semibold mb-10 uppercase font-bold"
                  bordered={false}
                >
                  <Select.Option value="participant">PARTICIPANT</Select.Option>
                  <Select.Option value="exhibitor">EXHIBITOR</Select.Option>
                </Select>
              </h1>
              <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
                {accountType === 'participant' ? (
                  <FormRegister
                    register={register}
                    history={history}
                    cartData={cartData}
                    fromCheckout={fromCheckout}
                  />
                ) : (
                  <FormRegisterExhibitor
                    registerExhibitor={registerExhibitor}
                    history={history}
                    region={region}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContentWrapper>
      <PageFooter />
    </React.Fragment>
  );
}

export default Register2;
