import { GUEST } from "./actions";

const INITIAL_STATE = {
  loading: false,
  error: "",
  totalGuestProducts: 0,
  totalGuestExhibitors: 0,
  totalGuestExhibitorProduct: 0,
  
  partnerDetailsGuest: [],
  expoHallExhibitors: [],
  expoHallExhibitorShow: [],
  discussionList: [],
  expoHallDetails: {},
  featuredProducts: [],
  seminarsGuest: [],
  mainSeminarDetails: {},
  exhibitorProductsGuest: [],
  exhibitorProductDetails: {},
  exhibitorDetailsGuest: {},
  attendedExpo: [],
  speakerDetailsGuest: [],
};

const guest = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GUEST.PARTNER_SHOW_START:
      return { ...state, loading: true, error: "" };
    case GUEST.PARTNER_SHOW_SUCCESS:
      return { ...state, loading: false, partnerDetailsGuest: action.payload };
    case GUEST.PARTNER_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.EXPO_HALL_EXHIBITOR_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXPO_HALL_EXHIBITOR_LIST_SUCCESS:
      let totalGuestExhibitors =  action.payload.total;
      return { ...state, loading: false, expoHallExhibitors: action.payload.data, totalGuestExhibitors };
    case GUEST.EXPO_HALL_EXHIBITOR_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case GUEST.EXPO_HALL_EXHIBITOR_SHOW_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXPO_HALL_EXHIBITOR_SHOW_SUCCESS:
      return { ...state, loading: false, expoHallExhibitorShow: action.payload };
    case GUEST.EXPO_HALL_EXHIBITOR_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.DISCUSSION_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.DISCUSSION_LIST_SUCCESS:
      return { ...state, loading: false, discussionList: action.payload };
    case GUEST.DISCUSSION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
      
    case GUEST.EXPO_HALL_SHOW_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXPO_HALL_SHOW_SUCCESS:
      return { ...state, loading: false, expoHallDetails: action.payload };
    case GUEST.EXPO_HALL_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case GUEST.FEATURED_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.FEATURED_PRODUCT_LIST_SUCCESS:
      let totalGuestProducts =  action.payload.total;
      return { ...state, loading: false, featuredProducts: action.payload, totalGuestProducts };
    case GUEST.FEATURED_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.SEMINAR_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.SEMINAR_LIST_SUCCESS:
      return { ...state, loading: false, seminarsGuest: action.payload };
    case GUEST.SEMINAR_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.MAIN_SEMINAR_START:
      return { ...state, loading: true, error: "" };
    case GUEST.MAIN_SEMINAR_SUCCESS:
      return { ...state, loading: false, mainSeminarDetails: action.payload };
    case GUEST.MAIN_SEMINAR_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case GUEST.EXHIBITOR_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXHIBITOR_PRODUCT_LIST_SUCCESS:
      let totalGuestExhibitorProduct =  action.payload.total;
      return { ...state, loading: false, exhibitorProductsGuest: action.payload.data, totalGuestExhibitorProduct };
    case GUEST.EXHIBITOR_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.EXHIBITOR_PRODUCT_SHOW_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXHIBITOR_PRODUCT_SHOW_SUCCESS:
      return { ...state, loading: false, exhibitorProductDetails: action.payload };
    case GUEST.EXHIBITOR_PRODUCT_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.EXHIBITOR_SHOW_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXHIBITOR_SHOW_SUCCESS:
      return { ...state, loading: false, exhibitorDetailsGuest: action.payload };
    case GUEST.EXHIBITOR_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case GUEST.EXHIBITOR_EXPO_LIST_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXHIBITOR_EXPO_LIST_SUCCESS:
      return { ...state, loading: false, attendedExpo: action.payload };
    case GUEST.EXHIBITOR_EXPO_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GUEST.EXHIBITOR_SPEAKER_DETAILS_START:
      return { ...state, loading: true, error: "" };
    case GUEST.EXHIBITOR_SPEAKER_DETAILS_SUCCESS:
      return { ...state, loading: false, speakerDetailsGuest: action.payload };
    case GUEST.EXHIBITOR_SPEAKER_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default guest;