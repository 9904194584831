import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

export default function ModalUser(props) {
  const { isModalVisible, handleOk, handleCancel } = props;
  return (
    <Modal
      title="User Authentication"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={''}
    >
      <p className="mb-3">You Need to login to proceed!</p>
      <div className="w-full flex">
        <Link to="/login" className="w-full text-center btn btn-red mr-2">
          <i className="fas fa-share"></i>&nbsp;
          <span className="text-xs">LOGIN AS PARTICIPANT</span>
        </Link>
        <Link to="/login" className="w-full text-center btn btn-red ml-2">
          <i className="fas fa-share"></i>&nbsp;
          <span className="text-xs">LOGIN AS EXHIBITOR</span>
        </Link>
      </div>
    </Modal>
  );
}
