import { SELLER } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  sellers: "",
  sellerDetails: [],
  stores: "",
  storeDetails: [],
};

const otopSeller = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case SELLER.OTOP_PUBLIC_SELLER_LIST_START:
      return { ...state, loading: true, error: "" };
    case SELLER.OTOP_PUBLIC_SELLER_LIST_SUCCESS:
      return { ...state, loading: false, sellers: action.payload };
    case SELLER.OTOP_PUBLIC_SELLER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
      
    case SELLER.OTOP_PUBLIC_SELLER_READ_START:
      return { ...state, loading: true, error: "" };
    case SELLER.OTOP_PUBLIC_SELLER_READ_SUCCESS:
      return { ...state, loading: false, sellerDetails: action.payload };
    case SELLER.OTOP_PUBLIC_SELLER_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SELLER.OTOP_PUBLIC_STORE_LIST_START:
      return { ...state, loading: true, error: "" };
    case SELLER.OTOP_PUBLIC_STORE_LIST_SUCCESS:
      return { ...state, loading: false, stores: action.payload };
    case SELLER.OTOP_PUBLIC_STORE_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SELLER.OTOP_PUBLIC_STORE_READ_START:
      return { ...state, loading: true, error: "" };
    case SELLER.OTOP_PUBLIC_STORE_READ_SUCCESS:
      return { ...state, loading: false, storeDetails: action.payload };
    case SELLER.OTOP_PUBLIC_STORE_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default otopSeller;