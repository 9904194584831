import React from "react";
import { Link } from "react-router-dom";

import StandardLanding from "../../../shared/components/StandardLanding";

export default function CheckoutStatusLanding(props) {
  console.log("props", props);
  const { pathname } = props.location;

  const renderStatus = () => {
    switch (pathname) {
      case "/checkout/success":
        return (
          <div className="text-center">
            <h1 className="text-red-20 lg:text-4xl uppercase">SUCCESS!</h1>
            <p className="text-lg">
              We have notified the exhibitor to ship out your order.
            </p>
          </div>
        );
      case "/checkout/failure":
        return (
          <div className="text-center">
            <h1 className="text-red-20 lg:text-4xl uppercase">FAILED!</h1>
            <p className="text-lg">
              Your transaction has been failed.
            </p>
          </div>
        );
      case "/checkout/cancel":
        return (
          <div className="text-center">
            <h1 className="text-red-20 lg:text-4xl uppercase">CANCELLED!</h1>
            <p className="text-lg">Your order has been cancelled.</p>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          CHECKOUT STATUS
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20 flex justify-center items-center">
            <div className="bg-white rounded-xl lg:w-1/2 shadow px-10 py-32 w-full">
              {renderStatus()}
              <div className="flex w-full lg:mt-20">
                <Link
                  to="/"
                  className="lg:w-1/2 btn btn-red text-center block py-3 mx-2"
                >
                  <span>Home</span>
                </Link>
                <Link
                  to="/expo"
                  className="lg:w-1/2 btn btn-red text-center block py-3 mx-2"
                >
                  <span>Expo</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}