import { getAuthHeader, getError } from '../../shared/utils';
import { reject } from 'lodash';
import {
  API_ADD_TO_CART,
  API_DELETE_TO_CART,
  API_CART_INDEX,
  API_PRODUCT_INQUIRY_CREATE,
  API_PRODUCT_TRANSACTION_CREATE,
  API_ADD_MULTIPLE_ITEMS_TO_CART,
} from '../../shared/constant/url';

export const CART = {
  CART_ALL: 'CART_ALL',
  CART_ADD: 'CART_ADD',

  CART_LIST_START: 'CART_LIST_START',
  CART_LIST_SUCCESS: 'CART_LIST_SUCCESS',
  CART_LIST_ERROR: 'CART_LIST_ERROR',

  CART_DELETE_START: 'CART_DELETE_START',
  CART_DELETE_SUCCESS: 'CART_DELETE_SUCCESS',
  CART_DELETE_ERROR: 'CART_DELETE_ERROR',

  CART_CREATE_START: 'CART_CREATE_START',
  CART_CREATE_SUCCESS: 'CART_CREATE_SUCCESS',
  CART_CREATE_ERROR: 'CART_CREATE_ERROR',

  CART_MULTIPLE_CREATE_START: 'CART_MULTIPLE_CREATE_START',
  CART_MULTIPLE_CREATE_SUCCESS: 'CART_MULTIPLE_CREATE_SUCCESS',
  CART_MULTIPLE_CREATE_ERROR: 'CART_MULTIPLE_CREATE_ERROR',

  CART_PRODUCT_LIST: 'CART_PRODUCT_LIST',
  CART_PRODUCT_ADD: 'CART_PRODUCT_ADD',
  CART_ADJUST_QTY: 'CART_ADJUST_QTY',
  CART_PRODUCT_REMOVE: 'CART_PRODUCT_REMOVE',

  CART_PROD_LIST: 'CART_PROD_LIST',
  CART_PROD_ADD: 'CART_PROD_ADD',
  CART_PROD_REMOVE: 'CART_PROD_REMOVE',

  CART_CHECKOUT: 'CART_CHECKOUT',
  CART_CHECKOUT_LIST: 'CART_CHECKOUT_LIST',

  INQUIRY_CREATE_START: 'INQUIRY_CREATE_START',
  INQUIRY_CREATE_SUCCESS: 'INQUIRY_CREATE_SUCCESS',
  INQUIRY_CREATE_ERROR: 'INQUIRY_CREATE_ERROR',

  TRANSACTION_CREATE_START: 'TRANSACTION_CREATE_START',
  TRANSACTION_CREATE_SUCCESS: 'TRANSACTION_CREATE_SUCCESS',
  TRANSACTION_CREATE_ERROR: 'TRANSACTION_CREATE_ERROR',
};

export const getCartAll = () => {
  return (dispatch) => {
    dispatch({ type: CART.CART_ALL });
  };
};

export const cartAdd = (values) => {
  return (dispatch) => {
    dispatch({ type: CART.CART_ADD, payload: values.quantity });
  };
};

export const getCartProductList = () => {
  return (dispatch) => {
    dispatch({ type: CART.CART_PRODUCT_LIST });
  };
};

export const cartProductAdd = (values, qty) => {
  return (dispatch) => {
    dispatch({ type: CART.CART_PRODUCT_ADD, payload: values, qty });
  };
};

export const cartProductRemove = (id, callback = null) => {
  return (dispatch) => {
    callback();
    dispatch({ type: CART.CART_PROD_REMOVE, payload: id });
  };
};

export const cartCheckout = (checkout, callback = null) => {
  return (dispatch) => {
    callback();
    dispatch({ type: CART.CART_CHECKOUT, payload: checkout });
  };
};

export const cartCheckoutList = () => {
  return (dispatch) => {
    dispatch({ type: CART.CART_CHECKOUT_LIST });
  };
};

export const deleteCartItem = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CART.CART_DELETE_START });
    let formData = new FormData();
    formData.append('id', id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DELETE_TO_CART, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CART.CART_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: CART.CART_DELETE_ERROR, payload: error });
      });
  };
};

export const transactionCreate = (params, items, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CART.TRANSACTION_CREATE_START });
    let formData = new FormData();
    formData.append('incude', 'detail');
    formData.append('payment_type', params.payment_type);
    formData.append('same_address', params.same_address);
    formData.append('shipping_address_line1', params.shipping_address_line1);
    formData.append('shipping_address_line2', params.shipping_address_line2);
    formData.append('shipping_city', params.shipping_city);
    formData.append('shipping_zipcode', params.shipping_zipcode);
    formData.append('shipping_firstname', params.shipping_firstname);
    formData.append('shipping_lastname', params.shipping_lastname);
    formData.append('shipping_phone', params.shipping_phone);
    formData.append('shipping_email', params.shipping_email);
    if (params.same_address === 'yes') {
      formData.append('billing_address_line1', params.shipping_address_line1);
      formData.append('billing_address_line2', params.shipping_address_line2);
      formData.append('billing_city', params.shipping_city);
      formData.append('billing_zipcode', params.shipping_zipcode);
      formData.append('billing_firstname', params.shipping_firstname);
      formData.append('billing_lastname', params.shipping_lastname);
      formData.append('billing_phone', params.shipping_phone);
      formData.append('billing_email', params.shipping_email);
    } else {
      formData.append('billing_address_line1', params.billing_address_line1);
      formData.append('billing_address_line2', params.billing_address_line2);
      formData.append('billing_city', params.billing_city);
      formData.append('billing_zipcode', params.billing_zipcode);
      formData.append('billing_firstname', params.billing_firstname);
      formData.append('billing_lastname', params.billing_lastname);
      formData.append('billing_phone', params.billing_phone);
      formData.append('billing_email', params.billing_email);
    }
    // if(items) {
    //   const getExhibitors = items.slice().reverse().filter((v,i,a)=>a.findIndex(t=>(t.exhibitor_id === v.exhibitor_id))===i).reverse()
    //   getExhibitors.forEach((exhibitor, i) => {
    //      items.map((item, index) => {
    //         let exhibitor_id = exhibitor['product']['data']['exhibitor']['data']['id']
    //         let item_exhibitor_id = item['product']['data']['exhibitor']['data']['id']
    //        if(exhibitor_id === item_exhibitor_id) {
    //         formData.append(`items[${i}][exhibitor_id]`, exhibitor_id);
    //         formData.append(`items[${i}][item][${index}][product_id]`, item.id);
    //         formData.append(`items[${i}][item][${index}][qty]`, item.quantity);
    //         formData.append(`items[${i}][item][${index}][color]`, item['product']['data']['color']);
    //         formData.append(`items[${i}][item][${index}][sizes]`, item['product']['data']['sizes']);

    //         if(params && params.proof) {
    //           params.proof.map((data) => {
    //             if(parseInt(data.exhibitor_id) === parseInt(exhibitor.exhibitor_id)) {
    //               formData.append(`items[${index}][filepath]`, data.upload.fileList[0].originFileObj);
    //             }
    //           })
    //         }
    //        }
    //     })
    //   });
    // }

    if (items) {
      const getExhibitors = items
        .slice()
        .reverse()
        .filter(
          (v, i, a) =>
            a.findIndex(
              (t) =>
                t['product']['data']['exhibitor_id'] ===
                v['product']['data']['exhibitor_id']
            ) === i
        )
        .reverse();
      getExhibitors.forEach((exhibitor, i) => {
        let exhibitor_id = exhibitor['product']['data']['exhibitor_id'];
        items.map((item, index) => {
          let item_exhibitor_id = item['product']['data']['exhibitor_id'];
          if (exhibitor_id === item_exhibitor_id) {
            formData.append(`items[${i}][exhibitor_id]`, item_exhibitor_id);
            formData.append(
              `items[${i}][item][${index}][product_id]`,
              item.product_id
            );
            formData.append(`items[${i}][item][${index}][qty]`, item.quantity);
            formData.append(
              `items[${i}][item][${index}][color]`,
              item['product']['data']['color']
            );
            formData.append(
              `items[${i}][item][${index}][sizes]`,
              item['product']['data']['sizes']
            );
          }
        });
        if (params && params.proof) {
          params.proof.map((data) => {
            if (parseInt(data.exhibitor_id) === parseInt(exhibitor_id)) {
              formData.append(
                `items[${i}][filepath]`,
                data.upload.fileList[0].originFileObj
              );
            }
          });
        }
      });
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_TRANSACTION_CREATE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CART.TRANSACTION_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: CART.TRANSACTION_CREATE_ERROR, payload: error });
      });
  };
};

export const inquiryCreate = (params, product_id, expo_id, callback) => {
  return (dispatch) => {
    dispatch({ type: CART.INQUIRY_CREATE_START });
    let formData = new FormData();
    formData.append('product_id', product_id);
    formData.append('inquiry_type', params.inquiry_type);
    formData.append('inquiry_message', params.inquiry_message);
    if (expo_id) {
      formData.append('expo_id', expo_id);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_INQUIRY_CREATE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CART.INQUIRY_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: CART.INQUIRY_CREATE_ERROR, payload: error });
      });
  };
};

export const getCartList = (callback, per_page) => {
  return (dispatch) => {
    dispatch({ type: CART.CART_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    }
    formData.append('include', 'product');
    formData.append('include', 'paymentoption');
    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_CART_INDEX, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: CART.CART_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: CART.CART_LIST_ERROR, payload: error });
      });
  };
};

export const addToCart = (params, details, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CART.CART_CREATE_START });
    let formData = new FormData();
    formData.append('product_id', details.id);
    formData.append('merchant_id', details.exhibitor_id);
    formData.append('quantity', params.quantity);
    formData.append('total', details.price);
    formData.append('include', 'product');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_TO_CART, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CART.CART_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: CART.CART_CREATE_ERROR, payload: error });
      });
  };
};

export const addMultipleItemsToCart = (callback = null) => {
  let items = JSON.parse(sessionStorage.getItem('cartProducts'));
  return (dispatch) => {
    dispatch({ type: CART.CART_MULTIPLE_CREATE_START });
    let formData = new FormData();
    if (items) {
      items.map((a, b) => {
        formData.append(`items[${b}][item][0][product_id]`, a.id);
        formData.append(`items[${b}][item][0][quantity]`, a.quantity);
        formData.append(`items[${b}][item][0][total]`, a.price);
        formData.append(`items[${b}][merchant_id]`, a.exhibitor_id);
      });
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_MULTIPLE_ITEMS_TO_CART, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CART.CART_MULTIPLE_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: CART.CART_MULTIPLE_CREATE_ERROR, payload: error });
      });
  };
};
