import React from 'react';
import { useDispatch } from "react-redux";
import { isEmpty } from 'lodash';

export default function ListCategory(props) {
  const dispatch = useDispatch();
  const { categories, getOtopProductList } = props;

  const renderCategory = () => {
    if(!isEmpty(categories)) {
      const { data } = categories;
      return !isEmpty(data) && data.map(item => <li key={item.id} onClick={() => onClickCategory(item.id)} className="px-5 whitespace-nowrap cursor-pointer hover:text-red-200">{item.attributes.name}</li>)
    }
  }

  const onClickCategory = (id) => {
    let params = { categories: id }
    dispatch(getOtopProductList(params))
  }

  return (
    <div className="px-5 h-12 flex items-center text-white">
      <ul className="flex">{renderCategory()}</ul>
    </div>
  )
}
