import React from "react";
import styled from "@emotion/styled";

function StandardContainer(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default StandardContainer;

const StyledDiv = styled.div({
  ".main-container" : {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "#f8f8f8", 
    
    ".banner-bg": {
      background: "#f0f0f1",
      height: "31rem",
    },
    
    ".content-bg": {
      width: "80rem",
      
      
      ".content-banner": {
        width: "80rem",

        ".banner-img": {
            objectFit: "cover",
            width: "80rem",
        } 
      },

      ".content-header": {
        color: "#CD1F30",
        fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-header-left": {
        fontSize: "1.5rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "left",
      },
      ".content-subheader-left": {
        color: "#CD1F30",
        fontSize: "1.5rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "left",
        textTransform: "uppercase",
      },
      ".content-header-white": {
        color: "#FFFFFF",
        fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-subheader": {
        color: "rgb(138, 136, 136)",
        fontSize: ".8rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
      },
      ".content-crop": {
        height: "calc(100vh - 95px); !important",
      },
    },
    ".ant-btn": {
      background: "#c96a5e",
      color: "#FFF",
      fontSize: "1.1em",
      borderRadius: "5px",
      border: "0",
      letterSpacing: "1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    },
    ".ant-btn:focus": {
      borderColor: "none",
      outline: "none"
    },
    ".ant-btn:hover": {
      borderColor: "none",
      background: "#E42F30 !important",
      outline: "none",
    },
    ".content-crop": {
      height: "calc(100vh - 95px); !important",
    },
  }
});
