import React from "react";
import styled from "@emotion/styled";

function FooterWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FooterWrapper;

const StyledDiv = styled.div({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "black",

        ".content-bg":{
            width: "80rem",
            background: "black",
            color: "#FFF",
            padding: "3rem 0px"
        }

});
