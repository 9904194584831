import React from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';
import { requestExpo } from '../../general/actions';

const { TextArea } = Input;
function FormCreateExpo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(requestExpo(values, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'SUCCESS') {
        notification.success({ message: `${response.msg}` });
        history.push('/');
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }
  };

  return (
    <>
      <h1 className="content-subheader">EXPO REQUEST FORM</h1>
      <FormWrapper>
        <Form
          name="createExpo"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="flex">
            <Form.Item
              label="First Name"
              name="firstname"
              className="relative w-full lg:w-1/2 mr-5 my-5"
              rules={[
                { required: true, message: 'Please input your first name!' },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <Input placeholder="First Name" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastname"
              className="relative w-full lg:w-1/2 ml-5 my-5"
              rules={[
                { required: true, message: 'Please input your last name!' },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>

          <div className="flex">
            <Form.Item
              label="Email Address"
              name="email"
              className="relative w-full lg:w-1/2 mr-5 my-5"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <Input placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              label="Contact Number"
              name="contact_number"
              className="relative w-full lg:w-1/2 ml-5 my-5"
              rules={[
                {
                  required: true,
                  message: 'Please input your contact number!',
                },
                {
                  pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                  message: 'Please input the correct contact number!',
                },
                {
                  pattern: new RegExp(/[^a-z\s]/g),
                  message: 'Invalid format',
                },
              ]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <Input type="tel" placeholder="Contact Number" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Tell Us About Your Idea"
              name="idea"
              className="relative w-full  my-5"
              rules={[{ required: true, message: 'Please input your idea!' }]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <TextArea placeholder="Description here" />
            </Form.Item>
          </div>

          <div className="flex mt-5">
            <Button className="btn-main mr-5" htmlType="submit">
              <i className="fas fa-check mr-1"></i>
              <span>SUBMIT</span>
            </Button>
            <Button className="btn-main" htmlType="submit">
              <NavLink to="/">
                <i className="fas fa-times mr-1"></i>
                <span>CANCEL</span>
              </NavLink>
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormCreateExpo;
