import React, { useState, useEffect } from 'react';
import { Form, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  updatePhoto,
  readProfile,
  readProfileExhibitor,
  updatePhotoExhibitor,
} from '../actions';

import avatar from '../../../shared/assets/images/avatar.png';

function Avatar(props) {
  const {
    className,
    profileDetails,
    profileDetailsExhibitor,
    autoSave = false,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const isExhibitor = localStorage.getItem('isExhibitor');

  useEffect(() => {
    const currentAvatar =
      isExhibitor === 'false'
        ? profileDetails?.avatar
        : profileDetailsExhibitor?.thumbnail || {};
    setUserAvatar(currentAvatar);
  }, [profileDetails, profileDetailsExhibitor]);

  useEffect(() => {
    if (previewAvatar && autoSave && fileList.length) {
      saveAvatar({ file: { fileList } });
    }
  }, [previewAvatar, fileList]);

  const saveAvatar = (values) => {
    const save = isExhibitor === 'true' ? updatePhotoExhibitor : updatePhoto;
    dispatch(save(values, callback));
  };

  const callback = async (response, error) => {
    if (response) {
      const read = isExhibitor === 'true' ? readProfileExhibitor : readProfile;
      dispatch(read(profileCallback));
    }
  };

  const profileCallback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === 'ACCOUNT_LOGOUT') {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/login');
      }
    }
  };

  const onChangeAvatar = async (file) => {
    if (!file) {
      setPreviewAvatar(null);
      return;
    }

    console.log('cc-file', file);

    let src;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setPreviewAvatar(src);
  };

  const Edit = () => {
    const uploadProps = {
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange: ({ file }) => {
        setFileList([file]);
        onChangeAvatar(file);
      },
      fileList,
    };

    return (
      <div
        className="absolute top-0 right-0 overflow-hidden"
        style={{ borderRadius: '0 0 0 8px' }}
      >
        <Form.Item name="file" className="custom-upload">
          <Upload className="file-upload" {...uploadProps}>
            <i className="bg-red-20 text-white fas fa-pen p-3"></i>
          </Upload>
        </Form.Item>
      </div>
    );
  };

  return (
    <div
      className={`relative bg-gray-300 rounded-2xl overflow-hidden ${className}`}
      style={{ maxWidth: '200px', maxHeight: '200px' }}
    >
      <div style={{ aspectRatio: '1/1' }}>
        <img
          src={
            previewAvatar
              ? previewAvatar
              : userAvatar?.filename
                ? userAvatar?.full_path
                : avatar
          }
          alt="Virtual Trade Expo Ph"
          className="h-full w-full object-cover object-center"
        />
        <Edit />
      </div>
    </div>
  );
}

export default Avatar;
