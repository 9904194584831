import React, { useState, useEffect, Component } from 'react';
import { Form, Input, Button, notification, Modal, Checkbox } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';

import FormWrapper from '../../../shared/components/FormWrapper';
import ModalJoinExpo from '../../expo/components/ModalJoinExpo';

function FormRegister(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [data, setData] = useState({});
  const { slug, item, register, cartData, fromCheckout } = props;
  const [details, setDetails] = useState('');
  const [form] = Form.useForm();
  const [isModalVisibleExpo, setIsModalVisibleExpo] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalpolicy, setModalPolicy] = useState(false);
  const [disable, setDisable] = useState('');
  const [disablepolicy, setDisablePolicy] = useState('');
  let token = localStorage.getItem('token');

  function onChange(e) {
    setDisable(e.target.checked);
  }

  function onChangePolicy(e) {
    setDisablePolicy(e.target.checked);
  }

  const showModalExpo = (data) => {
    setIsModalVisibleExpo(true);
    setData(data);
  };

  const handleOkExpo = () => {
    setIsModalVisibleExpo(false);
  };

  const handleCancelExpo = () => {
    setIsModalVisibleExpo(false);
  };

  const showModal = (value) => {
    setIsModalVisible(true);
    setDetails(value);
  };

  const showModalPolicy = () => {
    setModalPolicy(true);
    setIsModalVisible(false);
  };

  const handleOkPolicy = () => {
    setModalPolicy(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancelPolicy = () => {
    setModalPolicy(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    if (values) {
      showModal(values);
    }
  };

  const onRegister = () => {
    dispatch(register(details, slug, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    // console.log("Response here", response)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'REGISTERED') {
        notification.success({ message: `${response.msg}` });
        history.push({
          pathname: '/login',
          state: { cartData, fromCheckout },
        });
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
        if (response.errors.password) {
          form.setFields([
            {
              name: 'password',
              errors: [`${response.errors.password}`],
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password_confirmation: '',
      contact_number: '',
    });
  }, []);

  return (
    <>
      <FormWrapper>
        <Form
          name="register"
          className="form-flex"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="relative w-full my-3">
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                { required: true, message: 'Please enter your first name!' },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <i className="fas fa-user text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                { required: true, message: 'Please enter your last name!' },
                {
                  pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <i className="fas fa-user text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Invalid email format!' },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
            <i className="fas fa-envelope text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 8,
                  message: 'Password must be minimum 8 of digits.',
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <i className="fas fa-lock text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: 'Please input your password confirmation!',
                },
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <i className="fas fa-lock text-red-20 form-icon"></i>
          </div>
          <div className="relative w-full my-3">
            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: 'Please input your contact number!',
                },
                {
                  pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                  message: 'Please input the correct contact number!',
                },
                {
                  pattern: new RegExp(/[^a-z\s]/g),
                  message: 'Invalid format',
                },
              ]}
            >
              <Input type="tel" placeholder="Contact Number" />
            </Form.Item>
            <i className="fas fa-phone text-red-20 form-icon"></i>
          </div>

          <div className="flex flex-col lg:flex-row justify-between items-center mt-5 w-full">
            <div className="flex flex-col lg:flex-row lg:flex-row justify-between items-center">
              <Button className="btn-main" htmlType="submit">
                <i className="fas fa-sign-in-alt mr-1"></i>
                <span>Register</span>
              </Button>
              {token ? (
                <div></div>
              ) : (
                <div className="flex items-center pl-3 mt-3 lg:mt-0">
                  <h4 className="text-gray-20 pr-1">Have an account?</h4>
                  <Link to="/login" className="text-red-10 font-bold">
                    Sign In
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Form>
      </FormWrapper>
      <Modal
        footer={false}
        width={1000}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="mt-7 p-5 overflow-auto" style={{ height: '30rem' }}>
          <TermsAndConditions />
        </div>
        <div className="flex flex-col justify-center items-center mt-5">
          <Checkbox className="text-xs mb-5" onChange={onChange}>
            I agree to terms and conditions
          </Checkbox>
          <Button
            className="w-96 border-none bg-red-20 rounded-md text-white font-bold hover:bg-red-600 hover:text-white"
            disabled={disable ? false : true}
            onClick={showModalPolicy}
          >
            PROCEED
          </Button>
        </div>
      </Modal>
      <Modal
        footer={false}
        width={1000}
        visible={modalpolicy}
        onOk={handleOkPolicy}
        onCancel={handleCancelPolicy}
      >
        <div className="mt-7 p-5 overflow-auto" style={{ height: '30rem' }}>
          <PrivacyPolicy />
        </div>
        <div className="flex flex-col justify-center items-center mt-5">
          <Checkbox className="text-xs mb-5" onChange={onChangePolicy}>
            I agree to privacy policy
          </Checkbox>
          <Button
            className="w-96 border-none bg-red-20 rounded-md text-white font-bold hover:bg-red-600 hover:text-white"
            disabled={disablepolicy ? false : true}
            onClick={onRegister}
          >
            PROCEED
          </Button>
        </div>
      </Modal>
      <ModalJoinExpo
        isModalVisibleExpo={isModalVisibleExpo}
        handleOkExpo={handleOkExpo}
        handleCancelExpo={handleCancelExpo}
        data={data}
      />
    </>
  );
}

export default FormRegister;
