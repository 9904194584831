import React from 'react'
import { useHistory } from 'react-router-dom'

const PrivateRoute = () => {
    const history = useHistory();
     if(history && history.location && history.location.pathname === '/otop-hub/products'){
        history.push('/otop-hub/products')
    } else if(history && history.location && history.location.pathname === '/otop-hub/login'){
        history.push('/otop-hub/login')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin'){
        history.push('/otop-hub/admin')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin/account'){
        history.push('/otop-hub/admin/account')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin/products'){
        history.push('/otop-hub/admin/products')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin/order-management'){
        history.push('/otop-hub/admin/order-management')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin/create-seller'){
        history.push('/otop-hub/admin/create/seller')
    } else if(history && history.location && history.location.pathname === '/otop-hub/admin/products/:id/details'){
        history.push('/otop-hub/admin/product/:id/details')
    } 

    // vte
    else if(history && history.location && history.location.pathname === '/'){
        history.push('/')
    } else if(history && history.location && history.location.pathname === '/partners'){
        history.push('/partners')
    } else if(history && history.location && history.location.pathname === '/exhibitors'){
        history.push('/exhibitors')
    } else if(history && history.location && history.location.pathname === '/speakers'){
        history.push('/speakers')
    } else if(history && history.location && history.location.pathname === '/cart'){
        history.push('/cart')
    } else if(history && history.location && history.location.pathname === '/exhibitors'){
        history.push('/exhibitors')
    }
    
   
}

export default PrivateRoute
