import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../shared/assets/otop/Sellet/logo.svg';
import FooterWrapper from '../../../../shared/components/otop/FooterWrapper';

const PageFooter = () => {
  return (
    <FooterWrapper>
      <div className="content-bg">
        <div className="content-space flex justify-between items-top border-b pb-5">
          <div className="flex justify-between">
            <div className="w-96 mr-10">
              <img src={logo} alt="" className="h-20 mb-5" />
              <p>
                One Town, One Product (OTOP) is a stimulus program for Micro,
                Small & Medium-scale enterprises (MSMEs) to develop & market a
                locality's priority products.
              </p>
            </div>
            <div className="md-title w-60">
              <p className="text-brown-10 font-semibold">SITE TERMS</p>
              <ul>
                <li>
                  <Link to="/otop-hub/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/otop-hub/terms-and-condition">
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/otop-hub/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-between w-96">
            {/* <div className="md-title">
                            <p className="text-brown-10 font-semibold">HELP CENTER</p>
                            <p>Help Center</p>
                            <p>How to Buy</p>
                            <p>Delivery Details</p>
                            <p>Return Policy</p>
                            <p>Refund Policy</p>
                        </div> */}
            <div className="md-title">
              <p className="text-brown-10 font-semibold">CONTACT</p>
              <p>Telephone: 1-DTI (384)</p>
              <p>Mobile: 0917 834 3330</p>
              <p>ask@dti.gov.ph</p>
              <p>
                Trade & Industry Building 361 Sen. Gil J. Puyat Ave., 1200
                Makati City, Philippines
              </p>
            </div>
          </div>
        </div>
        <div className="font-bold flex justify-between w-full pt-10">
          <p>ⓒ 2021 OTOP HUB | ALL RIGHTS RESERVED</p>
          <div>
            <a target="_blank" href="https://www.facebook.com/OTOPPhilippines">
              <i className="text-xl fa mr-5">&#xf09a;</i>
              {/* <i className="text-xl fab fa-facebook mr-5"></i> */}
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCsW7ShH5QHQ0YzCxPklUgRA"
            >
              <i className="text-xl fa mr-5">&#xf16a;</i>
              {/* <i className="text-xl fab fa-youtube"></i> */}
            </a>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default PageFooter;
