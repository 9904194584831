import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = () => {
  let otopToken = localStorage.getItem('otopToken');
  let userType = localStorage.getItem('userType');

  return (
    <>
      {otopToken ? (
        <div
          className={`${userType === 'admin' && 'bg-green-40'} ${
            userType === 'seller' && 'bg-brown-10'
          } ${
            userType === 'user' && 'bg-blue-20'
          } text-white font-semibold px-32 py-5 items-center flex justify-between`}
        >
          <Link to="/otop-hub/admin">
            <div className="flex items-center">
              <i className="fas fa-home mr-2 text-lg"></i>
              <p>Dashboard</p>
            </div>
          </Link>
          <Link to="/otop-hub/admin/account">
            <div className="flex items-center">
              <i className="far fa-user mr-2 text-lg"></i>
              <p>My Account</p>
            </div>
          </Link>
          <Link to="/otop-hub/admin/products">
            <div className="flex items-center">
              <i className="fas fa-archive mr-2 text-lg"></i>
              <p>Products</p>
            </div>
          </Link>
          {userType === 'user' && (
            <Link to="/otop-hub/admin/tags">
              <div className="flex items-center">
                <i className="fas fa-tag mr-2 text-lg"></i>
                <p>Tags</p>
              </div>
            </Link>
          )}
          {userType === 'user' && (
            <Link to="/otop-hub/admin/categories">
              <div className="flex items-center">
                <i className="fas fa-list mr-2 text-lg"></i>
                <p>Categories</p>
              </div>
            </Link>
          )}
          <Link to="/otop-hub/admin/order-management">
            <div className="flex items-center">
              <i className="fas fa-poll-h mr-2 text-lg"></i>
              <p>Order Management</p>
            </div>
          </Link>
          {/* <Link to="/otop-hub/admin/finance">
            <div className="flex items-center">
              <i className="fas fa-file-invoice-dollar mr-2 text-lg"></i>
              <p>Finance</p>
            </div>
          </Link> */}
          {/* <Link to="/" disabled>
            <div className="flex items-center">
              <i className="fas fa-headset mr-2 text-lg"></i>
              <p>Help Center</p>
            </div>
          </Link> */}
          {(userType === 'admin' || userType === 'user') && (
            <Link to="/otop-hub/admin/manage-users">
              <div className="flex items-center">
                <i className="far fa-user mr-2 text-lg"></i>
                <p>
                  Manage{' '}
                  {userType === 'user' ? 'Regional Admin' : 'OTOP Hub Center'}{' '}
                  Account
                </p>
              </div>
            </Link>
          )}
        </div>
      ) : (
        <div className="md:px-10 p-5 z-50 font-semibold text-white bg-brown-10 items-center flex">
          {/* <p className="mr-5 lg:mr-20">CATEGORIES</p> */}
          <div className="categories w-full flex items-center justify-between overflow-x-auto">
            <Link to="/otop-hub/products">
              <div className="flex items-center w-full mr-20">
                <i className="fas fa-list mr-2 text-lg"></i>
                <p>All Franchise Model</p>
              </div>
            </Link>
            <Link to="/otop-hub/food">
              <div className="flex items-center mr-20 w-full">
                <i className="fas fa-hamburger mr-2 text-lg"></i>
                <p>Processed Food</p>
              </div>
            </Link>
            <Link to="/otop-hub/agri-products">
              <div className="flex items-center mr-28 w-full">
                <i className="fas fa-leaf mr-2 text-lg"></i>
                <p>Agri Based Products</p>
              </div>
            </Link>
            <Link to="/otop-hub/arts">
              <div className="flex items-center mr-20 w-full">
                <i className="fas fa-home mr-2 text-lg"></i>
                <p>Arts & Crafts</p>
              </div>
            </Link>
            <Link to="/otop-hub/fashions">
              <div className="flex items-center mr-28 w-full">
                <i className="fas fa-paint-brush mr-2 text-lg"></i>
                <p>Home and Fashion</p>
              </div>
            </Link>
            <div className="flex items-center">
              <i className="fas fa-user-circle mr-2 text-lg"></i>
              <p>Services</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubHeader;
