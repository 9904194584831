import {
  getAuthHeader,
  authHeader,
  getError,
  axios,
} from "../../../shared/utils";
import {
  API_OTOP_TRANSACTION_ADD,
  API_OTOP_TRANSACTION_LIST,
} from "../../../shared/constant/url";

export const OTOPCART = {
  CART_OTOP_ALL: "CART_OTOP_ALL",
  CART_OTOP_ADD: "CART_OTOP_ADD",

  CART_OTOP_PRODUCT_LIST: "CART_OTOP_PRODUCT_LIST",
  CART_OTOP_PRODUCT_ADD: "CART_OTOP_PRODUCT_ADD",
  CART_OTOP_PRODUCT_REMOVE: "CART_OTOP_PRODUCT_REMOVE",

  CART_CHECKOUT: "CART_CHECKOUT",
  CART_CHECKOUT_LIST: "CART_CHECKOUT_LIST",

  ORDER_CREATE_START: "ORDER_CREATE_START",
  ORDER_CREATE_SUCCESS: "ORDER_CREATE_SUCCESS",
  ORDER_CREATE_ERROR: "ORDER_CREATE_ERROR",

  ORDER_LIST_START: "ORDER_LIST_START",
  ORDER_LIST_SUCCESS: "ORDER_LIST_SUCCESS",
  ORDER_LIST_ERROR: "ORDER_LIST_ERROR",
};

export const getCartAll = () => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_OTOP_ALL });
  };
};

export const cartAdd = (values) => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_OTOP_ADD, payload: values });
  };
};

export const getCartOtopProductList = () => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_OTOP_PRODUCT_LIST });
  };
};

export const cartOtopProductAdd = (values) => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_OTOP_PRODUCT_ADD, payload: values });
  };
};

export const cartOtopProductRemove = (id, callback=null) => {
  return (dispatch) => {
    callback();
    dispatch({ type: OTOPCART.CART_OTOP_PRODUCT_REMOVE, payload: id });
  };
}

export const cartCheckout = (checkout) => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_CHECKOUT, payload: checkout });
  };
};

export const cartCheckoutList = () => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.CART_CHECKOUT_LIST });
  };
};

export const createOrder = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: OTOPCART.ORDER_CREATE_START });
    axios
      .post(`${API_OTOP_TRANSACTION_ADD}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: OTOPCART.ORDER_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OTOPCART.ORDER_CREATE_ERROR,
          payload: getError(error),
        });
      });
  };
};

//   export const addOtopCart = (id, callback = null) => {
//     let params = {
//       "status_id":"1","participant_id":"1","payment_method":"paymaya","products":[{"product_id":"25","quantity":15},{"product_id":"25","quantity":15}]
//     }
//     return (dispatch) => {
//       dispatch({ type: OTOPCART.ORDER_CREATE_START });
//       axios
//         .post(`${API_OTOP_CART_ADD}`, params, authHeader())
//         .then((response) => {
//           callback(response, null);
//           dispatch({
//             type: OTOPCART.ORDER_CREATE_SUCCESS,

// export const getOtopCartList = () => {
//   return (dispatch) => {
//     dispatch({ type: OTOPCART.ORDER_LIST_START });

//     let requestOptions = {
//       method: "GET",
//       headers: getAuthHeader(),
//     };
//     fetch(API_OTOP_TRANSACTION_LIST, requestOptions)
//       .then((response) => response.json())
//       .then((response) => {
//         console.log("response", response);
//         dispatch({
//           type: OTOPCART.ORDER_LIST_SUCCESS,
//           payload: response,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: OTOPCART.ORDER_LIST_ERROR,
//           payload: getError(error),
//         });
//       });
//   };
// };