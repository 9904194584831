import React, { useEffect } from "react";
import FormContact from "../components/FormContact";
import StandardLanding from "../../../shared/components/StandardLanding";

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          Contact Us
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
          
          <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
          <h1 className="content-subheader">CONTACT US</h1>
          <FormContact />
          </div>
         
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default Landing;