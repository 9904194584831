import React from 'react';
import styled from '@emotion/styled';

function PageContentWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default PageContentWrapper;

const StyledDiv = styled.div({
  '.ant-select .ant-select-arrow': {
    color: 'currentColor',
  },
  '.ant-select-single.ant-select-open .ant-select-selection-item': {
    color: 'currentColor',
  },
});
