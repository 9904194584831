import React from "react";
import { Card, List } from "antd";
import { Link } from "react-router-dom";
import CardWrapper from "../../../../shared/components/otop/CardWrapper";
import otop from "../../../../shared/assets/otop/Sellet/logo-1.svg";

const ListStore = (props) => {
  const { loading, stores } = props;
  const { data } = stores;

  return (
    <CardWrapper>
      <List
        grid={{
          gutter: 0,
          xs: 5,
          sm: 5,
          md: 5,
          lg: 5,
          xl: 5,
          xxl: 5,
        }}
        loading={loading}
        pagination={true}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Link to={`/otop-hub/store/${item.id}`}>
              <Card hoverable className="card w-34 h-40">
                <img
                  src={
                    item.attributes.logo_path ? item.attributes.logo_path : otop
                  }
                  className="object-contain h-28 w-full"
                />
                <div className="card-content">
                  <p className="sm-title text-center text-control-1">
                    {item.attributes.name}
                  </p>
                </div>
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </CardWrapper>
  );
};

export default ListStore;