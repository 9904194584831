import { getAuthHeader, getError, axios, authHeader } from "../../../../shared/utils";
import {
  API_OTOP_CATEGORY_LIST,
  API_OTOP_CATEGORY_CREATE,
  API_OTOP_CATEGORY_EDIT,
  API_OTOP_CATEGORY_DELETE,
  API_OTOP_CATEGORY_READ,
} from "../../../../shared/constant/url";

export const CATEGORY = {
  CATEGORY_LIST_START: "CATEGORY_LIST_START",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_ERROR: "CATEGORY_LIST_ERROR",

  CATEGORY_READ_START: "CATEGORY_READ_START",
  CATEGORY_READ_SUCCESS: "CATEGORY_READ_SUCCESS",
  CATEGORY_READ_ERROR: "CATEGORY_READ_ERROR",

  CATEGORY_ADD_EDIT_START: "CATEGORY_ADD_EDIT_START",
  CATEGORY_ADD_EDIT_SUCCESS: "CATEGORY_ADD_EDIT_SUCCESS",
  CATEGORY_ADD_EDIT_ERROR: "CATEGORY_ADD_EDIT_ERROR",

  CATEGORY_DELETE_START: "CATEGORY_DELETE_START",
  CATEGORY_DELETE_SUCCESS: "CATEGORY_DELETE_SUCCESS",
  CATEGORY_DELETE_ERROR: "CATEGORY_DELETE_ERROR",
};

export const getCategoryList = () => {
  return (dispatch) => {
    dispatch({ type: CATEGORY.CATEGORY_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_CATEGORY_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: CATEGORY.CATEGORY_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CATEGORY.CATEGORY_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readCategory = (id) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY.CATEGORY_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_CATEGORY_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: CATEGORY.CATEGORY_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CATEGORY.CATEGORY_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createCategory = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY.CATEGORY_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("name", params.name);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_OTOP_CATEGORY_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CATEGORY.CATEGORY_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: CATEGORY.CATEGORY_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editCategory = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY.CATEGORY_ADD_EDIT_START });

    axios
      .put(`${API_OTOP_CATEGORY_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CATEGORY.CATEGORY_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: CATEGORY.CATEGORY_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteCategory = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY.CATEGORY_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_CATEGORY_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: CATEGORY.CATEGORY_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: CATEGORY.CATEGORY_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

