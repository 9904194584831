import React from "react";
import styled from "@emotion/styled";

function CardWrapperNoBg(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default CardWrapperNoBg;

const StyledDiv = styled.div({
  ".ant-card": {
    background: "transparent !important"
  },
  ".ant-card-body": {
    padding: "0px !important",
  },
  ".card": {
    ".card-img": {
      objectFit: "cover",
      objectPosition: "center",
      borderTopRightRadius: "20px;",
      borderTopLeftRadius: "20px;",
    },
    ".card-content": {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      ".card-title": {
        color: "#D63D4B ",
        fontSize: "1.5rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        textTransform: "capitalize",
      },

      ".card-subtitle": {
        color: "#D63D4B ",
        fontSize: "1.125rem;",
        lineHeight: "1rem;",
        fontWeight: "500",
        fontStyle: "italic",
        textAlign: "center",
      },

      ".card-body": {
        // marginTop: "1rem",
        width: "100%",

        ".card-body-row": {
          flexDirection: "row",
        },

        ".card-body-col": {
          flexDirection: "col",
        },

        ".flex-justify-center": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        },

        ".card-body-icon": {
          color: "#D63D4B",
          fontSize: "1.5rem;",
          lineHeight: "2rem;",
        },
        ".card-body-content": {
          color: "#4E4E4E",
          fontWeight: "500",
        },

        ".btn": {
          fontSize: "1.1em",
          borderRadius: "1rem",
          border: "0",
          letterSpacing: "1px",
          padding: "10px 20px"
        }, 
        ".btn-red": {
          background: "#D63D4B", 
          color: "#FFFFFF",
        }
      },
    },
  },
});
