import React, {useState, useEffect} from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Tabs } from 'antd'

import FormSeller from './FormSeller'
import SellerList from '../components/SellerList'


const Tabulation = (props) => {
    const { createOtopSeller, sellerList, loading } = props; 
    const history = useHistory();  
    const { TabPane } = Tabs;
    const [tab, setTab] = useState("1");
    let match = useRouteMatch();
    let { path } = match;

    useEffect(() => {
        window.scrollTo(0,0);
        setTab("1")
    }, [tab])


    const callback = (key) => {
    }
    return (
        <React.Fragment>
        <Tabs defaultActiveKey={"1"} onChange={callback}>
            <TabPane tab="List of Admin" key="1">
                <SellerList 
                sellerList={sellerList} 
                loading={loading}
                />
            </TabPane>
            <TabPane tab="Create Admin" key="2">
                <FormSeller 
                createOtopSeller={createOtopSeller}
                />
            </TabPane>
        </Tabs>
        </React.Fragment>
    )
}

export default Tabulation
