import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_SALES_RECORD_LIST,
  API_SALES_RECORD_CREATE,
  API_SALES_RECORD_READ,
  API_SALES_RECORD_UPDATE,
  API_SALES_RECORD_DELETE,
} from "../../shared/constant/url";

export const SALES_RECORD = {
  SALES_RECORD_LIST_START: "SALES_RECORD_LIST_START",
  SALES_RECORD_LIST_SUCCESS: "SALES_RECORD_LIST_SUCCESS",
  SALES_RECORD_LIST_ERROR: "SALES_RECORD_LIST_ERROR",

  SALES_RECORD_READ_START: "SALES_RECORD_READ_START",
  SALES_RECORD_READ_SUCCESS: "SALES_RECORD_READ_SUCCESS",
  SALES_RECORD_READ_ERROR: "SALES_RECORD_READ_ERROR",

  SALES_RECORD_ADD_EDIT_START: "SALES_RECORD_ADD_EDIT_START",
  SALES_RECORD_ADD_EDIT_SUCCESS: "SALES_RECORD_ADD_EDIT_SUCCESS",
  SALES_RECORD_ADD_EDIT_ERROR: "SALES_RECORD_ADD_EDIT_ERROR",

  SALES_RECORD_DELETE_START: "SALES_RECORD_DELETE_START",
  SALES_RECORD_DELETE_SUCCESS: "SALES_RECORD_DELETE_SUCCESS",
  SALES_RECORD_DELETE_ERROR: "SALES_RECORD_DELETE_ERROR",

};

export const getSalesRecord = () => {
  return (dispatch) => {
    dispatch({ type: SALES_RECORD.SALES_RECORD_LIST_START });
    let formData = new FormData();

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SALES_RECORD_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: SALES_RECORD.SALES_RECORD_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SALES_RECORD.SALES_RECORD_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readSalesRecord = (id) => {
  return (dispatch) => {
    dispatch({ type: SALES_RECORD.SALES_RECORD_READ_START });
    let formData = new FormData();
    formData.append("sales_id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SALES_RECORD_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: SALES_RECORD.SALES_RECORD_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SALES_RECORD.SALES_RECORD_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createSalesRecord = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALES_RECORD.SALES_RECORD_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("expo_id", params.expo_id);
    formData.append("date", params.date);
    formData.append("cash", params.cash);
    formData.append("under-nego", params["under-nego"]);
    formData.append("booked", params.booked);
 
    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SALES_RECORD_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editSalesRecord = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALES_RECORD.SALES_RECORD_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("sales_id", id);
    formData.append("expo_id", params.expo_id);
    formData.append("date", params.date);
    formData.append("cash", params.cash);
    formData.append("under-nego", params["under-nego"]);
    formData.append("booked", params.booked);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SALES_RECORD_UPDATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteSalesRecord = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALES_RECORD.SALES_RECORD_DELETE_START });
    let formData = new FormData();
    formData.append("sales_id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_SALES_RECORD_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALES_RECORD.SALES_RECORD_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};