import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';

function FormResetPassword(props) {
  const dispatch = useDispatch();
  const { resetPassword, history, resetPasswordExhibitor, query } = props;
  const { pathname } = props.history.location;
  const { email, id } = query;

  const onFinish = (values) => {
    // console.log('Success:', values);
    values['id'] = id;
    values['email'] = email;
    if (pathname === '/exhibitor/reset-password') {
      dispatch(resetPasswordExhibitor(values, callback));
    } else {
      dispatch(resetPassword(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    // console.log("RESPONSE", response)
    // console.log("ERROR HERE", error)
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'RESET_PASSWORD_SUCCESS') {
        notification.success({
          message: `${response.msg}`,
          description: 'Successfully changed your password. You can login now!',
        });
        if (pathname === '/exhibitor/reset-password') {
          history.push('/login');
        } else {
          history.push('/login');
        }
      } else if (statusCode === 'UNAUTHORIZED') {
        notification.warning({ message: `${response.msg}` });
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        name="reset-password"
        className="form-flex"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="relative w-full lg:w-1/2 pl-2 my-3">
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <i className="fas fa-lock text-blue-20 form-icon"></i>
        </div>

        <div className="flex w-full mt-5">
          <Button className="btn-main" htmlType="submit">
            <i className="fas fa-sign-in-alt mr-1"></i>
            <span>Submit</span>
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
}

export default FormResetPassword;
