import React from "react";
import { List, Card, Pagination } from "antd";
import { Link } from "react-router-dom";

import booth2 from "../../../shared/assets/images/icon/booth/booth2.png";
import container from "../../../shared/assets/images/icon/booth/container.png";
import exhibitor from "../../../shared/assets/images/exhibitor.jpg";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";

function ListExhibitors(props) {
  const { exhibitors, loadingExpo } = props;
  const current_page = sessionStorage.getItem("page")

  const renderLogo = (item) => {
    if (item.business_info && item.business_info.logo.filename) {
      return (
        <img
          src={item.business_info.logo.full_path}
          alt="Virtual Trade Expo Ph"
          className="h-20 w-20 object-center object-contain absolute top-0 left-0 p-1"
        />
      );
    } else if (item.thumbnail && item.thumbnail.filename) {
      return (
        <img
          src={item.thumbnail.full_path}
          alt="Virtual Trade Expo Ph"
          className="h-20 w-20 object-center object-contain absolute top-0 left-0 p-1"
        />
      );
    } else {
      return (
        <img
          src={exhibitor}
          alt="Virtual Trade Expo Ph"
          className="h-20 w-20 object-center object-contain absolute top-0 left-0 p-1"
        />
      );
    }
  };

  return (
    <>
      <List
        grid={{
          gutter: 32,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 5,
          xl: 5,
          xxl: 5,
        }}
        pagination={{
          defaultCurrent: current_page,
          onChange: page => {
            sessionStorage.setItem("page", page)
          }
        }}
        dataSource={exhibitors ? exhibitors : []}
        className="mt-10"
        renderItem={(item) => (
          <List.Item>
            <CardWrapperNoBg>
              <Card
                bordered={false}
                className="mt-16 mb-10"
                loading={loadingExpo}
                style={{ background: "transparent !important" }}
              >
                <Link to={`/exhibitors/${item.id}`}>
                  <div className="relative">
                    <div className="absolute top-0 right-0 -mt-3 exhibitor-logo">
                      <div className="relative">
                        <img
                          src={container}
                          alt="PLDT Home Biz"
                          className="h-20 w-20"
                        />
                        {renderLogo(item)}
                      </div>
                    </div>
                    <img
                      src={booth2}
                      alt="PLDT Home Biz"
                      className="h-56 w-auto"
                    />
                    <p className="bg-red-20 text-white px-2 py-1 rounded-lg text-center">
                      {item.company_name}
                    </p>
                  </div>
                </Link>
                <div className="flex justify-center text-center mt-2">
                  {/* <Link
                    to={`/exhibitors/${item.id}`}
                    className="text-white bg-red-20 mx-2 px-5 py-2 rounded-lg block"
                  >
                    <i className="fas fa-share"></i>&nbsp;
                  </Link> */}
                  {item.website_link ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.website_link}
                      className="text-white bg-red-20 mx-2 px-5 py-2 rounded-lg block"
                    >
                      <i className="fas fa-globe"></i>&nbsp;
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Card>
            </CardWrapperNoBg>
          </List.Item>
        )}
      />
    </>
  );
}

export default ListExhibitors;