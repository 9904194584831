import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormTag from "../components/FormTag";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import {
  createTag,
  editTag,
  readTag,
} from "../actions";

function AddEditTag() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector((state) => state);
  const { tagDetails } = state.tag;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(readTag(id));
    }
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg content-crop w-3/4 my-5">
            <FormTag
              createTag={createTag}
              editTag={editTag}
              tagDetails={tagDetails}
              id={id}
            />
        </div>
      </div>
    </StandardContainer>
  );
}
export default AddEditTag;