import React, { useState } from 'react';
import { Form, Button, Input, notification } from 'antd';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';

function FormComment(props) {
  const { mainSeminar, discussionCreate, getDiscussionList } = props;
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    setIsDisabled(true);
    if (mainSeminar) {
      const { id } = mainSeminar;
      console.log('cc-discussionCreate', id, values);
      dispatch(discussionCreate(values, id, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'SUCCESS') {
        notification.success({ message: `${response.msg}` });
      } else if (statusCode === 'ACCOUNT_LOGOUT') {
        notification.error({ message: `${response.msg}` });
        return;
      }
      form.resetFields();
      dispatch(getDiscussionList(mainSeminar?.id));
    } else {
      notification.error({ message: `${error.msg}` });
    }
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="formComment"
          form={form}
          className="form-flex"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="relative w-full lg:w-3/4 mr-2">
            <Form.Item
              label="Message"
              name="comment"
              rules={[
                { required: true, message: 'Please input your comment!' },
              ]}
            >
              <Input.TextArea
                placeholder="Enter Your Message"
                rows={4}
                style={{ paddingLeft: '15px' }}
              />
            </Form.Item>
            {/* <i className="fas fa-comments text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
          </div>
          <Button
            className="btn-main mx-2"
            htmlType="submit"
            disabled={isDisabled}
          >
            <i className="bg-red-20 text-white fas fa-pen mr-3"></i>
            <span>Post Comment</span>
          </Button>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormComment;
