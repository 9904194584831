import React, {useEffect} from 'react'
import StandardContainer from '../../../../../shared/components/otop/StandardContainer'
import { createOtopSeller, getOtopSellerList } from "../action";
import { useDispatch, useSelector } from 'react-redux';
import Tabulation from '../components/Tabulation'

const AddEditSeller = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { sellerList, seller, loading } = state.manageSeller;

        useEffect(() => {
            dispatch(getOtopSellerList())
        }, [getOtopSellerList])
    return (
        <StandardContainer>
            <div className="main-container">
                <div className="content-bg">
                    <Tabulation 
                    createOtopSeller={createOtopSeller}
                    sellerList={sellerList}
                    loading={loading}
                    />
                </div>
            </div>
        </StandardContainer>
    )
}

export default AddEditSeller
