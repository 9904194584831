import React, { useEffect } from 'react';
import queryString from 'qs';

import { resetPasswordExhibitor, resetPassword } from '../actions';
import PageHeader from '../../layout/containers/PageHeader';
import PageFooter from '../../layout/containers/PageFooter';
import PageContentWrapper from '../../../shared/components/PageContentWrapper';

import FormResetPassword from '../components/FormResetPassword';

function ResetPassword(props) {
  const { token, history } = props;
  const hasToken = localStorage.getItem('token');

  const { search } = props.location;
  const query = queryString.parse(search, { ignoreQueryPrefix: true });

  // if (hasToken) window.location.replace('/');

  return (
    <React.Fragment>
      <PageHeader profileDetails={{}} />
      <PageContentWrapper>
        <div>
          <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
            <div className="flex justify-center items-center px-5 lg:px-20 h-full">
              <h1 className="text-white font-medium text-3xl z-10">
                RESET PASSW0RD
              </h1>
            </div>
            <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
          </div>
          <div className="bg-main-content bg-center bg-cover bg-no-repeat">
            <div className="px-5 py-20 lg:p-20 text-blue-20">
              <h1 className="text-red-10 text-3xl font-semibold mb-10 uppercase font-semibold">
                RESET PASSWORD
              </h1>
              <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
                <FormResetPassword
                  resetPassword={resetPassword}
                  resetPasswordExhibitor={resetPasswordExhibitor}
                  history={history}
                  query={query}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContentWrapper>
      <PageFooter />
    </React.Fragment>
  );
}

export default ResetPassword;
