import React from "react";
import styled from "@emotion/styled";

function HeaderWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default HeaderWrapper;

const StyledDiv = styled.div({
        zIndex: "999",
        position: "sticky",
        top: "0",
        boxShadow: "0 1px 1px -3px rgba(0, 0, 0, 0.1), 0 2px 2px -2px rgba(0, 0, 0, 0.01)",


    ".private-header-content": {
        background: "#fff",
        width: "1905px",
        "button": {
            background: "#c96a5e",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            width: "2.2rem",
            height: "2.2rem",
            borderRadius: "50%",
        },

        "button:hover": {
            background: "#ca847a",
            outline: "0",
            "i":{
                color: "#fff",
             },
        },

        "button:focus": {
            background: "#c96a5e",
            color: "#fff",
            outline: "0",
        },
    },    

    ".header-container": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#FFF",
        
        
        ".header-container-top": {
            background: "#f3f1f1",
            display: "flex",
            justifyContent: "center",
            // color: "#c96a5e",
            fontWeight: "550",

            ".header-content-top": {
                width: "80rem",
                display: "flex",
            }
        },

        ".public-header-content": {
            width: "80rem",
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0px 10px 0px",
        },

        

        ".search-container": {
            width: "35rem",
            height: "2.5rem",
            display: "flex",

            "input": {
                width: "32rem",
                height: "2.5rem",
                background: "#f5f3f3",
                padding: "0px 10px 0px 10px", 
            },
            "input:focus": {
                outline: 'none',
              },

            "i": {
                color: "#fff"
            },

            "button": {
                background: "#c96a5e",
                color: "#fff",
                display: "flex",
                alignItems: "center",
            },
    
            "button:hover": {
                background: "#ca847a",
                outline: "0",
                borderTopRightRadius: ".4rem",
                borderBottomRightRadius: ".4rem",
                "i":{
                    color: "#fff",
                 },
            },
    
            "button:focus": {
                background: "#c96a5e",
                color: "#fff",
                outline: "0",
            },
        },

        
        "i":{
            fontSize: "1.5rem"
        },
        "i:hover":{
            color: "#ca847a",
         },

        ".badge": {
            width: "1.20rem",
            height: "1.20rem",
            position: "absolute",
            zIndex: "10",
            marginLeft: "0.9rem",
            marginTop: "-1.7rem",
            borderRadius: "1.5rem",
            background: "#505050",
            display: "flex",
            fontWeight: "700",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.75rem"
        },

        ".ant-btn": {
            background: "none !important",
            color: "rgb(138, 136, 136)",
            fontSize: "1.4rem",
            fontWeight: "700",
            borderRadius: "10px",
            border: "0",
            height: "45px",
            boxShadow: "none !important"
          },
          ".ant-btn:focus": {
            borderColor: "none",
          },


        ".header-left":{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.125rem",
            lineHeight: "1.75rem",
        

            ".header-img": {
                objectFit: "cover",
                marginRight: "1.25rem",
                padding: "0.5px 0px 0.5px 0px",
            },

            ".title-lg": {
                fontSize: "1.125rem",
                lineHeight: "1.75rem",
                fontWeight: "700",
                color: "#c96a5e",
            }
        }, 

        ".header-right": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            ".subheader-right": {
                display: "flex",
                justifyContent: "",
                alignItems: "center",
                fontSize: "1.25rem",
                lineHeight: "1.75rem"
            }
        }

    }
});
