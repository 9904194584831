import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
// import { useDispatch } from 'react-redux';
// import { notification } from 'antd';

import { login, loginExhibitor, googleLogin } from '../actions';
import PageHeader from '../../layout/containers/PageHeader';
import PageFooter from '../../layout/containers/PageFooter';
import PageContentWrapper from '../../../shared/components/PageContentWrapper';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import FormLogin from '../components/FormLogin';
// import { FormProvider } from 'antd/lib/form/context';

function Login(props) {
  // const dispatch = useDispatch();
  // const [accessToken, setAccessToken] = useState('');
  const { history } = props;
  const { state } = props.location;
  const hasToken = localStorage.getItem('token');
  const [hasExpo, setHasExpo] = useState({});
  const [fromCheckout, setFromCheckout] = useState(null);
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    if (!isEmpty(state)) {
      const { data, fromCheckout, cartData } = props.location.state;
      setHasExpo(data);
      setFromCheckout(fromCheckout);
      setCartData(cartData);
    } else {
      setHasExpo({});
      setFromCheckout(null);
    }
  }, [state]);

  // const componentClicked = (data) => {
  //   console.log('data', data);
  // };

  // const responseFacebook = (response) => {
  //   fetch(
  //     // Production
  //     `https://admin.virtualtradeexpoph.com/api/login/facebook?access_token=` +
  //       response.accessToken,
  //     // Development
  //     // `http://internal.vte-admin.highlysucceed.com/api/login/facebook?access_token=` + response.accessToken,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //       accessToken: {
  //         access_token: response.access_token,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       localStorage.setItem('token', response.token);
  //       localStorage.setItem('isExhibitor', false);
  //       history.push('/');
  //     });
  // };

  // const responseGoogle = (response) => {
  //   dispatch(googleLogin(response.accessToken, callback));
  // };

  // useEffect(() => {
  //   dispatch(googleLogin());
  // }, [googleLogin()]);

  // const callback = (response) => {
  //   console.log('RESPONSE', response);
  //   if (response) {
  //     const statusCode = response.status_code;
  //     const status_code = response.data.status_code;
  //     if (statusCode === 'LOGIN_SUCCESS') {
  //       notification.success({ message: `${response.msg}` });
  //     } else if (statusCode === 'UNAUTHORIZED') {
  //       notification.warning({ message: `${response.msg}` });
  //     } else if (statusCode === 'ACCOUNT_NOT_VERIFIED') {
  //       notification.warning({ message: `${response.msg}` });
  //     } else if (status_code === 'LOGIN_SUCCESS') {
  //       history.push('/participant/profile');
  //       notification.success({ message: `${response.data.msg}` });
  //     }
  //   }
  // };

  // if (hasToken) window.location.replace('/');

  return (
    <React.Fragment>
      <PageHeader profileDetails={{}} />
      <PageContentWrapper>
        <div>
          <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
            <div className="flex justify-center items-center px-5 lg:px-20 h-full">
              <h1 className="text-white font-medium text-3xl z-10">
                LOGIN TO WOMENBIZ PH
              </h1>
            </div>
            <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
          </div>
          <div className="bg-main-content bg-center bg-cover bg-no-repeat">
            <div className="px-5 py-20 lg:p-20 text-blue-20">
              <h1 className="text-red-10 text-3xl mb-10 uppercase">
                SIGN IN TO YOUR ACCOUNT
              </h1>
              <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
                <FormLogin
                  login={login}
                  loginExhibitor={loginExhibitor}
                  history={history}
                  data={hasExpo}
                  fromCheckout={fromCheckout}
                  cartData={cartData}
                />
                {/* <hr className="my-10" />
                  <h2 className="text-center text-red-10 text-2xl mb-2 uppercase font-semibold">
                    Login via
                  </h2>
                  <br />
                  <div className="text-center">
                    <FacebookLogin
                      appId={`${process.env.REACT_APP_FB_ID}`}
                      autoLoad={false}
                      fields="name,email,picture"
                      onClick={componentClicked}
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <button
                          className="px-10 py-2 border fa-2x mx-1"
                          onClick={renderProps.onClick}
                        >
                          <span className="fab fa-facebook " />
                        </button>
                      )}
                    />
                    <GoogleLogin
                      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                      render={(renderProps) => (
                        <button
                          className="px-10 py-2 border fa-2x mx-1"
                          onClick={renderProps.onClick}
                        >
                          <span className="fab fa-google" />
                        </button>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </PageContentWrapper>
      <PageFooter />
    </React.Fragment>
  );
}

export default Login;
