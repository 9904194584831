import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Button } from 'antd';
import CardWrapper from '../../../../../shared/components/otop/CardWrapper';
import TableProducts from '../components/TableProducts';
import { getOtopProductList, deleteOtopProduct } from '../actions';
import { getTagList } from '../../tags/actions';

const Products = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products, loading } = state.myProducts;
  const { tags } = state.tag;
  const { categories } = state.category;
  let userType = localStorage.getItem('userType');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOtopProductList(null, 0));
    dispatch(getTagList());
  }, [getOtopProductList]);

  return (
    <CardWrapper>
      <Card className="card">
        <div className="flex justify-between card-content">
          <p>My Products</p>
          {userType === 'seller' && (
            <Link to="/otop-hub/admin/products/add">
              <Button className="flex items-center">
                <i className="fas fa-edit mr-2 text-lg"></i>
                Add Product
              </Button>
            </Link>
          )}
        </div>
        <div className="card-content">
          <TableProducts
            products={products}
            getOtopProductList={getOtopProductList}
            deleteOtopProduct={deleteOtopProduct}
            loading={loading}
            tags={tags}
            categories={categories}
          />
        </div>
      </Card>
    </CardWrapper>
  );
};

export default Products;
