import axios from "axios";
import { decode } from "he";
import { BASE_URL } from "../constant/url";

const TIMEOUT = 10000;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});

export { instance as axios };

export function getAuthHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");
  let otopToken = localStorage.getItem("otopToken");

  if (token) {
    return { Authorization: "Bearer " + token, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods' : '*'};
  } 
  if (otopToken) {
    return {
      Authorization: "Bearer " + otopToken,
      "Access-Control-Allow-Origin": "*",
    };

  }
}

export const authHeader = () => {
  let token = localStorage.getItem("token");
  let otopToken = localStorage.getItem("otopToken");
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods' : '*'

      },
    };
  } else if (otopToken) {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("otopToken")}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  }
};

export const getError = (error) => {
  let message, errorCode, httpStatus;
  if (error.response) {
    const { msg } = error.response.data;
    httpStatus = error.response.status;
    message = msg;
  } else if (typeof error === "string") {
    errorCode = "no_code";
    message = error;
  } else {
    message =
      "Could not connect to the server, Please check your network connection.";
  }
  return {
    errorCode: errorCode,
    message,
    httpStatus: httpStatus || 0,
  };
};

export const isUserLoggedIn = (user) => {
  return !!user && !!localStorage.getItem("token" || "otopToken");
};

export const renderDetails = (desc) => {
  if (desc) {
    let holder = desc.replace(/<\/?[^>]+(>|$)/g, "");
    let temp = holder;
    let temp2 = decode(temp);

    return temp2;
  }
};

export const renderTag = (status) => {
  switch (status) {
    case "Pending":
    case "Incomplete":
      return "tag-pending";
    case "Completed":
    case "Approved":
    case "Closed":
    case "Paid": 
      return "tag-completed";
    case "Cancelled":
    case "Rejected":
      return "tag-declined";
    case "Order Accepted": 
      return "tag-order-accepted"
    case "To ship":
      return "tag-to-ship";
    case "To receive": 
      return "tag-to-receive"
    default:
      return "tag-success";
  }
};

export const currentScreen = (screen) => {
  switch (screen) {
    case "vte": localStorage.setItem("activeScreen", "otop")
      break;
    case "otop": localStorage.setItem("activeScreen", "vte")
      break;
    default:  
      break;
  }
}