import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormAddress from "../components/FormAddress";
import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import {
  createAddress,
  editAddress,
  readAddress,
} from "../actions";

function AddEditAddress() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector((state) => state);
  const { addressDetails } = state.address;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(readAddress(id));
    }
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg w-3/4 my-5">
          <FormAddress
            createAddress={createAddress}
            editAddress={editAddress}
            addressDetails={addressDetails}
            id={id}
          />
        </div>
      </div>
    </StandardContainer>
  );
}
export default AddEditAddress;