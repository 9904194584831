import React, { useState, useEffect } from "react";
import exhibitorImg from "../../../shared/assets/images/exhibitor.jpg";
import FormShippingDetails from "../components/FormShippingDetails";
import { Form, Input, Radio } from 'antd'

const Checkout = (props) => {
  const { checkout_list, history } = props;
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [exhibitor, setExhibitor] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (checkout_list) {
      const filteredArr = checkout_list.reduce((cart, current) => {
        const holder = cart.find((item) => item.id === current.id);
        if (!holder) {
          return cart.concat([current]);
        } else {
          return cart;
        }
      }, []);
      setList(filteredArr);

      const filteredExhibitor = checkout_list.reduce((exhibitor, current) => {
        const temp = exhibitor.find(
          (item) => item['product']['data']['exhibitor_id'] === current['product']['data']['exhibitor_id']
        );

        if (!temp) {
          return exhibitor.concat([current]);
        } else {
          return exhibitor;
        }
      }, []);
      setExhibitor(filteredExhibitor);


      let arr = [];
      checkout_list.forEach((element) => {
        arr.push(parseInt(element.total.replace(/,/g,"")));
      });
      

      let total = arr.reduce((a, b) => a + b, 0);
      setTotal(total.toLocaleString());
    }

  }, [checkout_list]);

  const renderCheckoutProduct = (exhi) => {
    const filtered = list.filter(
      (item) => item['product']['data']['exhibitor_id'] === exhi['product']['data']['exhibitor_id']
    );
    if (filtered && filtered.length > 0) {
      
      return filtered.map((item) => {
        let product_image = item['product']['data']['thumbnail']['full_path']
        let name = item['product']['data']['name']
        let price = item['product']['data']['display_price']
        return (
          <div
            key={item.id}
            className="lg:flex justify-between lg:w-full px-5 py-5 border-b"
          >
            <div className="lg:w-full flex lg:flex items-center justify-between lg:px-2 text-blue-20">
              <div className="w-3/6 w-20 flex flex-col lg:flex-row lg:items-center">
                <div className="w-1/2">
                  <img
                    src={
                      product_image
                        ? product_image
                        : exhibitorImg
                    }
                    alt="Virtual Trade Expo Ph"
                    className="w-auto h-16 lg:h-24 object-center"
                  />
                </div>
                <p className="w-1/2 ml-2 text-xs md:mr-3 mt-2 uppercase lg:text-lg">
                  {name}
                </p>
              </div>

              <p className="lg:mt-3 w-1/6 text-xs md:mr-3 lg:text-lg">
                PHP {price}
              </p>
              <p className="lg:mt-3 w-1/6 ml-2 text-xs md:mr-3 lg:text-lg">
                QTY {item.quantity ? item.quantity : "1"}
              </p>
              <p className="lg:mt-3 w-1/6 ml-2 text-xs -mr-2 text-red-20 lg:text-xl font-bold">
                PHP {item.total ? item.total : item.price}
              </p>
            </div>
          </div>
        );
      });
    }
  };

  const  renderShippingForm  = () => {
    return (
      <>
      <div className="flex item-center w-1/3">
        <div className="flex">
        <p className="ml-5 -mr-8 mt-5 w-28">Shipping Fee:</p>
          <Form className="flex">
            <Input className="border-l-0 border-t-0 border-r-0 mx-2 w-60  "/>
            <div className="w-96">
              <Radio.Group defaultValue="a" size="small" className="mt-5">
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
              </Radio.Group>
            </div>
          </Form>
        </div>
      </div>
      </>
    )
  }


  const renderExhibitor = () => {
    if (exhibitor && exhibitor.length > 0) {
      return exhibitor.map((item) => {
        let exhibitor_image = item['product']['data']['exhibitor']['data']['thumbnail']['full_path']
        let firstname = item['product']['data']['exhibitor']['data']['firstname']
        let lastname = item['product']['data']['exhibitor']['data']['lastname']
        let company_name = item['product']['data']['exhibitor']['data']['company_name']
        let contact_number = item['product']['data']['exhibitor']['data']['contact_number']
        return (
          <div key={item.id}>
            <div className="border-dashed border-b-2 border-gray-20"></div>

              <div className="flex items-center px-10 py-10">
                <img
                  src={
                    exhibitor_image
                      ? exhibitor_image
                      : exhibitorImg
                  }
                  alt="Virtual Trade Expo Ph"
                  className="w-auto h-12 object-center object-contain rounded-lg"
                />
                <div className="ml-5">
                  <p className="lg:text-lg text-red-20 font-bold">
                    {firstname} {lastname}
                  </p>
                  <p className="text-red-20 italic">
                    {company_name}
                  </p>
                  <p className="text-red-20 italic">
                    {contact_number}
                  </p>
                </div>
              </div>
              <div className="mb-5">
                  {/* {renderShippingForm()} */}
                </div>
            <div className="border-dashed border-b-2 border-gray-20"></div>
            {renderCheckoutProduct(item)}
            <div className="border-dashed border-b-2 border-gray-20"></div>
          </div>
        );
      });
    }
  };

  // const renderExhibitor = () => {
  //   if (list) {
  //     return list.map((item) => {
  //       let exhibitor_image = item['product']['data']['exhibitor']['data']['thumbnail']['full_path']
  //       let firstname = item['product']['data']['exhibitor']['data']['firstname']
  //       let lastname = item['product']['data']['exhibitor']['data']['lastname']
  //       let company_name = item['product']['data']['exhibitor']['data']['company_name']
  //       let contact_number = item['product']['data']['exhibitor']['data']['contact_number']

  //       let product_image = item['product']['data']['thumbnail']['full_path']
  //       let name = item['product']['data']['name']
  //       let price = item['product']['data']['display_price']
        
  //       return (
  //         <div key={item.id}>
  //           <div className="border-dashed border-b-2 border-gray-20"></div>

  //             <div className="flex items-center px-10 py-10">
  //               <img
  //                 src={
  //                   exhibitor_image
  //                     ? exhibitor_image
  //                     : exhibitorImg
  //                 }
  //                 alt="Virtual Trade Expo Ph"
  //                 className="w-auto h-12 object-center object-contain rounded-lg"
  //               />
  //               <div className="ml-5">
  //                 <p className="lg:text-lg text-red-20 font-bold">
  //                   {firstname} {lastname}
  //                 </p>
  //                 <p className="text-red-20 italic">
  //                   {company_name}
  //                 </p>
  //                 <p className="text-red-20 italic">
  //                   {contact_number}
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="mb-5">
  //                 {/* {renderShippingForm()} */}
  //               </div>
  //           <div className="border-dashed border-b-2 border-gray-20"></div>
            
        
  //         <div key={item.id} className="lg:flex justify-between lg:w-full px-5 py-5 border-b">
  //           <div className="lg:w-full flex lg:flex items-center justify-between lg:px-2 text-blue-20">
  //             <div className="w-3/6 w-20 flex flex-col lg:flex-row lg:items-center">
  //               <div className="w-1/2">
  //                 <img
  //                   src={
  //                     product_image
  //                       ? product_image
  //                       : exhibitorImg
  //                   }
  //                   alt="Virtual Trade Expo Ph"
  //                   className="w-auto h-16 lg:h-24 object-center"
  //                 />
  //               </div>
  //               <p className="w-1/2 ml-2 text-xs md:mr-3 mt-2 uppercase lg:text-lg">
  //                 {name}
  //               </p>
  //             </div>

  //             <p className="lg:mt-3 w-1/6 text-xs md:mr-3 lg:text-lg">
  //               PHP {price}
  //             </p>
  //             <p className="lg:mt-3 w-1/6 ml-2 text-xs md:mr-3 lg:text-lg">
  //               QTY {item.quantity ? item.quantity : "1"}
  //             </p>
  //             <p className="lg:mt-3 w-1/6 ml-2 text-xs -mr-2 text-red-20 lg:text-xl font-bold">
  //               PHP {item.total ? item.total : item.price}
  //             </p>
  //           </div>
  //         </div>
        
  //           <div className="border-dashed border-b-2 border-gray-20"></div>
  //         </div>
  //       );
  //     });
  //   }
  // };

  return (
    <>
      <div className="w-full rounded-lg shadow mt-5 bg-white">
        {renderExhibitor()}

        <div className="flex justify-between w-full text-xs rounded-b-lg shadow bg-red-20 text-white p-5 lg:text-xl">
          <h1 className="text-white">TOTAL</h1>
          <h1 className="text-white">PHP {total ? total : "0.00"}</h1>
        </div>
      </div>

      <FormShippingDetails
        list={list}
        history={history}
        total={total}
        exhibitor={exhibitor}
      />
    </>
  );
};

export default Checkout;