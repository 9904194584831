import React from 'react';
import { Link } from 'react-router-dom';
import { List, Card, Button, Image, Skeleton } from 'antd';

import placeholder from '../../../shared/assets/images/placeholder.jpg';
import CardWrapper from '../../../shared/components/CardWrapper';

function ListProduct(props) {
  const { loadingExhibitor, productList, header, isEditable, onDelete } = props;

  const renderPrice = (data) => {
    if (data.discount_rate) {
      return (
        <div className="flex">
          <p className="text-gray-20 line-through mr-2">{data.display_price}</p>
          <p>{data.discount_rate}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>{data.display_price}</p>
        </div>
      );
    }
  };

  return (
    <>
      <h1 className="mt-10 content-header-left">{header ?? 'PRODUCTS'}</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        pagination={productList.length > 10}
        dataSource={productList}
        renderItem={(item) => (
          <List.Item>
            <CardWrapper>
              <Card
                bordered={false}
                className="card my-5"
                loading={loadingExhibitor}
              >
                {isEditable && (
                  <div className="absolute z-10 btn-action">
                    <span
                      className="bg-red-20 text-white"
                      onClick={() => onDelete?.(item)}
                    >
                      <i className="fas fa-trash p-3"></i>
                    </span>
                    <span
                      className="bg-red-20 text-white"
                      onClick={() =>
                        window.location.assign(
                          `/exhibitor/products/${item.id}/edit`
                        )
                      }
                    >
                      <i className="fas fa-pen p-3"></i>
                    </span>
                  </div>
                )}
                <div className="relative card-img">
                  <div
                    className={`absolute h-full w-full top-0 left-0 ${
                      !item.thumbnail ? 'card-img-placeholder' : ''
                    }`}
                  >
                    {item.thumbnail && (
                      <Image
                        preview={false}
                        placeholder={<Skeleton.Avatar shape="square" active />}
                        src={item.thumbnail.full_path}
                        alt="Virtual Trade Expo Ph"
                        width={'100%'}
                        height={'100%'}
                      />
                    )}
                  </div>
                </div>
                <div className="card-content">
                  <h3 className="card-title text-2xl text-control-1">
                    {item.name}
                  </h3>
                  <div className="card-body">
                    <div className="card-body-row product">
                      <div className="flex justify-center items-center text-red-30 mt-2">
                        <i className="fas fa-tag text-2xl"></i>&nbsp;&nbsp;
                        <span className="font-semibold">
                          {renderPrice(item)}
                        </span>
                      </div>
                    </div>
                    {item?.product_url && (
                      <Button
                        className="btn btn-red my-1 mx-auto"
                        onClick={() => window.open(item.product_url, '_blank')}
                      >
                        Go to Item
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </CardWrapper>
          </List.Item>
        )}
      />
    </>
  );
}

export default ListProduct;
