import { ADDRESS } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  addresses: [],
  addressDetails: "",
  address: {},
  addressDelete: {},
};

const address = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ADDRESS.ADDRESS_LIST_START:
      return { ...state, loading: true, error: "" };
    case ADDRESS.ADDRESS_LIST_SUCCESS:
      return { ...state, loading: false, addresses: action.payload };
    case ADDRESS.ADDRESS_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ADDRESS.ADDRESS_READ_START:
      return { ...state, loading: true, error: "" };
    case ADDRESS.ADDRESS_READ_SUCCESS:
      return { ...state, loading: false, addressDetails: action.payload };
    case ADDRESS.ADDRESS_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ADDRESS.ADDRESS_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case ADDRESS.ADDRESS_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, address: action.payload };
    case ADDRESS.ADDRESS_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ADDRESS.ADDRESS_DELETE_START:
      return { ...state, loading: true, error: "" };
    case ADDRESS.ADDRESS_DELETE_SUCCESS:
      return { ...state, loading: false, addressDelete: action.payload };
    case ADDRESS.ADDRESS_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default address;