import React, { useState, useEffect } from "react";
import { Breadcrumb } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormProduct from "../components/FormProduct";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import {
  createOtopProduct,
  editOtopProduct,
  readOtopProduct,
} from "../actions";
import { getTagList } from "../../tags/actions";
import { getCategoryList } from "../../categories/actions";

function AddEditProducts() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector((state) => state);
  const { productDetails } = state.myProducts;
  const { tags } = state.tag;
  const { categories } = state.category;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(readOtopProduct(id));
    }
    dispatch(getTagList());
    dispatch(getCategoryList());
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg w-3/4 my-10">
          <div className="content-space">
            <div className="flex items-center w-5/6 justify-between mb-5">
              <p className="content-subheader-left mr-5">Seller Dashboard</p>
              <Breadcrumb>
                <Breadcrumb.Item>OTOP HUB SELLER CENTER</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">My Products</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Add Product</a>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <FormProduct
              createOtopProduct={createOtopProduct}
              editOtopProduct={editOtopProduct}
              productDetails={productDetails}
              id={id}
              tags={tags}
              categories={categories}
            />
          </div>
        </div>
      </div>
    </StandardContainer>
  );
}
export default AddEditProducts;