import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLanding from "../../main/containers/Landing";
import ExpoLanding from "../../expo/containers/Landing";
import ExpoHallLanding from "../../expo/containers/ExpoHallLanding";
import ExpoExhibitorLanding from "../../expo/containers/ExpoExhibitorLanding";
import ExpoSeminarLanding from "../../expo/containers/ExpoSeminarLanding";
import ProductLanding from "../../expo/containers/ProductLanding";
import PartnerLanding from "../../partner/containers/Landing";
import PartnerShow from "../../partner/containers/PartnerShow";
import ExhibitorLanding from "../../exhibitor/containers/Landing";
import ExhibitorShow from "../../exhibitor/containers/ExhibitorShow";
import ExhibitorProductShow from "../../exhibitor/containers/ExhibitorProductShow";
import ExpoCreate from "../../expo/containers/ExpoCreate";
import SpeakerLanding from "../../speaker/containers/Landing";
import SpeakerShow from "../../speaker/containers/SpeakerShow";
import CartLanding from "../../cart/containers/Landing";
import CheckoutLanding from "../../cart/containers/CheckoutLanding";
import CheckoutStatusLanding from "../../cart/containers/CheckoutStatusLanding";
import ContactUsLanding from "../../contact-us/containers/Landing";
import ParticipantProfile from "../../profile/containers/ParticipantProfile";
import ExhibitorProfile from "../../profile/containers/ExhibitorProfile";
import ExhibitorPolicy from "../../exhibitor/containers/ExhibitorPolicy";
import LandingGallery from "../../gallery/containers/LandingGallery";
import LandingInquiry from "../../inquiries/containers/LandingInquiry";

import OtopLanding from '../../otop/main/containers/Landing';
import Products from '../../otop/products/containers/Landing'
import Login from '../../otop/auth/containers/Login'
import Admin from '../../otop/admin/dashboard/containers/Landing'
import MyAccount from '../../otop/admin/my-account/containers/Landing'
import MyProducts from '../../otop/admin/my-products/containers/Landing'
import AddEditProducts from '../../otop/admin/my-products/containers/AddEditProducts'
import OrderManagement from '../../otop/admin/order-management/containers/Landing'
import OrderDetails from '../../otop/admin/order-management/containers/OrderDetails';
import AddSeller from '../../otop/admin/manage-seller/containers/AddEditSeller';
import ProductShow from "../../otop/products/containers/ProductShow";
import Cart from '../../otop/cart/containers/Landing'
import LandingTransaction from '../../otop/transactions/containers/LandingTransaction';
import ShowTransaction from '../../otop/transactions/containers/ShowTransaction';
import ShowStore from "../../otop/stores/containers/StoreShow";
import LandingAddress from '../../otop/addresses/containers/LandingAddress';
import AddEditAddress from '../../otop/addresses/containers/AddEditAddress';

import _404 from "../../result/components/_404";
import TermsAndConditions from "../../general/components/TermsAndConditions";
import PrivacyPolicy from "../../general/components/PrivacyPolicy";

export default function PageContent(props) {
  const isExhibitor = localStorage.getItem("isExhibitor");
  return (
    <Switch>
      <Route exact path="/otop-hub/admin" component={Admin} />
      <Route exact path="/otop-hub/admin/account" component={MyAccount} />
      <Route exact path="/otop-hub/admin/products" component={MyProducts} />
      <Route exact path="/otop-hub/admin/products/add" component={AddEditProducts} />
      <Route exact path="/otop-hub/admin/products/:id/edit" component={AddEditProducts} />
      <Route exact path="/otop-hub/admin/manage-seller" component={AddSeller} />
      <Route exact path="/otop-hub/admin/order-management" component={OrderManagement} />
      <Route exact path="/otop-hub/admin/order-management/unpaid-orders/order-details" component={OrderDetails} />
      <Route exact path="/otop-hub/transactions" component={LandingTransaction} />
      <Route exact path="/otop-hub/transactions/:id" component={ShowTransaction} />
      <Route exact path="/otop-hub/login" component={Login} />
      <Route exact path="/otop-hub/products" component={Products} />
      <Route exact path="/otop-hub/cart" component={Cart} />
      <Route exact path="/otop-hub/my-addresses" component={LandingAddress} />
      <Route exact path="/otop-hub/my-addresses/add" component={AddEditAddress} />
      <Route exact path="/otop-hub/my-addresses/:id/edit" component={AddEditAddress} />
      <Route exact path="/otop-hub">
        <OtopLanding {...props} />
      </Route>
      <Route exact path="/otop-hub/product/:id"
        render={(props) =>
        (<ProductShow {...props} />
        )} />
      <Route exact path="/otop-hub/store/:id"
        render={(props) =>
        (<ShowStore {...props} />
        )} />


      {/* Main  */}
      {/* <Route exact path="/" render={() => <Redirect to="/expo"/>} /> */}
      <Route exact path="/">
        <MainLanding {...props} />
      </Route>
      <Route exact path="/expo" component={ExpoLanding} />
      <Route exact path="/expo/create" component={ExpoCreate} />
      <Route
        exact
        path='/expo/:slug'
        render={(props) => (
          <ExpoHallLanding {...props} />
        )}
      />
      <Route exact path="/auditorium" component={ExpoSeminarLanding} />
      {/* <Route exact path="/expo/:slug" component={ExpoHallLanding} click={click} /> */}

      <Route exact path="/expo/:slug/exhibitors" component={ExpoExhibitorLanding} />
      <Route exact path="/expo/:slug/seminar" component={ExpoSeminarLanding} />
      <Route exact path="/expo/:slug/products/:id" component={ProductLanding} />
      <Route exact path="/expo/:slug/speakers" component={SpeakerLanding} />
      <Route exact path="/expo/:slug/speakers/:id" component={SpeakerShow} />
      <Route exact path="/expo/:slug/exhibitor" component={ExhibitorLanding} />
      {/* <Route exact path="/partners" component={PartnerLanding} />
      <Route exact path="/partners/:id" component={PartnerShow} /> */}
      {/* <Route exact path="/exhibitors" component={ExhibitorLanding} />
      <Route exact path="/exhibitors/:id" component={ExhibitorShow} /> */}
      <Route exact path="/exhibitors/:id/products/:product_id" component={ExhibitorProductShow} />
      {/* <Route exact path="/exhibitors/:id/policies" component={ExhibitorPolicy} /> */}
      <Route exact path="/speakers" component={SpeakerLanding} />
      <Route exact path="/speakers/:id" component={SpeakerShow} />
      <Route exact path="/cart" component={CartLanding} />
      <Route exact path="/cart/checkout" component={CheckoutLanding} />
      <Route exact path="/checkout/success" component={CheckoutStatusLanding} />
      <Route exact path="/checkout/failure" component={CheckoutStatusLanding} />
      <Route exact path="/checkout/cancel" component={CheckoutStatusLanding} />
      <Route path="/contact-us" component={ContactUsLanding} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route exact path="/booth/partners" component={PartnerLanding} />
      <Route exact path="/booth/partners/:id" component={PartnerShow} />
      <Route exact path="/booth/exhibitors" component={ExhibitorLanding} />
      <Route exact path="/booth/exhibitors/:id" component={ExhibitorShow} />
      <Route exact path="/booth/exhibitors/:id/policies" component={ExhibitorPolicy} />
      <Route path="/inquiries" component={LandingInquiry} />


      {isExhibitor === "false" && <Route exact path="/participant/profile" component={ParticipantProfile} />}
      {isExhibitor === "false" && <Route exact path="/participant/profile/:id/edit" component={ParticipantProfile} />}
      {isExhibitor === "false" && <Route exact path="/participant/profile/:id/edit-password" component={ParticipantProfile} />}
      {isExhibitor === "false" && <Route exact path="/participant/profile/:id/edit-avatar" component={ParticipantProfile} />}
      {isExhibitor === "false" && <Route path="/participant/expo" component={ParticipantProfile} />}
      {/* { isExhibitor === "false" && <Route path="/participant/inquiries" component={ParticipantProfile} />} */}
      {isExhibitor === "false" && <Route exact path="/participant/my-orders" component={ParticipantProfile} />}
      {isExhibitor === "false" && <Route exact path="/participant/my-orders/:transaction_id" component={ParticipantProfile} />}

      {isExhibitor === "true" && <Route exact path="/exhibitor/gallery" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/refund" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/payment" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/profile" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/profile/:id/edit" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/profile/:id/edit-password" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/profile/:id/edit-avatar" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route path="/exhibitor/expo" component={ExhibitorProfile} />}
      {/* { isExhibitor === "true" && <Route path="/exhibitor/inquiries" component={ExhibitorProfile} />} */}
      {isExhibitor === "true" && <Route exact path="/exhibitor/products" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/products/create" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/products/:id/edit" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/my-orders" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/my-orders/:transaction_id" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/sales-record" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/sales-record/create" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/sales-record/:id/edit" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/bank-details" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/bank-details/create" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/bank-details/:id/edit" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/live-selling" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/live-selling/create" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/live-selling/:id/edit" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/policies" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/disbursements" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/disbursements/create" component={ExhibitorProfile} />}
      {isExhibitor === "true" && <Route exact path="/exhibitor/disbursements/:id/edit" component={ExhibitorProfile} />}

      <Route path="*" component={_404}>
        {/* Partners Page */}
        {/* <Route path="/partnerprofile" component={PartnerProfile} /> */}

        {/* Speakers Page */}
        {/* <Route path="/speakerprofile" component={SpeakerProfile} />         */}

        {/* Exhibitors Page */}
        {/* <Route path="/exhibitorprofile" component={ExhibitorProfile} />    */}
      </Route>
    </Switch>
  );
}