import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import {
  createProduct,
  editProduct,
  readProduct,
  getProductImageList,
  deleteProductImage,
} from '../actions';
import FormProduct from '../components/FormProduct';

function AddEditProduct(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const state = useSelector((state) => state);
  const { productDetails, productImageList } = state.product;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(readProduct(id));
    dispatch(getProductImageList(id));
    if (id) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [isEdit]);

  return (
    <Card className="rounded-2xl p-1 lg:p-10 mt-5 w-full">
      <FormProduct
        createProduct={createProduct}
        editProduct={editProduct}
        productDetails={productDetails}
        isEdit={isEdit}
        id={id}
        productImageList={productImageList}
        deleteProductImage={deleteProductImage}
      />
    </Card>
  );
}

export default AddEditProduct;
