import { BUYER_TRANSACTION } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  transactions: [],
  transactionDetails: "",
};

const buyerTransaction = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_START:
      return { ...state, loading: true, error: "" };
    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_SUCCESS:
      return { ...state, loading: false, transactions: action.payload };
    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_START:
      return { ...state, loading: true, error: "" };
    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_SUCCESS:
      return { ...state, loading: false, transactionDetails: action.payload };
    case BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default buyerTransaction;