import React from "react";
import styled from "@emotion/styled";

function StandardLanding(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default StandardLanding;

const StyledDiv = styled.div({
  ".content-bg": {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "cover",

    ".content-space": {
      ".content-header": {
        color: "#CD1F30",
        fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-header-left": {
        color: "#CD1F30",
        fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "left",
        textTransform: "uppercase",
      },
      ".content-subheader-left": {
        color: "#CD1F30",
        fontSize: "1.5rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "left",
        textTransform: "uppercase",
      },
      ".content-header-white": {
        color: "#FFFFFF",
        fontSize: "1.875rem;",
        lineHeight: "2.25rem;",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "2rem",
        textTransform: "uppercase",
      },
      ".content-subheader": {
        color: "#CD1F30",
        fontSize: "1rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
      },
    },
  },
  ".content-crop": {
    height: "calc(100vh - 95px); !important",
  },
  ".ant-btn": {
    background: "#c96a5e",
    color: "#FFF",
    fontSize: "1.1em",
    borderRadius: "5px",
    border: "0",
    letterSpacing: "1px",
    height: "45px",
    padding: "1.5rem",
    alignItems: "center"
  },
  ".ant-btn:focus": {
    borderColor: "none",
  },
  ".ant-btn:hover": {
    borderColor: "none",
    background: "#E42F30 !important",
    outline: "0",
  },
});
