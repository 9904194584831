import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, notification, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import FormWrapper from '../../../../../shared/components/otop/FormWrapper';

const FormUser = (props) => {
  const history = useHistory();
  const { createUser, editUser, id, userDetails } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && userDetails) {
      const { attributes } = userDetails;
      form.setFieldsValue({
        firstname: attributes.firstname ? attributes.firstname : '',
        lastname: attributes.lastname ? attributes.lastname : '',
        barangay: attributes.barangay ? attributes.barangay : '',
        contact_number: attributes.contact_number
          ? attributes.contact_number
          : '',
        email: attributes.email ? attributes.email : '',
        region: attributes.region ? attributes.region : '',
        street_name: attributes.street_name ? attributes.street_name : '',
        town: attributes.town ? attributes.town : '',
        unit: attributes.unit ? attributes.unit : '',
      });
    }
  }, [userDetails]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  function renderImg(values) {
    if (!isEmpty(fileList)) {
      values['store_image'] = fileList;
    }
  }

  const onFinish = (values) => {
    if (id) {
      dispatch(editUser(values, id, callback));
    } else {
      renderImg(values);
      dispatch(createUser(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    console.log('here', response);
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'REGISTERED') {
        notification.success({ message: `${response.msg}` });
        history.push('/otop-hub/admin/manage-users');
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.password) {
          form.setFields([
            {
              name: 'password',
              errors: [`${response.errors.password}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
      } else if (statusCode === 'APP_ERROR') {
        notification.error({ message: `${response.msg}` });
      } else if (response.data.status_code === 'UPDATED') {
        notification.success({ message: `${response.data.msg}` });
        history.push('/otop-hub/admin/manage-users');
      } else if (response.data.status_code === 'FAILED') {
        notification.error({ message: `${response.data.msg}` });
        history.push('/otop-hub/admin/manage-users');
      } else {
        notification.success({ message: 'Successfully Updated!' });
        history.push('/otop-hub/admin/account');
      }
    }
  };

  return (
    <FormWrapper>
      <Form
        name="createUser"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="formProduct"
        className="shadow py-10 px-10"
      >
        <div className="w-full">
          <h2 className="lg:text-xl text-brown-10">
            {id ? 'Edit' : 'Add'} User
          </h2>
        </div>
        <div className="w-full flex flex-wrap">
          <Upload
            name="store_image"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
          >
            {fileList.length < 1 && (
              <i className="fas fa-camera text-gray-10 text-5xl"></i>
            )}
          </Upload>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
            className="w-full lg:w-1/2 px-3"
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password' }]}
            className="w-full lg:w-1/2 px-3"
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            rules={[{ required: true, message: 'Please input your password' }]}
            className="w-full lg:w-1/2 px-3"
          >
            <Input type="password" placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item
            name="firstname"
            rules={[
              { required: true, message: 'Please input your first name' },
              {
                pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                message: 'Invalid format',
              },
            ]}
            className="w-full lg:w-1/2 px-3"
          >
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[
              { required: true, message: 'Please input your last name' },
              {
                pattern: RegExp(/^([a-zA-Z\s]+)$/g),
                message: 'Invalid format',
              },
            ]}
            className="w-full lg:w-1/2 px-3"
          >
            <Input placeholder="Last name" />
          </Form.Item>
          <Form.Item name="contact_number" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Contact Number (09xxxxxxxxx)" />
          </Form.Item>
          {/* <Form.Item name="position" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Position" />
          </Form.Item>
          <Form.Item name="regions" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Region" />
          </Form.Item>
          <Form.Item name="town" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Town" />
          </Form.Item>
          <Form.Item name="barangay" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Barangay" />
          </Form.Item>
          <Form.Item name="unit" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Unit" />
          </Form.Item>
          <Form.Item name="street_name" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Street Name" />
          </Form.Item> */}
          <Form.Item name="store_name" className="w-full lg:w-1/2 px-3">
            <Input placeholder="Store Name" />
          </Form.Item>
        </div>
        <Button
          htmlType="submit"
          className="flex items-center justify-center w-full"
        >
          {id ? 'Edit' : 'Add'} Account
        </Button>
      </Form>
    </FormWrapper>
  );
};

export default FormUser;
