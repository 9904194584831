import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, notification, Modal, Dropdown, Menu } from "antd";
import ReactPlayer from "react-player";
import { isEmpty } from "lodash";
import { useParams } from "react-router";

import StandardLanding from "../../../shared/components/StandardLanding";
import watchHolder from "../../../shared/assets/images/icon/hall/watchHolder.png";
import watch from "../../../shared/assets/images/icon/hall/watch.png";
import exhibitor from "../../../shared/assets/images/icon/hall/exhibitor.png";
import help from "../../../shared/assets/images/icon/hall/help.png";
import holder from "../../../shared/assets/images/icon/hall/holder.png";
import seminar from "../../../shared/assets/images/icon/hall/seminar.png";
import speaker from "../../../shared/assets/images/icon/hall/speaker.png";
import speakerHolder from "../../../shared/assets/images/icon/hall/speakerHolder.png";
import expo from "../../../shared/assets/images/expo.jpg";
import exhibitors from "../../../shared/assets/images/exhibitors.jpg";
import speakers from "../../../shared/assets/images/speakers.jpg";
import FormSearchFilterProduct from "../components/FormSearchFilterProduct";
import ListProduct from "../components/ListProduct";
import {
  showExpoFeatureProductList,
  showExpoDetails,
  showExpoDetailsExhibitor,
  showExpoFeatureProductExibitorList,
} from "../actions";
import { getCategoryList } from "../../general/actions";
import {
  getGuestExpoHallShow,
  getGuestFeaturedProductList,
} from "../../guest/actions";
import { Link } from "react-router-dom";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";

function ExpoHallLanding(props) {
  const dispatch = useDispatch();
  const { slug } = props.match.params;
  const state = useSelector((state) => state);
  const {
    products,
    expoDetails,
    totalProducts,
    exhibitorExpoDetails,
    exhibitorProducts,
    totalExhibitorProducts,
  } = state.expo;
  const { categories } = state.general;
  const { expoHallDetails, featuredProducts, totalGuestProducts } = state.guest;
  const loadingExpo = state.expo.loading;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deets, setDeets] = useState([]);
  const [productList, setProductList] = useState([]);
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === "true") {
      dispatch(
        showExpoFeatureProductExibitorList(null, slug, totalExhibitorProducts)
      );
      dispatch(showExpoDetailsExhibitor(slug));
    }

    dispatch(getCategoryList());
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      dispatch(showExpoFeatureProductList(null, slug, totalProducts));
      dispatch(showExpoDetails(slug));
    }
  }, []);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestExpoHallShow(slug));
      dispatch(getGuestFeaturedProductList(null, slug, totalGuestProducts));
    }
  }, []);

  useEffect(() => {
    !isEmpty(expoDetails) && setDeets(expoDetails);
    !isEmpty(exhibitorExpoDetails) && setDeets(exhibitorExpoDetails);
    !isEmpty(expoHallDetails) && setDeets(expoHallDetails);

    return () => {
      setDeets([]);
    };
  }, [expoDetails, exhibitorExpoDetails, expoHallDetails]);

  useEffect(() => {
    if (isExhibitor === "false") {
      !isEmpty(products) && setProductList(products);
    }

    if (isExhibitor === "true") {
      !isEmpty(exhibitorProducts) && setProductList(exhibitorProducts);
    }

    if (!token) {
      !isEmpty(featuredProducts) && setProductList(featuredProducts);
    }

    return () => {
      setProductList([]);
    };
  }, [products, exhibitorProducts, featuredProducts]);

  // useEffect(() => {
  //   if (isExhibitor === "true") {
  //     notification.warning({
  //       message: "You need to log in first!",
  //       description: `Log in your account and visit the ${
  //         slug === "otop-national-virtual-trade-fair-2021"
  //           ? "OTOP National Virtual Trade Fair"
  //           : "expo"
  //       }!`,
  //     });
  //     return props.history.push("/expo");
  //   }
  // }, []);

  const onRedirectToOtop = () => {
    // props.click();
  };

  const menuExhibitor = (
    <div className="bg-white lg:p-10">
      <h1 className="lg:text-xl">
        Click this to view and purchase products from our MSMEs
      </h1>
    </div>
  );

  const menuSeminar = (
    <div className="bg-white lg:p-10">
      <h1 className="lg:text-xl">Click this to watch our live stream</h1>
    </div>
  );

  const renderExpoHall = () => {
    return (
      <div className="flex flex-col text-white h-full w-full">
        <div className="h-2/3">
          <div className="flex h-full">
            <div className="w-1/3 mt-20">
              <div className="w-full flex items-center">
                <div className="w-1/12">
                  <img
                    src={watchHolder}
                    alt="Virtual Trade Expo Ph"
                    className="h-1/3 w-full"
                  />
                </div>
                <div className="relative w-10/12">
                  <img
                    src={watch}
                    alt="Virtual Trade Expo Ph"
                    className="h-2/3 w-full"
                  />
                  <div className="rounded-lg absolute top-0 left-0 w-full h-full hidden lg:flex">
                    <div className="player-wrapper w-full">
                      <ReactPlayer
                        url={
                          deets.video_link
                            ? deets.video_link
                            : "https://www.facebook.com/PLDTHomeBiz/videos/638796226759198"
                        }
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-1/3 flex justify-center"
              style={{ transform: "skewY(8deg)" }}
            >
              <div className="w-1/2 h-1/2 flex flex-col">
                <div className="w-full flex justify-center h-1/6">
                  <img
                    src={holder}
                    alt="Virtual Trade Expo Ph"
                    className="h-full w-1/4"
                  />
                </div>
                <div className="relative w-full h-3/6">
                  <img
                    src={watch}
                    alt="Virtual Trade Expo Ph"
                    className="h-full w-full"
                  />
                  {loadingExpo ? (
                    <div className="absolute top-0 left-0 h-full w-full py-1 px-2">
                      <CardWrapperNoBg>
                        <Card loading={loadingExpo} bordered={false}>
                          &nsp;
                        </Card>
                      </CardWrapperNoBg>
                    </div>
                  ) : (
                    <img
                      src={
                        !isEmpty(deets) &&
                          !isEmpty(deets.expo_banner) &&
                          !isEmpty(deets.expo_banner.filename)
                          ? deets.expo_banner.full_path
                          : expo
                      }
                      alt="Virtual Trade Expo Ph"
                      className="absolute top-0 left-0 h-full w-full object-center object-contain py-1 px-2"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="w-1/3 flex justify-center items-center"
              style={{ transform: "skewY(-15deg)" }}
            >
              <div className="w-3/5 h-2/5 flex mt-10">
                <div className="relative w-full">
                  <img
                    src={watch}
                    alt="Virtual Trade Expo Ph"
                    className="h-full w-full"
                  />
                  {loadingExpo ? (
                    <div className="absolute top-0 left-0 h-full w-full py-1 px-2">
                      <CardWrapperNoBg>
                        <Card loading={loadingExpo} bordered={false}>
                          &nsp;
                        </Card>
                      </CardWrapperNoBg>
                    </div>
                  ) : (
                    <div onClick={onRedirectToOtop}>
                      <img
                        src={
                          !isEmpty(deets) &&
                            !isEmpty(deets.featured_exhibitor) &
                            !isEmpty(deets.featured_exhibitor.filename)
                            ? deets.featured_exhibitor.full_path
                            : exhibitors
                        }
                        alt="Virtual Trade Expo Ph"
                        className="absolute top-0 left-0 h-full w-full object-center object-contain  py-1 px-2"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/3 pb-2">
          <div className="flex items-end justify-end h-full">
            <div className="w-1/3 h-full flex flex-col items-center justify-center">
              <div className="relative h-5/6 w-5/6">
                <img
                  src={speaker}
                  alt="Virtual Trade Expo Ph"
                  className="h-full w-full"
                />
                {loadingExpo ? (
                  <div className="absolute top-0 left-0 h-full w-full py-1 px-2">
                    <CardWrapperNoBg>
                      <Card loading={loadingExpo} bordered={false}>
                        &nsp;
                      </Card>
                    </CardWrapperNoBg>
                  </div>
                ) : (
                  <img
                    src={
                      !isEmpty(deets) &&
                        !isEmpty(deets.featured_speaker) &&
                        !isEmpty(deets.featured_speaker.filename)
                        ? deets.featured_speaker.full_path
                        : speakers
                    }
                    alt="Virtual Trade Expo Ph"
                    className="absolute top-0 left-0 h-full w-full object-center object-contain pl-5"
                  />
                )}
              </div>
              <img
                src={speakerHolder}
                alt="Virtual Trade Expo Ph"
                className="h-auto w-full"
              />
            </div>
            <div className="w-1/3 flex items-end justify-between">
              <div className="h-1/2 w-1/6" onClick={() => showModal()}>
                <img
                  src={help}
                  alt="Virtual Trade Expo Ph"
                  className="w-full h-full"
                />
              </div>
              <div className="h-4/6 w-1/4">
                <Dropdown overlay={menuExhibitor} placement="topCenter" arrow>
                  <Link to={`/expo/${slug}/exhibitors`}>
                    <img
                      src={exhibitor}
                      alt="Virtual Trade Expo Ph"
                      className="w-full h-full"
                    />
                  </Link>
                </Dropdown>
              </div>
            </div>
            <div className="w-1/3 flex justify-center items-end">
              <div className="h-5/6 w-1/3">
                <Dropdown overlay={menuSeminar} placement="topCenter" arrow>
                  <Link to={`/expo/${slug}/seminar`}>
                    <img
                      src={seminar}
                      alt="Virtual Trade Expo Ph"
                      className="w-full h-full"
                    />
                  </Link>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="relative">
          <div className="bg-main-hall bg-top bg-cover bg-no-repeat lg:h-screen">
            {renderExpoHall()}
          </div>
          <div className="absolute top-0 right-0 lg:mt-10 lg:mr-24 flex flex-col lg:flex-row">
            <Link
              to={`/expo/${deets && deets.slug}/speakers`}
              className="btn btn-red text-center cursor-pointer mr-1 lg:px-5 lg:py-3 text-xs lg:text-lg"
            >
              <i className="fas fa-list-alt"></i>&nbsp;
              <span>Go To Speakers</span>
            </Link>
            <Link
              to={`/expo/${deets && deets.slug}/exhibitor`}
              className="btn btn-red text-center cursor-pointer mr-1 lg:px-5 lg:py-3 text-xs lg:text-lg mt-1 lg:mt-0"
            >
              <i className="fas fa-list-alt"></i>&nbsp;
              <span>Go To Exhibitors</span>
            </Link>
          </div>
        </div>

        <StandardLanding>
          <div className="bg-main-content content-bg">
            <div className="content-space px-5 py-10 lg:p-20">
              <FormSearchFilterProduct
                categories={categories}
                slug={slug}
                showExpoFeatureProductList={showExpoFeatureProductList}
                showExpoFeatureProductExibitorList={
                  showExpoFeatureProductExibitorList
                }
                getGuestFeaturedProductList={getGuestFeaturedProductList}
                totalProducts={totalProducts}
                totalExhibitorProducts={totalExhibitorProducts}
                totalGuestProducts={totalGuestProducts}
                isExhibitor={isExhibitor}
              />
              <ListProduct
                products={productList}
                loadingExpo={loadingExpo}
                slug={slug}
                isExhibitor={isExhibitor}
              />
            </div>
          </div>
        </StandardLanding>
      </div>
      <Modal
        title="NEED HELP?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <p className="lg:text-xl">
          Hi, good day! Do you need any help from us? Here's our official
          contact details. Kindly message us here{" "}
        </p>

        <ul className="mt-10">
          <li className="flex items-center my-2">
            <i className="bg-red-20 text-white rounded-lg fas fa-envelope p-4"></i>
            <p className="ml-3">Email: support@highlysucceed.com</p>
          </li>
          <li className="flex items-center my-2">
            <i className="bg-red-20 text-white rounded-lg fas fa-phone p-4"></i>
            <p className="ml-3">Contact Number: 09567901998</p>
          </li>
        </ul>
        <p className="text-gray-10 mt-5">
          One of our team will check and will reach out to you soon. If you have
          any questions in the future, please let us know.
        </p>
      </Modal>
    </>
  );
}

export default ExpoHallLanding;