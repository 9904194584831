import { authHeader, getAuthHeader, getError, axios } from "../../../shared/utils";
import {
  API_OTOP_ADDRESS_LIST,
  API_OTOP_ADDRESS_CREATE,
  API_OTOP_ADDRESS_EDIT,
  API_OTOP_ADDRESS_DELETE,
  API_OTOP_ADDRESS_READ,
} from "../../../shared/constant/url";

export const ADDRESS = {
  ADDRESS_LIST_START: "ADDRESS_LIST_START",
  ADDRESS_LIST_SUCCESS: "ADDRESS_LIST_SUCCESS",
  ADDRESS_LIST_ERROR: "ADDRESS_LIST_ERROR",

  ADDRESS_READ_START: "ADDRESS_READ_START",
  ADDRESS_READ_SUCCESS: "ADDRESS_READ_SUCCESS",
  ADDRESS_READ_ERROR: "ADDRESS_READ_ERROR",

  ADDRESS_ADD_EDIT_START: "ADDRESS_ADD_EDIT_START",
  ADDRESS_ADD_EDIT_SUCCESS: "ADDRESS_ADD_EDIT_SUCCESS",
  ADDRESS_ADD_EDIT_ERROR: "ADDRESS_ADD_EDIT_ERROR",

  ADDRESS_DELETE_START: "ADDRESS_DELETE_START",
  ADDRESS_DELETE_SUCCESS: "ADDRESS_DELETE_SUCCESS",
  ADDRESS_DELETE_ERROR: "ADDRESS_DELETE_ERROR",
};

export const getAddressList = () => {
  return (dispatch) => {
    dispatch({ type: ADDRESS.ADDRESS_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_ADDRESS_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ADDRESS.ADDRESS_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.ADDRESS_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readAddress = (id) => {
  return (dispatch) => {
    dispatch({ type: ADDRESS.ADDRESS_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ADDRESS_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ADDRESS.ADDRESS_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.ADDRESS_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createAddress = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADDRESS.ADDRESS_ADD_EDIT_START });
  
    axios
      .post(`${API_OTOP_ADDRESS_CREATE}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADDRESS.ADDRESS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ADDRESS.ADDRESS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editAddress = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADDRESS.ADDRESS_ADD_EDIT_START });
  
    axios
      .put(`${API_OTOP_ADDRESS_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADDRESS.ADDRESS_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ADDRESS.ADDRESS_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteAddress = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADDRESS.ADDRESS_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_ADDRESS_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADDRESS.ADDRESS_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ADDRESS.ADDRESS_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};