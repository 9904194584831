import { getAuthHeader, getError, axios, authHeader } from "../../../../shared/utils";
import {
  API_OTOP_USER_LIST,
  API_OTOP_USER_CREATE,
  API_OTOP_USER_EDIT,
  API_OTOP_USER_DELETE,
  API_OTOP_USER_READ,
} from "../../../../shared/constant/url";

export const USER = {
  USER_LIST_START: "USER_LIST_START",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_ERROR: "USER_LIST_ERROR",

  USER_READ_START: "USER_READ_START",
  USER_READ_SUCCESS: "USER_READ_SUCCESS",
  USER_READ_ERROR: "USER_READ_ERROR",

  USER_ADD_EDIT_START: "USER_ADD_EDIT_START",
  USER_ADD_EDIT_SUCCESS: "USER_ADD_EDIT_SUCCESS",
  USER_ADD_EDIT_ERROR: "USER_ADD_EDIT_ERROR",

  USER_DELETE_START: "USER_DELETE_START",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_ERROR: "USER_DELETE_ERROR",
};

export const getUserList = (userType) => {
  return (dispatch) => {
    dispatch({ type: USER.USER_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_USER_LIST}?type=${userType ? userType: ''}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: USER.USER_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readUser = (id, isSuperAdmin=null) => {
  return (dispatch) => {
    dispatch({ type: USER.USER_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_USER_READ}/${id}?super_admin=${isSuperAdmin ? true : ''}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: USER.USER_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createUser = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: USER.USER_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("firstname", params.firstname); 
    formData.append("lastname", params.lastname);
    formData.append("contact_number", params.contact_number);
    formData.append("email", params.email);
    formData.append("password", params.password);
    formData.append("password_confirmation", params.password_confirmation);
    formData.append("position", params.position); 
    formData.append("region", params.region); 
    formData.append("town", params.town); 
    formData.append("barangay", params.barangay); 
    formData.append("unit", params.unit); 
    formData.append("store_name", params.store_name); 
    formData.append("street_name", params.street_name);
    if(params.store_image && params.store_image[0]) {
      formData.append("store_image", params.store_image[0].originFileObj);
    } 

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_OTOP_USER_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: USER.USER_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: USER.USER_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editUser = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: USER.USER_ADD_EDIT_START });
  
    axios
      .put(`${API_OTOP_USER_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: USER.USER_ADD_EDIT_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: USER.USER_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteUser = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: USER.USER_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_USER_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: USER.USER_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: USER.USER_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

