import { getAuthHeader, getError } from "../../shared/utils";
import moment from "moment";
import {
  API_DISBURSEMENT_LIST,
  API_DISBURSEMENT_CREATE,
  API_DISBURSEMENT_READ,
  API_DISBURSEMENT_UPDATE,
  API_DISBURSEMENT_DELETE,
  API_DISBURSEMENT_TRANSACTION_LIST,
} from "../../shared/constant/url";

export const DISBURSEMENT = {
  DISBURSEMENT_LIST_START: "DISBURSEMENT_LIST_START",
  DISBURSEMENT_LIST_SUCCESS: "DISBURSEMENT_LIST_SUCCESS",
  DISBURSEMENT_LIST_ERROR: "DISBURSEMENT_LIST_ERROR",

  DISBURSEMENT_READ_START: "DISBURSEMENT_READ_START",
  DISBURSEMENT_READ_SUCCESS: "DISBURSEMENT_READ_SUCCESS",
  DISBURSEMENT_READ_ERROR: "DISBURSEMENT_READ_ERROR",

  DISBURSEMENT_ADD_EDIT_START: "DISBURSEMENT_ADD_EDIT_START",
  DISBURSEMENT_ADD_EDIT_SUCCESS: "DISBURSEMENT_ADD_EDIT_SUCCESS",
  DISBURSEMENT_ADD_EDIT_ERROR: "DISBURSEMENT_ADD_EDIT_ERROR",

  DISBURSEMENT_DELETE_START: "DISBURSEMENT_DELETE_START",
  DISBURSEMENT_DELETE_SUCCESS: "DISBURSEMENT_DELETE_SUCCESS",
  DISBURSEMENT_DELETE_ERROR: "DISBURSEMENT_DELETE_ERROR",

  DISBURSEMENT_TRANSACTION_LIST_START: "DISBURSEMENT_TRANSACTION_LIST_START",
  DISBURSEMENT_TRANSACTION_LIST_SUCCESS: "DISBURSEMENT_TRANSACTION_LIST_SUCCESS",
  DISBURSEMENT_TRANSACTION_LIST_ERROR: "DISBURSEMENT_TRANSACTION_LIST_ERROR",
};

export const getDisbursementList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 0);
    }

    if(params){
      if(params.status){
        formData.append("status", params.status);
      } else if(params.start_date && params.end_date){
      if(params.end_date){
        formData.append("end_date", moment(params.end_date).format("YYYY-MM-DD"));
      } 
      if(params.start_date){
        formData.append("start_date", moment(params.start_date).format("YYYY-MM-DD"));
      }
    }
  }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DISBURSEMENT_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readDisbursement = (id) => {
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_READ_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DISBURSEMENT_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createDisbursement = (params, id, callback = null) => {
console.log("id", id)
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("account_number", params.account_number);
    formData.append("account_name", params.account_name);
    formData.append("bank_name", params.bank_name);
    formData.append("amount", params.amount);
    // const product_id = id.slice().reverse().filter((v,i,a)=>a.findIndex(t=>(t.prod_id === v.prod_id))===i).reverse()
    if(id){
    id.forEach((prod, i) => {
         formData.append(`item[${i}][product_id]`, prod);
         console.log("prod", prod, i)
   });
  }
   
    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DISBURSEMENT_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editDisbursement = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("account_number", params.account_number);
    formData.append("account_name", params.account_name);
    formData.append("bank_name", params.bank_name);
    formData.append("amount", params.amount);
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DISBURSEMENT_UPDATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteDisbursement = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_DELETE_START });
    let formData = new FormData();
    formData.append("id", id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_DISBURSEMENT_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getDisbursementTransactionList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 0);
    }

    if(params){
    if(params.status){
      formData.append("status", params.status);
    } else if(params.start_date && params.end_date){
    if(params.end_date){
      formData.append("end_date", moment(params.end_date).format("YYYY-MM-DD"));
    } 
    if(params.start_date){
      formData.append("start_date", moment(params.start_date).format("YYYY-MM-DD"));
    }
  }
  }


    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData
    };
    fetch(API_DISBURSEMENT_TRANSACTION_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DISBURSEMENT.DISBURSEMENT_TRANSACTION_LIST_ERROR,
          payload: getError(error),
        });
      });
    }
  }