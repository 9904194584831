import React from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';
import { sendContactUs } from '../../general/actions';

function FormCreateExpo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(sendContactUs(values, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'SUCCESS') {
        notification.success({
          message:
            'You have successfully sent a message to us. One of our team will check and will reach out to you soon.',
        });
        form.resetFields();
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
        if (response.errors.contact_number) {
          form.setFields([
            {
              name: 'contact_number',
              errors: [`${response.errors.contact_number}`],
            },
          ]);
        }
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }
  };

  return (
    <FormWrapper>
      <Form
        name="contact-us"
        className="form-flex"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: 'Please input your name!' },
              { pattern: new RegExp(/[^0-9\s]/g), message: 'Invalid format' },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <i className="fas fa-user text-blue-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Interest"
            name="interest"
            rules={[
              { required: true, message: 'Please input your interest!' },
              { pattern: new RegExp(/[^0-9\s]/g), message: 'Invalid format' },
            ]}
          >
            <Input placeholder="Interest" />
          </Form.Item>
          <i className="fas fa-list text-blue-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Invalid format' },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <i className="fas fa-envelope text-blue-20 form-icon"></i>
        </div>
        <div className="relative w-full lg:w-1/2 my-3 px-2">
          <Form.Item
            label="Contact Number"
            name="contact_number"
            rules={[
              {
                required: true,
                message: "Please input your contact number!",
              },
              {
                pattern: new RegExp(/(^(9|09|639)\d{9}$)/g),
                message: "Please input the correct contact number!",
              },
              {
                pattern: new RegExp(/[^a-z\s]/g),
                message: "Invalid format",
              },
            ]}
          >
            <Input type="tel" placeholder="Contact Number" />
          </Form.Item>
          <i className="fas fa-phone text-blue-20 form-icon"></i>
        </div>
        <div className="relative w-full my-3 px-2">
          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: 'Please input your message!',
              },
            ]}
          >
            <Input.TextArea placeholder="Message" />
          </Form.Item>
          <i className="fas fa-envelope text-blue-20 form-icon"></i>
        </div>

        <div className="flex justify-between items-center mt-5 mx-3">
          <Button className="btn-main" htmlType="submit">
            <i className="fas fa-pen mr-1"></i>
            <span>Submit</span>
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
}

export default FormCreateExpo;
