import React from 'react';
import styled from '@emotion/styled';

function TabsWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default TabsWrapper;

const StyledDiv = styled.div({
  width: '100%',
  '.ant-tabs .ant-tabs-nav': {
    backgroundColor: '#FFF !important',
    borderRadius: '20px',
    padding: '10px 50px 20px 50px',
    fontSize: '2rem',
  },
  '.ant-tabs-top .ant-tabs-nav::before': {
    borderBottom: 'none !important',
  },
  '.ant-tabs-tab': {
    fontSize: '1.2rem',
    color: '#b1b1b1',
  },
  '.ant-tabs-tab-active .ant-tabs-tab-btn': {
    color: '#cd1f30',
  },
  '.ant-tabs-tab-disabled': {
    color: '#cd1f30',
    cursor: 'default',
  },
  '.ant-tabs-ink-bar': {
    display: 'none',
  },
});
