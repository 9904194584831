import React, { useState } from "react";
import { logout } from '../../auth/actions';
import { register } from "../actions";
import FormRegister from "../components/FormRegister";

function Register(props) {
  return (
    <FormRegister register={register} slug={props.slug} item={props.item} />
    )
}

export default Register;