import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { decode } from "he";

import StandardLanding from "../../../shared/components/StandardLanding";

function ExhibitorPolicy(props) {
  const { state } = props.location;
  const [deets, setDeets] = useState({});
  console.log("deets", deets);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isEmpty(state)) {
      const { deets } = props.location.state;
      setDeets(deets);
    }
  }, [state]);

  const displayTerms = (desc) => {
    let holder = desc.replace(/<\/?[^>]+(>|$)/g, "");
    let temp = holder;
    let temp2 = decode(temp);

    return temp2;
  };

  const displayRefund = (desc) => {
    let holder = desc.replace(/<\/?[^>]+(>|$)/g, "");
    let temp = holder;
    let temp2 = decode(temp);

    return temp2;
  };

  const renderTermsAndConditions = () => {
    if (deets && deets.terms_condition) {
      return (
        <div dangerouslySetInnerHTML={{ __html: deets.terms_condition }} />
      );
    } else {
      return "No Terms and Condition";
    }
  };

  const renderRefundPolicy = () => {
    if (deets && deets.refund_policy) {
      return (
        <div dangerouslySetInnerHTML={{ __html: deets.refund_policy }} />
      );
    } else {
      return "No Refund Policy";
    }
  };

  return (
    <StandardLanding>
      <div className="bg-main-content content-bg">
        <div className="content-space px-5 py-10 lg:p-20">
          <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
            <h1 className="content-subheader">
              TERMS AND CONDITIONS AND POLICIES
            </h1>
            {renderTermsAndConditions()}
          </div>

          <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full mt-20">
            <h1 className="content-subheader">REFUND POLICY</h1>
            {renderRefundPolicy()}
          </div>
        </div>
      </div>
    </StandardLanding>
  );
}
export default ExhibitorPolicy;