import { PAYMENT } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  listPayment: [],
};

const payment = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case PAYMENT.LIST_PAYMENT_OPTION_START:
      return { ...state, loading: true, error: "" };
    case PAYMENT.LIST_PAYMENT_OPTION_SUCCESS:
      return { ...state, loading: false, listPayment: action.payload.data };
    case PAYMENT.LIST_PAYMENT_OPTION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default payment;