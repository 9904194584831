import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { subscribeNewsletter } from '../../general/actions';
import FormWrapperNoMargin from '../../../shared/components/FormWrapperNoMargin';

function FormSubscribe(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // console.log("history", pathname)
  const onFinish = (values) => {
    dispatch(subscribeNewsletter(values, callback));
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'SUCCESS') {
        notification.success({ message: `${response.msg}` });
      } else if (statusCode === 'ALREADY_SUBSCIRBED') {
        notification.warning({ message: `${response.msg}` });
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.email) {
          form.setFields([
            {
              name: 'email',
              errors: [`${response.errors.email}`],
            },
          ]);
        }
      }
    }
  };

  return (
    <FormWrapperNoMargin>
      <Form
        name="login"
        className="flex w-full"
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="email"
          className="rounded-l-lg text-black bg-gray-10 px-3 py-3 w-full"
          rules={[{ required: false, message: 'Please input your email!' }]}
        >
          <Input placeholder="Enter Email Address" />
        </Form.Item>

        <button
          className="btn-left btn-blue flex items-center px-2 lg:px-5"
          htmlType="submit"
        >
          <i className="fas fa-paper-plane pr-3 text-white"></i>
          <span className="text-white">SEND</span>
        </button>
      </Form>
    </FormWrapperNoMargin>
  );
}

export default FormSubscribe;
