import React from 'react';
import styled from '@emotion/styled';

function FormWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FormWrapper;

export const FormItem = styled.div`
  margin-top: "-17px !important",
  position: "absolute !important",
  top: "0 !important",
  z-ndex: "10",
  margin-left: "10px",
  padding: "0px 5px",
`;

const StyledDiv = styled.div({
  width: '100%',
  '.form-flex': {
    display: 'flex !important',
    flexFlow: 'wrap',
    '.form-icon': {
      position: 'absolute',
      left: '0',
      top: '0',
      margin: '1.5em 1.5em 0 1.5em',
      zIndex: 10,
    },
  },
  '.ant-input': {
    color: '#4E4E4E',
    border: '2px #C5C5C5 solid',
    borderRadius: '0.5rem',
    padding: '15px 10px 15px 40px',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px white inset !important',
    },
  },
  '.ant-input-password': {
    color: '#4E4E4E',
    border: '2px #C5C5C5 solid',
    borderRadius: '0.5rem',
    padding: '15px 10px 15px 40px',
  },
  '.ant-select': {
    color: '#4E4E4E',
    border: '2px #C5C5C5 solid !important',
    borderRadius: '0.5rem',
    padding: '10px 10px 10px 40px',
    zIndex: '5',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px white inset !important',
    },
  },
  '.ant-form-item-control-input-content': {
    background: '#FFF !important',
    borderRadius: '0.5rem',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px white inset !important',
    },
  },
  '.ant-picker': {
    color: '#4E4E4E',
    border: '2px #C5C5C5 solid !important',
    borderRadius: '0.5rem',
    padding: '10px 10px 10px 40px',
    zIndex: '5',
    width: '100%',
  },
  '.ant-input-number': {
    color: '#4E4E4E',
    border: '2px #C5C5C5 solid',
    borderRadius: '0.5rem',
    padding: '10px 10px 10px 40px',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px white inset !important',
    },
  },
  '.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: '0',
  },
  '.ant-form-item-label': {
    marginTop: '-10px !important',
    position: 'absolute !important',
    top: '0 !important',
    zIndex: '10',
    marginLeft: '10px',
    padding: '0px 5px',
  },
  '.ant-form-item .ant-form-item-label > label': {
    color: '#AE1C27',
    background: '#FFF',
    height: '20px !important',
    fontSize: '16px',
    padding: '0px 5px',
  },
  '.ant-form-item .ant-form-item-label > label:before, .ant-form-item .ant-form-item-label > label:after':
    {
      display: 'none',
    },
  '.ant-btn:not(.ant-upload-list-item-actions .ant-btn)': {
    background: '#cd1f30',
    color: '#FFF',
    fontSize: '1.1em',
    borderRadius: '0.625rem',
    border: '0',
    letterSpacing: '1px',
    minWidth: '120px',
    height: '56px',
    '&:focus': {
      borderColor: 'none',
    },
    '&:hover': {
      borderColor: 'none',
      background: '#E42F30 !important',
      outline: '0',
    },
    '&.btn-white': {
      color: '#d63d4b',
      background: '#fff',
      '&:hover': {
        color: '#fff',
      },
    },
  },
  '.custom-upload-item': {
    '.ant-upload.ant-upload-select-picture-card, .ant-upload-list-picture-card-container':
      {
        marginRight: 0,
        width: '100% !important',
        maxWidth: '600px !important',
        height: '100% !important',
        maxHeight: '600px !important',
      },
    '.ant-upload.ant-upload-select-picture-card, .ant-upload-list-picture-card-container *':
      {
        aspectRatio: '1',
        borderRadius: '1rem',
      },
    '.ant-upload-list-item-actions': {
      a: {
        display: 'none',
      },
    },
  },
});
