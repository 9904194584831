import { getAuthHeader, getError, axios } from "../../../../shared/utils";
import {
  API_OTOP_SUMMARY,
  API_OTOP_SALES_GENERATE
} from "../../../../shared/constant/url";
import moment from "moment";

export const PRODUCT = {
  OTOP_SUMMARY_START: "OTOP_SUMMARY_START",
  OTOP_SUMMARY_SUCCESS: "OTOP_SUMMARY_SUCCESS",
  OTOP_SUMMARY_ERROR: "OTOP_SUMMARY_ERROR",

  OTOP_SALE_GENERATE_START: "OTOP_SALE_GENERATE_START",
  OTOP_SALE_GENERATE_SUCCESS: "OTOP_SALE_GENERATE_SUCCESS",
  OTOP_SALE_GENERATE_ERROR: "OTOP_SALE_GENERATE_ERROR",
};

export const getOtopSummary = () => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_SUMMARY_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_SUMMARY}${moment().format("YYYY")}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.OTOP_SUMMARY_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_SUMMARY_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getOtopSale = () => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.OTOP_SALE_GENERATE_START });
    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_SALES_GENERATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response)
        dispatch({
          type: PRODUCT.OTOP_SALE_GENERATE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.OTOP_SALE_GENERATE_ERROR,
          payload: getError(error),
        });
      });
  };
};