import { EXHIBITOR } from "./actions";

const INITIAL_STATE = {
  loading: false,
  loadingProduct: false,
  total: 0,
  totalProducts: 0,
  totalProductMerchant: 0,
  error: "",
  exhibitors: [],
  exhibitorDetails: {},
  exhibitorProducts: [],
  exhibitorProduct: {},
  exhibitorProductShow: {},
  exhibitorDetailsMerchant: {},
  exhibitorProductMerchant: [],
  exhibitorListGallery: []
};

const exhibitor = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXHIBITOR.EXHIBITOR_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXHIBITOR.EXHIBITOR_LIST_SUCCESS:
      let temp = action.payload;
      let { total } = temp;
      return {
        ...state,
        loading: false,
        exhibitors: action.payload.data,
        total,
      };
    case EXHIBITOR.EXHIBITOR_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_SHOW_START:
      return { ...state, loading: true, error: "" };
    case EXHIBITOR.EXHIBITOR_SHOW_SUCCESS:
      return { ...state, loading: false, exhibitorDetails: action.payload };
    case EXHIBITOR.EXHIBITOR_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_SUCCESS:
      let holder = action.payload;
      let totalProducts = holder.total;
      return {
        ...state,
        loading: false,
        exhibitorProducts: action.payload.data,
        totalProducts,
      };
    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_START:
      return { ...state, loadingProduct: true, error: "" };
    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_SUCCESS:
      return {
        ...state,
        loadingProduct: false,
        exhibitorProduct: action.payload,
      };
    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_ERROR:
      return { ...state, loadingProduct: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_START:
      return { ...state, loading: true, error: "" };
    case EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        exhibitorDetailsMerchant: action.payload,
      };
    case EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_START:
      return { ...state, loadingProduct: true, error: "" };
    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_SUCCESS:
      let holder2 = action.payload;
      let totalProductMerchant = holder2.total;
      return {
        ...state,
        loadingProduct: false,
        exhibitorProductMerchant: action.payload.data,
        totalProductMerchant,
      };
    case EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_ERROR:
      return { ...state, loadingProduct: false, error: action.payload };

    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_START:
      return { ...state, loadingProduct: true, error: "" };
    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_SUCCESS:
      return {
        ...state,
        loadingProduct: false,
        exhibitorProductShow: action.payload,
      };
    case EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_ERROR:
      return { ...state, loadingProduct: false, error: action.payload };

      case EXHIBITOR.EXHIBITOR_LIST_GALLERY_START:
        return { ...state, loading: true, error: "" };
      case EXHIBITOR.EXHIBITOR_LIST_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          exhibitorListGallery: action.payload,
        };
      case EXHIBITOR.EXHIBITOR_LIST_GALLERY_ERROR:
        return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default exhibitor;