import { CATEGORY } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  categories: [],
  categoryDetails: "",
  category: {},
  categoryDelete: {},
};

const category = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case CATEGORY.CATEGORY_LIST_START:
      return { ...state, loading: true, error: "" };
    case CATEGORY.CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case CATEGORY.CATEGORY_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATEGORY.CATEGORY_READ_START:
      return { ...state, loading: true, error: "" };
    case CATEGORY.CATEGORY_READ_SUCCESS:
      return { ...state, loading: false, categoryDetails: action.payload };
    case CATEGORY.CATEGORY_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATEGORY.CATEGORY_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case CATEGORY.CATEGORY_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, category: action.payload };
    case CATEGORY.CATEGORY_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATEGORY.CATEGORY_DELETE_START:
      return { ...state, loading: true, error: "" };
    case CATEGORY.CATEGORY_DELETE_SUCCESS:
      return { ...state, loading: false, categoryDelete: action.payload };
    case CATEGORY.CATEGORY_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default category;