import { PROFILE } from "./actions";

const INITIAL_STATE = {
  loading: false,
  errorProfile: "",
  totalExpoParticipant: 0,
  totalExpoExhibitor: 0,
  totalActivities: 0,
  totalTransactions: 0,
  totalActivitiesExhibitor: 0,
  profileDetails: {},
  profilePassword: {},
  profilePhoto: {},
  expoParticipant: [],
  expoExhibitor: [],
  profileDetailsExhibitor: {},
  activity: [],
  transactions: [], 
  transactionDetails: {}, 
  transactionsExhibitor: [], 
  transactionDetailsExhibitor: {}, 
  transactionStatus: {},
  activityExhibitor: [],
};

const profile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE.PROFILE_READ_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_READ_SUCCESS:
      return { ...state, loading: false, profileDetails: action.payload };
    case PROFILE.PROFILE_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_SUCCESS:
      return { ...state, loading: false, profile: action.payload };
    case PROFILE.PROFILE_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_PASSWORD_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, profilePassword: action.payload };
    case PROFILE.PROFILE_UPDATE_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_PHOTO_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_PHOTO_SUCCESS:
      return { ...state, loading: false, profilePhoto: action.payload };
    case PROFILE.PROFILE_UPDATE_PHOTO_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_EXPO_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_EXPO_SUCCESS:
      let temp = action.payload;
      let totalExpoParticipant = temp.total;
      return { ...state, loading: false, expoParticipant: action.payload.data, totalExpoParticipant};
    case PROFILE.PROFILE_EXPO_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_ACTIVITY_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_ACTIVITY_SUCCESS:
      let temp2 = action.payload;
      let totalActivities = temp2.total;
      return { ...state, loading: false, activity: action.payload.data, totalActivities};
    case PROFILE.PROFILE_ACTIVITY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_TRANSACTION_LIST_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_TRANSACTION_LIST_SUCCESS:
      let temp3 = action.payload;
      let totalTransactions = temp3.total;
      return { ...state, loading: false, transactions: action.payload.data, totalTransactions};
    case PROFILE.PROFILE_TRANSACTION_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    
    case PROFILE.PROFILE_TRANSACTION_SHOW_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_TRANSACTION_SHOW_SUCCESS:
      return { ...state, loading: false, transactionDetails: action.payload};
    case PROFILE.PROFILE_TRANSACTION_SHOW_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_READ_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_READ_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, profileDetailsExhibitor: action.payload,};
    case PROFILE.PROFILE_READ_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, profile: action.payload };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_SUCCESS:
      return { ...state, loading: false, profilePassword: action.payload };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_SUCCESS:
      return { ...state, loading: false, profilePhoto: action.payload };
    case PROFILE.PROFILE_UPDATE_EXHIBITOR_PHOTO_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_EXPO_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_EXPO_EXHIBITOR_SUCCESS:
      let temp4 = action.payload;
      let totalExpoExhibitor = temp4.total;
      return { ...state, loading: false, expoExhibitor: action.payload.data, totalExpoExhibitor};
    case PROFILE.PROFILE_EXPO_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_SUCCESS:
      let temp5 = action.payload;
      let totalTransactionExhibitor = temp5.total;
      return { ...state, loading: false, transactionsExhibitor: action.payload.data, totalTransactionExhibitor }
    case PROFILE.PROFILE_TRANSACTION_LIST_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_SUCCESS:
      return { ...state, loading: false, transactionDetailsExhibitor: action.payload};
    case PROFILE.PROFILE_TRANSACTION_SHOW_EXIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };      

    case PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_SUCCESS:
      return { ...state, loading: false, transactionStatus: action.payload};
    case PROFILE.PROFILE_TRANSACTION_UPDATE_STATUS_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };
        
    case PROFILE.PROFILE_ACTIVITY_EXHIBITOR_START:
      return { ...state, loading: true, error: "" };
    case PROFILE.PROFILE_ACTIVITY_EXHIBITOR_SUCCESS:
      let temp6 = action.payload;
      let totalActivitiesExhibitor = temp6.total;
      return { ...state, loading: false, activityExhibitor: action.payload.data, totalActivitiesExhibitor};
    case PROFILE.PROFILE_ACTIVITY_EXHIBITOR_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default profile;