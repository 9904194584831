import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "antd";

import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import { getBuyerTransactionList, editBuyerTransaction } from "../actions";
import TableTransaction from "../components/TableTransacton";

const { TabPane } = Tabs;

const LandingTransaction = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { transactions, loading } = state.buyerTransaction;
  const [toPayList, setToPayList] = useState([]);
  const [toShipList, setToShipList] = useState([]);
  const [toReceiveList, setToReceiveList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [tab, setTab] = useState("1");
  const { included } = transactions;

  useEffect(() => {
    dispatch(getBuyerTransactionList());
  }, []);

  useEffect(() => {
    if (transactions && transactions.data) {
      const { data } = transactions;
      let toPayListFiltered = data.filter(
        (item) => item.attributes.status === "Incomplete"
      );
      setToPayList(toPayListFiltered);

      let toShipListFiltered = data.filter(
        (item) => item.attributes.status === "To ship"
      );
      setToShipList(toShipListFiltered);

      let toReceiveListFiltered = data.filter(
        (item) => item.attributes.status === "To receive"
      );
      setToReceiveList(toReceiveListFiltered);

      let completedListFiltered = data.filter(
        (item) => item.attributes.status === "Completed"
      );
      setCompletedList(completedListFiltered);
    }
  }, [transactions]);

  const callback = (key) => {
    switch (key) {
      case "1":
        setTab("1");
        break;
      case "2":
        setTab("2");
        break;
      case "3":
        setTab("3");
        break;
      case "4":
        setTab("4");
        break;
      default:
        setTab("1");
        break;
    }
  };

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
          <div className="my-10 p-5 bg-white">
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="To Pay" key="1">
                <TableTransaction
                  toPayList={toPayList}
                  loading={loading}
                  getOrderList={getBuyerTransactionList}
                  type={"buyer"}
                  tab={tab}
                  included={included}
                  editBuyerTransaction={editBuyerTransaction}
                />
              </TabPane>
              <TabPane tab="To Ship" key="2">
                <TableTransaction
                  toShipList={toShipList}
                  loading={loading}
                  getOrderList={getBuyerTransactionList}
                  type={"buyer"}
                  tab={tab}
                  included={included}
                  editBuyerTransaction={editBuyerTransaction}
                />
              </TabPane>
              <TabPane tab="To Receive" key="3">
                <TableTransaction
                  toReceiveList={toReceiveList}
                  loading={loading}
                  getOrderList={getBuyerTransactionList}
                  type={"buyer"}
                  tab={tab}
                  included={included}
                  editBuyerTransaction={editBuyerTransaction}
                />
              </TabPane>
              <TabPane tab="Completed" key="4">
                <TableTransaction
                  completedList={completedList}
                  loading={loading}
                  getOrderList={getBuyerTransactionList}
                  type={"buyer"}
                  tab={tab}
                  included={included}
                  editBuyerTransaction={editBuyerTransaction}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default LandingTransaction;