import { getAuthHeader, getError } from "../../../shared/utils";
import {
  API_OTOP_TRANSACTION_EDIT,
  API_OTOP_TRANSACTION_LIST,
  API_OTOP_TRANSACTION_READ,
} from "../../../shared/constant/url";

export const BUYER_TRANSACTION = {
  OTOP_BUYER_TRANSACTION_LIST_START: "OTOP_BUYER_TRANSACTION_LIST_START",
  OTOP_BUYER_TRANSACTION_LIST_SUCCESS: "OTOP_BUYER_TRANSACTION_LIST_SUCCESS",
  OTOP_BUYER_TRANSACTION_LIST_ERROR: "OTOP_BUYER_TRANSACTION_LIST_ERROR",

  OTOP_BUYER_TRANSACTION_READ_START: "OTOP_BUYER_TRANSACTION_READ_START",
  OTOP_BUYER_TRANSACTION_READ_SUCCESS: "OTOP_BUYER_TRANSACTION_READ_SUCCESS",
  OTOP_BUYER_TRANSACTION_READ_ERROR: "OTOP_BUYER_TRANSACTION_READ_ERROR",
  
  OTOP_BUYER_TRANSACTION_EDIT_START: "OTOP_BUYER_TRANSACTION_EDIT_START",
  OTOP_BUYER_TRANSACTION_EDIT_SUCCESS: "OTOP_BUYER_TRANSACTION_EDIT_SUCCESS",
  OTOP_BUYER_TRANSACTION_EDIT_ERROR: "OTOP_BUYER_TRANSACTION_EDIT_ERROR",
};

export const getBuyerTransactionList = () => {
  return (dispatch) => {
    dispatch({ type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_TRANSACTION_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readBuyerTransaction = (id) => {
  return (dispatch) => {
    dispatch({ type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_TRANSACTION_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editBuyerTransaction = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_EDIT_START });
    let formData = new FormData();
    formData.append("status_id", params.status_id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_OTOP_TRANSACTION_EDIT}/${id}?_method=PUT`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: BUYER_TRANSACTION.OTOP_BUYER_TRANSACTION_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};