import { getAuthHeader, getError } from '../../shared/utils';
import {
  API_EXPO_LIST,
  API_EXPO_SHOW,
  API_EXPO_PARTNER_LIST,
  API_FEATURED_OFFER_LIST,
  API_FEATURED_PRODUCT_LIST,
  API_EXPO_HALL_EXHIBITOR,
  API_EXPO_HALL_SHOW,
  API_EXPO_UPCOMING_LIST,
  API_EXPO_JOIN,
  API_EXPO_SEMINAR_LIST,
  API_EXPO_SEMINAR_MAIN,
  API_EXPO_JOIN_EXHIBITOR,
  API_EXPO_SHOW_EXHIBITOR,
  API_FEATURED_PRODUCT_EXHIBITOR_LIST,
  API_EXPO_HALL_EXHIBITOR_MERCHANT,
  API_EXPO_SEMINAR_MAIN_MERCHANT,
  API_EXPO_SEMINAR_LIST_MERCHANT,
  API_EXPO_SEMINAR_DISCUSSION_LIST,
  API_EXPO_SEMINAR_DISCUSSION_CREATE,
  API_CLICK_COUNTER,
  API_EXPO_HALL_PRODUCTS,
} from '../../shared/constant/url';
import Axios from 'axios';

export const EXPO = {
  EXPO_LIST_START: 'EXPO_LIST_START',
  EXPO_LIST_SUCCESS: 'EXPO_LIST_SUCCESS',
  EXPO_LIST_ERROR: 'EXPO_LIST_ERROR',

  EXPO_SHOW_START: 'EXPO_SHOW_START',
  EXPO_SHOW_SUCCESS: 'EXPO_SHOW_SUCCESS',
  EXPO_SHOW_ERROR: 'EXPO_SHOW_ERROR',

  EXPO_SHOW_PARTNER_LIST_START: 'EXPO_SHOW_PARTNER_LIST_START',
  EXPO_SHOW_PARTNER_LIST_SUCCESS: 'EXPO_SHOW_PARTNER_LIST_SUCCESS',
  EXPO_SHOW_PARTNER_LIST_ERROR: 'EXPO_SHOW_ACCREDITATION_ERROR',

  EXPO_SHOW_FEATURED_OFFER_LIST_START: 'EXPO_SHOW_FEATURED_OFFER_LIST_START',
  EXPO_SHOW_FEATURED_OFFER_LIST_SUCCESS:
    'EXPO_SHOW_FEATURED_OFFER_LIST_SUCCESS',
  EXPO_SHOW_FEATURED_OFFER_LIST_ERROR: 'EXPO_SHOW_FEATURED_OFFER_LIST_ERROR',

  EXPO_SHOW_FEATURED_PRODUCT_LIST_START:
    'EXPO_SHOW_FEATURED_PRODUCT_LIST_START',
  EXPO_SHOW_FEATURED_PRODUCT_LIST_SUCCESS:
    'EXPO_SHOW_FEATURED_PRODUCT_LIST_SUCCESS',
  EXPO_SHOW_FEATURED_PRODUCT_LIST_ERROR: 'SHOW_FEATURED_PRODUCT_LIST_ERROR',

  EXPO_HALL_EXHIBITOR_LIST_START: 'EXPO_HALL_EXHIBITOR_LIST_START',
  EXPO_HALL_EXHIBITOR_LIST_SUCCESS: 'EXPO_HALL_EXHIBITOR_LIST_SUCCESS',
  EXPO_HALL_EXHIBITOR_LIST_ERROR: 'EXPO_HALL_EXHIBITOR_LIST_ERROR',

  EXPO_HALL_SHOW_START: 'EXPO_HALL_SHOW_START',
  EXPO_HALL_SHOW_SUCCESS: 'EXPO_HALL_SHOW_SUCCESS',
  EXPO_HALL_SHOW_ERROR: 'EXPO_HALL_SHOW_ERROR',

  EXPO_UPCOMING_LIST_START: 'EXPO_UPCOMING_LIST_START',
  EXPO_UPCOMING_LIST_SUCCESS: 'EXPO_UPCOMING_LIST_SUCCESS',
  EXPO_UPCOMING_LIST_ERROR: 'EXPO_UPCOMING_LIST_ERROR',

  EXPO_JOIN_START: 'EXPO_JOIN_START',
  EXPO_JOIN_SUCCESS: 'EXPO_JOIN_SUCCESS',
  EXPO_JOIN_ERROR: 'EXPO_JOIN_ERROR',

  EXPO_SEMINAR_LIST_START: 'EXPO_SEMINAR_LIST_START',
  EXPO_SEMINAR_LIST_SUCCESS: 'EXPO_SEMINAR_LIST_SUCCESS',
  EXPO_SEMINAR_LIST_ERROR: 'EXPO_SEMINAR_LIST_ERROR',

  EXPO_SEMINAR_MAIN_START: 'EXPO_SEMINAR_MAIN_START',
  EXPO_SEMINAR_MAIN_SUCCESS: 'EXPO_SEMINAR_MAIN_SUCCESS',
  EXPO_SEMINAR_MAIN_ERROR: 'EXPO_SEMINAR_MAIN_ERROR',

  EXPO_JOIN_EXHIBITOR_START: 'EXPO_JOIN_EXHIBITOR_START',
  EXPO_JOIN_EXHIBITOR_SUCCESS: 'EXPO_JOIN_EXHIBITOR_SUCCESS',
  EXPO_JOIN_EXHIBITOR_ERROR: 'EXPO_JOIN_EXHIBITOR_ERROR',

  EXPO_SHOW_EXHIBITOR_START: 'EXPO_SHOW_EXHIBITOR_START',
  EXPO_SHOW_EXHIBITOR_SUCCESS: 'EXPO_SHOW_EXHIBITOR_SUCCESS',
  EXPO_SHOW_EXHIBITOR_ERROR: 'EXPO_SHOW_EXHIBITOR_ERROR',

  EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_START:
    'EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_START',
  EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_SUCCESS:
    'EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_SUCCESS',
  EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_ERROR:
    'EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_ERROR',

  EXPO_HALL_EXHIBITOR_LIST_MERCHANT_START:
    'EXPO_HALL_EXHIBITOR_LIST_MERCHANT_START',
  EXPO_HALL_EXHIBITOR_LIST_MERCHANT_SUCCESS:
    'EXPO_HALL_EXHIBITOR_LIST_MERCHANT_SUCCESS',
  EXPO_HALL_EXHIBITOR_LIST_MERCHANT_ERROR:
    'EXPO_HALL_EXHIBITOR_LIST_MERCHANT_ERROR',

  EXPO_SEMINAR_LIST_EXHIBITOR_START: 'EXPO_SEMINAR_LIST_EXHIBITOR_START',
  EXPO_SEMINAR_LIST_EXHIBITOR_SUCCESS: 'EXPO_SEMINAR_LIST_EXHIBITOR_SUCCESS',
  EXPO_SEMINAR_LIST_EXHIBITOR_ERROR: 'EXPO_SEMINAR_LIST_EXHIBITOR_ERROR',

  EXPO_SEMINAR_MAIN_EXHIBITOR_START: 'EXPO_SEMINAR_MAIN_EXHIBITOR_START',
  EXPO_SEMINAR_MAIN_EXHIBITOR_SUCCESS: 'EXPO_SEMINAR_MAIN_EXHIBITOR_SUCCESS',
  EXPO_SEMINAR_MAIN_EXHIBITOR_ERROR: 'EXPO_SEMINAR_MAIN_EXHIBITOR_ERROR',

  EXPO_DISCUSSION_LIST_START: 'EXPO_DISCUSSION_LIST_START',
  EXPO_DISCUSSION_LIST_SUCCESS: 'EXPO_DISCUSSION_LIST_SUCCESS',
  EXPO_DISCUSSION_LIST_ERROR: 'EXPO_DISCUSSION_LIST_ERROR',

  EXPO_DISCUSSION_CREATE_START: 'EXPO_DISCUSSION_CREATE_START',
  EXPO_DISCUSSION_CREATE_SUCCESS: 'EXPO_DISCUSSION_CREATE_SUCCESS',
  EXPO_DISCUSSION_CREATE_ERROR: 'EXPO_DISCUSSION_CREATE_ERROR',

  EXPO_CLICK_START: 'EXPO_CLICK_START',
  EXPO_CLICK_SUCCESS: 'EXPO_CLICK_SUCCESS',
  EXPO_CLICK_ERROR: 'EXPO_CLICK_ERROR',

  EXPO_HALL_PRODUCT_START: 'EXPO_HALL_PRODUCT_START',
  EXPO_HALL_PRODUCT_SUCCESS: 'EXPO_HALL_PRODUCT_SUCCESS',
  EXPO_HALL_PRODUCT_ERROR: 'EXPO_HALL_PRODUCT_ERROR',
};

export const getExpoList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);

    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.region) {
        formData.append('region', params.region);
      }
      if (params.expo_date) {
        formData.append('expo_date', params.expo_date);
      }
      if (params.year) {
        formData.append('year', params.year);
      }
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: EXPO.EXPO_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const showExpoDetails = (slug) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_START });
    let formData = new FormData();
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: EXPO.EXPO_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_SHOW_ERROR, payload: error });
      });
  };
};

export const showExpoPartnerList = (params) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_PARTNER_LIST_START });
    let formData = new FormData();
    formData.append('per_page', 0);
    formData.append('page', 1);
    if (params) {
      formData.append('keyword', params.keyword);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_PARTNER_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SHOW_PARTNER_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_SHOW_PARTNER_LIST_ERROR, payload: error });
      });
  };
};

export const showExpoFeaturedOfferList = () => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_START });
    let formData = new FormData();
    formData.append('per_page', 2);
    formData.append('page', 1);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_FEATURED_OFFER_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_OFFER_LIST_ERROR,
          payload: error,
        });
      });
  };
};

export const showExpoFeatureProductList = (params, value, slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    formData.append('page', 1);
    formData.append('slug', slug);
    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      } else if (params.category_id === 1 || params.category_id === 0) {
        formData.append('category_id', params.category_id);
      } else if (params.price) {
        formData.append('price', params.price);
      }
    }
    if (value) {
      formData.append('sort_alphabetically', value);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_HALL_PRODUCTS, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_ERROR,
          payload: error,
        });
      });
  };
};

export const getExpoHallExhibitorList = (params, value, slug, per_page) => {
  console.log('asad', value);
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_HALL_EXHIBITOR_LIST_START });
    let formData = new FormData();
    formData.append('slug', slug);

    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.category_id === 1 || params.category_id === 1) {
        formData.append('category_id', params.category_id);
      }
      if (params.region) {
        formData.append('region', params.region);
      }
      if (params.product_name) {
        formData.append('product_name', params.product_name);
      }
      if (params.merchant_name) {
        formData.append('merchant_name', params.merchant_name);
      }
    }

    if (value) {
      formData.append('sort_alphabetically', value);
    } else if (value === 0) {
      formData.append('sort_alphabetically', value);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_HALL_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_HALL_EXHIBITOR_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_HALL_EXHIBITOR_LIST_ERROR, payload: error });
      });
  };
};

export const showExpoHall = (params, slug) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_HALL_SHOW_START });
    let formData = new FormData();
    formData.append('slug', slug);
    formData.append('exhibitor_id', params.exhibitor_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_HALL_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: EXPO.EXPO_HALL_SHOW_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_HALL_SHOW_ERROR, payload: error });
      });
  };
};

export const getUpcomingExpoList = () => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_UPCOMING_LIST_START });
    let formData = new FormData();

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_UPCOMING_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_UPCOMING_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_UPCOMING_LIST_ERROR, payload: error });
      });
  };
};

export const joinExpo = (slug, callback = null) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_JOIN_START });
    let formData = new FormData();
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_JOIN, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({ type: EXPO.EXPO_JOIN_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: EXPO.EXPO_JOIN_ERROR, payload: error });
      });
  };
};

export const getSeminarList = (slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SEMINAR_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getSeminarMain = (slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SEMINAR_MAIN_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_MAIN, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_MAIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_MAIN_ERROR,
          payload: getError(error),
        });
      });
  };
};

//  EXHIBITORS
export const joinExpoExhibitor = (slug, callback = null) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_JOIN_EXHIBITOR_START });
    let formData = new FormData();
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_JOIN_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: EXPO.EXPO_JOIN_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: EXPO.EXPO_JOIN_EXHIBITOR_ERROR, payload: error });
      });
  };
};

export const showExpoDetailsExhibitor = (slug) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_EXHIBITOR_START });
    let formData = new FormData();
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SHOW_EXHIBITOR, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SHOW_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: EXPO.EXPO_SHOW_EXHIBITOR_ERROR, payload: error });
      });
  };
};

export const showExpoFeatureProductExibitorList = (
  params,
  value,
  slug,
  per_page
) => {
  // return (dispatch) => {
  //   dispatch({ type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_START });
  //   let formData = new FormData();
  //   if (per_page) {
  //     formData.append("per_page", per_page);
  //   } else {
  //     formData.append("per_page", 1000);
  //   }

  //   formData.append("page", 1);
  //   formData.append("slug", slug);
  //   if (params) {
  //     formData.append("keyword", params.keyword);
  //     formData.append("category_id", params.category_id);
  //     formData.append("price", params.price);
  //   }
  //   if(value){
  //     formData.append("sort_alphabetically", value);
  //   }

  //   let requestOption = {
  //     method: "POST",
  //     headers: getAuthHeader(),
  //     body: formData,
  //   };
  //   fetch(API_EXPO_HALL_PRODUCTS, requestOption)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       dispatch({
  //         type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_SUCCESS,
  //         payload: response,
  //       });
  //     })
  //     .catch((error) => {
  //       dispatch({
  //         type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_ERROR,
  //         payload: error,
  //       });
  //     });
  // };

  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    formData.append('page', 1);
    formData.append('slug', slug);
    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      } else if (params.price) {
        formData.append('price', params.price);
      } else if (params.category_id === 1 || params.category_id === 0) {
        formData.append('category_id', params.category_id);
      }
    }
    if (value) {
      formData.append('sort_alphabetically', value);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_HALL_PRODUCTS, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SHOW_FEATURED_PRODUCT_LIST_EXHIBITOR_ERROR,
          payload: error,
        });
      });
  };
};

export const getExpoHallExhibitorListMerchant = (
  params,
  value,
  slug,
  per_page
) => {
  console.log('value', value);
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_START });
    let formData = new FormData();
    formData.append('slug', slug);

    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.category_id) {
        formData.append('category_id', params.category_id);
      }
      if (params.region) {
        formData.append('region', params.region);
      }
      if (params.product_name) {
        formData.append('product_name', params.product_name);
      }
      if (params.merchant_name) {
        formData.append('merchant_name', params.merchant_name);
      }
    }

    if (value) {
      formData.append('sort_alphabetically', value);
    } else if (value === 0) {
      formData.append('sort_alphabetically', value);
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_HALL_EXHIBITOR_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_HALL_EXHIBITOR_LIST_MERCHANT_ERROR,
          payload: error,
        });
      });
  };
};

export const getSeminarListExhibitor = (slug, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_LIST_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_SEMINAR_LIST_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getSeminarMainExhibitor = (slug, per_page, callback = null) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);
    formData.append('slug', slug);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_MAIN_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: EXPO.EXPO_SEMINAR_MAIN_EXHIBITOR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getDiscussionList = (seminar_id) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_DISCUSSION_LIST_START });
    let formData = new FormData();
    formData.append('per_page', 0);
    formData.append('page', 1);
    formData.append('seminar_id', seminar_id);
    // formData.append('include', 'participant');
    formData.append('include[]', 'participant');
    formData.append('include[]', 'exhibitor');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_DISCUSSION_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXPO.EXPO_DISCUSSION_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPO.EXPO_DISCUSSION_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const discussionCreate = (params, seminar_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_DISCUSSION_CREATE_START });
    let formData = new FormData();
    formData.append('seminar_id', seminar_id);
    formData.append('comment', params.comment);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SEMINAR_DISCUSSION_CREATE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);

        dispatch({
          type: EXPO.EXPO_DISCUSSION_CREATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: EXPO.EXPO_DISCUSSION_CREATE_ERROR, payload: error });
      });
  };
};

export const countClickEvent = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: EXPO.EXPO_CLICK_START });
    Axios.post(API_CLICK_COUNTER)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);

        dispatch({
          type: EXPO.EXPO_CLICK_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({ type: EXPO.EXPO_CLICK_ERROR, payload: error });
      });
  };
};
