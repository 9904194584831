import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import CardWrapper from '../../../../../shared/components/otop/CardWrapper';

const Summary = (props) => {
  const { summary } = props;
  const userType = localStorage.getItem('userType');
  const [disabled, setDisabled] = useState(false);
  const renderUnpaidOrders = () => {
    return (
      <p className="content-header-left text-brown-10">
        {summary && summary.unpaid_orders}
      </p>
    );
  };

  const renderCancelledOrders = () => {
    return (
      <p className="content-header-left text-brown-10">
        {summary && summary.cancelled}
      </p>
    );
  };

  const renderForDelivery = () => {
    return (
      <p className="content-header-left text-brown-10">
        {summary && summary.for_delivery}
      </p>
    );
  };

  const renderCompletedOrders = () => {
    return (
      <p className="content-header-left text-brown-10">
        {summary && summary.unpaid_orders}
      </p>
    );
  };

  return (
    <CardWrapper>
      <Card className={disabled ? 'opacity-50' : 'opacity-100'}>
        <div className="flex items-center py-5 px-10 justify-between">
          <div className="pr-5">
            <p className="content-subheader">My Daily Summary</p>
          </div>
          <div className="border-r">&nbsp;</div>
          <div className="flex items-center px-5">
            <i className="far fa-question-circle text-5xl mr-2"></i>
            <div className="text-gray-10">
              {renderUnpaidOrders()}
              <p>Unpaid Orders</p>
            </div>
          </div>
          <div className="flex items-center px-5">
            <i className="fas fa-ban text-5xl mr-2 text-red-10"></i>
            <div className="text-gray-10">
              {renderCancelledOrders()}
              <p>Cancelled/Refund</p>
            </div>
          </div>
          <div className="flex items-center px-5">
            <i className="fas fa-box text-5xl mr-2 text-blue-10"></i>
            <div className="text-gray-10">
              {renderForDelivery()}
              <p>For Delivery</p>
            </div>
          </div>
          <div className="flex items-center px-5">
            <i className="far fa-check-square text-5xl mr-2"></i>
            <div className="text-gray-10">
              {renderCompletedOrders()}
              <p>Completed Orders</p>
            </div>
          </div>
        </div>
      </Card>
    </CardWrapper>
  );
};

export default Summary;
