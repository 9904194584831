import React, { useState, useEffect } from 'react';
import { Table, notification, Modal, Button } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import placeholder from '../../../shared/assets/images/placeholder.jpg';
import {
  cartProductRemove,
  cartCheckout,
  deleteCartItem,
  getCartList,
} from '../actions';

function CartTable(props) {
  const dispatch = useDispatch();
  const { cartProducts, history, cartList, loading } = props;
  const [list, setList] = useState([]);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [view, setView] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  let token = localStorage.getItem('token');

  useEffect(() => {
    if (cartList === null) {
      setList([]);
    }
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSumbit = () => {
    if (selectedRowKeys.length > 0) {
      let arr = [];
      let key = selectedRowKeys.map((item) => item);
      cartList.map((item, index) => {
        if (item.id === key[index]) {
          return arr.push(item.merchant_id);
        }
      });

      arr.reduce((a, b) => {
        if (a === b) {
          setIsModalVisible(true);
          setView(false);
        } else {
          setIsModalVisible(false);
          setView(true);
        }
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showViewModal = () => {
    setView(true);

    setIsModalVisible(false);
  };

  const handleViewOk = () => {
    setView(false);
  };

  const handleViewCancel = () => {
    setView(false);
  };

  const goBack = () => {
    setProceed(true);
    setView(false);
  };

  useEffect(() => {
    if (cartList) {
      const filteredArr = cartList.reduce(
        (cart, current, currentIndex, array) => {
          const holder = cart.find((item) => item.id === current.id);
          // const holderQuantity = array.filter(el => el.id === current.id)
          // let count = holderQuantity.length;

          if (!holder) {
            return cart.concat([current]);
          } else {
            // holder.quantity = count;
            // holder.total = holder.price * count;
            return cart;
          }
        },
        []
      );
      setList(filteredArr);

      if (cartProducts) {
        let arr = [];
        cartProducts.forEach((element) => {
          arr.push(element.total);
        });
        let total = arr.reduce((a, b) => a + b, 0);
        setTotal(total.toLocaleString());
      }
    } else if (cartProducts) {
      const filteredArr = cartProducts.reduce(
        (cart, current, currentIndex, array) => {
          const holder = cart.find((item) => item.id === current.id);
          // const holderQuantity = array.filter(el => el.id === current.id)
          // let count = holderQuantity.length;

          if (!holder) {
            return cart.concat([current]);
          } else {
            // holder.quantity = count;
            // holder.total = holder.price * count;
            return cart;
          }
        },
        []
      );
      setList(filteredArr);

      let arr = [];
      cartProducts.forEach((element) => {
        arr.push(element.total);
      });
      let total = arr.reduce((a, b) => a + b, 0);
      setTotal(total.toLocaleString());
    }
  }, [cartList, cartProducts]);

  const columns = [
    {
      title: 'SELECT ALL ITEMS',
      dataIndex: 'images',
      key: 'thumbnail',
      width: 250,
      render: (data, rowData) => renderProductImage(data, rowData),
    },
    {
      title: 'EXHIBITOR',
      dataIndex: 'exhibitor',
      key: 'exhibitor',
      width: 250,
      render: (data, rowData) => renderExhibitor(data, rowData),
    },
    {
      title: 'PRICE',
      dataIndex: 'display_price',
      key: 'price',
      width: 150,
      render: (data, rowData) => renderPrice(data, rowData),
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (data, rowData) => renderQuantity(data, rowData),
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      width: 150,
      render: (data, rowData) => renderTotal(data, rowData),
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: '',
      width: 100,
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    let products = [];
    selectedRowKeys.forEach((element) => {
      if (token) {
        let productSelected = list.filter((item) => item.id === element);
        products.push(productSelected[0]);
      } else {
        let productSelected = cartProducts.filter(
          (item) => item.id === element
        );
        products.push(productSelected[0]);
      }
    });

    let total = 0;
    if (token) {
      for (let i = 0; i < products.length; i++) {
        total += parseInt(products[i].total.replace(/,/g, ''));
      }
      setTotal(total);
    } else {
      for (let i = 0; i < products.length; i++) {
        total += parseInt(products[i].price.replace(/,/g, ''));
        setTotal(total);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderProductImage = (data, rowData) => {
    if (token && rowData) {
      return (
        <div className="flex flex-col lg:flex-row items-center">
          <img
            src={
              rowData
                ? rowData['product']['data']['thumbnail']['full_path']
                : placeholder
            }
            alt="Virtual Trade Expo Ph"
            className="h-40 w-40 object-contain object-center rounded-2xl"
          />
          <p className="text-blue-20 text-control-2 text-center lg:text-left pl-3">
            {rowData['product']['data']['name']}
          </p>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col lg:flex-row items-center">
          <img
            src={rowData ? rowData['thumbnail']['full_path'] : placeholder}
            alt="Virtual Trade Expo Ph"
            className="h-40 w-40 object-contain object-center rounded-2xl"
          />
          <p className="text-blue-20 text-control-2 text-center lg:text-left pl-3">
            {rowData['name']}
          </p>
        </div>
      );
    }
  };

  const renderExhibitor = (data, rowData) => {
    if (token && rowData) {
      let firstname =
        rowData['product']['data']['exhibitor']['data']['firstname'];
      let lastname =
        rowData['product']['data']['exhibitor']['data']['lastname'];
      let company_name =
        rowData['product']['data']['exhibitor']['data']['company_name'];
      let contact_number =
        rowData['product']['data']['exhibitor']['data']['contact_number'];
      return (
        <div className="text-blue-20">
          <p className="font-bold">
            {firstname} {lastname}
          </p>
          <p className="text-control-2 italic">{company_name}</p>
          <p className="text-control-2 italic">{contact_number}</p>
        </div>
      );
    } else {
      let firstname = rowData['exhibitor']['data']['firstname'];
      let lastname = rowData['exhibitor']['data']['lastname'];
      let company_name = rowData['exhibitor']['data']['company_name'];
      let contact_number = rowData['exhibitor']['data']['contact_number'];
      return (
        <div className="text-blue-20">
          <p className="font-bold">
            {firstname} {lastname}
          </p>
          <p className="text-control-2 italic">{company_name}</p>
          <p className="text-control-2 italic">{contact_number}</p>
        </div>
      );
    }
  };

  const renderPrice = (data, rowData) => {
    if (token && rowData) {
      let price = rowData['product']['data']['price'];
      return <p className="text-blue-20">PHP {price}</p>;
    } else {
      return <p className="text-blue-20">PHP {rowData.price}</p>;
    }
  };

  const renderQuantity = (data, rowData) => {
    setQuantity(rowData.quantity);
    if (token && rowData) {
      return <p className="text-blue-20">{rowData.quantity}</p>;
    } else {
      return <p className="text-blue-20">{rowData.quantity}</p>;
    }
  };

  const renderTotal = (data, rowData) => {
    if (token && rowData) {
      return <p className="text-red-20">PHP {rowData.total}</p>;
    } else {
      return <p className="text-red-20">PHP {rowData.price * quantity}</p>;
    }
  };

  const renderAction = (data, rowData) => {
    return (
      <button onClick={() => onSelectIdToDelete(data)}>
        <i className="fas fa-trash bg-red-20 text-white p-2 ml-1"></i>
      </button>
    );
  };

  const onSelectIdToDelete = (data) => {
    if (token) {
      onDeleteCartItems(data.id, callback);
    } else if (!token) {
      dispatch(cartProductRemove(data.id, callback));
    }
  };

  const callback = () => {
    notification.success({ message: 'Successfully removed item from cart' });
  };

  const onCheckout = (keys) => {
    if (!keys.length) {
      notification.warning({
        message:
          'Cart is empty or You have not yet selected item(s) to checkout.',
      });
    } else {
      if (token) {
        sessionStorage.removeItem('checkout_list');
        sessionStorage.removeItem('checkout');
        sessionStorage.removeItem('cartData');
        let checkout = cartList.filter((item) =>
          selectedRowKeys.includes(item.id)
        );
        dispatch(cartCheckout(checkout, onCheckoutCallback));
      } else {
        history.push({
          pathname: '/login',
          state: { fromCheckout: true, cartData: list },
        });
      }
    }
  };

  const onCheckoutCallback = () => {
    history.push('/cart/checkout');
    window.scrollTo(0, 0);
  };

  const onDeleteCartItems = (id) => {
    dispatch(deleteCartItem(id, callback));
    dispatch(getCartList());
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <Table
          loading={loading}
          rowKey="id"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={token ? list : cartProducts}
        />
      </div>

      <div className="bg-red-20 flex justify-between p-5 rounded-lg my-5 font-bold lg:text-lg">
        <h3 className="text-white">TOTAL</h3>
        <h3 className="text-white">
          PHP {!isEmpty(selectedRowKeys) ? total.toLocaleString() : '0'}.00
        </h3>
      </div>

      <div className="flex">
        <Link
          to="/expo"
          className="btn btn-red text-center w-32 block py-3 mx-2"
        >
          <i className="fas fa-times-circle text-white pr-2"></i>
          <span>Cancel</span>
        </Link>

        <button
          onClick={showModal}
          className="btn btn-blue text-center w-32 block py-3 ml-2"
        >
          <i className="fas fa-check-circle text-white pr-2"></i>
          <span>Checkout</span>
        </button>
      </div>
      <Modal
        footer={false}
        title={<p className="text-red-10 font-bold">NOTE!</p>}
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="my-10 font-bold text-red-10 text-center">
          Please be advised that the shipping fee is not yet included on the
          total amount stated on your check out cart. For products from multiple
          exhibitors, you will be asked to settle the shipping fee individually
          (multiple exhibitors means multiple shipping fee).
        </p>
        <Button
          onClick={() => onCheckout(selectedRowKeys)}
          className="text-white rounded-2xl focus:border-none hover:text-black border-none bg-blue-20"
          htmlType="submit"
        >
          Confirm
        </Button>
      </Modal>
      <Modal
        footer={false}
        title={<p className="text-red-10 font-bold">NOTE!</p>}
        width={800}
        visible={view}
        onOk={handleViewOk}
        onCancel={handleViewCancel}
      >
        <p className="my-10 font-bold text-red-10 text-center">
          The payment option of each exhibitors doesn't match. Kindly check out
          the items per exhibitor.
        </p>
        <Button
          onClick={goBack}
          className="text-white rounded-2xl focus:border-none hover:text-black border-none bg-blue-20"
          htmlType="submit"
        >
          Go Back
        </Button>
      </Modal>
    </div>
  );
}

export default CartTable;
