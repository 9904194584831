import { getAuthHeader, getError } from '../../shared/utils';
import {
  API_EXHIBITOR_LIST,
  API_EXHIBITOR_PRODUCT_LIST,
  API_EXHIBITOR_SHOW,
  API_EXHIBITOR_PRODUCT_SHOW,
  API_EXHIBITOR_PRODUCT_SHOW_MERCHANT,
  API_EXHIBITOR_SHOW_MERCHANT,
  API_EXHIBITOR_PRODUCT_LIST_MERCHANT,
  API_EXHIBITOR_LIST_GALLERY,
  API_GENERAL_LIKE,
} from '../../shared/constant/url';

export const EXHIBITOR = {
  EXHIBITOR_LIST_START: 'EXHIBITOR_LIST_START',
  EXHIBITOR_LIST_SUCCESS: 'EXHIBITOR_LIST_SUCCESS',
  EXHIBITOR_LIST_ERROR: 'EXHIBITOR_LIST_ERROR',

  EXHIBITOR_LIST_GALLERY_START: 'EXHIBITOR_GALLERY_LIST_START',
  EXHIBITOR_LIST_GALLERY_SUCCESS: 'EXHIBITOR_GALLERY_LIST_SUCCESS',
  EXHIBITOR_LIST_GALLERY_ERROR: 'EXHIBITOR_GALLERY_LIST_ERROR',

  EXHIBITOR_SHOW_START: 'EXHIBITOR_SHOW_START',
  EXHIBITOR_SHOW_SUCCESS: 'EXHIBITOR_SHOW_SUCCESS',
  EXHIBITOR_SHOW_ERROR: 'EXHIBITOR_ SHOW_ERROR',

  EXHIBITOR_PRODUCT_LIST_START: 'EXHIBITOR_PRODUCT_LIST_START',
  EXHIBITOR_PRODUCT_LIST_SUCCESS: 'EXHIBITOR_PRODUCT_LIST_SUCCESS',
  EXHIBITOR_PRODUCT_LIST_ERROR: 'EXHIBITOR_PRODUCT_LIST_ERROR',

  EXHIBITOR_PRODUCT_SHOW_START: 'EXHIBITOR_PRODUCT_SHOW_START',
  EXHIBITOR_PRODUCT_SHOW_SUCCESS: 'EXHIBITOR_PRODUCT_SHOW_SUCCESS',
  EXHIBITOR_PRODUCT_SHOW_ERROR: 'EXHIBITOR_PRODUCT_SHOW_ERROR',

  EXHIBITOR_SHOW_MERCHANT_START: 'EXHIBITOR_SHOW_MERCHANT_START',
  EXHIBITOR_SHOW_MERCHANT_SUCCESS: 'EXHIBITOR_SHOW_MERCHANT_SUCCESS',
  EXHIBITOR_SHOW_MERCHANT_ERROR: 'EXHIBITOR_ SHOW_MERCHANT_ERROR',

  EXHIBITOR_PRODUCT_LIST_MERCHANT_START:
    'EXHIBITOR_PRODUCT_LIST_MERCHANT_START',
  EXHIBITOR_PRODUCT_LIST_MERCHANT_SUCCESS:
    'EXHIBITOR_PRODUCT_LIST_MERCHANT_SUCCESS',
  EXHIBITOR_PRODUCT_LIST_MERCHANT_ERROR:
    'EXHIBITOR_PRODUCT_LIST_MERCHANT_ERROR',

  EXHIBITOR_PRODUCT_SHOW_MERCHANT_START:
    'EXHIBITOR_PRODUCT_SHOW_MERCHANT_START',
  EXHIBITOR_PRODUCT_SHOW_MERCHANT_SUCCESS:
    'EXHIBITOR_PRODUCT_SHOW_MERCHANT_SUCCESS',
  EXHIBITOR_PRODUCT_SHOW_MERCHANT_ERROR:
    'EXHIBITOR_PRODUCT_SHOW_MERCHANT_ERROR',

  GENERAL_LIKE_START: 'GENERAL_LIKE_START',
  GENERAL_LIKE_SUCCESS: 'GENERAL_LIKE_SUCCESS',
  GENERAL_LIKE_ERROR: 'GENERAL_LIKE_ERROR',
};

export const getExhibitorList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }
    formData.append('page', 1);
    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.category_id) {
        formData.append('category_id', params.category_id);
      }
      if (params.region) {
        formData.append('region', params.region);
      }
      if (params.industry) {
        formData.append('industry', params.industry);
      }
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: EXHIBITOR.EXHIBITOR_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const showExhibitorDetails = (exhibitor_id) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_SHOW_START });
    let formData = new FormData();
    formData.append('exhibitor_id', exhibitor_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: EXHIBITOR.EXHIBITOR_SHOW_ERROR, payload: error });
      });
  };
};

export const getExhibitorProductList = (params, exhibitor_id, per_page) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    formData.append('page', 1);
    formData.append('exhibitor_id', exhibitor_id);
    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.types === 1 || params.types === 0) {
        formData.append('types', params.types);
      }
      if (params.price) {
        formData.append('price', params.price);
      }
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_PRODUCT_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const showExhibitorProductDetails = (product_id) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_START });
    let formData = new FormData();
    formData.append('product_id', product_id);
    formData.append('include', 'images');
    formData.append('include', 'exhibitor');
    formData.append('include', 'bankdetails');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_PRODUCT_SHOW, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_ERROR,
          payload: error,
        });
      });
  };
};

export const showExhibitorProductDetailsMerchant = (product_id) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_START });
    let formData = new FormData();
    formData.append('product_id', product_id);
    formData.append('include', 'images');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_PRODUCT_SHOW_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_SHOW_MERCHANT_ERROR,
          payload: error,
        });
      });
  };
};

export const showExhibitorDetailsMerchant = (exhibitor_id) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_START });
    let formData = new FormData();
    formData.append('exhibitor_id', exhibitor_id);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_SHOW_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_SHOW_MERCHANT_ERROR,
          payload: error,
        });
      });
  };
};
export const getExhibitorProductListMerchant = (
  params,
  exhibitor_id,
  per_page
) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_START });
    let formData = new FormData();
    if (per_page) {
      formData.append('per_page', per_page);
    } else {
      formData.append('per_page', 1000);
    }

    formData.append('page', 1);
    formData.append('exhibitor_id', exhibitor_id);

    if (params) {
      if (params.keyword) {
        formData.append('keyword', params.keyword);
      }
      if (params.types === 1 || params.types === 0) {
        formData.append('types', params.types);
      }
      if (params.price) {
        formData.append('price', params.price);
      }
    }

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_PRODUCT_LIST_MERCHANT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_PRODUCT_LIST_MERCHANT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getExhibitorListGallery = () => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.EXHIBITOR_LIST_GALLERY_START });
    let formData = new FormData();
    formData.append('include', 'exhibitor');

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXHIBITOR_LIST_GALLERY, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_LIST_GALLERY_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXHIBITOR.EXHIBITOR_LIST_GALLERY_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const sendLove = (user_id, merchant_id, user_type, love, callback) => {
  return (dispatch) => {
    dispatch({ type: EXHIBITOR.GENERAL_LIKE_START });
    let formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('merchant_id', merchant_id);
    formData.append('user_type', user_type);
    formData.append('love', love);

    let requestOption = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GENERAL_LIKE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: EXHIBITOR.GENERAL_LIKE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: EXHIBITOR.GENERAL_LIKE_ERROR,
          payload: getError(error),
        });
      });
  };
};
