import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_PRODUCT_LIST,
  API_PRODUCT_READ,
  API_PRODUCT_CREATE,
  API_PRODUCT_EDIT,
  API_PRODUCT_DELETE,
  API_PRODUCT_IMAGE_LIST,
  API_PRODUCT_IMAGE_DELETE,
} from "../../shared/constant/url";

export const PRODUCT = {
  PRODUCT_LIST_START: "PRODUCT_LIST_START",
  PRODUCT_LIST_SUCCESS: "PRODUCT_LIST_SUCCESS",
  PRODUCT_LIST_ERROR: "PRODUCT_LIST_ERROR",

  PRODUCT_READ_START: "PRODUCT_READ_START",
  PRODUCT_READ_SUCCESS: "PRODUCT_READ_SUCCESS",
  PRODUCT_READ_ERROR: "PRODUCT_READ_ERROR",

  PRODUCT_ADD_EDIT_START: "PRODUCT_ADD_EDIT_START",
  PRODUCT_ADD_EDIT_SUCCESS: "PRODUCT_ADD_EDIT_SUCCESS",
  PRODUCT_ADD_EDIT_ERROR: "PRODUCT_ADD_EDIT_ERROR",

  PRODUCT_DELETE_START: "PRODUCT_DELETE_START",
  PRODUCT_DELETE_SUCCESS: "PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_ERROR: "PRODUCT_DELETE_ERROR",

  PRODUCT_IMAGE_LIST_START: "PRODUCT_IMAGE_LIST_START",
  PRODUCT_IMAGE_LIST_SUCCESS: "PRODUCT_IMAGE_LIST_SUCCESS",
  PRODUCT_IMAGE_LIST_ERROR: "PRODUCT_IMAGE_LIST_ERROR",

  PRODUCT_IMAGE_DELETE_START: "PRODUCT_IMAGE_DELETE_START",
  PRODUCT_IMAGE_DELETE_SUCCESS: "PRODUCT_IMAGE_DELETE_SUCCESS",
  PRODUCT_IMAGE_DELETE_ERROR: "PRODUCT_IMAGE_DELETE_ERROR",
};

export const getProductList = (params, per_page) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_LIST_START });
    let formData = new FormData();
    if (per_page) {
      formData.append("per_page", per_page);
    } else {
      formData.append("per_page", 1000);
    }
    formData.append("page", 1);
    formData.append("include", "images");

    if (params) {
      formData.append("keyword", params.keyword);
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
      redirect: 'follow',
    };
    fetch(API_PRODUCT_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.PRODUCT_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.PRODUCT_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readProduct = (product_id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_READ_START });
    let formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("include", "images");

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_READ, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.PRODUCT_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.PRODUCT_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createProduct = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("product_name", params.product_name);
    formData.append("price", params.price);
    formData.append("types", params.types);
    formData.append("description", params.description);
    formData.append("product_url", params.product_url);
    formData.append("stock", params?.stock || 0);
    if (params.video_url) {
      formData.append("video_url", params.video_url);
    }

    if(params.sizes) {
      formData.append("sizes", params.sizes);
    }
    if(params.color) {
      formData.append("color", params.color);
    }

    if(params.image) {
      params.image.fileList.forEach(element => {
        formData.append("image[]", element.originFileObj);
      });
    }
    

    if (params.size_chart) {
      formData.append("size_chart", params.size_chart.fileList[0].originFileObj);
    }


    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.PRODUCT_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.PRODUCT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editProduct = (params, product_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("product_name", params.product_name);
    formData.append("price", params.price);
    formData.append("types", params.types);
    formData.append("description", params.description);
    formData.append("product_url", params.product_url);
    formData.append("stock", params?.stock || 0);
    if (params.video_url) {
      formData.append("video_url", params.video_url);
    }
    if(params.sizes) {
      formData.append("sizes", params.sizes);
    }
    if(params.color) {
      formData.append("color", params.color);
    }
    if (params.image) {
      formData.append("image[]", params.image.fileList[0].originFileObj);
    }
    if (params.size_chart) {
      formData.append("size_chart", params.size_chart.fileList[0].originFileObj);
    }

  

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_EDIT, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.PRODUCT_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.PRODUCT_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteProduct = (product_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_DELETE_START });
    let formData = new FormData();
    formData.append("product_id", product_id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.PRODUCT_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.PRODUCT_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getProductImageList = (product_id) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_IMAGE_LIST_START });
    let formData = new FormData();
    formData.append("per_page", 0);
    formData.append("page", 1);
    formData.append("product_id", product_id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_IMAGE_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: PRODUCT.PRODUCT_IMAGE_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCT.PRODUCT_IMAGE_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteProductImage = (product_id, image_id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT.PRODUCT_IMAGE_DELETE_START });
    let formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("image_id", image_id);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_PRODUCT_IMAGE_DELETE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PRODUCT.PRODUCT_IMAGE_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PRODUCT.PRODUCT_IMAGE_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};
