import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { isEmpty } from "lodash";

import StandardLanding from "../../../shared/components/StandardLanding";
import { cartCheckoutList } from "../actions";
import Checkout from "../components/Checkout";

function Landing(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  let isExhibitor = localStorage.getItem("isExhibitor");
  const { checkout_list } = state.cart;
  const { history } = props;
  let cartData = JSON.parse(sessionStorage.getItem("cartData"))
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(cartCheckoutList());
  }, []);

  useEffect(() => {
    if (isExhibitor === "true") {
      notification.warning({
        message: "Could not access.",
      });
      return history.push("/");
    }
  }, []);

  useEffect(() => {
    const { state } = props.location;
    if(!isEmpty(state)) {
      const { cartData } = props.location.state;
      sessionStorage.setItem('cartData', JSON.stringify(cartData));
    } 
  }, [state]);

  return (
    <React.Fragment>
      <div className="banner h-40 flex justify-center items-center">
        <h1 className="text-white text-center lg:text-4xl uppercase">
          CHECKOUT
        </h1>
      </div>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <h1 className="content-header-left">CHECKOUT</h1>
            <Checkout checkout_list={cartData ? cartData : checkout_list} history={history}/>
          </div>
        </div>
      </StandardLanding>
    </React.Fragment>
  );
}

export default Landing;
