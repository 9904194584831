import React, { useEffect } from 'react';
import { Form, Select, Button, Input } from 'antd';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';

const { Option } = Select;

function FormSearchFilter(props) {
  const dispatch = useDispatch();
  const {
    industries,
    categories,
    regions,
    getExhibitorList,
    total,
    getExpoHallExhibitorList,
    getExpoHallExhibitorListMerchant,
    getGuestExpoHallExhibitorList,
    totalExhibitors,
    totalMerchants,
    totalGuestExhibitors,
    slug,
    path,
  } = props;
  const [form] = Form.useForm();
  let isExhibitor = localStorage.getItem('isExhibitor');

  // const onFinish = (values) => {
  //   // console.log("Success:", values);
  //   if (isExhibitor === "false") {
  //     dispatch(getExpoHallExhibitorList(values, slug, totalExhibitors));
  //   } else if (isExhibitor === "true") {
  //     dispatch(getExpoHallExhibitorListMerchant(values, slug, totalMerchants));
  //   } else if (path === "/exhibitors") {
  //     dispatch(getExhibitorList(values, total));
  //   } else {
  //     dispatch(
  //       getGuestExpoHallExhibitorList(values, slug, totalGuestExhibitors)
  //     );
  //   }
  // };

  const onFinish = (values) => {
    dispatch(getExhibitorList(values, total));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const renderRegion = () => {
    return regions.map((item) => (
      <Option key={item.code} value={item.code}>
        {item.name}
      </Option>
    ));
  };

  const renderCategories = () => {
    return categories.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  };

  // const renderInduries = () => {
  //   return (
  //     industries &&
  //     industries.map((item) => (
  //       <Option key={item.code} value={item.code}>
  //         {item.name}
  //       </Option>
  //     ))
  //   );
  // };

  // const onResetField = () => {
  //   if (isExhibitor === "false") {
  //     dispatch(getExpoHallExhibitorList(0, slug, 100));
  //   } else if (isExhibitor === "true") {
  //     dispatch(getExpoHallExhibitorListMerchant(0, slug, 100));
  //   } else if(path === "/exhibitors") {
  //     dispatch(getExhibitorList(0, 100));
  //   } else {
  //     dispatch(getGuestExpoHallExhibitorList(0, slug, 100));
  //   }
  //   form.resetFields();
  // };

  const onResetField = () => {
    dispatch(getExhibitorList(0, 100));
    form.resetFields();
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="searchFilterExhibitor"
          form={form}
          className="form-flex"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="w-full">
            <h1 className="content-subheader">Search Exhibitors</h1>
            <div className="flex">
              <div className="relative w-full lg:w-1/4 pr-2">
                <Form.Item
                  label="Search"
                  name="keyword"
                  rules={[
                    { required: false, message: 'Please input keyword!' },
                  ]}
                >
                  <Input placeholder="Enter Keyword" />
                </Form.Item>
                <i className="fas fa-search text-red-20 form-icon"></i>
              </div>
            </div>
          </div>
          <div className="w-full">
            <h1 className="content-subheader">Search Filters</h1>
            <div className="flex">
              <div className="relative w-full lg:w-1/4 pr-2">
                <Form.Item
                  label="Region"
                  name="region"
                  rules={[
                    { required: false, message: 'Please select region!' },
                  ]}
                >
                  <Select placeholder="Select Region">{renderRegion()}</Select>
                </Form.Item>
                <i className="fa fa-map text-red-20 form-icon pl-2"></i>
              </div>
              {/* <div className="relative w-full lg:w-1/4 pl-2">
                <Form.Item
                  label="Category"
                  name="category_id"
                  rules={[
                    { required: false, message: 'Please select category!' },
                  ]}
                >
                  <Select placeholder="Select Category">
                    {renderCategories()}
                  </Select>
                </Form.Item>
                <i className="fa fa-list text-red-20 form-icon pl-2"></i>
              </div> */}
              {/* <div className="relative w-full lg:w-1/4 px-2">
                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={[{ required: false, message: "Please select region!" }]}
                >
                  <Select placeholder="Industry">{renderInduries()}</Select>
                </Form.Item>
                <i className="fas fa-user text-red-20 form-icon"></i>
              </div> */}
            </div>
          </div>
          {/* <div className="w-full lg:w-1/4">
            <Button className="btn-main" htmlType="submit">
              <span>Apply Filter</span>
            </Button>
            <Button className="btn-main ml-2" onClick={() => onResetField()}>
              <span>Reset Filter</span>
            </Button>
          </div> */}
          <div className="w-full">
            <Button className="btn-main" htmlType="submit">
              <i className="fas fa-check-circle mr-1"></i>
              <span>Apply Filter</span>
            </Button>
            <Button
              className="btn-main btn-white ml-4"
              style={{ boxShadow: '0px 3px 6px #00000029' }}
              onClick={() => onResetField()}
            >
              <i className="fas fa-times-circle mr-1"></i>
              <span>Reset Filter</span>
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormSearchFilter;
