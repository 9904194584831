import React from 'react';
import { Breadcrumb, Button } from 'antd';
import StandardContainer from '../../../../../shared/components/otop/StandardContainer';

const Landing = () => {
  return (
    <StandardContainer>
      <div className="content-bg w-3/4 pb-10">
        <div className="content-space">
          <div className="flex items-center justify-between py-10">
            <div className="flex w-1/2 items-center">
              <p className="content-subheader-left mr-5">Seller Dashboard</p>
              <Breadcrumb>
                <Breadcrumb.Item>OTOP HUB SELLER CENTER</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Dashboard</a>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Button className="flex items-center">
              <i className="fas fa-chart-bar text-lg mr-2"></i>
              Generate Report
            </Button>
          </div>
          <div>This Feature is under development</div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;
