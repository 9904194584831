import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, notification } from 'antd';
import { useDispatch } from 'react-redux';
import logo from '../../../../../shared/assets/otop/Sellet/logo-1.svg';
import { filter, iteratee } from 'lodash';

const TableProducts = (props) => {
  const dispatch = useDispatch();
  const {
    products,
    loading,
    deleteOtopProduct,
    getOtopProductList,
    tags,
    categories,
  } = props;
  const { data, included } = products;
  const [deleteProductID, setDeleteProductID] = useState(null);
  let userType = localStorage.getItem('userType');

  const columns = [
    {
      title: 'Image',
      dataIndex: '',
      render: (data, rowData) => renderProductImage(data, rowData),
    },
    {
      title: 'Item Name/ID',
      dataIndex: 'name',
      key: 'name',
      render: (data, rowData) => renderProductName(data, rowData),
    },
    {
      title: 'Price/Qty',
      dataIndex: 'price',
      key: 'price',
      render: (data, rowData) => renderPriceAndQuantity(data, rowData),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  const renderProductImage = (data, rowData) => {
    const { included } = products;
    let productImage = filter(included, iteratee({ type: 'product_image' }));
    let img = rowData.relationships.images.data;

    if (productImage && img && img[0]) {
      let filtered = productImage.filter(
        (item) => parseInt(item.id) === parseInt(img[0].id)
      );
      if (filtered && filtered[0]) {
        return (
          <img
            src={filtered[0].attributes.path}
            alt="OTOP Hub Product"
            className="object-contain object-center w-48 h-48"
          />
        );
      }
    }
    return (
      <img
        src={logo}
        alt="OTOP Hub Product"
        className="object-contain object-center w-48 h-48"
      />
    );
  };

  const renderProductName = (data, rowData) => {
    return rowData.attributes.name;
  };

  const renderPriceAndQuantity = (data, rowData) => {
    return (
      <div>
        {rowData.attributes.price} {rowData.attributes.quantity}/
        {rowData.attributes.quantity_type}
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectIdToDelete = (data) => {
    setDeleteProductID(data);
  };

  const onConfirm = () => {
    dispatch(deleteOtopProduct(deleteProductID, callback));
  };

  const callback = (response, error) => {
    if (response) {
      const { status_code, msg } = response;
      if (status_code === 'PRODUCT_DELETED') {
        notification.success({ message: `${msg}` });
      } else {
        notification.warning({ message: `${msg}` });
      }
    } else {
      notification.error({
        message: 'Error',
        // description: error.msg
      });
    }
    dispatch(getOtopProductList(null, 0));
    window.scrollTo(0, 0);
  };

  const renderAction = (data, rowData) => {
    return (
      <div className="flex items-center">
        {userType === 'seller' && (
          <>
            <Link to={`/otop-hub/admin/products/${rowData.id}/edit`}>
              <button className="btn">
                <i className="far fa-edit"></i>
              </button>
            </Link>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={onConfirm}
              onClick={() => onSelectIdToDelete(rowData.id)}
            >
              <button className="btn">
                <i className="far fa-trash-alt"></i>
              </button>
            </Popconfirm>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
      />
    </div>
  );
};

export default TableProducts;
