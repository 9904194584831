import React, { useEffect } from "react";
import CartTable from "../components/CartTable";
import { useDispatch, useSelector } from "react-redux";
import { getOtopProductList } from "../../products/actions";
import ListFeaturedProducts from "../../products/components/ListFeaturedProducts";
import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import { getCartOtopProductList, cartOtopProductRemove } from "../actions";
import { getOtopSellerList } from "../../stores/actions";

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products, loading } = state.otopProduct;
  const { cartProducts } = state.otopCart;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOtopProductList());
    dispatch(getCartOtopProductList());
    dispatch(getOtopSellerList());
  }, []);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
          <div className="bg-gray-90 py-5">
            <CartTable
              cartProducts={cartProducts}
              getCartOtopProductList={getCartOtopProductList}
              products={products}
              cartOtopProductRemove={cartOtopProductRemove}
            />
            {/* <OrderSummary loading={loading}/> */}
          </div>
          <p className="content-header-left mb-5">Just For You</p>
          <div className="my-5">
          <ListFeaturedProducts
            products={products}
            loading={loading}
            type={"all"}
          />
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;