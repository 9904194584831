import React, { useEffect } from 'react';
import { Card } from 'antd';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
// import Countdown from 'react-countdown';
// import moment from 'moment';
// import { isEmpty } from "lodash";

import Register from '../../auth/containers/Register';
import StandardLanding from '../../../shared/components/StandardLanding';
// import CardSpeaker from "../../speaker/components/CardSpeaker";
import Countdown from '../components/Countdown';
// import OtopLanding from '../../otop/main/containers/Landing';

import { getSpeakerList } from '../../speaker/actions';
import { getExpoList, getUpcomingExpoList } from '../../expo/actions';
import { getWelcomeMessage } from '../../general/actions';
import CardWrapperNoBg from '../../../shared/components/CardWrapperNoBg';
// import logo from '../../../shared/assets/images/logo/logo.png';
// import vte from '../../../shared/assets/images/logo/vte.png';
import WomenbizPH from '../../../shared/assets/images/logo/WomenbizPH-black.png';
import airspeed from '../../../shared/assets/images/logo/partners/airspeed.png';
import dti from '../../../shared/assets/images/logo/partners/dti.png';
import hs from '../../../shared/assets/images/logo/partners/hs.png';
import ilo from '../../../shared/assets/images/logo/partners/ilo.png';
import sm_cebu from '../../../shared/assets/images/logo/partners/sm_cebu.png';
import sm_mega from '../../../shared/assets/images/logo/partners/sm_mega.png';
import wsn from '../../../shared/assets/images/logo/partners/wsn.png';
import apdec from '../../../shared/assets/images/logo/partners/apdec.png';
import pal from '../../../shared/assets/images/logo/partners/pal.png';
// import FeaturedExpo from '../components/FeaturedExpo';
// import ModalFeaturedExpo from '../components/ModalFeaturedExpo';

function Landing({ click }) {
  const dispatch = useDispatch();
  // let location = useLocation();
  const state = useSelector((state) => state);
  // const { speakers } = state.speaker;
  const { expo, expoUpcoming, loading } = state.expo;
  // const { message } = state.general;
  let token = localStorage.getItem('token');
  // const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSpeakerList(null, 4));
    dispatch(getExpoList(null));
    dispatch(getUpcomingExpoList());
    dispatch(getWelcomeMessage());
    sessionStorage.setItem('vte', true);
    // showModal();
  }, []);

  // useEffect(() => {
  //   if(location) {
  //     const { state } = location
  //     if(!isEmpty(state)) {
  //       console.log("state", state)
  //     }
  //   }
  // }, [])

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const settings = {
    dots: true,
    dotsClass: 'custom-slick-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderUpComing = () => {
    if (expoUpcoming && expoUpcoming.length > 0) {
      return expoUpcoming.map((item) => {
        return (
          <div key={item.id}>
            <div
              className="content-space text-gray-30 flex flex-col lg:flex-row items-center h-full w-full px-5 py-10"
              style={{ paddingTop: '25vh' }}
            >
              <div className="w-full lg:w-1/2 lg:px-20 lg:text-xl">
                <h1
                  className="text-3xl uppercase font-thin text-white"
                  style={{
                    fontFamily: '"Times New Roman", Times, sans-serif',
                  }}
                >
                  {item.name}
                </h1>
                {/* <div className="flex items-center my-5">
                  <div className="border-l-2 border-white h-12">&nbsp.</div>
                  <h4 className="text-white">
                    <i className="fas fa-calendar-alt ml-3"></i> &nbsp;
                    {item.expo_date}
                  </h4>
                </div> */}
                <h3 className="lg:mt-20 text-white">THE EXPO STARTS IN:</h3>
                <Countdown date={item.start_date} />
              </div>
              {/* <div className="w-full h-full lg:w-1/2 flex justify-center lg:py-10 lg:py-0">
                <div className="bg-white rounded-lg py-5 w-full lg:w-3/4 mt-10">
                  <h1 className="text-red-10 text-2xl font-semibold px-10">
                    Register for the event
                  </h1>
                  <div className="border-dashed border-b-2 border-gray my-3"></div>
                  <div className="px-5 pt-3">
                    <Register slug={item.slug} item={item} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="flex items-center place-items-center min-h-screen w-full lg:px-20 pt-20">
          <div style={{ paddingTop: '17vh' }}>
            <h1 className="lg:text-6xl text-white leading-3">
              WELCOME TO STRONG NETWORK <br />
              HYBRID TRADE FAIR
            </h1>
          </div>
        </div>
      );
    }
  };

  const ContentSpace = ({ header, subHeader, content, contentClassName }) => {
    return (
      <div className="container m-auto content-space px-5 py-20 lg:p-20 flex flex-col">
        <h1 className="content-header">{header}</h1>
        {!!subHeader && (
          <h2
            className="content-subheader text-center"
            style={{ color: '#1B1B1B', fontSize: '1.875rem' }}
          >
            {subHeader}
          </h2>
        )}

        <div
          className={`flex items-center justify-center ${contentClassName}`}
          style={{ color: '#1B1B1B', fontSize: '1.875rem' }}
        >
          {content}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <OtopLanding click={click} /> */}
      <StandardLanding>
        <div className="bg-main-landing content-bg overflow-hidden">
          {loading ? (
            <CardWrapperNoBg>
              <Card
                loading={loading}
                bordered={false}
                className="min-h-screen"
                style={{ paddingTop: '25vh' }}
              >
                sample
              </Card>
            </CardWrapperNoBg>
          ) : (
            <Slider
              {...settings}
              className="min-h-screen"
              style={{ paddingTop: '25vh' }}
            >
              {renderUpComing()}
            </Slider>
          )}
        </div>
      </StandardLanding>

      <div className="bg-red-20 flex flex-col lg:flex-row lg:justify-center lg:items-center py-10 text-gray-30 px-5">
        <i className="fas fa-handshake text-5xl text-center lg:text-left"></i>
        <p className="px-5 text-lg text-center">
          Connectivity and productivity for your negosyo needs!, transform your
          business through PLDT HomeBiz!
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pldthome.com/biz"
          className="bg-white text-red-20 p-3 rounded-lg mt-10 lg:mt-0 text-center"
        >
          <i className="fas fa-globe mr-1"></i>
          <span style={{ fontWeight: '700' }}>Visit EXPO</span>
        </a>
      </div>

      {/* <FeaturedExpo expo={expo} /> */}
      {/* <ModalFeaturedExpo
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        expo={expo}
        message={message}
      /> */}
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <img
            src={WomenbizPH}
            alt="Women's Business Council Ph"
            className="m-auto"
          />

          <ContentSpace
            header={'OUR ORGANIZATION'}
            content={
              'Composed of the country’s top women business leaders and entrepreneurs, the Women’s Business Council Philippines, Inc. (WomenBizPH) is the leading voice of women in commerce, inspiring and empowering women in the Philippines. Serving as the platform to discuss women’s issues in business and possible government policies and solutions, WomenBizPH is the lead private sector partner of the government, particularly the Department of Trade and Industry and the Philippine Commission on Women, and represents the Philippines in APEC and ASEAN events.'
            }
            contentClassName="text-center"
          />
          <ContentSpace
            header={'- VISION -'}
            subHeader={
              'We, the Women’s Business Council Philippines, aspire to:'
            }
            content={
              <ul className="list-disc w-full lg:w-3/4">
                <li>Serve as role models for women in business.</li>
                <li>
                  Act as change agents to forge political and business
                  influence.
                </li>
                <li>Advocate for women’s issues and needs in business.</li>
                <li>
                  Represent the Philippines in the global business community.
                </li>
                <li>
                  Be the lead organizer of Philippine women’s business groups.
                </li>
                <li>and Inspire and empower women in the Philippines.</li>
              </ul>
            }
          />
          <ContentSpace
            header={'- MISSION -'}
            subHeader={'Our Mission is to:'}
            content={
              <ul className="list-disc w-full lg:w-3/4">
                <li>
                  Be the voice of Filipino women in business and influence
                  business in the country.
                </li>
                <li>Be a major force in global networking.</li>
                <li>Instill passion and love for country and for business.</li>
                <li>
                  Represent women in business in the Philippines as brand
                  ambassadors.
                </li>
                <li>
                  Promote ethical business practices, particularly integrity and
                  good governance.
                </li>
                <li>
                  Leverage our individual skills and strengths to influence
                  government policies and the future of women in the economy.
                </li>
              </ul>
            }
          />
          <ContentSpace
            header={'PARTNERS'}
            content={
              <div className="flex flex-wrap w-full lg:w-100">
                <img
                  src={airspeed}
                  alt="Airspeed"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={dti}
                  alt="DTI Philippines"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={hs}
                  alt="Highly Succeed"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={ilo}
                  alt="International Labour Organization"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={sm_cebu}
                  alt="SM Cebu City"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={sm_mega}
                  alt="SM Mega Mall"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={wsn}
                  alt="Women Strong Network"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={apdec}
                  alt="Amazing Philippines Digital Economy Corporation"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={pal}
                  alt="Philippine Airlines"
                  className="h-44 p-3 m-auto"
                />
              </div>
            }
          />
        </div>
      </StandardLanding>
    </>
  );
}

export default Landing;
