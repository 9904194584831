import { authHeader, getAuthHeader, getError, axios } from "../../../../shared/utils";
import {
  API_OTOP_TAG_LIST,
  API_OTOP_TAG_CREATE,
  API_OTOP_TAG_EDIT,
  API_OTOP_TAG_DELETE,
  API_OTOP_TAG_READ,
  API_OTOP_PUBLIC_TAG_LIST
} from "../../../../shared/constant/url";

export const TAG = {
  TAG_LIST_START: "TAG_LIST_START",
  TAG_LIST_SUCCESS: "TAG_LIST_SUCCESS",
  TAG_LIST_ERROR: "TAG_LIST_ERROR",

  TAG_READ_START: "TAG_READ_START",
  TAG_READ_SUCCESS: "TAG_READ_SUCCESS",
  TAG_READ_ERROR: "TAG_READ_ERROR",

  TAG_ADD_EDIT_START: "TAG_ADD_EDIT_START",
  TAG_ADD_EDIT_SUCCESS: "TAG_ADD_EDIT_SUCCESS",
  TAG_ADD_EDIT_ERROR: "TAG_ADD_EDIT_ERROR",

  TAG_DELETE_START: "TAG_DELETE_START",
  TAG_DELETE_SUCCESS: "TAG_DELETE_SUCCESS",
  TAG_DELETE_ERROR: "TAG_DELETE_ERROR",
};

export const getTagList = () => {
  return (dispatch) => {
    dispatch({ type: TAG.TAG_LIST_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_OTOP_TAG_LIST, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: TAG.TAG_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TAG.TAG_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const readTag = (id) => {
  return (dispatch) => {
    dispatch({ type: TAG.TAG_READ_START });

    let requestOptions = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_TAG_READ}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: TAG.TAG_READ_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TAG.TAG_READ_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const createTag = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: TAG.TAG_ADD_EDIT_START });
    let formData = new FormData();
    formData.append("name", params.name);

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_OTOP_TAG_CREATE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: TAG.TAG_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: TAG.TAG_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editTag = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: TAG.TAG_ADD_EDIT_START });
  
    axios
      .put(`${API_OTOP_TAG_EDIT}/${id}`, params, authHeader())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: TAG.TAG_ADD_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: TAG.TAG_ADD_EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteTag = (id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: TAG.TAG_DELETE_START });
    let requestOptions = {
      method: "DELETE",
      headers: getAuthHeader(),
    };
    fetch(`${API_OTOP_TAG_DELETE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: TAG.TAG_DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: TAG.TAG_DELETE_ERROR,
          payload: getError(error),
        });
      });
  };
};

