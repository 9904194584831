import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { filter, iteratee, isEmpty } from "lodash";
import moment from "moment";
import { Table, Menu, Dropdown, notification, Modal, Card } from "antd";
import { DownOutlined } from "@ant-design/icons";

import CardWrapper from "../../../../shared/components/otop/CardWrapper";
import FormWayBill from "../../admin/order-management/components/FormWayBill";
import FormPayment from "../../admin/order-management/components/FormPayment";
import { renderTag } from "../../../../shared/utils";

export default function TableTransaction(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    transactions,
    loading,
    type,
    toPayList,
    toShipList,
    toReceiveList,
    completedList,
    tab,
    editOrder,
    editBuyerTransaction,
    getOrderList,
    included,
  } = props;
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [status, setStatus] = useState("");
  const [isModalVisibleToShip, setIsModalVisibleToShip] = useState(false);
  let userType = localStorage.getItem("userType");

  useEffect(() => {
    switch (tab) {
      case "1":
        setData(toPayList);
        break;
      case "2":
        setData(toShipList);
        break;
      case "3":
        setData(toReceiveList);
        break;
      case "4":
        setData(completedList);
        break;
      default:
        return tab;
    }
  }, [tab, toPayList, toReceiveList, toShipList, completedList]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalToShip = () => {
    setIsModalVisibleToShip(true);
  };

  const handleOkToShip = () => {
    setIsModalVisibleToShip(false);
  };

  const handleCancelToShip = () => {
    setIsModalVisibleToShip(false);
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    // },
    {
      title: "Item Name/ID",
      dataIndex: "id",
      key: "id",
      render: (data, rowData) => renderID(data, rowData),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (data, rowData) => renderTotal(data, rowData),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (data, rowData) => renderBuyer(data, rowData),
    },
    {
      title: "Date Ordered",
      dataIndex: "date_ordered",
      key: "date_ordered",
      render: (data, rowData) => renderDateOrdered(data, rowData),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (data, rowData) => renderPaymentMethod(data, rowData),
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "status",
      render: (data, rowData) => renderStatus(data, rowData),
      // render: () => (
      //   <div className="text-center text-white">
      //     <p className="bg-red-10 py-2 w-20 rounded-lg">Refund</p>
      //   </div>
      // ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  const renderID = (data, rowData) => {
    return (
      <Link
        to={
          type === "admin"
            ? `/otop-hub/admin/order-management/${rowData.id}`
            : `/otop-hub/transactions/${rowData.id}`
        }
        className="text-brown-10"
      >
        Order #000{rowData.id}{" "}
      </Link>
    );
  };

  const renderTotal = (data, rowData) => {
    return rowData.attributes.total;
  };

  const renderBuyer = (data, rowData) => {
    let dataParticipant = rowData.relationships.participant.data[0];
    let participant = filter(included,iteratee({ type: "participant" }));
    if(!isEmpty(participant)) {
      let parti = participant.filter(item => parseInt(item.id) === parseInt(dataParticipant.id))
      return parti && parti[0].attributes.firstname + " " + parti && parti[0].attributes.lastname;
    }
  };

  const renderDateOrdered = (data, rowData) => {
    return moment(rowData.attributes.created_at).format("MM-DD-YYYY");
  };

  const renderPaymentMethod = (data, rowData) => {
    let dataPayment = rowData.relationships.payments.data[0];
    let payment = filter(included,iteratee({ type: "payment" }));

    if(!isEmpty(payment)) {
      let payment_status = payment.filter(item => parseInt(item.id) === parseInt(dataPayment.id))
      if(payment_status && payment_status[0]) {
        let type = "";
        if(payment_status[0] && payment_status[0].attributes.cash_on_delivery === "COD") {
          type = payment_status[0].attributes.cash_on_delivery
        } else {
          type = "paymaya"
        }
        return <p>{type} {payment_status && payment_status[0].attributes.status && <code>[{payment_status[0].attributes.status}]</code>}</p>
      }
    }
  };

  const renderStatus = (data, rowData) => {
    return (
      <div className={`tag ${renderTag(rowData.attributes.status)}`}>
        {rowData.attributes.status}
      </div>
    );
  };

  const renderAction = (data, rowData) => {
    return (
      <Dropdown overlay={() => menu(rowData)}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Action <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  const onClickToShip = (data, status) => {
    // dispatch(editOrder(params, parseInt(data.id), callback));
    showModalToShip();
    setStatus(status);
    setStatusData(data);
  };

  const onClickToReceive = (data, status) => {
    let params = { status_id: status };
    dispatch(editOrder(params, parseInt(data.id), callback));
  };

  const onApprovedPayment = (data, status) => {
    let params = { status_id: status };
    dispatch(editOrder(params, parseInt(data.id), callback));
  };

  const onDeclinedPayment = (data, status) => {
    showModal();
    setStatus(status);
    setStatusData(data);
  };

  const onClickOrderReceived = (data, status) => {
    let params = { status_id: status };
    dispatch(editBuyerTransaction(params, parseInt(data.id), callback));
  };

  const onAcceptOrder = (data, status) => {
    let params = { status_id: status };
    dispatch(editOrder(params, data.id, callback));
  };

  const menu = (data) => {
    let dataPayment = data.relationships.payments.data[0];
    let status = data.attributes.status;
    let paymaya = filter(included,iteratee({ type: "paymaya" }));
    let payment = filter(included,iteratee({ type: "payment" }));
    
    if(!isEmpty(payment)) {
      let payment_status = payment.filter(item => parseInt(item.id) === parseInt(dataPayment.id))
      let paymayaData = {};
      if(payment_status && payment_status[0]) {
        let paymayaId = payment_status[0].relationships.paymaya.data ? payment_status[0].relationships.paymaya.data.id : ""
        paymayaData = paymaya.filter(item => item.id === paymayaId)
      }
      //7-completed, 8-to ship, 9-to receive, 10-accept order
      return (
        <Menu>
          {(paymayaData && paymayaData[0] && paymayaData[0].attributes.status === "COMPLETED") && status !== "Paid" && userType === "seller" &&
             (
              <Menu.Item key="1" onClick={() => onApprovedPayment(data, "2")}>
                Approve Payment
              </Menu.Item>
            )}
          {(paymayaData && paymayaData[0] && paymayaData[0].attributes.status === "COMPLETED") && status !== "Cancelled" && userType === "seller" &&
             (
              <Menu.Item key="2" onClick={() => onDeclinedPayment(data, "6")}>
                Decline Payment
              </Menu.Item>
            )}
          {(payment_status && payment_status[0].attributes.cash_on_delivery === "COD") && status !== "Order Accepted" && status !== "To ship" && status !== "To receive" && userType === "seller" &&
             (
              <Menu.Item key="3" onClick={() => onAcceptOrder(data, "10")}>
                Accept Order
              </Menu.Item>
            )}
          {/* {((payment_status && payment_status[0].attributes.status === "paid") ||
            (payment_status && payment_status[0].attributes.status === "unpaid") ||
            data.payment_status === "order_accepted") &&
            data.transaction_status === "pending" &&
             ( */}
             {
              (status === "Order Accepted" || status === "Paid") && userType === "seller"  && (
                <Menu.Item key="4" onClick={() => onClickToShip(data, "8")}>
                To Ship
              </Menu.Item>
               )
             }
              
            {/* )} */}
          {data.attributes.status === "To ship" && userType === "seller" &&  (
            <Menu.Item key="5" onClick={() => onClickToReceive(data, "9")}>
              To Receive
            </Menu.Item>
          )}
          {data.attributes.status === "To receive" && type === "buyer" && (
            <Menu.Item key="6" onClick={() => onClickOrderReceived(data, "7")}>
              Order Received
            </Menu.Item>
          )}
            <Menu.Item
              key="7" 
              onClick={() =>
                history.push(
                  type === "admin"
                  ? `/otop-hub/admin/order-management/${parseInt(data.id)}`
                  : `/otop-hub/transactions/${parseInt(data.id)}`
                )
              }
            >
              Show Details
            </Menu.Item>
          
        </Menu>
      );
    }
  };

  const callback = (response, error) => {
    console.log("response", response)
    if (response) {
      const { data } = response;
      if (data.type === "order") {
        notification.success({ message: "Order has been successfully updated!" });
      } else {
        notification.warning({ message: "Warning" });
      }
    } else {
      notification.error({
        message: "Error",
        // description: error.msg
      });
    }
    window.scrollTo(0, 0);
    dispatch(getOrderList());
  };

  return (
    <CardWrapper>
      <Card className="p-10">
        <div className="overflow-x-auto">
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>

        <Modal
          title="DECLINE PAYMENT!"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <FormPayment
            status={status}
            statusData={statusData}
            editOrder={editOrder}
            handleCancel={handleCancel}
            getOrderList={getOrderList}
          />
        </Modal>

        <Modal
          title="DO YOU HAVE WAYBILL?"
          visible={isModalVisibleToShip}
          onOk={handleOkToShip}
          onCancel={handleCancelToShip}
          footer={[]}
        >
          <FormWayBill
            status={status}
            statusData={statusData}
            editOrder={editOrder}
            handleCancelToShip={handleCancelToShip}
            getOrderList={getOrderList}
          />
        </Modal>
      </Card>
    </CardWrapper>
  );
}