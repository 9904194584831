import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import { readBuyerTransaction } from "../actions";
import { getOtopProductList } from "../../products/actions";
import TransactionDetails from "../components/TransactionDetails";

const LandingTransaction = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const state = useSelector((state) => state);
  const { transactionDetails, loading } = state.buyerTransaction;
  const { products } = state.otopProduct;

  useEffect(() => {
    if (id) {
      dispatch(readBuyerTransaction(id));
    }
    dispatch(getOtopProductList());
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
          <div className="bg-gray-90 py-5">
            <Card loading={loading}>
              <TransactionDetails transactionDetails={transactionDetails} products={products} />
            </Card>
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};
export default LandingTransaction;