import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "antd";

import Summary from "../../dashboard/components/Summary";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import HeaderTitle from "../../../layouts/components/HeaderTitle";
import { getOrderList, editOrder } from "../actions";
import { getOtopSummary } from "../../dashboard/action";
import TableTransaction from "../../../transactions/components/TableTransacton";

const { TabPane } = Tabs;

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { orders, loading } = state.order;
  const { summary } = state.otopDashboard;
  const [toPayList, setToPayList] = useState([]);
  const [toShipList, setToShipList] = useState([]);
  const [toReceiveList, setToReceiveList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [tab, setTab] = useState("1");
  const { included } = orders;

  useEffect(() => {
    dispatch(getOrderList());
    dispatch(getOtopSummary());
  }, []);

  useEffect(() => {
    if (orders && orders.data) {
      const { data } = orders;
      let toPayListFiltered = data.filter(
        (item) => item.attributes.status === "Incomplete" || item.attributes.status === "Order Accepted" || item.attributes.status === "Paid"
      );
      setToPayList(toPayListFiltered);

      let toShipListFiltered = data.filter(
        (item) => item.attributes.status === "To ship"
      );
      setToShipList(toShipListFiltered);

      let toReceiveListFiltered = data.filter(
        (item) => item.attributes.status === "To receive"
      );
      setToReceiveList(toReceiveListFiltered);

      let completedListFiltered = data.filter(
        (item) => item.attributes.status === "Completed"
      );
      setCompletedList(completedListFiltered);
    }
  }, [orders]);

  const callback = (key) => {
    switch (key) {
      case "1":
        setTab("1");
        break;
      case "2":
        setTab("2");
        break;
      case "3":
        setTab("3");
        break;
      case "4":
        setTab("4");
        break;
      default:
        setTab("1");
        break;
    }
  };

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
          <HeaderTitle />
          <Summary summary={summary} />
          <div className="my-10 p-5 bg-white">
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="To Pay" key="1">
                <TableTransaction
                  toPayList={toPayList}
                  loading={loading}
                  getOrderList={getOrderList}
                  type={"admin"}
                  tab={tab}
                  included={included}
                  editOrder={editOrder}
                />
              </TabPane>
              <TabPane tab="To Ship" key="2">
                <TableTransaction
                  toShipList={toShipList}
                  loading={loading}
                  getOrderList={getOrderList}
                  type={"admin"}
                  tab={tab}
                  included={included}
                  editOrder={editOrder}
                />
              </TabPane>
              <TabPane tab="To Receive" key="3">
                <TableTransaction
                  toReceiveList={toReceiveList}
                  loading={loading}
                  getOrderList={getOrderList}
                  type={"admin"}
                  tab={tab}
                  included={included}
                  editOrder={editOrder}
                />
              </TabPane>
              <TabPane tab="Completed" key="4">
                <TableTransaction
                  completedList={completedList}
                  loading={loading}
                  getOrderList={getOrderList}
                  type={"admin"}
                  tab={tab}
                  included={included}
                  editOrder={editOrder}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};

export default Landing;