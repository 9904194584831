import { SALES_RECORD } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  salesRecord: [],
  salesRecordDetails: "",
  salesRecordData: {},
  salesRecordDelete: {},
};

const salesRecord = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case SALES_RECORD.SALES_RECORD_LIST_START:
      return { ...state, loading: true, error: "" };
    case SALES_RECORD.SALES_RECORD_LIST_SUCCESS:
      return { ...state, loading: false, salesRecord: action.payload };
    case SALES_RECORD.SALES_RECORD_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SALES_RECORD.SALES_RECORD_READ_START:
      return { ...state, loading: true, error: "" };
    case SALES_RECORD.SALES_RECORD_READ_SUCCESS:
      return { ...state, loading: false, salesRecordDetails: action.payload };
    case SALES_RECORD.SALES_RECORD_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SALES_RECORD.SALES_RECORD_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case SALES_RECORD.SALES_RECORD_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, salesRecordData: action.payload };
    case SALES_RECORD.SALES_RECORD_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SALES_RECORD.SALES_RECORD_DELETE_START:
      return { ...state, loading: true, error: "" };
    case SALES_RECORD.SALES_RECORD_DELETE_SUCCESS:
      return { ...state, loading: false, salesRecordDelete: action.payload };
    case SALES_RECORD.SALES_RECORD_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default salesRecord;