import { LIVE } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  liveList: [],
  liveDetails: {},
  live: "",
  liveDelete: {},
};

const live = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case LIVE.LIVE_LIST_START:
      return { ...state, loading: true, error: "" };
    case LIVE.LIVE_LIST_SUCCESS:
      return { ...state, loading: false, liveList: action.payload };
    case LIVE.LIVE_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LIVE.LIVE_READ_START:
      return { ...state, loading: true, error: "" };
    case LIVE.LIVE_READ_SUCCESS:
      return { ...state, loading: false, liveDetails: action.payload };
    case LIVE.LIVE_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LIVE.LIVE_ADD_EDIT_START:
      return { ...state, loading: true, error: "" };
    case LIVE.LIVE_ADD_EDIT_SUCCESS:
      return { ...state, loading: false, live: action.payload };
    case LIVE.LIVE_ADD_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LIVE.LIVE_DELETE_START:
      return { ...state, loading: true, error: "" };
    case LIVE.LIVE_DELETE_SUCCESS:
      return { ...state, loading: false, liveDelete: action.payload };
    case LIVE.LIVE_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default live;