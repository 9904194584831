import React, { useEffect, useState } from "react";
import { Card, Select } from "antd";
import CardWrapper from "../../../../../shared/components/otop/CardWrapper";
import Chart from "../components/Chart";

const OrderChart = (props) => {
  return (
    <CardWrapper>
      <Card>
        <div className="card-content p-5">
          <div className="flex justify-between items-center">
            <p className="md-title">Monthly Sales</p>
          </div>
          <div className="flex items-center h-80 mt-8">
            <Chart className="h-full" {...props} />
          </div>
        </div>
      </Card>
    </CardWrapper>
  );
};

export default OrderChart;