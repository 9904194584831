import React, { useState } from 'react';
import { Form, Button, Select, notification, Input } from 'antd';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../shared/components/FormWrapper';
import { inquiryCreate } from '../actions';

function FormInquire(props) {
  const { expoDetails, productDetails } = props;
  const [form] = Form.useForm();
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    // console.log("Success:", values);

    if (expoDetails) {
      dispatch(
        inquiryCreate(values, productDetails.id, expoDetails.id, callback)
      );
    } else {
      dispatch(inquiryCreate(values, productDetails.id, null, callback));
    }

    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'PRODUCT_INQUIRY') {
        notification.success({ message: `${response.msg}` });
      }
    } else {
      notification.error({ message: `${error.msg}` });
    }
    window.scrollTo(0, 0);
  };

  const onInquiryChange = (val) => {
    setShowMessage(true);
  };

  return (
    <FormWrapper>
      <Form
        name="searchFilterExpo"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* <div className="relative w-full">
            <Form.Item
              label="Inquiry Purpose"
              name="inquiry_type"
              rules={[
                { required: true, message: "Please select inquiry purpose!" },
              ]}
            >
              <Select onChange={onInquiryChange}>
                <Option value="I'd like to order this product">I'd like to order this product</Option>
                <Option value="I'd like to negotiate for an order">I'd like to negotiate for an order</Option>
                <Option value="I am just looking">I am just looking</Option>
              </Select>
            </Form.Item>
            <i className="fas fa-file text-blue-20 absolute left-0 top-0 z-10 mt-5 ml-10 mr-5"></i>
          </div> */}
        <div className="relative w-full">
          <Form.Item
            label="Inquiry Message"
            name="inquiry_message"
            rules={[
              { required: true, message: 'Please enter the inquiry message!' },
            ]}
          >
            <Input.TextArea placeholder="Inquiry Message" />
          </Form.Item>
          <i className="fas fa-file text-blue-20 absolute left-0 top-0 z-10 mt-5 ml-5 mr-5"></i>
        </div>
        {/* {showMessage ?
             : <div></div>} */}
        <Button className="btn-main" htmlType="submit">
          <i className="fas fa-handshake text-white pr-3"></i>
          <span className="uppercase">Inquire Now</span>
        </Button>
      </Form>
    </FormWrapper>
  );
}

export default FormInquire;
