import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "antd";
import CardWrapper from "../../../../../shared/components/otop/CardWrapper";
import ListProducts from "../components/ListProducts";
import { getOtopProductList, deleteOtopProduct } from "../../my-products/actions";

const ProductPreview = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products, loading } = state.myProducts;
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOtopProductList());
  }, []);

  return (
    <CardWrapper>
      <Card className="card">
        <div className="card-content">
          <div className="flex justify-between items-center">
            <p className="md-title">Product Preview</p>
            <div className="bg-gray-10 px-5 rounded-3xl w-40">
              <select placeholder="Date Added" />
            </div>
          </div>
          <div>
            <div className="my-2 h-80 overflow-y-auto">
              <ListProducts
                products={products}
                loading={loading}
                deleteOtopProduct={deleteOtopProduct}
                getOtopProductList={getOtopProductList}
              />
            </div>
          </div>
        </div>
      </Card>
    </CardWrapper>
  );
};

export default ProductPreview;