import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import StandardLanding from '../../../shared/components/StandardLanding';
import { getPartnerList } from '../../partner/actions';
import { showExpoFeaturedOfferList } from '../../expo/actions';
import FormSubscribe from '../components/FormSubscribe';

function PageFooter(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { total } = state.partner;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPartnerList(null, total));
    dispatch(showExpoFeaturedOfferList(null, 4));
  }, [getPartnerList, showExpoFeaturedOfferList]);

  return (
    <>
      <StandardLanding>
        <div className="bg-main-contact content-bg">
          <div className="content-space px-5 py-20 lg:p-20">
            <h1 className="content-header-white">CONTACT US</h1>
            <div className="flex justify-center flex-col lg:flex-row text-white py-10">
              <div className="lg:w-60">
                <div className="flex items-center flex-col mt-1">
                  <div
                    className="bg-red-20 rounded-lg p-3"
                    style={{ width: '54px', height: '54px', textAlign: 'center' }}
                  >
                    <i className="fas fa-envelope fa-2x"></i>
                  </div>
                  <p className="mt-3">ask@virtualtradeexpo.com.ph</p>
                </div>
              </div>
              <div className="lg:w-60">
                <div className="flex items-center flex-col mt-1">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/PLDTHomeBiz/"
                    className="bg-red-20 rounded-lg p-3"
                    style={{ width: '54px', height: '54px', textAlign: 'center' }}
                  >
                    <i className="fa" style={{ fontSize: '2em' }}>&#xf09a;</i>
                    {/* <i className="fab fa-facebook fa-2x"></i> */}
                  </a>
                  <p className="mt-3">Facebook</p>
                </div>
              </div>

              <div className="lg:w-1/3 flex flex-col items-center justify-center mt-10 lg:mt-0">
                <h1 className="font-semibold px-10 mb-5 uppercase text-center text-white">
                  STAY UPDATED - JOIN OUR NEWSLETTER
                </h1>
                <div className="w-full">
                  <FormSubscribe />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>

      <footer
        id="footer"
        className="bg-black-10 text-white px-5 py-10 flex flex-col justify-start items-start lg:justify-center lg:items-center"
      >
        <h4 className="text-gray-50 text-center">
          Copyright © {moment().format('YYYY')} WomenBiz. All Rights Reserved
        </h4>
      </footer>
    </>
  );
}

export default PageFooter;
