import { getAuthHeader, getError } from "../../shared/utils";
import {
  API_GENERAL_REGION_LIST,
  API_GENERAL_CATEGORY_LIST,
  API_GENERAL_INDUSTRY_LIST,
  API_GENERAL_CONTACT_SEND,
  API_EXPO_REQUEST,
  API_EXPO_SUBSCRIBE,
  API_WELCOME_MESSAGE
} from "../../shared/constant/url";

export const GENERAL = {
  REGION_LIST_START: "REGION_LIST_START",
  REGION_LIST_SUCCESS: "REGION_LIST_SUCCESS",
  REGION_LIST_ERROR: "REGION_LIST_ERROR",

  WELCOME_MESSAGE_START: "WELCOME_MESSAGE_START",
  WELCOME_MESSAGE_SUCCESS: "WELCOME_MESSAGE_SUCCESS",
  WELCOME_MESSAGE_ERROR: "WELCOME_MESSAGE_ERROR",

  CATEGORY_LIST_START: "CATEGORY_LIST_START",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_ERROR: "CATEGORY_LIST_ERROR",

  INDUSTRY_LIST_START: "INDUSTRY_LIST_START",
  INDUSTRY_LIST_SUCCESS: "INDUSTRY_LIST_SUCCESS",
  INDUSTRY_LIST_ERROR: "INDUSTRY_LIST_ERROR",

  CONTACT_US_SEND_START: "CONTACT_US_SEND_START",
  CONTACT_US_SEND_SUCCESS: "CONTACT_US_SEND_SUCCESS",
  CONTACT_US_SEND_ERROR: "PARTNER_ SHOW_ERROR",

  EXPO_REQUEST_START: "EXPO_REQUEST_START",
  EXPO_REQUEST_SUCCESS: "EXPO_REQUEST_SUCCESS",
  EXPO_REQUEST_ERROR: "EXPO_ EXPO_REQUEST_ERROR",

  SUBSCRIBE_START: "SUBSCRIBE_START",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_ERROR: "EXPO_ SUBSCRIBE_ERROR",
};

export const getRegionList = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL.REGION_LIST_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GENERAL_REGION_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: GENERAL.REGION_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GENERAL.REGION_LIST_ERROR, payload: getError(error) });
      });
  };
};

export const getCategoryList = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL.CATEGORY_LIST_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GENERAL_CATEGORY_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.CATEGORY_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERAL.CATEGORY_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const getIndustryList = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL.INDUSTRY_LIST_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_GENERAL_INDUSTRY_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.INDUSTRY_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERAL.INDUSTRY_LIST_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const sendContactUs = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.CONTACT_US_SEND_START });
    let formData = new FormData();
    formData.append("name", params.name);
    formData.append("email", params.email);
    formData.append("contact_number", params.contact_number);
    formData.append("interest", params.interest);
    formData.append("message", params.message);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GENERAL_CONTACT_SEND, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.CONTACT_US_SEND_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: GENERAL.CONTACT_US_SEND_ERROR, payload: error });
      });
  };
};

export const requestExpo = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.EXPO_REQUEST_START });
    let formData = new FormData();
    formData.append("firstname", params.firstname);
    formData.append("lastname", params.lastname);
    formData.append("email", params.email);
    formData.append("contact_number", params.contact_number);
    formData.append("idea", params.idea);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_REQUEST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.EXPO_REQUEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: GENERAL.EXPO_REQUEST_ERROR, payload: error });
      });
  };
};

export const subscribeNewsletter = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.SUBSCRIBE_START });
    let formData = new FormData();
    formData.append("email", params.email);

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_EXPO_SUBSCRIBE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({ type: GENERAL.SUBSCRIBE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: GENERAL.SUBSCRIBE_ERROR, payload: error });
      });
  };
};


export const getWelcomeMessage = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL.WELCOME_MESSAGE_START });

    let requestOption = {
      method: "POST",
      headers: getAuthHeader(),
    };
    fetch(API_WELCOME_MESSAGE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.WELCOME_MESSAGE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERAL.WELCOME_MESSAGE_ERROR,
          payload: getError(error),
        });
      });
  };
};