import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';

import FormWrapper from '../../../../../shared/components/FormWrapper';

function FormPayment(props) {
  const { editOrder, status, statusData, handleCancel, getOrderList } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    values['status_id'] = status;
    dispatch(editOrder(values, statusData.id, callback));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const callback = async (response, error) => {
    if (response) {
      const { data } = response;
      if (data.type === 'order') {
        notification.success({
          message: 'Payment status has been succesfully updated!',
        });
        handleCancel();
        getOrderList();
      } else {
        notification.warning({ message: 'Warning' });
      }
      form.resetFields();
    }
  };

  return (
    <>
      <FormWrapper>
        <Form
          name="form-payment"
          layout="vertical"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="flex flex-wrap w-full">
            <Form.Item
              label="Remarks"
              name="remarks"
              className="w-full relative my-5 px-5"
              rules={[
                {
                  required: true,
                  message: 'Please input your remarks!',
                },
              ]}
            >
              {/* <i className="fas fa-user text-blue-20 absolute left-0 top-0 z-10 mt-4 mx-5"></i> */}
              <Input.TextArea placeholder="Remarks" />
            </Form.Item>
          </div>
          <div className="flex flex-col lg:flex-row items-center mt-5 lg:mx-5">
            <div className="flex mt-5 lg:mt-0">
              <Button className="btn btn-red w-40" htmlType="submit">
                <i className="fas fa-pen mr-1"></i>
                Send
              </Button>
            </div>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
}

export default FormPayment;
