import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Tabs, List, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import StandardContainer from "../../../../shared/components/otop/StandardContainer";
import TabsWrapper from "../../../../shared/components/otop/TabsWrapper";
import CardWrapper from "../../../../shared/components/otop/CardWrapper";
import logo from "../../../../shared/assets/otop/Sellet/logo-1.svg";
import { otopSellerRead, otopStoreRead } from "../actions";
import { getOtopProductList } from "../../products/actions";
import { isEmpty, filter, iteratee } from "lodash";
import ListFeaturedProducts from "../../products/components/ListFeaturedProducts";
import SellerProfile from "../components/SellerProfile";

const { TabPane } = Tabs;

const StoreShow = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { storeDetails, loading, sellerDetails } = state.otopSeller;
  const { products } = state.otopProduct;
  const { id } = props.match.params;
  const [productsFromStore, setProductsFromStore] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(otopStoreRead(id));
      dispatch(otopSellerRead(id));
    }
    dispatch(getOtopProductList());
  }, [id]);

  useEffect(() => {
    if (storeDetails && storeDetails.data && products && products.data) {
      const { attributes } = storeDetails.data;
      const { data } = products;
      let list = data.filter(item => parseInt(attributes.user_id) === parseInt(item.attributes.seller_id));
      setProductsFromStore(list);
    }
  }, [storeDetails, products])

  const renderImg = () => {
    if (storeDetails.data && storeDetails.data.attributes) {
      let holder = storeDetails["data"]["attributes"]["logo_path"];
      if (!isEmpty(holder)) {
          return (
            <img
              src={holder}
              alt="OTOP Hub Product"
              className="w-14 h-14 object-cover mr-5"
            />
          );
      }
    }
    return (
      <img
        src={logo}
        alt="OTOP Hub Product"
        className="w-14 h-14 object-center border mr-5"
      />
    );
  };

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg py-10">
            <div className=" bg-main-login p-5 flex items-center ">
              <Card className="w-60">
                <div className="flex items-center font-semibold text-gray-20">
                  {renderImg()}
                  <p>{storeDetails.data && storeDetails.data.attributes.name}'s&nbsp;Store</p>
                </div>
              </Card>
            </div>
            <TabsWrapper>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Products" key="1">
                  <ListFeaturedProducts
                    productsFromStore={productsFromStore}
                    products={products}
                    loading={loading}
                    type={"seller"}
                  />
                </TabPane>
                <TabPane tab="Otop Hub Store Profile" key="2">
                  <SellerProfile sellerDetails={sellerDetails} loading={loading} />
                </TabPane>
              </Tabs>
            </TabsWrapper>
        </div>
      </div>
    </StandardContainer>
  );
};

export default StoreShow;