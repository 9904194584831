import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Popconfirm, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

function TableGallery(props) {
  const {
    listGallery,
    onSetForm,
    deleteGallery,
    callback,
    setEdit,
    setDetails,
  } = props;
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Image',
      dataIndex: 'full_path',
      key: 'full_path',
      width: 200,
      render: (data, rowData) => renderImage(data, rowData),
    },
    {
      title: 'Exhibitor Name',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 200,
      render: (data, rowData) => renderCompanyName(data, rowData),
    },
    {
      title: 'Product Name',
      dataIndex: 'title',
      key: 'title',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: 100,
      render: (data, rowData) => renderAction(data, rowData),
    },
  ];

  const renderImage = (data, rowData) => {
    if (rowData.video !== 'undefined') {
      return (
        <div className="player-wrapper">
          <ReactPlayer
            url={
              rowData.video
                ? rowData.video
                : 'https://www.facebook.com/PLDTHomeBiz/videos/638796226759198'
            }
            className="react-player"
            width="100%"
            height="100%"
            controls
          />
        </div>
      );
    } else if (rowData['thumbnail']['full_path']) {
      return <img src={rowData['thumbnail']['full_path']} className="h-20" />;
    }
  };

  const renderCompanyName = (data, rowData) => {
    return rowData['exhibitor']['data']['company_name'];
  };

  const renderAction = (data, rowData) => {
    return (
      <div>
        <i
          onClick={() => onEdit(rowData)}
          className="far fa-edit mr-5 cursor-pointer"
        ></i>
        <i
          onClick={() => deleteItem(rowData.id)}
          className="far cursor-pointer fa-trash-alt text-red-10"
        ></i>
      </div>
    );
  };

  const onEdit = (value) => {
    onSetForm(value);
    setEdit(true);
    setDetails(value);
  };

  const deleteItem = (id) => {
    dispatch(deleteGallery(id, callback));
  };

  return (
    <div className="overflow-x-auto">
      <Table rowKey="id" columns={columns} dataSource={listGallery} />
    </div>
  );
}

export default TableGallery;
