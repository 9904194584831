import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import { readOrder } from "../actions";
import { getOtopProductList } from "../../../products/actions";
import TransactionDetails from "../../../transactions/components/TransactionDetails";

const LandingTransaction = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const state = useSelector((state) => state);
  const { orderDetails, loading } = state.order;
  const { products } = state.otopProduct;

  useEffect(() => {
    if (id) {
      dispatch(readOrder(id));
    }
    dispatch(getOtopProductList());
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg">
          <div className="bg-gray-90 py-5">
            <Card loading={loading}>
              <TransactionDetails transactionDetails={orderDetails} products={products} type={"admin"} />
            </Card>
          </div>
        </div>
      </div>
    </StandardContainer>
  );
};
export default LandingTransaction;