import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Upload, Button, Input, notification, Select } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import FormWrapper from '../../../../../shared/components/otop/FormWrapper';
import { isEmpty, filter, iteratee } from 'lodash';

const { Option } = Select;

const FormProduct = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    createOtopProduct,
    editOtopProduct,
    productDetails,
    id,
    tags,
    categories,
  } = props;
  const [fileList, setFileList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [form] = Form.useForm();
  let isEdit = id;

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isEdit && productDetails) {
      const { included, data } = productDetails;
      let tags = [];
      let categories = [];
      let productTag = filter(included, iteratee({ type: 'tag' }));
      let productCat = filter(included, iteratee({ type: 'productcategory' }));

      productTag.forEach((tag) => {
        const { name } = tag.attributes;
        tags.push(name);
      });

      productCat.forEach((tag) => {
        const { name } = tag.attributes;
        categories.push(name);
      });

      if (data) {
        const { attributes } = data;
        form.setFieldsValue({
          name: attributes.name ? attributes.name : '',
          price: attributes.price ? attributes.price : '',
          quantity: attributes.quantity ? attributes.quantity : '',
          quantity_type: attributes.quantity_type
            ? attributes.quantity_type
            : '',
          description: attributes.description ? attributes.description : '',
          tags: tags ? tags : '',
          categories: categories ? categories : '',
          // images: filtered && filtered[0]
          //   ? `${filtered.path["images"]}`
          //   : "",
        });
      }
    }
  }, [productDetails]);

  function renderImg(values) {
    if (!isEmpty(fileList)) {
      values['images'] = fileList;
    }
  }

  const onFinish = (values, e) => {
    // console.log("values", values)
    if (isEdit) {
      let temp1 = [];
      let temp2 = [];
      if (!isEmpty(values.categories)) {
        values.categories.forEach((cat) => {
          temp1.push({ name: cat });
        });
      }
      if (!isEmpty(values.tags)) {
        values.tags.forEach((tag) => {
          temp2.push({ name: tag });
        });
      }
      values['categories'] = temp1;
      values['tags'] = temp2;
      dispatch(editOtopProduct(values, id, callback));
      setIsDisabled(true);
    } else {
      renderImg(values);
      dispatch(createOtopProduct(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.data.status_code;
      if (statusCode === 'PRODUCT_UPDATE') {
        notification.success({
          message: `Product is successfully updated!`,
          duration: '1',
        });
        history.push(`/otop-hub/admin/products`);
      } else if (statusCode === 'INVALID_DATA') {
        if (response.data.errors.name) {
          form.setFields([
            {
              name: 'name',
              errors: [`${response.data.errors.name}`],
            },
          ]);
        }
        if (response.data.errors.price) {
          form.setFields([
            {
              name: 'price',
              errors: [`${response.data.errors.price}`],
            },
          ]);
        }
        if (response.data.errors.quantity) {
          form.setFields([
            {
              name: 'quantity',
              errors: [`${response.data.errors.quantity}`],
            },
          ]);
        }
        if (response.data.errors.quantity_type) {
          form.setFields([
            {
              name: 'quantity_type',
              errors: [`${response.data.errors.quantity_type}`],
            },
          ]);
        }
        if (response.data.errors.description) {
          form.setFields([
            {
              name: 'description',
              errors: [`${response.data.errors.description}`],
            },
          ]);
        }
        if (response.data.errors.images) {
          form.setFields([
            {
              name: 'images',
              errors: [`${response.data.errors.images}`],
            },
          ]);
        }
      } else {
        notification.success({
          message: `Product is successfully created!`,
          duration: '1',
        });
        history.push(`/otop-hub/admin/products`);
      }
    }
  };
  const renderCategory = () => {
    if (!isEmpty(categories)) {
      return categories.map((tag) => (
        <Option key={tag.id} value={tag.attributes.name}>
          {tag.attributes.name}
        </Option>
      ));
    } else {
      return <Option key="no data">There is no category</Option>;
    }
  };

  const renderTag = () => {
    if (!isEmpty(tags)) {
      return tags.map((tag) => (
        <Option key={tag.id} value={tag.attributes.name}>
          {tag.attributes.name}
        </Option>
      ));
    } else {
      return <Option key="no data">There is no tag</Option>;
    }
  };

  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
        name="formProduct"
        className="shadow pt-10 px-10 bg-white"
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
      >
        <div className="flex justify-between mb-5">
          <p className="md-title">Product Details</p>
          <p className="sm-title">Enter the product details here</p>
        </div>
        <p className="sm-title">Product Images</p>
        <Upload
          name="images"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
        >
          {fileList.length < 3 && (
            <i className="fas fa-camera text-gray-10 text-5xl"></i>
          )}
        </Upload>
        <div className="flex w-5/6 mt-5">
          <div className="w-1/2 mr-10 pr-5">
            <div>
              <p className="xs-title">Product Name</p>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input product name!',
                  },
                ]}
              >
                <Input placeholder="Product Name" />
              </Form.Item>
            </div>
            <div>
              <p className="xs-title">Item Price</p>
              <Form.Item
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Please input item price!',
                  },
                ]}
              >
                <Input placeholder="Item Price" />
              </Form.Item>
            </div>
          </div>
          <div className="w-1/2">
            <div>
              <p className="xs-title">Item Qty</p>
              <Form.Item
                name="quantity"
                rules={[{ required: true, message: 'Please input item qty!' }]}
              >
                <Input placeholder="Item Qty" />
              </Form.Item>
            </div>
            <div>
              <p className="xs-title">
                Unit Sold: (sample: per piece, one dozen, per pack)
              </p>
              <Form.Item
                name="quantity_type"
                rules={[
                  {
                    required: true,
                    message: 'Please input product quantity type!',
                  },
                ]}
              >
                <Input placeholder="Unit Sold: (sample: per piece, one dozen, per pack)" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="w-5/6">
          <p className="xs-title">Product Description</p>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input product description!',
              },
            ]}
          >
            <Input.TextArea rows={5} placeholder="Product Description" />
          </Form.Item>
        </div>
        <div className="border-b-2">&nbsp;</div>
        <div className="flex w-5/6 pt-10">
          <div className="w-1/2 mr-10 pr-5">
            <p className="xs-title">Stocks Available</p>
            <Form.Item>
              <Input placeholder="Stocks Available" />
            </Form.Item>
          </div>
          <div className="w-1/2">
            <p className="xs-title">Product Category: (can select multiple)</p>
            <Form.Item name="categories">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Select Product Category"
                className="mt-4"
              >
                {renderCategory()}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="w-5/6">
          <p className="xs-title">
            Product Tags: (can input as many as tag as preferred)
          </p>
          <Form.Item name="tags">
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Select Product Tag"
              className="mt-4"
            >
              {renderTag()}
            </Select>
          </Form.Item>
        </div>
        <div className="border-b-2">&nbsp;</div>
        <div className="py-10 flex">
          {isEdit ? (
            <Button htmlType="submit" className="mr-5 flex items-center">
              Edit Product
            </Button>
          ) : (
            <Button
              htmlType="submit"
              disabled={isDisabled}
              className="mr-5 flex items-center"
            >
              Add Product
            </Button>
          )}
          `
          <Link to="/otop-hub/admin/products">
            <Button className="flex items-center">Cancel</Button>
          </Link>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default FormProduct;
