import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormUser from "../components/FormUser";
import StandardContainer from "../../../../../shared/components/otop/StandardContainer";
import {
  createUser,
  editUser,
  readUser,
} from "../actions";

function AddEditUser() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector((state) => state);
  const { userDetails } = state.user;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(readUser(id));
    }
  }, [id]);

  return (
    <StandardContainer>
      <div className="main-container">
        <div className="content-bg my-5 content-crop">
          <FormUser
            createUser={createUser}
            editUser={editUser}
            userDetails={userDetails}
            id={id}
          />
          </div>
      </div>
    </StandardContainer>
  );
}
export default AddEditUser;