import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { notification, Card } from "antd";
import { isEmpty } from "lodash";
// import InnerImageZoom from 'react-inner-image-zoom';

import StandardLanding from "../../../shared/components/StandardLanding";
import ListExhibitor from "../components/ListExhibitor";
import {
  getExpoHallExhibitorList,
  getExpoHallExhibitorListMerchant,
  countClickEvent
} from "../actions";
import { getGuestExpoHallExhibitorList } from "../../guest/actions";
import { getIndustryList } from "../../general/actions";
import FormSearchExhibitor from "../components/FormSearchExhibitor";
import booth4 from "../../../shared/assets/images/icon/booth/booth4.png";
import booth5 from "../../../shared/assets/images/icon/booth/booth5.png";
import dtiLogo from "../../../shared/assets/images/logo/dti logo.png";
import pldtLogo from "../../../shared/assets/images/logo/logo.png";
import container from "../../../shared/assets/images/icon/booth/container.png";
import CardWrapperNoBg from "../../../shared/components/CardWrapperNoBg";
import ExhibitorPaginationWrapper from "../../../shared/components/ExhibitorPaginationWrapper";

function ExpoExhibitorLanding(props) {
  const dispatch = useDispatch();
  const { slug } = props.match.params;
  const { history } = props;
  const state = useSelector((state) => state);
  const { exhibitors, merchants, totalExhibitors, totalMerchants } = state.expo;
  const { expoHallExhibitors, totalGuestExhibitors } = state.guest;
  const { industries } = state.general;
  const loadingExpo = state.expo.loading;
  const [exhibitorList, setExhibitorList] = useState([]);
  let isExhibitor = localStorage.getItem("isExhibitor");
  let token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === "false") {
      dispatch(getExpoHallExhibitorList(null, null, slug, totalExhibitors, callback));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "true") {
      dispatch(
        getExpoHallExhibitorListMerchant(null, null, slug, totalMerchants, callback)
      );
    }
  }, []);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestExpoHallExhibitorList(null, null, slug, totalGuestExhibitors));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === "false") {
      !isEmpty(exhibitors)
        ? setExhibitorList(exhibitors)
        : setExhibitorList([]);
    }
  }, [exhibitors]);

  useEffect(() => {
    if (isExhibitor === "true") {
      !isEmpty(merchants) ? setExhibitorList(merchants) : setExhibitorList([]);
    }
  }, [merchants]);

  useEffect(() => {
    if (!token) {
      !isEmpty(expoHallExhibitors)
        ? setExhibitorList(expoHallExhibitors)
        : setExhibitorList([]);
    }
  }, [expoHallExhibitors]);

  useEffect(() => {
    dispatch(getIndustryList());
  }, []);

  // useEffect(() => {
  //   if (token === null) {
  //     notification.warning({
  //       message: "Could not access.",
  //       description: "To view this, you need to login first!",
  //     });
  //     return props.history.push("/expo");
  //   }
  // }, []);

  const callback = (response, error) => {
    if (response) {
      const { status_code } = response;
      if (status_code === "UNREGISTERED_PARTICIPANT") {
        notification.warning({ message: `${response.msg}` });
        history.push(`/expo/${slug}`);
      }
    }
  };

  const onCountClickEvent = () => {
    dispatch(countClickEvent(callback));
  };

  return (
    <>
      <StandardLanding>
        <div className="banner content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="flex justify-between items-center">
              {/* <h1 className="text-white font-medium text-3xl z-10">
                WELCOME TO VIRTUAL TRADE EXPO!
              </h1> */}
              <div className="w-full">
                <FormSearchExhibitor
                  getExpoHallExhibitorList={getExpoHallExhibitorList}
                  getExpoHallExhibitorListMerchant={
                    getExpoHallExhibitorListMerchant
                  }
                  getGuestExpoHallExhibitorList={getGuestExpoHallExhibitorList}
                  totalExhibitors={totalExhibitors}
                  totalMerchants={totalMerchants}
                  totalGuestExhibitors={totalGuestExhibitors}
                  slug={slug}
                  industries={industries}
                />
              </div>
              <div>
                <Link
                  to="."
                  className="text-blue-10 bg-white mx-2 px-5 py-2 rounded-lg z-10 flex items-center mb-5"
                >
                  <i className="fas fa-chevron-left"></i>&nbsp;
                  <span className="whitespace-nowrap">Go back</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StandardLanding>
      <StandardLanding>
        <div className="bg-gray-80 content-bg">
          <div className="content-space px-5 py-10 lg:p-20">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              <CardWrapperNoBg>
                <Card
                  bordered={false}
                  className="my-5"
                  style={{ background: "transparent !important" }}
                >
                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-1/2">
                      <div className="bg-white p-5">
                        <h2 className="text-lg text-black-20">
                          Take the next step for your business!{" "}
                          <span className="font-bold">
                            Register your business with DTI now!
                          </span>{" "}
                          Get access to FREE workshops, events and more!
                        </h2>
                      </div>
                    </div>
                    <div onClick={onCountClickEvent} className="w-full lg:w-1/2 cursor-pointer">
                      <div className="relative flex items-center justify-center flex-col">
                        <div
                          className="absolute top-0 right-0 -mt-3"
                          style={{ marginRight: "30%" }}
                        >
                          <div className="relative">
                            <img
                              src={container}
                              alt="PLDT Home Biz"
                              className="h-20 w-20"
                            />
                            <img
                              src={dtiLogo}
                              alt="PLDT Home Biz"
                              className="h-20 w-20 object-center object-contain absolute top-0 left-0 p-1"
                            />
                          </div>
                        </div>
                        <img
                          src={booth5}
                          alt="PLDT Home Biz"
                          className="h-56 w-auto"
                        />
                      </div>
                      <div className="flex justify-center text-center mt-2">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://bnrs.dti.gov.ph/?gclid=CjwKCAjwx8iIBhBwEiwA2quaq7kTiNUvYhrHBwWojuvOXhmzodAK4TaEgsS9OsJ_09jjIwTStYb8kxoCyhgQAvD_BwE"
                          className="text-white bg-red-20 mx-2 px-5 py-2 rounded-lg block"
                        >
                          <i className="fas fa-pen"></i>&nbsp;
                          <span>Click here to register your business!</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Card>
              </CardWrapperNoBg>
              <CardWrapperNoBg>
                <Card
                  bordered={false}
                  className="my-5"
                  style={{ background: "transparent !important" }}
                >
                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-1/2">
                      <div className="relative flex items-center justify-center flex-col">
                        <div
                          className="absolute top-0 right-0 -mt-3"
                          style={{ marginRight: "30%" }}
                        >
                          <div className="relative">
                            <img
                              src={container}
                              alt="PLDT Home Biz"
                              className="h-20 w-20"
                            />
                            <img
                              src={pldtLogo}
                              alt="PLDT Home Biz"
                              className="h-20 w-20 object-center object-contain absolute top-0 left-0 p-1"
                            />
                          </div>
                        </div>
                        <img
                          src={booth4}
                          alt="PLDT Home Biz"
                          className="h-56 w-auto"
                        />
                      </div>
                      <div className="flex justify-center text-center mt-2">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://pldthome.com/sales-and-service-centers"
                          className="text-white bg-red-20 mx-2 px-5 py-2 rounded-lg block"
                        >
                          <i className="fas fa-map"></i>&nbsp;
                          <span>
                            Apply real time online with our sales representative
                            here
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <div className="bg-white p-5">
                        <h2 className="text-lg text-black-20">
                          <span className="font-bold">
                            Earn better at Home with PLDT Home Biz!
                          </span>{" "}
                          Get Up to 60Mbps Unlimited Fiber, Free Back up WIFI,
                          Unli Calls to PLDT Networks and Access to E-commerce
                          partner solutions for P2099/month!
                        </h2>
                      </div>
                    </div>
                  </div>
                </Card>
              </CardWrapperNoBg>
            </div>
            <div className="mt-10">
              <ExhibitorPaginationWrapper>
                <ListExhibitor
                  exhibitors={exhibitorList}
                  isExhibitor={isExhibitor}
                  loadingExpo={loadingExpo}
                />
              </ExhibitorPaginationWrapper>
            </div>
          </div>
        </div>
      </StandardLanding>
    </>
  );
}

export default ExpoExhibitorLanding;