import { PRODUCT } from "./actions";

const INITIALSTATE = {
  loading: false,
  error: "",
  products: [],
  productDetails: {},
  categories: [],
  categoryDetails: {},
  tags: [],
  tagDetails: {},
};

const otopProduct = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, products: action.payload};
    case PRODUCT.OTOP_PUBLIC_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PUBLIC_PRODUCT_READ_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_PRODUCT_READ_SUCCESS:
      return { ...state, loading: false, productDetails: action.payload };
    case PRODUCT.OTOP_PUBLIC_PRODUCT_READ_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, categories: action.payload};
    case PRODUCT.OTOP_PUBLIC_CATEGORY_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PUBLIC_CATEGORY_READ_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_CATEGORY_READ_SUCCESS:
      return { ...state, loading: false, categoryDetails: action.payload };
    case PRODUCT.OTOP_PUBLIC_CATEGORY_READ_ERROR:
      return { ...state, loading: false, error: action.payload };
    
      case PRODUCT.OTOP_PUBLIC_TAG_LIST_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_TAG_LIST_SUCCESS:
      return { ...state, loading: false, tags: action.payload};
    case PRODUCT.OTOP_PUBLIC_TAG_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PRODUCT.OTOP_PUBLIC_TAG_READ_START:
      return { ...state, loading: true, error: "" };
    case PRODUCT.OTOP_PUBLIC_TAG_READ_SUCCESS:
      return { ...state, loading: false, tagDetails: action.payload };
    case PRODUCT.OTOP_PUBLIC_TAG_READ_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default otopProduct;