import { OTOPCART } from "../cart/actions";
import { reject } from "lodash";

const INITIAL_STATE = {
  loading: false,
  error: "",
  cartProducts: [],
  totalCart: [],
  cartProduct: {},
  cartRemove: 0,
  checkout: {},
  checkoutList: [],
  qty: 0,
};

const otopCart = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OTOPCART.CART_OTOP_ALL: 
    let totalCart;
    if(state.cartProducts) {
      totalCart = state.cartProducts.length
    } 
    return {...state, totalCart};

  case OTOPCART.CART_OTOP_ADD:
    let total = action.payload;
    return {...state, qty: total};

  case OTOPCART.CART_OTOP_PRODUCT_LIST:
    sessionStorage.getItem("cartOtopProducts")
    let list = JSON.parse(sessionStorage.getItem("cartOtopProducts"))
    return {...state, cartProducts: list}

  case OTOPCART.CART_OTOP_PRODUCT_ADD:
    sessionStorage.setItem('cartOtopProduct', JSON.stringify(action.payload));
    let currentList = state.cartProducts ? state.cartProducts : []
    let holder = action.payload;
    let temp = holder.data.attributes.price.toLocaleString().replace(',','')
    let temp2 = temp.slice(0, temp.length-3)
    holder.total = holder.quantity * parseInt(temp2)
    currentList.push(holder)
    sessionStorage.setItem('cartOtopProducts', JSON.stringify(currentList));
    return {...state, cartProduct: action.payload, cartProducts:currentList}

  case OTOPCART.CART_OTOP_PRODUCT_REMOVE:
    sessionStorage.getItem("cartOtopProducts")
    let current_list = JSON.parse(sessionStorage.getItem("cartOtopProducts"))
    let id  = action.payload;
    let row = current_list.filter(item => parseInt(item.data.id) !== parseInt(id))
    // let row = reject(current_list, { 'data.id': id });
    console.log("row", row)
    sessionStorage.setItem('cartOtopProducts', JSON.stringify(row));
    return {...state, cartRemove: id, cartProducts: row};

  case OTOPCART.CART_CHECKOUT_LIST:
    sessionStorage.getItem("checkoutOtop")
    let checkoutList = JSON.parse(sessionStorage.getItem("checkoutOtop"))
    return {...state, checkoutList}

  case OTOPCART.CART_CHECKOUT:
    sessionStorage.setItem('checkoutOtop', JSON.stringify(action.payload));
    let checkout = action.payload
    return {...state, checkout};

    case OTOPCART.ORDER_CREATE_START:
      return { ...state, loading: true, error: "" };
    case OTOPCART.ORDER_CREATE_SUCCESS:
      return { ...state, loading: false, checkout: action.payload };
    case OTOPCART.ORDER_CREATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case OTOPCART.ORDER_LIST_START:
      return { ...state, loading: true, error: "" };
    case OTOPCART.ORDER_LIST_SUCCESS:
      return { ...state, loading: false, checkoutList: action.payload };
    case OTOPCART.ORDER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default otopCart;
