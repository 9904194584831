import React, { useState, useEffect } from 'react';
import { Space, Form, Input, Button, notification, Upload, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import FormWrapper from '../../../shared/components/FormWrapper';

const { Option } = Select;

function FormProduct(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const {
    isEdit,
    createProduct,
    editProduct,
    productDetails,
    id,
    productImageList,
    deleteProductImage,
  } = props;
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [onPreviewImage, setOnPreviewImage] = useState(null);

  const onChange = ({ fileList: newFileList }) => {
    if (!onPreviewImage) {
      setOnPreviewImage(newFileList[0]);
    }
    setFileList(newFileList);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isEdit && productDetails) {
      form.setFieldsValue({
        product_name: productDetails['name'] || '',
        description: productDetails['description'] || '',
        price: productDetails['price'] || '',
        stock: productDetails['stock'] || '',
        types: productDetails['types'] === '1' ? 'Food' : 'Non-Food',
        product_url: productDetails['product_url'] || '',
        video_url: productDetails['video_url'] || '',
        sizes: productDetails['sizes']?.split(',') || '',
        color: productDetails['color']?.split(',') || '',
      });
    }
  }, [productDetails]);

  useEffect(() => {
    if (isEdit && productImageList?.length > 0) {
      const newList = productImageList.map((list) => ({
        uid: list.id,
        name: list.filename,
        url: list.full_path,
        thumbUrl: list.thumb_path,
      }));
      setFileList(newList);
    }
  }, [productImageList]);

  const onFinish = (values, e) => {
    setIsDisabled(true);
    if (isEdit) {
      dispatch(editProduct(values, id, callback));
    } else {
      dispatch(createProduct(values, callback));
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const callback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'PRODUCT_CREATED') {
        notification.success({ message: `${response.msg}`, duration: '1' });
        history.push('/exhibitor/profile');
      } else if (statusCode === 'PRODUCT_UPDATED') {
        notification.success({ message: `${response.msg}`, duration: '1' });
        history.push('/exhibitor/profile');
      } else if (statusCode === 'INVALID_DATA') {
        if (response.errors.price) {
          form.setFields([
            {
              name: 'price',
              errors: [`${response.errors.price}`],
            },
          ]);
        }
        if (response.errors.video_url) {
          form.setFields([
            {
              name: 'video_url',
              errors: [`${response.errors.video_url}`],
            },
          ]);
        }
      }
    }
  };

  const onDeleteImage = ({ uid }) => {
    dispatch(deleteProductImage(id, uid, imageCallback));
  };

  const imageCallback = (response, error) => {
    if (response) {
      const statusCode = response.status_code;
      if (statusCode === 'IMAGE_DELETED') {
        notification.success({ message: `${response.msg}`, duration: '1' });
        history.push(`/exhibitor/products/${id}/edit`);
      }
    } else {
      notification.error({ message: `${error.msg}`, duration: '1' });
    }
  };

  return (
    <FormWrapper>
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <Form.Item
              name="image"
              className="mb-0"
              rules={[
                {
                  required: isEdit ? false : true,
                  message: 'Please input your product image!',
                },
              ]}
            >
              <Upload
                className="custom-upload-item w-full h-full"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onRemove={onDeleteImage}
                multiple={false}
                maxCount={1}
              >
                {fileList.length < 1 && 'Upload'}
              </Upload>
            </Form.Item>
            <small className="font-semibold text-gray-20">
              Max 10MB. Can be jpg, jpeg and png file types only.
            </small>
          </div>
          <div className="w-full lg:w-1/2 lg:ml-20 mt-10 lg:mt-0">
            <h4 className="text-red-20 text-xl">PRODUCT DETAILS</h4>
            <div className="flex w-full">
              <Form.Item
                label="Product Name"
                name="product_name"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product name!',
                  },
                ]}
              >
                <Input placeholder="Product Name" />
              </Form.Item>
            </div>
            <div className="flex w-full">
              <Form.Item
                label="Item Type"
                name="types"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product price!',
                  },
                ]}
              >
                <Select placeholder="Type">
                  <Option value={1}>Food</Option>
                  <Option value={0}>Non-Food</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex w-full">
              <Form.Item
                label="Price"
                name="price"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product price!',
                  },
                ]}
              >
                <Input type="number" placeholder="Price" />
              </Form.Item>
            </div>
            {/* <div className="flex w-full">
              <Form.Item
                label="Stock"
                name="stock"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product stocks!',
                  },
                ]}
              >
                <Input placeholder="Stock" />
              </Form.Item>
            </div> */}
            {/* <div className="flex w-full">
              <Form.Item
                label="Video URL"
                name="video_url"
                className="w-full relative my-5"
                rules={[
                  {
                    required: false,
                    message: 'Please input your product video url!',
                  },
                ]}
              >
                <Input placeholder="Video URL" />
              </Form.Item>
            </div> */}
            <div className="flex w-full">
              <Form.Item
                label="Product Description"
                name="description"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product description!',
                  },
                ]}
              >
                <Input.TextArea placeholder="Description" />
              </Form.Item>
            </div>
            <div className="flex w-full">
              <Form.Item
                label="Product Url"
                name="product_url"
                className="w-full relative my-5"
                rules={[
                  {
                    required: true,
                    message: 'Please input your product url!',
                  },
                ]}
              >
                <Input placeholder="Product Url" />
              </Form.Item>
            </div>
            {/* <div className="flex w-full">
              <Form.Item
                label="Color (Optional: Type color if not available in options)"
                name="color"
                className="w-full relative my-5"
                rules={[
                  {
                    required: false,
                    message: 'Please select color!',
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Type color if not available in options"
                >
                  {colors.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div> */}
            {/* <div className="flex w-full">
              <Form.Item
                label="Sizes (Optional: Type size if not available in options)"
                name="sizes"
                className="w-full relative my-5"
                rules={[
                  {
                    required: false,
                    message: 'Please select sizes!',
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Type size if not available in options"
                >
                  {sizes.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div> */}
            {/* <div className="flex items-center pt-5 pb-0 border-b-gray pb-10">
              <div className="w-3/4">
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: isEdit ? false : true,
                      message: 'Please input your product image!',
                    },
                  ]}
                >
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    {...propsFile}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload product image
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row lg:justify-center pt-10">
          <Space className="justify-center">
            <Button
              htmlType="submit"
              className="btn btn-red rounded py-3 px-5 h-auto"
              onClick={() => {
                setIsDisabled(true);
                setTimeout(setIsDisabled(false), 5000);
              }}
              disabled={isDisabled}
            >
              <span>{isEdit ? 'Update' : 'Add'} Product</span>
            </Button>
            <Button
              className="btn btn-white rounded py-3 px-5 h-auto"
              style={{ boxShadow: '0px 3px 6px #00000029' }}
            >
              <Link to={'/exhibitor/profile'}>
                <span>Cancel</span>
              </Link>
            </Button>
          </Space>
        </div>
      </Form>
    </FormWrapper>
  );
}

export default FormProduct;

const colors = ['red', 'orange', 'yellow', 'green', 'violet', 'indigo', 'blue'];

const sizes = ['extra small', 'small', 'medium', 'large', 'extra large'];
