import React, { useState } from "react";
import { Link } from "react-router-dom";
import { List, Card } from "antd";

import placeholder from "../../../shared/assets/images/placeholder.jpg";
import CardWrapper from "../../../shared/components/CardWrapper";
// import ModalUser from "../../general/components/ModalUser";

function ListSpeaker(props) {
  const {
    speakers,
    loadingSpeaker,
  } = props;
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // let token = localStorage.getItem("token");

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <>
      <h1 className="mt-10 content-header-left">OUR SPEAKERS</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 5,
        }}
        pagination={true}
        dataSource={speakers}
        renderItem={(item) => (
          <List.Item>
            <CardWrapper>
              <Card
                bordered={false}
                className="card my-5"
                loading={loadingSpeaker}
              >
                <img
                  src={item.thumbnail ? item.thumbnail.full_path : placeholder}
                  alt="Virtual Trade Expo Ph"
                  className="h-56 lg:h-56 w-full card-img"
                />
                <div className="card-content">
                  <h3 className="card-title text-control-1">{item.name}</h3>
                  <h4 className="card-subtitle text-control-1">{item.work}</h4>
                  <div className="card-body">
                    <div className="card-body-row flex-justify-center mt-5">
                      <i className="fas fa-laptop card-body-icon"></i>&nbsp;
                      <span className="card-body-content text-control-1">
                        Virtual Trade EXPO & 3 more
                      </span>
                    </div>
                    <div className="flex justify-center mt-2">
                      <Link
                        to={`/speakers/${item.id}`}
                        className="btn btn-red"
                      >
                        <i className="fas fa-share"></i>&nbsp;
                        <span>GO TO SPEAKER</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </CardWrapper>
          </List.Item>
        )}
      />
      {/* <ModalUser
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      /> */}
    </>
  );
}

export default ListSpeaker;