import React, { useEffect } from 'react';
import { forgotPasswordExhibitor, forgotPassword } from '../actions';
import PageHeader from '../../layout/containers/PageHeader';
import PageFooter from '../../layout/containers/PageFooter';
import PageContentWrapper from '../../../shared/components/PageContentWrapper';

import FormForgotPassword from '../components/FormForgotPassword';

function ForgotPassword(props) {
  const { token, history } = props;
  const hasToken = localStorage.getItem('token');

  // if (hasToken) window.location.replace('/');

  return (
    <React.Fragment>
      <PageHeader profileDetails={{}} />
      <PageContentWrapper>
        {
          <div>
            <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
              <div className="flex justify-center items-center px-5 lg:px-20 h-full">
                <h1 className="text-white font-medium text-3xl z-10">
                  FORGOT PASSW0RD
                </h1>
              </div>
              <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
            </div>
            <div className="bg-main-content bg-center bg-cover bg-no-repeat">
              <div className="px-5 py-20 lg:p-20 text-blue-20">
                <h1 className="text-red-10 text-3xl font-semibold mb-10 uppercase font-semibold">
                  FORGOT PASSWORD
                </h1>
                <div className="bg-white rounded-xl lg:w-3/4 shadow p-10 w-full">
                  <FormForgotPassword
                    forgotPassword={forgotPassword}
                    forgotPasswordExhibitor={forgotPasswordExhibitor}
                    history={history}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </PageContentWrapper>
      <PageFooter />
    </React.Fragment>
  );
}

export default ForgotPassword;
