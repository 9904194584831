import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { decode } from 'he';
import ReactPlayer from 'react-player';
import { isEmpty, find } from 'lodash';
import StandardLanding from '../../../shared/components/StandardLanding';
import { getExpoList } from '../actions';
import { getRegionList } from '../../general/actions';

import expoImgPlaceholder from '../../../shared/assets/images/expo.jpg';
import exhibitorsImgPlaceholder from '../../../shared/assets/images/exhibitors.jpg';
import speakersImgPlaceholder from '../../../shared/assets/images/icon/hall/speaker.png';
import airspeed from '../../../shared/assets/images/logo/partners/airspeed.png';
import dti from '../../../shared/assets/images/logo/partners/dti.png';
import hs from '../../../shared/assets/images/logo/partners/hs.png';
import ilo from '../../../shared/assets/images/logo/partners/ilo.png';
import sm_cebu from '../../../shared/assets/images/logo/partners/sm_cebu.png';
import sm_mega from '../../../shared/assets/images/logo/partners/sm_mega.png';
import wsn from '../../../shared/assets/images/logo/partners/wsn.png';
import apdec from '../../../shared/assets/images/logo/partners/apdec.png';
import pal from '../../../shared/assets/images/logo/partners/pal.png';

function Landing(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { expo, totalExpo } = state.expo;
  const loadingExpo = state.expo.loading;
  const [featuredExpo, setFeaturedExpo] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExpoList(null, totalExpo));
    dispatch(getRegionList());
  }, []);

  useEffect(() => {
    !isEmpty(expo) && setFeaturedExpo(find(expo, 'is_featured'));

    return () => {
      setFeaturedExpo([]);
    };
  }, [expo]);

  const ContentSpace = ({
    header,
    headerLeft,
    subHeader,
    content,
    contentClassName,
  }) => {
    return (
      <div className="container m-auto content-space px-5 py-20 lg:p-20 flex flex-col">
        {header && <h1 className="content-header">{header}</h1>}
        {headerLeft && <h1 className="content-header-left">{headerLeft}</h1>}
        {!!subHeader && (
          <h2
            className="content-subheader text-center"
            style={{ color: '#1B1B1B', fontSize: '1.875rem' }}
          >
            {subHeader}
          </h2>
        )}

        <div
          className={contentClassName}
          style={{ color: '#1B1B1B', fontSize: '1.25rem' }}
        >
          {content}
        </div>
      </div>
    );
  };

  const renderDesc = (desc) => {
    if (desc) return decode(desc.replace(/<\/?[^>]+(>|$)/g, ''));
  };

  return (
    <>
      <StandardLanding>
        <div className="bg-main-expo content-bg content-crop">
          <div className="flex h-full p-3">
            {loadingExpo ? (
              <div className="expo h-full w-1/3 p-1">
                <Skeleton paragraph={{ rows: 25 }} active />
              </div>
            ) : (
              <img
                src={
                  !isEmpty(featuredExpo) &&
                    !isEmpty(featuredExpo.thumbnail) &&
                    !isEmpty(featuredExpo.thumbnail.filename)
                    ? featuredExpo.thumbnail.full_path
                    : expoImgPlaceholder
                }
                alt="Virtual Trade Expo Ph"
                className="expo h-full w-1/3 p-1"
              />
            )}
            {loadingExpo ? (
              <div className="expo h-full w-1/3 p-1">
                <Skeleton paragraph={{ rows: 25 }} active />
              </div>
            ) : (
              <img
                src={
                  !isEmpty(featuredExpo) &&
                    !isEmpty(featuredExpo.expo_banner) &
                    !isEmpty(featuredExpo.expo_banner.filename)
                    ? featuredExpo.expo_banner.full_path
                    : exhibitorsImgPlaceholder
                }
                alt="Virtual Trade Expo Ph"
                className="expo h-full w-1/3 p-1"
              />
            )}
            {loadingExpo ? (
              <div className="expo h-full w-1/3 p-1">
                <Skeleton paragraph={{ rows: 25 }} active />
              </div>
            ) : (
              <img
                src={
                  !isEmpty(featuredExpo) &&
                    !isEmpty(featuredExpo.featured_speaker) &&
                    !isEmpty(featuredExpo.featured_speaker.filename)
                    ? featuredExpo.featured_speaker.full_path
                    : speakersImgPlaceholder
                }
                alt="Virtual Trade Expo Ph"
                className="expo h-full w-1/3 p-1"
              />
            )}
          </div>
        </div>
      </StandardLanding>
      <StandardLanding>
        <div className="bg-main-content content-bg">
          <ContentSpace
            headerLeft={'FEATURED VIDEO'}
            content={
              <>
                <div className="p-3 w-full h-full">
                  <div className="player-wrapper">
                    <ReactPlayer
                      url={featuredExpo?.video_link}
                      className="react-player"
                      width="100%"
                      height="95%"
                      controls
                    />
                  </div>
                </div>
                <h1
                  className="content-header-left mt-20"
                  style={{ color: '#1B1B1B' }}
                >
                  {featuredExpo?.semminar?.title}
                </h1>
                <p>{renderDesc(featuredExpo?.semminar?.description)}</p>
              </>
            }
          />
          <ContentSpace
            headerLeft={'FEATURED MSME'}
            content={
              <>
                <div className="featured-msme-image mb-10">
                  <img
                    src={featuredExpo?.featured_exhibitor?.thumb_path}
                    alt=""
                  />
                </div>
                <div className="flex flex-col lg:flex-row w-full justify-between">
                  <div className="flex mb-10">
                    <div className="expo_thumbnail mr-5">
                      <img
                        src={
                          featuredExpo?.featured_exhibitor_thumbnail?.thumb_path
                        }
                        alt=""
                      />
                    </div>
                    <div className="exhibitor">
                      <h1
                        className="content-subheader text-center"
                        style={{
                          color: '#1B1B1B',
                          fontSize: '1.875rem',
                          margin: 0,
                        }}
                      >
                        {featuredExpo?.name}
                      </h1>
                      <p className="lg-title">
                        {featuredExpo?.featured_exhibitor?.address}
                      </p>
                    </div>
                  </div>
                  <div className="flex mb-10">
                    {featuredExpo?.featured_exhibitor?.fb_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={featuredExpo.featured_exhibitor.fb_link}
                        className="facebook mx-1 rounded-lg"
                      >
                        <i className="fa" style={{ fontSize: '2em' }}>
                          &#xf09a;
                        </i>
                        {/* <i className="fab fa-facebook fa-2x"></i> */}
                      </a>
                    )}
                    {featuredExpo?.featured_exhibitor?.twitter_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={featuredExpo.featured_exhibitor.twitter_link}
                        className="twitter mx-1 rounded-lg"
                      >
                        <i className="fa" style={{ fontSize: '2em' }}>
                          &#xf099;
                        </i>
                        {/* <i className="fab fa-twitter fa-2x"></i> */}
                      </a>
                    )}
                    {featuredExpo?.featured_exhibitor?.gmail_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={featuredExpo.featured_exhibitor.gmail_link}
                        className="gmail mx-1 rounded-lg"
                      >
                        <i className="fa" style={{ fontSize: '2em' }}>
                          &#xf1a0;
                        </i>
                        {/* <i className="fab fa-google fa-2x"></i> */}
                      </a>
                    )}
                    {featuredExpo?.featured_exhibitor?.instagram_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={featuredExpo.featured_exhibitor.instagram_link}
                        className="instagram mx-1 rounded-lg"
                      >
                        <i className="fa" style={{ fontSize: '2em' }}>
                          &#xf16d;
                        </i>
                        {/* <i className="fab fa-instagram fa-2x"></i> */}
                      </a>
                    )}
                    {featuredExpo?.featured_exhibitor?.contact_number && (
                      <div className="contact rounded-lg bg-red-20 ml-1">
                        <i className="fas fa-phone mr-1"></i>
                        <span className="lg-title" style={{ margin: 0 }}>
                          {featuredExpo.featured_exhibitor.contact_number}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <p>{renderDesc(featuredExpo?.description)}</p>
              </>
            }
          />
          <ContentSpace
            header={'PARTNERS'}
            content={
              <div className="flex flex-wrap w-full lg:w-100">
                <img
                  src={airspeed}
                  alt="Airspeed"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={dti}
                  alt="DTI Philippines"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={hs}
                  alt="Highly Succeed"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={ilo}
                  alt="International Labour Organization"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={sm_cebu}
                  alt="SM Cebu City"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={sm_mega}
                  alt="SM Mega Mall"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={wsn}
                  alt="Women Strong Network"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={apdec}
                  alt="Amazing Philippines Digital Economy Corporation"
                  className="h-44 p-3 m-auto"
                />
                <img
                  src={pal}
                  alt="Philippine Airlines"
                  className="h-44 p-3 m-auto"
                />
              </div>
            }
          />
        </div>
      </StandardLanding>
    </>
  );
}

export default Landing;
