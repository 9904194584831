import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Button, Modal, notification, Input } from 'antd';
import { isEmpty } from 'lodash';

import {
  getActivityList,
  getActivityListExhibitor,
} from '../../profile/actions';
import TableInquiry from '../components/TableInquiry';
import FormSearch from '../../general/components/FormSearch';
import FormWrapper from '../../../shared/components/FormWrapper';
import { renderDetails } from '../../../shared/utils';

function LandingInquiry() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { activity, loading, activityExhibitor } = state.profile;
  const [activities, setActivities] = useState([]);
  const [rowSelected, setRowSelected] = useState(null);
  let isExhibitor = localStorage.getItem('isExhibitor');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isExhibitor === 'true') {
      dispatch(getActivityListExhibitor(null));
    } else {
      dispatch(getActivityList(null));
    }
  }, []);

  useEffect(() => {
    if (isExhibitor === 'true') {
      !isEmpty(activityExhibitor) && setActivities(activityExhibitor);
    } else {
      !isEmpty(activity) && setActivities(activity);
    }
  }, [activity, activityExhibitor]);

  useEffect(() => {
    if (rowSelected) {
      const _exhibitor = rowSelected?.product?.data?.exhibitor?.data;
      form.setFieldsValue({
        shop_name: _exhibitor?.company_name || '',
        inquiry_date: rowSelected?.inquiry_date?.date_db || '',
        inquired_item_product: renderDetails(rowSelected?.inquiry || ''),
        shop_details: renderDetails(
          _exhibitor?.business_info?.description || ''
        ),
      });
    }
  }, [rowSelected]);

  return (
    <>
      <div className="banner bg-top bg-cover bg-no-repeat h-40 relative">
        <div className="flex justify-center items-center px-5 lg:px-20 h-full">
          <h1 className="text-white font-medium text-3xl z-10">
            VIEW INQUIRY LIST
          </h1>
        </div>
        <div className="absolute top-0 w-full h-full opac-bg">&nbsp;</div>
      </div>
      <div className="bg-main-content bg-center bg-cover bg-no-repeat">
        <div className="px-5 py-20 lg:p-20 text-blue-20">
          <h1 className="text-red-10 text-3xl mb-10 uppercase">INQUIRY LIST</h1>
          <div className="w-full">
            <TableInquiry
              activities={activities}
              activity={activity}
              loading={loading}
              rowSelected={setRowSelected}
            />
          </div>
        </div>
      </div>
      <Modal
        footer={false}
        closable={false}
        title={<h1 className="text-red-20 font-semibold px-3">INQUIRY</h1>}
        width={800}
        visible={!!rowSelected}
      >
        <FormWrapper>
          <Form name="form-profile" className="form-flex" form={form}>
            <div className="flex flex-col lg:flex-row lg:flex-wrap w-full">
              <div className="relative w-full px-3 my-1">
                <Form.Item label="Shop Name" name="shop_name">
                  <Input placeholder="Shop Name" readOnly />
                </Form.Item>
                <i className="fas fa-store text-red-20 form-icon pl-2"></i>
              </div>
              <div className="relative w-full lg:w-1/2 px-3 my-1">
                <Form.Item label="Shop Details" name="shop_details">
                  <Input placeholder="Shop Details" readOnly />
                </Form.Item>
                <i className="fas fa-shopping-bag text-red-20 form-icon pl-2"></i>
              </div>
              <div className="relative w-full lg:w-1/2 px-3 my-1">
                <Form.Item label="Inquiry Date" name="inquiry_date">
                  <Input placeholder="Inquiry Date" readOnly />
                </Form.Item>
                <i className="fas fa-calendar text-red-20 form-icon pl-2"></i>
              </div>
              <div className="relative w-full px-3 my-1">
                <Form.Item
                  label="Inquired Item/Product"
                  name="inquired_item_product"
                >
                  <Input.TextArea
                    placeholder="Inquired Item/Product"
                    readOnly
                    rows={6}
                  />
                </Form.Item>
                <i className="fas fa-info-circle text-red-20 form-icon pl-2"></i>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-right w-full lg:w-auto">
              <Button
                className="btn btn-white my-1"
                style={{ boxShadow: '0px 3px 6px #00000029' }}
                onClick={() => setRowSelected(null)}
              >
                <i className="fas fa-times-circle mr-1"></i>
                <span>Close</span>
              </Button>
            </div>
          </Form>
        </FormWrapper>
      </Modal>
    </>
  );
}

export default LandingInquiry;
